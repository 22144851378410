import React from 'react'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'

function PrivacyPolicyPage () {
  return (
    <div>
      <Navigation navigationMode={NavigationModeValues.FIXED_MAIN}/>
      <div className={'container'}>
        Coming soon
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
