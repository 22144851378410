/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewBalanceCardEditDto
 */
export interface InterviewBalanceCardEditDto {
    /**
     * Interview count
     * @type {number}
     * @memberof InterviewBalanceCardEditDto
     */
    interviewCount?: number;
    /**
     * Interviewer price
     * @type {number}
     * @memberof InterviewBalanceCardEditDto
     */
    price?: number;
    /**
     * Interviewer discount price
     * @type {number}
     * @memberof InterviewBalanceCardEditDto
     */
    discountPrice?: number;
    /**
     * Balance type
     * @type {string}
     * @memberof InterviewBalanceCardEditDto
     */
    balanceType?: InterviewBalanceCardEditDtoBalanceTypeEnum;
    /**
     * Interviewer id
     * @type {Array<string>}
     * @memberof InterviewBalanceCardEditDto
     */
    tagIds?: Array<string>;
    /**
     * Tag ID
     * @type {string}
     * @memberof InterviewBalanceCardEditDto
     */
    id: string;
}


/**
 * @export
 */
export const InterviewBalanceCardEditDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type InterviewBalanceCardEditDtoBalanceTypeEnum = typeof InterviewBalanceCardEditDtoBalanceTypeEnum[keyof typeof InterviewBalanceCardEditDtoBalanceTypeEnum];


/**
 * Check if a given object implements the InterviewBalanceCardEditDto interface.
 */
export function instanceOfInterviewBalanceCardEditDto(value: object): value is InterviewBalanceCardEditDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function InterviewBalanceCardEditDtoFromJSON(json: any): InterviewBalanceCardEditDto {
    return InterviewBalanceCardEditDtoFromJSONTyped(json, false);
}

export function InterviewBalanceCardEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewBalanceCardEditDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewCount': json['interviewCount'] == null ? undefined : json['interviewCount'],
        'price': json['price'] == null ? undefined : json['price'],
        'discountPrice': json['discountPrice'] == null ? undefined : json['discountPrice'],
        'balanceType': json['balanceType'] == null ? undefined : json['balanceType'],
        'tagIds': json['tagIds'] == null ? undefined : json['tagIds'],
        'id': json['id'],
    };
}

export function InterviewBalanceCardEditDtoToJSON(value?: InterviewBalanceCardEditDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewCount': value['interviewCount'],
        'price': value['price'],
        'discountPrice': value['discountPrice'],
        'balanceType': value['balanceType'],
        'tagIds': value['tagIds'],
        'id': value['id'],
    };
}

