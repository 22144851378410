/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBankCardDto
 */
export interface GetBankCardDto {
    /**
     * Bank card id
     * @type {string}
     * @memberof GetBankCardDto
     */
    id: string;
    /**
     * Bank card number
     * @type {string}
     * @memberof GetBankCardDto
     */
    num: string;
    /**
     * CVV code
     * @type {number}
     * @memberof GetBankCardDto
     */
    cvv: number;
    /**
     * Bank card expire date
     * @type {string}
     * @memberof GetBankCardDto
     */
    expireDate: string;
    /**
     * Bank card holder
     * @type {string}
     * @memberof GetBankCardDto
     */
    cardHolder: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetBankCardDto
     */
    _default?: boolean;
}

/**
 * Check if a given object implements the GetBankCardDto interface.
 */
export function instanceOfGetBankCardDto(value: object): value is GetBankCardDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('num' in value) || value['num'] === undefined) return false;
    if (!('cvv' in value) || value['cvv'] === undefined) return false;
    if (!('expireDate' in value) || value['expireDate'] === undefined) return false;
    if (!('cardHolder' in value) || value['cardHolder'] === undefined) return false;
    return true;
}

export function GetBankCardDtoFromJSON(json: any): GetBankCardDto {
    return GetBankCardDtoFromJSONTyped(json, false);
}

export function GetBankCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBankCardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'num': json['num'],
        'cvv': json['cvv'],
        'expireDate': json['expireDate'],
        'cardHolder': json['cardHolder'],
        '_default': json['default'] == null ? undefined : json['default'],
    };
}

export function GetBankCardDtoToJSON(value?: GetBankCardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'num': value['num'],
        'cvv': value['cvv'],
        'expireDate': value['expireDate'],
        'cardHolder': value['cardHolder'],
        'default': value['_default'],
    };
}

