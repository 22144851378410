import { BaseAPI } from '../runtime'
import * as runtime from '../runtime'
import { AuthManager } from '../../../manager/AuthManager'
import {
  type GetInterviewTypeDto,
  GetInterviewTypeDtoFromJSON,
  type InterviewQuestionInsertionResponse,
  InterviewQuestionInsertionResponseFromJSON
} from '../models'

type UploaderType = 'interview_type' | 'question_insertion'

type UploaderResponse<T extends UploaderType> =
  T extends 'interview_type' ? GetInterviewTypeDto : InterviewQuestionInsertionResponse

export class ImageUploaderControllerApi extends BaseAPI {
  async uploadImage <T extends UploaderType>(requestParameters: {
    id?: string
    type?: string
    uploader: T
    fileParamName: string
    file: File
  }, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploaderResponse<T>> {
    if (requestParameters.file == null) {
      throw new runtime.RequiredError(
        'imageFile',
        'Required parameter "imageFile" was null or undefined when calling uploadImageById().'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.id != null) {
      queryParameters.id = requestParameters.id
    }

    if (requestParameters.type != null) {
      queryParameters.type = requestParameters.type
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const formData = new FormData()
    formData.append(requestParameters.fileParamName, requestParameters.file)

    const response = await this.request({
      path: requestParameters.uploader === 'interview_type' ? '/interviewType/uploadImage' : '/interview/question/insert',
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: formData
    }, initOverrides)

    await AuthManager.validateAuthRefresh(response, this)

    const res = new runtime.JSONApiResponse(
      response, (jsonValue) => {
        if (requestParameters.uploader === 'interview_type') {
          return GetInterviewTypeDtoFromJSON(jsonValue)
        }
        return InterviewQuestionInsertionResponseFromJSON(jsonValue)
      })
    return await res.value() as UploaderResponse<T>
  }
}
