import { Avatar } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { COLORS } from '../../util/Consts'
interface DefaultPictureProps {
  name: string
  surname: string
  inverse?: boolean
  size: number
  onClick?: any
}

function DefaultProfilePicture (props: DefaultPictureProps) {
  const [avatarTxt, setAvatarTxt] = useState('')
  useEffect(() => {
    setAvatarTxt((props.name.trim()[0].toUpperCase() + props.surname.trim()[0]).toUpperCase())
  }, [props.name, props.surname])

  return (
    <>
      <Avatar
        sx = {{
          bgcolor: props.inverse ? COLORS.MAIN_COLOR : 'white',
          color: !props.inverse ? COLORS.MAIN_COLOR : 'white',
          width: props.size + 'px',
          height: props.size + 'px',
          fontSize: props.size - props.size / 1.8 + 'px',
          fontWeight: 500,
          lineHeight: 'normal',
          fontFamily: 'Inter, sans-serif',
          cursor: props.inverse ? 'pointer' : 'cursor'
        }}>
        {avatarTxt}
      </Avatar>
    </>
  )
}

export default DefaultProfilePicture
