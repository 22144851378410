/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewBalanceCardCreationDto
 */
export interface InterviewBalanceCardCreationDto {
    /**
     * Interview count
     * @type {number}
     * @memberof InterviewBalanceCardCreationDto
     */
    interviewCount: number;
    /**
     * Interviewer price
     * @type {number}
     * @memberof InterviewBalanceCardCreationDto
     */
    price: number;
    /**
     * Interviewer discount price
     * @type {number}
     * @memberof InterviewBalanceCardCreationDto
     */
    discountPrice?: number;
    /**
     * Balance type
     * @type {string}
     * @memberof InterviewBalanceCardCreationDto
     */
    balanceType: InterviewBalanceCardCreationDtoBalanceTypeEnum;
    /**
     * Interviewer id
     * @type {Array<string>}
     * @memberof InterviewBalanceCardCreationDto
     */
    tagIds?: Array<string>;
}


/**
 * @export
 */
export const InterviewBalanceCardCreationDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type InterviewBalanceCardCreationDtoBalanceTypeEnum = typeof InterviewBalanceCardCreationDtoBalanceTypeEnum[keyof typeof InterviewBalanceCardCreationDtoBalanceTypeEnum];


/**
 * Check if a given object implements the InterviewBalanceCardCreationDto interface.
 */
export function instanceOfInterviewBalanceCardCreationDto(value: object): value is InterviewBalanceCardCreationDto {
    if (!('interviewCount' in value) || value['interviewCount'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('balanceType' in value) || value['balanceType'] === undefined) return false;
    return true;
}

export function InterviewBalanceCardCreationDtoFromJSON(json: any): InterviewBalanceCardCreationDto {
    return InterviewBalanceCardCreationDtoFromJSONTyped(json, false);
}

export function InterviewBalanceCardCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewBalanceCardCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewCount': json['interviewCount'],
        'price': json['price'],
        'discountPrice': json['discountPrice'] == null ? undefined : json['discountPrice'],
        'balanceType': json['balanceType'],
        'tagIds': json['tagIds'] == null ? undefined : json['tagIds'],
    };
}

export function InterviewBalanceCardCreationDtoToJSON(value?: InterviewBalanceCardCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewCount': value['interviewCount'],
        'price': value['price'],
        'discountPrice': value['discountPrice'],
        'balanceType': value['balanceType'],
        'tagIds': value['tagIds'],
    };
}

