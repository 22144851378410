import React from 'react'
import VideoSyncComponent from '../components/VideoSyncComponent'
// import { VideoPlayer } from '../components/VideoPlayer'
// import SyncedVideoPlayer from '../components/VideosForRepositories'
export default function TestPage () {
  return (
    <div className={'m-5 p-5'}>
      {/* <VideoPlayer videoUrls={[ */}
      {/*   'https://videos.pexels.com/video-files/9945032/9945032-uhd_2560_1440_24fps.mp4', */}
      {/*   'https://www.w3schools.com/html/mov_bbb.mp4', */}
      {/*   'https://media.w3.org/2010/05/sintel/trailer.mp4' */}
      {/* ]}/> */}
      <VideoSyncComponent
        taker={[
          {
            url: 'CUSTOM',
            startTimeOffset: 0,
            stopTimeOffset: 3000
          },
          {
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            startTimeOffset: 3000,
            stopTimeOffset: 13000
          },
          {
            url: 'CUSTOM',
            startTimeOffset: 13000,
            stopTimeOffset: 17000
          },
          {
            url: 'https://videos.pexels.com/video-files/9945032/9945032-uhd_2560_1440_24fps.mp4',
            startTimeOffset: 17000,
            stopTimeOffset: 30000
          }

        ]}
        giver={[
          {
            url: 'https://www.w3schools.com/html/mov_bbb.mp4',
            startTimeOffset: 0,
            stopTimeOffset: 10000
          },
          {
            url: 'https://videos.pexels.com/video-files/9945032/9945032-uhd_2560_1440_24fps.mp4',
            startTimeOffset: 10000,
            stopTimeOffset: 23000
          },
          {
            url: 'CUSTOM',
            startTimeOffset: 23000,
            stopTimeOffset: 30000
          }
        ]}/>
    </div>
  )
}
