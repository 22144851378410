import { type CaptionProps, useNavigation } from 'react-day-picker'
import { format } from 'date-fns'
import left_calendar_arrow from '../../svg/resources/left_calendar_arrow.svg'
import right_calendar_arrow from '../../svg/resources/right_calendar_arrow.svg'
import React from 'react'

export function CustomCaption (props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const formattedMonth = format(props.displayMonth, 'MMM yyyy')
  return (
    <div className="d-flex justify-content-center align-items-center caption-class">
      <button
        onClick={() => {
          previousMonth && goToMonth(previousMonth)
        }}
        style={{ visibility: !previousMonth ? 'hidden' : 'visible' }}>
        <img src={left_calendar_arrow} alt={'left arrow'} />
      </button>
      <span style={{ fontSize: '20px', fontFamily: 'Inter,sans-serif', color: '#000', fontWeight: 300 }}>
        {formattedMonth}
      </span>
      <button
        style={{ visibility: !nextMonth ? 'hidden' : 'visible' }}
        onClick={() => {
          nextMonth && goToMonth(nextMonth)
        }}>
        <img src={right_calendar_arrow} alt={'right arrow'} />
      </button>
    </div>
  )
}
