import React, { useCallback } from 'react'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { ColorMode } from '../modes/ColorMode'
import { NavigationItemType } from '../../enumeration/NavigationItemType'
import { HEIGHT_MODES, HeightModeValues } from '../modes/HeightMode'
import ColoredText from '../text/ColoredText'
import { useNavigate } from 'react-router-dom'

interface CircleButtonWithBorderProps {
  text: string | undefined
  color?: string
  colorMode?: ColorMode
  onClick?: () => void
  route?: NavigationRoute | undefined
}

function CircleButtonWithBorder (props: CircleButtonWithBorderProps) {
  const borderColorMode = props.colorMode ?? ColorMode.LIGHT
  const color = props.color ?? (borderColorMode === ColorMode.LIGHT ? 'white' : 'black')
  const text = props.text ?? NavigationItemType.CONTACT_US
  const route = props.route ?? NavigationRoute.CONTACT_US
  const height = HEIGHT_MODES[HeightModeValues.DEFAULT].scalar
  const navigate = useNavigate()
  const handleOnclick = useCallback(() => {
    if (props.onClick) {
      props.onClick()
    }
    navigate(route)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '25px',
        border: `2px solid ${color}`,
        userSelect: 'none',
        cursor: 'pointer',
        maxWidth: '160px',
        padding: '5px 25px 5px 25px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height
      }}
      onClick={handleOnclick}>
      <ColoredText
        color={color}
        userSelect={'none'}
        cursor={'pointer'}
        lineHeight={1}
        singleText={text}
        whiteSpace={'nowrap'}
        fontWeight={500}/>
    </div>
  )
}

export default CircleButtonWithBorder
