/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import { AuthManager } from "../../../manager/AuthManager";

/**
 * 
 */
export class AppControllerApi extends runtime.BaseAPI {

    /**
     */
    async aboutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/about`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async about(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.aboutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async get1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async get1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.get1Raw(initOverrides);
        return await response.value();
    }

}
