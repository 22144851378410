import React from 'react'
import { NavigationModeValues } from '../../components/modes/NavigationMode'
import Navigation from '../../components/navigation/Navigation'
import ColoredText from '../../components/text/ColoredText'
import Line from '../../components/util/Line'
import SettingActionManager from './SettingActionManager'
import SettingAction from './SettingAction'
import creditCardIcon from '../../components/svg/resources/credit_card_settings_page.svg'
import historyIcon from '../../components/svg/resources/history_setting_page.svg'
import desktopIcon from '../../components/svg/resources/desktop_settings_page.svg'
import signOutIcon from '../../components/svg/resources/sign_out-Icon.svg'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import SettingActionBodyManager from './SettingActionBodyManager'
import SettingBody from './SettingBody'
import SettingsPaymentCards from './contents/SettingsPaymentCards'
import LoginDevices from './contents/LoginDevices'
import { AuthManager } from '../../manager/AuthManager'
import { useNavigate } from 'react-router-dom'
import ApiManager from '../../manager/ApiManager'
import { SessionControllerApi } from '../../api/ic'
import { COLORS } from '../../util/Consts'

function SettingsPage () {
  const navigate = useNavigate()
  const sessionApi = ApiManager.getInstance(SessionControllerApi)

  const layoutStyle = {
    background: '#FFFFFF',
    height: '100%',
    borderRadius: '10px',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
  }

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE}/>
      <div
        className="container"
        style={{
          marginTop: '20px',
          display: 'grid',
          gridTemplateColumns: '1fr 3fr',
          gap: '20px',
          height: '80vh',
          marginBottom: '20px'
        }}>
        <div style={{ gridColumn: '1 / 2', ...layoutStyle }}>
          <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
            <div className={'d-flex flex-column gap-3'}>
              <div className={'d-flex flex-column gap-2'}>
                <ColoredText singleText={'Settings'} fontSize={'22px'}/>
                <Line lineWidth={'100%'}/>
              </div>
              <div className={'d-flex flex-column gap-3'}>
                <SettingActionManager title={'Payment'}>
                  <SettingAction
                    title={'Cards'}
                    iconSrc={creditCardIcon}
                    navigationRoute={NavigationRoute.SETTING_PAYMENT_CARD}
                  />
                  <SettingAction
                    title={'History'}
                    iconSrc={historyIcon}
                    navigationRoute={NavigationRoute.SETTING_PAYMENT_HISTORY}
                  />
                </SettingActionManager>
                <SettingActionManager title={'Device'}>
                  <SettingAction
                    title={'Manage'}
                    iconSrc={desktopIcon}
                    navigationRoute={NavigationRoute.SETTING_DEVICE_MANAGE}
                  />
                </SettingActionManager>
              </div>
            </div>
            <div className={'d-flex flex-column gap-3'}>
              <ColoredText
                singleText={'Privacy&Policy'}
                color={COLORS.MAIN_COLOR}
                textDecoration={'underline'}
                cursor={'pointer'}
                onClick={() => {
                  window.open(NavigationRoute.PRIVACY_POLICY, '_blank', 'noopener,noreferrer')
                }}/>
              <Line lineWidth={'100%'}/>
              <SettingAction
                title={'Sign out'}
                iconSrc={signOutIcon}
                navigationRoute={NavigationRoute.LOG_IN}
                onClick={async () => {
                  await sessionApi.terminate({ uuid: AuthManager.getAccessTokenBody()?.sessionId })
                    .then(() => {
                      AuthManager.deleteTokens()
                      navigate(NavigationRoute.LOG_IN)
                    }).catch(() => {})
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ gridColumn: '2 / 3', ...layoutStyle }}>
          <SettingActionBodyManager>
            <SettingBody
              title={'Payment Cards'}
              navigationRoute={NavigationRoute.SETTING_PAYMENT_CARD}>
              <SettingsPaymentCards/>
            </SettingBody>
            <SettingBody
              title={'Payment History'}
              navigationRoute={NavigationRoute.SETTING_PAYMENT_HISTORY}>
              todo
            </SettingBody>
            <SettingBody
              title={'Manage'}
              navigationRoute={NavigationRoute.SETTING_DEVICE_MANAGE}>
              <LoginDevices/>
            </SettingBody>
          </SettingActionBodyManager>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
