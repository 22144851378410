/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewQuestionInsertionResponse
 */
export interface InterviewQuestionInsertionResponse {
    /**
     * Inserted count
     * @type {number}
     * @memberof InterviewQuestionInsertionResponse
     */
    insertedCount: number;
    /**
     * Skipped count
     * @type {number}
     * @memberof InterviewQuestionInsertionResponse
     */
    skippedCount: number;
    /**
     * Updated count
     * @type {number}
     * @memberof InterviewQuestionInsertionResponse
     */
    updatedCount: number;
}

/**
 * Check if a given object implements the InterviewQuestionInsertionResponse interface.
 */
export function instanceOfInterviewQuestionInsertionResponse(value: object): value is InterviewQuestionInsertionResponse {
    if (!('insertedCount' in value) || value['insertedCount'] === undefined) return false;
    if (!('skippedCount' in value) || value['skippedCount'] === undefined) return false;
    if (!('updatedCount' in value) || value['updatedCount'] === undefined) return false;
    return true;
}

export function InterviewQuestionInsertionResponseFromJSON(json: any): InterviewQuestionInsertionResponse {
    return InterviewQuestionInsertionResponseFromJSONTyped(json, false);
}

export function InterviewQuestionInsertionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewQuestionInsertionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'insertedCount': json['insertedCount'],
        'skippedCount': json['skippedCount'],
        'updatedCount': json['updatedCount'],
    };
}

export function InterviewQuestionInsertionResponseToJSON(value?: InterviewQuestionInsertionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'insertedCount': value['insertedCount'],
        'skippedCount': value['skippedCount'],
        'updatedCount': value['updatedCount'],
    };
}

