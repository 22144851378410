import React, { useState } from 'react'
import MarginWrapper from '../util/MarginWrapper'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import StandardInput from '../input/StandardInput'
import { InputModeValues } from '../modes/InputModes'
import { LoginPage } from '../../enumeration/LoginPage'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { addLoginUserEmail, getLoginUser } from '../../features/LoginUserSlice'
import ApiManager from '../../manager/ApiManager'
import { ForgotPasswordControllerApi, ICExceptionDtoReasonEnum, type LoginRequestDto, type ResponseError } from '../../api/ic'
import { type ReduxActionType } from '../../enumeration/ReduxActionType'

interface sendCodeProps {
  pageChangeAction: (newValue: LoginPage) => { type: ReduxActionType, payload: LoginPage }
}

function SendCode (props: sendCodeProps) {
  const loginUser: LoginRequestDto = useSelector(getLoginUser)
  const dispatch = useDispatch()
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [isMailValid, setIsMailValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const forgotPasswordApi = ApiManager.getInstance(ForgotPasswordControllerApi)

  const requestForNewPassword = async () => {
    setLoading(true)
    await forgotPasswordApi.forgot({
      forgotPasswordRequestDto: { email: loginUser.email }
    }).then(() => {
      toast.success(`Check your inbox ${loginUser.email}`)
      props.pageChangeAction(LoginPage.VERIFICATION)
      setLoading(false)
    }).catch((err: ResponseError) => {
      if (err.response.reason !== ICExceptionDtoReasonEnum.EmailWasSentRecentlyException) {
        toast.error(err.message)
      }
    })
    setLoading(false)
  }

  const handleEmailChange = (email: string) => {
    dispatch(addLoginUserEmail(email))
  }

  return (
    <>
      <div
        className={'d-flex flex-column justify-content-center align-items-center'}>
        <MarginWrapper
          bottom={'60px'}>
          <div
            style = {{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '20px'
            }}>
          </div>
        </MarginWrapper>
        <StandardInput
          sizeMode={InputModeValues.LARGE}
          label={'Please enter your email address'}
          placeHolder={'Enter your email address…'}
          inputValue={loginUser.email ?? ''}
          backgroundColor={'rgba(246, 246, 246, 1)'}
          type = {StandardInputModeValues.EMAIL}
          setInputValue={handleEmailChange}
          setTriggerValidate={setTriggerValidate}
          triggerValidate={triggerValidate}
          setIsValid={setIsMailValid}/>
        <MarginWrapper
          top={'10px'}>
          <StandardButton
            sizeMode={ButtonModeValues.EXTRA_LARGE}
            text={'Send'}
            backgroundColorMode={'dark'}
            textFontWeight={700}
            setTriggerValidate={setTriggerValidate}
            isValid={isMailValid}
            isLoading={loading}
            onClick={requestForNewPassword}/>
        </MarginWrapper>
      </div>
    </>
  )
}

export default SendCode
