import React from 'react'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { ColorMode } from '../modes/ColorMode'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface NavigationItemTextProps {
  text: string
  link: string | undefined
  colorMode?: ColorMode
}

function NavigationItemText (props: NavigationItemTextProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const colorMode = props.colorMode ?? ColorMode.LIGHT
  const navigate = useNavigate()
  return (
    <div
      style={{
        color: colorMode === ColorMode.LIGHT ? 'white' : 'rgba(0, 0, 0, 0.65)',
        userSelect: 'none'
      }}>
      <div style={{
        cursor: 'pointer',
        color: 'inherit',
        textDecoration: 'none',
        marginLeft: isMobile ? '20px' : '2vw'
      }}
        onClick={() => { navigate(props.link ? props.link : NavigationRoute.DEFAULT) }}>
        {props.text}
      </div>
    </div>
  )
}

export default NavigationItemText
