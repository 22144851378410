/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPreProductionUserDto
 */
export interface GetPreProductionUserDto {
    /**
     * Pre-production user id
     * @type {string}
     * @memberof GetPreProductionUserDto
     */
    id: string;
    /**
     * Pre-production user email
     * @type {string}
     * @memberof GetPreProductionUserDto
     */
    email: string;
    /**
     * Pre-production user role
     * @type {string}
     * @memberof GetPreProductionUserDto
     */
    role: GetPreProductionUserDtoRoleEnum;
}


/**
 * @export
 */
export const GetPreProductionUserDtoRoleEnum = {
    Giver: 'GIVER',
    Taker: 'TAKER',
    GiverTaker: 'GIVER_TAKER',
    HrRecruiter: 'HR_RECRUITER'
} as const;
export type GetPreProductionUserDtoRoleEnum = typeof GetPreProductionUserDtoRoleEnum[keyof typeof GetPreProductionUserDtoRoleEnum];


/**
 * Check if a given object implements the GetPreProductionUserDto interface.
 */
export function instanceOfGetPreProductionUserDto(value: object): value is GetPreProductionUserDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function GetPreProductionUserDtoFromJSON(json: any): GetPreProductionUserDto {
    return GetPreProductionUserDtoFromJSONTyped(json, false);
}

export function GetPreProductionUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPreProductionUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'role': json['role'],
    };
}

export function GetPreProductionUserDtoToJSON(value?: GetPreProductionUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'role': value['role'],
    };
}

