import React, { useState } from 'react'
import { COLORS, STATIC_TEXTS } from '../../util/Consts'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import MarginWrapper from '../util/MarginWrapper'
import WorkExperienceList from '../VerifyModal/VerificationFormComponents/WorkExperienceList'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import InformationBanner from './InformationBanner'
import { type WorkExperienceDto } from '../../api/ic'

interface TakerCompanyProps {
  workExperiences?: WorkExperienceDto[] | undefined
  editable?: boolean
}

function CompanySection (props: TakerCompanyProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [localCompanyList, setLocalCompanyList] = useState(props.workExperiences ?? [])
  return (
    <div
      style = {{
        border: '2px solid #D9D9D9',
        borderRadius: '10px',
        padding: isMobile ? '25px' : '25px 50px 25px 50px'
      }}>
      <>
        {!props.editable && <InformationBanner singleText={STATIC_TEXTS.READ_ONLY_SECTION_INFO}/>}
        <ColoredText
          singleText = 'Company'
          color = {COLORS.MAIN_COLOR}
          fontSize = {FontModeValues.EXTRA_LARGE}
          fontWeight={300}/>
      </>
      <div
        className= {`d-flex flex-column justify-content-${isMobile ? 'center' : 'between'} flex-wrap`}>
        <MarginWrapper
          top = '10px'>
          <WorkExperienceList
            editable={props.editable}
            type={'notModal'}
            workExperiences={localCompanyList}
            setLocalList={setLocalCompanyList}/>
        </MarginWrapper>
      </div>
    </div>
  )
}

export default CompanySection
