import React, { type MouseEventHandler, useEffect, useState } from 'react'
import AttachedCard from './AttachedCard'
import { type GetBankCardDto } from '../../api/ic'
import { type SkeletonSupported, type SkeletonSupportedInterface } from '../../util/common-types'

interface AttachedCardListProps extends SkeletonSupportedInterface {
  attachedBankCards: GetBankCardDto[]
  maxAllowedCardsCount: number
  onAddClick?: MouseEventHandler
  addedCard?: GetBankCardDto
  selectedCardIndex?: number
  setSelectedCardIndex?: React.Dispatch<React.SetStateAction<number>>
  makeDefaultAction?: boolean
  selectable: boolean
  deletable: boolean
}

function AttachedCardList (props: SkeletonSupported<AttachedCardListProps>) {
  const [updateList, setUpdateList] = useState<boolean | undefined>(undefined)
  const [sortedCards, setSortedCards] = useState<GetBankCardDto[]>()
  const [isAllowedToAdd, setIsAllowedToAdd] = useState<boolean>(false)

  useEffect(() => {
    if (props.addedCard) {
      props.attachedBankCards?.push(props.addedCard)
      setUpdateList(true)
    }
  }, [props.addedCard])

  useEffect(() => {
    if (props.attachedBankCards?.length && props.maxAllowedCardsCount) {
      setIsAllowedToAdd(props.attachedBankCards?.length < props.maxAllowedCardsCount)
    }
  }, [updateList])

  if (props.loading) {
    const skeletonCount = props.skeletonCount ?? 3
    return (<div className={'d-flex flex-column gap-3'}>
      {Array.from({ length: skeletonCount }, (_, i) => (
        <div key={i}>
          <AttachedCard loading={true}/>
        </div>
      ))}
    </div>)
  }

  const handleMakeDefaultCallback = (updatedCard: GetBankCardDto) => {
    props.attachedBankCards.forEach(card => {
      card._default = card.id === updatedCard.id
    })
    setUpdateList(true)
  }

  const deleteCallback = (updatedCard: GetBankCardDto) => {
    props.attachedBankCards.forEach((card, index) => {
      if (card.id === updatedCard.id) {
        props.attachedBankCards.splice(index, 1)
      }
    })
    if (props.attachedBankCards?.length) {
      props.attachedBankCards[0]._default = true
    }
    setUpdateList(true)
  }

  const sortCards = (cardA: GetBankCardDto) => {
    if (cardA._default) return -1
    if (!cardA._default) return 1
    return 0
  }

  useEffect(() => {
    if (updateList !== false) {
      const sortedCards = props.attachedBankCards.sort(sortCards)
      setSortedCards(sortedCards)
      setUpdateList(false)
    }
  }, [updateList])

  return (
    <div className={'d-flex flex-column gap-3'}>
      { sortedCards?.map((card, index) => (
        <AttachedCard
          card={card}
          key={index}
          index={index}
          setSelectedIndex={props.setSelectedCardIndex}
          isSelected={index === props.selectedCardIndex}
          deletable={props.deletable}
          selectable={props.selectable}
          makeDefaultAction={props.makeDefaultAction}
          makeDefaultCallback={handleMakeDefaultCallback}
          deleteCallback={deleteCallback}/>
      )) }

      <span
        style={{
          fontSize: '16px',
          color: isAllowedToAdd ? '#880CE8' : 'grey',
          cursor: 'pointer'
        }}
        onClick={(event) => {
          if (isAllowedToAdd && props.onAddClick) {
            props.onAddClick(event)
          }
        }}>
        Add+
      </span>
    </div>
  )
}

export default AttachedCardList
