import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetTimerValue, updateTimerValue } from '../../features/ResendTimerSlice'
import { SignupPage } from '../../enumeration/SignupPage'
import toast from 'react-hot-toast'
import ApiManager from '../../manager/ApiManager'
import { SignupControllerApi } from '../../api/ic'

interface ResendCodeProps {
  email: string
  pageValue: number
}

function ResendCode (props: ResendCodeProps) {
  const [isTimerActive, setIsTimerActive] = useState(false)
  const [isClickable, setIsClickable] = useState(false)

  const dispatch = useDispatch()
  const timerValue = useSelector(GetTimerValue)
  const signupApi = ApiManager.getInstance(SignupControllerApi)

  const startTimer = (value: number) => {
    setIsTimerActive(true)
    dispatch(updateTimerValue(value))
  }
  useEffect(() => {
    if (props.pageValue === SignupPage.VERIFICATION) {
      startTimer(timerValue)
    }
  }, [props.pageValue, timerValue])
  useEffect(() => {
    if (props.pageValue === SignupPage.VERIFICATION) {
      let countdown: NodeJS.Timeout
      if (isTimerActive && timerValue > 0) {
        countdown = setTimeout(() => {
          dispatch(updateTimerValue(timerValue - 1))
        }, 1000)
      } else if (timerValue === 0) {
        setIsTimerActive(false)
        setIsClickable(true)
      }

      return () => {
        clearTimeout(countdown)
      }
    }
  }, [timerValue, isTimerActive])
  const handleResendClick = () => {
    if (isClickable) {
      void signupApi.resendVerificationCode({
        signupRequestDto: { email: props.email }
      })
        .then(() => { toast.success('Email was sent') })
        .catch(() => {})
      startTimer(62)
      setIsClickable(false)
    }
  }
  return (
    <>
      {isTimerActive
        ? (<div
            style={{
              color: '#8B32D1',
              textAlign: 'center',
              fontFamily: 'Inter,sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal'
            }}>
          {`Resend Code ${timerValue}`}
        </div>)
        : (<div
            onClick={handleResendClick}
            style={{
              color: '#8B32D1',
              textAlign: 'center',
              fontFamily: 'Inter,sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              cursor: isClickable ? 'pointer' : 'default'
            }}>
          Resend Code
        </div>)}
    </>
  )
}

export default ResendCode
