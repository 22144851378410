import React, { useState } from 'react'
import EducationList from '../VerifyModal/VerificationFormComponents/EducationList'
import { COLORS, STATIC_TEXTS } from '../../util/Consts'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import MarginWrapper from '../util/MarginWrapper'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import InformationBanner from './InformationBanner'
import { type EducationDto } from '../../api/ic'

interface TakerEducationProps {
  educations: EducationDto[] | undefined
  editable?: boolean
}

function EducationSection (props: TakerEducationProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [localEducationList, setLocalEducationList] = useState(props.educations ?? [])
  const [triggerAddEducation, setTriggerAddEducation] = useState(false)
  return (
    <div
      style = {{
        border: '2px solid #D9D9D9',
        borderRadius: '10px',
        padding: isMobile ? '25px' : '25px 50px 25px 50px'
      }}>
      <>
        {!props.editable && <InformationBanner singleText={STATIC_TEXTS.READ_ONLY_SECTION_INFO}/>}
        <ColoredText
          singleText = 'Education'
          color = {COLORS.MAIN_COLOR}
          fontSize = {FontModeValues.EXTRA_LARGE}
          fontWeight={300}/>
      </>
      <div
        className= {`d-flex flex-column justify-content-${isMobile ? 'center' : 'between'} flex-wrap`}>
        <MarginWrapper top = '10px'>
          <EducationList
            editable={props.editable}
            educationList={localEducationList}
            setTriggerAddEducation={setTriggerAddEducation}
            triggerAddEducation={triggerAddEducation}
            setLocalList={setLocalEducationList}
            type={'notModal'}/>
        </MarginWrapper>
      </div>
    </div>
  )
}

export default EducationSection
