import React from 'react'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import { HEIGHT_MODES, HeightModeValues } from '../modes/HeightMode'

interface GetStartedButtonProps {
  borderRadius?: string
  width?: string
}

function CircleBorderedButton (props: GetStartedButtonProps) {
  const scrollToElement = () => {
    const element = document.getElementById('2')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const height = HEIGHT_MODES[HeightModeValues.MEDIUM].scalar
  return (
    <a
      style={{ background: 'transparent', textDecoration: 'none', userSelect: 'none' }}
      onClick={scrollToElement}>
      <div
        style={{
          display: 'flex',
          width: props.width, // TODO generic solution
          height,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: props.borderRadius ?? '30px',
          backgroundColor: '#8C0CF0',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          cursor: 'pointer'
        }}>
        <ColoredText
          cursor={'pointer'}
          whiteSpace={'nowrap'}
          texts={COLORED_TEXT_VALUES.GET_STARTED}/>
      </div>
    </a>
  )
}

export default CircleBorderedButton
