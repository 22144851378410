import React from 'react'
import ColoredText from '../text/ColoredText'
import StandardButton from '../buttons/StandardButton'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { useNavigate } from 'react-router-dom'
import { FontModeValues } from '../modes/FontMode'
import { COLORS } from '../../util/Consts'
import done_white from '../svg/resources/Done_round_white.svg'
import done_purple from '../svg/resources/Done_round_purple.svg'
import { ButtonModeValues } from '../modes/ButtonMode'
import { cardType } from '../../enumeration/cardType'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface cardProps {
  messages: string[]
  height: string
  cardType: cardType
}
function HomepageCard (props: cardProps) {
  const navigate = useNavigate()
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  return (
    <>
      <div
        style = {{
          width: !isMobile ? '450px' : '70%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '24px',
          backgroundColor: props.cardType === cardType.GIVE ? '#890CEA' : 'white',
          boxShadow: '0px 10px 4px 0px rgba(0, 0, 0, 0.25)',
          height: `calc(${props.height} + ${!isMobile ? '350px' : '400px'})`,
          padding: '30px 0 60px 0',
          gap: isMobile ? '10px' : '50px',
          position: 'relative'
        }}>
        <ColoredText
          color = {props.cardType === cardType.GIVE ? 'white' : '#890CEA'}
          singleText={props.cardType === cardType.GIVE ? 'Give' : 'Take'}
          fontWeight={700}
          centered={true}
          fontSize={FontModeValues.EPIC_LARGE}/>
        <div
          style = {{ paddingLeft: isMobile ? '20px' : '20px', lineHeight: 3 }}>
          {
            props.messages.map((el: string, idx: number) => (
              idx * 10 < 70
                ? <div
                    style = {{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    key = {idx}>
                  <img
                    src = {props.cardType === cardType.GIVE ? done_white : done_purple}
                    alt = 'done'/>
                  <ColoredText color={props.cardType === cardType.GIVE ? 'white' : '#890CEA'} singleText={el}/>
                </div>
                : null
            ))}
        </div>
        <StandardButton
          sizeMode={ButtonModeValues.LARGE}
          text = "Let's start"
          textFontSize={FontModeValues.LARGE}
          textFontWeight={600}
          style = {{
            background: props.cardType === cardType.GIVE ? 'white' : COLORS.MAIN_COLOR,
            borderRadius: '25px',
            position: 'absolute',
            bottom: '50px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          textColor={props.cardType === cardType.GIVE ? COLORS.MAIN_COLOR : 'white'}
          onClick={() => { navigate(NavigationRoute.SIGN_UP) }}
               />
      </div>
    </>
  )
}

export default HomepageCard
