/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetICUserDto,
  PersonalInformationRequestDto,
  PersonalInformationResponseDto,
} from '../models/index';
import {
    GetICUserDtoFromJSON,
    GetICUserDtoToJSON,
    PersonalInformationRequestDtoFromJSON,
    PersonalInformationRequestDtoToJSON,
    PersonalInformationResponseDtoFromJSON,
    PersonalInformationResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface GetSectionInformationRequest {
    userId?: string;
    urlSection?: boolean;
    educationSection?: boolean;
    workSection?: boolean;
    takerRejection?: boolean;
}

export interface SetPersonalInformationRequest {
    personalInformationRequestDto: PersonalInformationRequestDto;
}

/**
 * 
 */
export class ICUserControllerApi extends runtime.BaseAPI {

    /**
     * Gets personal information
     * Get personal information
     */
    async getPersonalInformationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalInformationResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/personalInformation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Gets personal information
     * Get personal information
     */
    async getPersonalInformation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalInformationResponseDto> {
        const response = await this.getPersonalInformationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets section information based on the parameters
     * Get section information
     */
    async getSectionInformationRaw(requestParameters: GetSectionInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetICUserDto>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        if (requestParameters['urlSection'] != null) {
            queryParameters['urlSection'] = requestParameters['urlSection'];
        }

        if (requestParameters['educationSection'] != null) {
            queryParameters['educationSection'] = requestParameters['educationSection'];
        }

        if (requestParameters['workSection'] != null) {
            queryParameters['workSection'] = requestParameters['workSection'];
        }

        if (requestParameters['takerRejection'] != null) {
            queryParameters['takerRejection'] = requestParameters['takerRejection'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/section/information`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetICUserDtoFromJSON(jsonValue));
    }

    /**
     * Gets section information based on the parameters
     * Get section information
     */
    async getSectionInformation(requestParameters: GetSectionInformationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetICUserDto> {
        const response = await this.getSectionInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets personal information
     * Set personal information
     */
    async setPersonalInformationRaw(requestParameters: SetPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalInformationResponseDto>> {
        if (requestParameters['personalInformationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'personalInformationRequestDto',
                'Required parameter "personalInformationRequestDto" was null or undefined when calling setPersonalInformation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/personalInformation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonalInformationRequestDtoToJSON(requestParameters['personalInformationRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalInformationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Sets personal information
     * Set personal information
     */
    async setPersonalInformation(requestParameters: SetPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalInformationResponseDto> {
        const response = await this.setPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
