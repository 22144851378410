import React, { useEffect, useState } from 'react'
import InterviewTicketCard from './InterviewTicketCard'
import { type BalanceDto } from '../../api/ic'

interface InterviewBalanceCardsProps {
  handleChange: (ticket: BalanceDto, type: 'balance') => void
  balanceCards: BalanceDto[]
}

function InterviewBalanceCards (props: InterviewBalanceCardsProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  useEffect(() => {
    props.handleChange(props.balanceCards[selectedIndex], 'balance')
  }, [selectedIndex])
  return (
    <>
      {props.balanceCards.length > 0 ? <>
        {props.balanceCards.map((card: BalanceDto, key: number) => {
          return (
            <InterviewTicketCard
              tags={card.tags}
              interviewCount={card.availableBalance ?? 0}
              key={key}
              index={key}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}/>
          )
        })}
      </>
        : <label className={'p-5'}>No Balance Card Available</label>
        }
    </>
  )
}

export default InterviewBalanceCards
