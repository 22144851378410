import { ReduxActionType } from '../enumeration/ReduxActionType'

export const GetInputValue = (state: any) => {
  return state.standardInput
}

export const standardInputReducer = (state: string = '', action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_OR_REMEMBER_INPUT_VALUE:
      return action.payload
    default:
      return state
  }
}

export const updateInputValue = (value: string) => {
  return {
    type: ReduxActionType.UPDATE_OR_REMEMBER_INPUT_VALUE,
    payload: value
  }
}
