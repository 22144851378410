/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Interview types
 * @export
 * @interface TakerInterviewTypeDto
 */
export interface TakerInterviewTypeDto {
    /**
     * 
     * @type {string}
     * @memberof TakerInterviewTypeDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TakerInterviewTypeDto
     */
    level?: TakerInterviewTypeDtoLevelEnum;
}


/**
 * @export
 */
export const TakerInterviewTypeDtoLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type TakerInterviewTypeDtoLevelEnum = typeof TakerInterviewTypeDtoLevelEnum[keyof typeof TakerInterviewTypeDtoLevelEnum];


/**
 * Check if a given object implements the TakerInterviewTypeDto interface.
 */
export function instanceOfTakerInterviewTypeDto(value: object): value is TakerInterviewTypeDto {
    return true;
}

export function TakerInterviewTypeDtoFromJSON(json: any): TakerInterviewTypeDto {
    return TakerInterviewTypeDtoFromJSONTyped(json, false);
}

export function TakerInterviewTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakerInterviewTypeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'level': json['level'] == null ? undefined : json['level'],
    };
}

export function TakerInterviewTypeDtoToJSON(value?: TakerInterviewTypeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'level': value['level'],
    };
}

