import React from 'react'
import interviewCenter from '../svg/resources/interview_black.svg'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
// import instagram_black from '../svg/resources/instagram_black.svg'
// import whatsapp_black from '../svg/resources/whatsapp_black.svg'
// import linkedin_black from '../svg/resources/linkedin_black.svg'
// import { useNavigate } from 'react-router-dom'
// const aboutRouter: Record<string, string> = {
//   Upcoming: '/homepage/upcoming',
//   Explore: '/homepage',
//   History: '/homepage/history',
//   Payment: '/homepage/payment',
//   Plans: '/homepage/plan',
//   Profile: '/profile',
//   'Contact us': '/contactus'
// }
// const menuItems = Object.entries(aboutRouter).map(([label, href]) => ({
//   label,
//   href
// }))
// const textStyle: CSSProperties = {
//   color: 'rgba(0, 0, 0, 0.85)',
//   textAlign: 'center',
//   fontFamily: 'Inter',
//   fontSize: '16px',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   lineHeight: 'normal',
//   textDecoration: 'none',
//   marginTop: '60px'
// }
function AboutFooter () {
  // const navigate = useNavigate()
  // INFO !!!!!
  // IF YOU WANT TO SEE FOOTER UNCOMMENT THE CODE
  // CHANGE 55 LINE SPACE-BETWEEN TO SPACE-EVENLY
  // DELETE PADDINGS FROM FOOTER SVG AND TEXT
  // DON'T DELETE THIS TO HAVE EXAMPLE FOR FOOTER !!!
  return (
    <div style={{
      width: '100%',
      height: '100px',
      borderRadius: '5px',
      background: '#F8F8F8',
      boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.25)',
      position: 'relative'
    }}>
      {/* <div className={'d-flex flex-row justify-content-evenly align-items-center'}> */}
      {/*  {menuItems.map((item, index) => ( */}
      {/*    <a style={ textStyle } key={index} */}
      {/*      onClick={() => { navigate(item.href) }}> */}
      {/*      {item.label} */}
      {/*    </a> */}
      {/*  ))} */}
      {/* </div> */}
      <div style={{ position: 'absolute', bottom: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <img style={{ paddingLeft: '50px' }} src={interviewCenter}
          alt='interviewCenter'/>
        {/* <span style={{ ...textStyle, cursor: 'pointer', marginTop: '0px' }} onClick={() => { navigate('/privacy') }}>Privacy Policy</span> */}
        {/* <span style={{ ...textStyle, cursor: 'pointer', marginTop: '0px' }} onClick={() => { navigate('/help') }}>FAQ</span> */}
        {/* <div */}
        {/*  style={{ */}
        {/*    display: 'flex', */}
        {/*    flexDirection: 'row', */}
        {/*    gap: '7px', */}
        {/*    marginTop: '5px', */}
        {/*    userSelect: 'none' */}
        {/*  }}> */}
        {/*  <span style={{ cursor: 'pointer' }}> */}
        {/*    <img */}
        {/*      src = {instagram_black} */}
        {/*      alt = 'insta' */}
        {/*      onClick={() => { alert('instagram link') }} */}
        {/*      draggable={false}/> */}
        {/*  </span> */}
        {/*  <span style={{ cursor: 'pointer' }}> */}
        {/*    <img */}
        {/*      src = {whatsapp_black} */}
        {/*      alt = 'whatsapp' */}
        {/*      onClick={() => { alert('whatsapp link') }} */}
        {/*      draggable={false}/> */}
        {/*  </span> */}
        {/*  <span style={{ cursor: 'pointer' }}> */}
        {/*    <img */}
        {/*      src = {linkedin_black} */}
        {/*      alt = 'linkedin' */}
        {/*      onClick={() => { alert('linkedin link') }} */}
        {/*      draggable={false}/> */}
        {/*  </span> */}
        {/* </div> */}
        <span style={{ paddingRight: '50px' }}>
          <ColoredText
            singleText='©2023 Interview Center'
            fontSize={FontModeValues.DEFAULT}/>
        </span>
      </div>

    </div>
  )
}

export default AboutFooter
