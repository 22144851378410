import React, { useEffect, useState } from 'react'
import { InputModeValues } from '../../modes/InputModes'
import EnumValueDropDown from '../../dropdown/EnumValueDropDown'
import { findObjectFromKey } from '../../../util/Helper'
import { type EnumTypeDto, type GetInterviewDto, type GetInterviewDtoLevelEnum, type TakerInterviewTypeDto } from '../../../api/ic'

interface verificationUrlProps {
  idx: number
  obj: GetInterviewDto | TakerInterviewTypeDto
  setLocalList: React.Dispatch<React.SetStateAction<GetInterviewDto[] | TakerInterviewTypeDto[]>>
  localList: GetInterviewDto[] | TakerInterviewTypeDto[]
  triggerValidate?: boolean
  editable?: boolean
  levelValues?: EnumTypeDto[]
  typeValues?: EnumTypeDto[]

}

const alreadySelectedOptions = (
  typeValues: EnumTypeDto[],
  localList: GetInterviewDto[] | TakerInterviewTypeDto[]
): Array<string | number | undefined> => {
  const filteredTypeValues: Array<string | number | undefined> = []
  const localListValues = localList.map(obj => obj.type)
  typeValues.forEach(enumTypeDTO => {
    if (localListValues.includes(enumTypeDTO.key)) {
      filteredTypeValues.push(enumTypeDTO.name)
    }
  })
  return filteredTypeValues
}

function LanguageVerification (props: verificationUrlProps) {
  const [type, setType] = useState(findObjectFromKey(props.typeValues ?? [], { key: props.obj.type?.valueOf() }))
  const [level, setLevel] = useState(findObjectFromKey(props.levelValues ?? [], { key: props.obj.level?.valueOf() }))
  useEffect(() => {
    setType(findObjectFromKey(props.typeValues ?? [], { key: props.obj.type?.valueOf() }))
    setLevel(findObjectFromKey(props.levelValues ?? [], { key: props.obj.level?.valueOf() }))
  }, [props.obj, props.localList])

  useEffect(() => {
    const newObj: Partial<GetInterviewDto> = {
      type: type?.key ?? undefined,
      level: level?.key as GetInterviewDtoLevelEnum ?? undefined
    }
    props.setLocalList((prev: GetInterviewDto[] | TakerInterviewTypeDto[]) => {
      const newList = [...prev]
      newList[props.idx] = newObj
      return newList
    })
  }, [type, level])
  return (
    <>
      <div className={'d-flex flex-row mb-4'} style={{ gap: '10px' }}>
        <div style={{ width: '25%' }}>
          <EnumValueDropDown
            alreadySelectedOptions={alreadySelectedOptions(props.typeValues ?? [], props.localList)}
            showOptionWhenDisabled={!props.editable}
            disabled={!props.editable}
            placeholder={'Select'}
            items={props.typeValues ?? []}
            selectedValue={type}
            setSelectedValue={setType}
            label={'Name'}
            isRequired={true}
            sizeMode={InputModeValues.MAX_WIDTH_2}/>
        </div>
        <div style={{ width: '70%' }}>
          <EnumValueDropDown
            showOptionWhenDisabled={!props.editable}
            isRequired={true}
            disabled={!props.editable}
            placeholder={'Select'}
            items={props.levelValues ?? []}
            selectedValue={level}
            setSelectedValue={setLevel}
            label={'Level'}
            sizeMode={InputModeValues.MAX_WIDTH_2}/>
        </div>
      </div>

    </>
  )
}
export default LanguageVerification
