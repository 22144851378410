import React from 'react'
import { Modal, type SxProps, type Theme } from '@mui/material'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import ColoredText from '../text/ColoredText'
import { COLORS } from '../../util/Consts'
import { type FontModeValues } from '../modes/FontMode'
import close_icon from '../svg/resources/close_x_icon.svg'

interface StandardModalProps {
  isOpen: boolean
  closeModal?: React.Dispatch<React.SetStateAction<boolean>>
  closeWhenClickingOutOfModal?: boolean
  children: React.ReactNode
  headerComponent?: React.ReactNode
  footerComponent?: React.ReactNode
  underHeaderFixedComponent?: React.ReactNode
  title?: string
  titleFontSize?: string | FontModeValues
  needDividers?: boolean
  titleColor?: string
  titleFontWeight?: number
  needTitle?: boolean
  closeX?: boolean
  sx?: SxProps<Theme> | undefined
  width?: string
  height?: string
  footerButtonText?: string
  footerHeight?: string
  overflow?: string
  bodyPadding?: string
  justifyBody?: string
  disableUnderHeaderFixedComponent?: boolean
}

const StandardModal = (props: StandardModalProps) => {
  const closeWhenClickingOutOfModal = props.closeWhenClickingOutOfModal ?? false
  const overflow = props.overflow ?? 'scroll'
  const needDividers = props.needDividers === undefined ? true : props.needDividers
  const justifyBody = props.justifyBody ?? 'space-evenly'
  const needTitle = props.needTitle === undefined ? true : props.needTitle

  const handleClosingModal = () => {
    if (props.closeModal) {
      props.closeModal(false)
    }
  }
  return (
    <>
      <Modal open = {props.isOpen} onClose = {closeWhenClickingOutOfModal ? handleClosingModal : () => {}}
        disableAutoFocus={true} disableScrollLock={false}
        disableEscapeKeyDown={true} disableEnforceFocus={true}
        disableRestoreFocus={true} sx={props.sx}>
        <div
          style={{
            width: props.width,
            height: props.height,
            outline: 'none',
            position: 'fixed',
            // START CENTERING DIV
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // FINISH CENTERING DIV
            background: 'white',
            padding: '10px 30px 10px 30px',
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: '100%',
            maxHeight: '100%'
          }}>
          {/* HEADER */}
          <div className={'d-flex flex-column'}>
            <div
              className={'d-flex flex-row justify-content-between align-items-center'}
              style={{
                borderBottom: needDividers ? '2px solid #D9D9D9' : ''
              }}>
              <div
                style = {{
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'relative'
                }}>
                {needTitle &&
                  (props.title
                    ? <ColoredText singleText={props.title} color={props.titleColor ?? COLORS.MAIN_COLOR}
                        fontSize={props.titleFontSize ?? '16px'} fontWeight={props.titleFontWeight ?? 400} />
                    : props.headerComponent
                      ? props.headerComponent
                      : <ColoredText singleText={'Title'} color={COLORS.MAIN_COLOR}
                          fontSize={'16px'}/>)
                }
              </div>
              {props.closeX && <div style={{ cursor: 'pointer' }} onClick={handleClosingModal}>
                <img src={close_icon} alt={'close'}/>
              </div>}
            </div>
            {(props.underHeaderFixedComponent && !props.disableUnderHeaderFixedComponent) && (
              <div className={'mt-4'} style={{ height: '35px' }}>{props.underHeaderFixedComponent}</div>
            )}
          </div>
          {/* END HEADER */}
          {/* BODY */}
          <div style={{
            width: '100%',
            height: '100%',
            padding: props.bodyPadding ?? '10px 30px 10px 30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: justifyBody,
            position: 'relative',
            overflow,
            flexWrap: 'wrap'
          }}>
            {props.children}
          </div>
          {/* END BODY */}

          {/* FOOTER */}
          <div
            style = {{
              height: props.footerHeight ?? '70px',
              width: '100%',
              borderTop: needDividers ? '2px solid #D9D9D9' : '',
              padding: '10px 0px 0px 0px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            {props.footerComponent
              ? props.footerComponent
              : <>
                <div></div>
                <StandardButton onClick={handleClosingModal}
                  sizeMode={ButtonModeValues.SMALL}
                  text={props.footerButtonText ?? 'Great!'}/>
              </>}
          </div>
          {/* END FOOTER */}
        </div>
      </Modal>
    </>
  )
}

export default StandardModal
