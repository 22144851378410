import React, { useEffect, useState } from 'react'
import StandardList from '../giver/StandardList'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import { WidthModeValues } from '../components/modes/WidthMode'
import MarginWrapper from '../components/util/MarginWrapper'
import { useNavigate } from 'react-router-dom'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import ApiManager from '../manager/ApiManager'
import { type GetICUserDto, TakerControllerApi } from '../api/ic'

interface AllTakersListProps {
  mode: 'requested' | 'rejected' | 'all'
}

function AllTakersListPage (props: AllTakersListProps) {
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)
  const [rows, setRows] = useState<GetICUserDto[]>([])
  const navigate = useNavigate()
  const requestedColNames = [
    'checkbox',
    'id',
    'fullName',
    'email',
    'country',
    'gender',
    'preferredLanguages']

  const rejectedColNames = [
    'checkbox',
    'id',
    'reason',
    'fullName',
    'email',
    'country',
    'gender',
    'preferredLanguages']

  const takerApi = ApiManager.getInstance(TakerControllerApi)

  useEffect(() => {
    const callback = (res: GetICUserDto[]) => {
      setRows(res)
    }

    if (props.mode === 'rejected') {
      void takerApi.getRejectedTakers().then(callback).catch(() => {})
    } else if (props.mode === 'requested') {
      void takerApi.getRequestedTakers().then(callback).catch(() => {})
    } else if (props.mode === 'all') {
      void takerApi.getAllTakers().then(callback).catch(() => {})
    }
  }, [])

  const handleSelect = (id: string) => {
    setCurrentId(id)
  }

  const seeDetails = () => {
    if (!currentId) {
      console.log('currentId is null')
      return
    }
    navigate(NavigationRoute.ADMIN_TAKER_DETAILS.replace('/:id', `/${String(currentId)}`))
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={
        props.mode === 'rejected'
          ? 'All Rejected Takers'
          : props.mode === 'requested'
            ? 'All Takers Requests'
            : 'All takers'
      } />
      <StandardList
        rows={rows}
        withArrow={true}
        handleCheckbox={handleSelect}
        SetIsUpcomingBlank={() => {
        }}
        columnNames={props.mode === 'rejected' ? rejectedColNames : requestedColNames}
        deleteButton={false}/>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row'}>
          {rows.length
            ? <MarginWrapper left={'20px'}>
              <StandardButton
                clickable={!!currentId}
                text={'See Details'}
                onClick={seeDetails}
                widthMode={WidthModeValues.EXTRA_SMALL}/>
            </MarginWrapper>
            : 'No Takers'}
        </div>
      </MarginWrapper>
    </div>
  )
}

export default AllTakersListPage
