import React, { type CSSProperties } from 'react'
import ColoredText from './ColoredText'
import SVGHandler from '../svg/SVGHandler'
import left_arrow from '../svg/resources/left_arrow.svg'
import { useDispatch } from 'react-redux'
import { updatePageValue } from '../../features/PageValueSlice'

interface TextWithLeftArrowProps {
  is_not_moved: boolean
  page_to_be_moved?: any
  is_loading: boolean
  text?: string
  fontsize?: string
  onClick?: any
}

function TextWithLeftArrow (props: TextWithLeftArrowProps) {
  const dispatch = useDispatch()
  const handlePageValueChange = () => {
    if (props.onClick) {
      props.onClick()
    }
    if (props.page_to_be_moved || props.page_to_be_moved === 0) {
      dispatch(updatePageValue(props.page_to_be_moved))
    }
  }
  const containerStyles: CSSProperties = {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  const textStyles: CSSProperties = {
    transform: props.is_not_moved ? 'translateX(0)' : 'translateX(15px)', // Adjusted translateX value
    transition: 'transform 0.5s ease', // Faster transition time
    marginRight: '15px', // Adjusted margin
    marginLeft: props.is_not_moved ? '0px' : '15px' // Adjusted margin
  }
  const arrowContainerStyles: CSSProperties = {
    opacity: props.is_not_moved ? 0 : 1,
    transform: props.is_not_moved ? 'translateX(-100px)' : 'translateX(0px)', // Adjusted translateX value
    visibility: props.is_not_moved ? 'hidden' : 'visible',
    transition: 'opacity 0.2s ease 0.2s, visibility 0.3s ease, transform 0.5s ease', // Faster transition time
    pointerEvents: props.is_not_moved ? 'none' : 'auto',
    cursor: 'pointer'
  }
  return (
    <div
      style={containerStyles}>
      <div
        style={arrowContainerStyles}>
        {/* TODO Strange use of button */}
        <button
          disabled={props.is_loading}
          onClick={handlePageValueChange}
          style={{
            width: 'fit-content',
            userSelect: 'none',
            textDecoration: 'none',
            border: 'none',
            outline: 'none',
            background: 'transparent'
          }}>
          <SVGHandler svg={left_arrow} inner_style={{ opacity: props.is_loading ? 0.5 : 1 }}/>
        </button>
      </div>
      <span
        style={textStyles}>
        <ColoredText
          userSelect={'none'}
          whiteSpace={'nowrap'}
          texts={ [
            {
              value: props.text ?? 'Sign up',
              color: '#7000C7',
              fontSize: props.fontsize ?? '70px',
              fontWeight: 700
            }
          ]}/>
      </span>
    </div>
  )
}

export default TextWithLeftArrow
