import React from 'react'
import MarginWrapper from '../util/MarginWrapper'
import ColoredText from '../text/ColoredText'
import left_arrow from '../svg/resources/left_arrow.svg'
import SVGHandler from '../svg/SVGHandler'
import { useNavigate } from 'react-router-dom'

interface AdminHeaderProps {
  title: string
}

function AdminHeader (props: AdminHeaderProps) {
  const navigate = useNavigate()
  return (
    <div>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row justify-content-center align-items-center'}
          onClick={() => { navigate(-1) }}>
          <SVGHandler
            static={true}
            svg={left_arrow}
            outer_style={{ marginRight: '10px', marginBottom: '5px' }}/>
          <ColoredText singleText={props.title} fontSize={'21px'}/>
        </div>
      </MarginWrapper>
    </div>
  )
}

export default AdminHeader
