/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewQuestionDto } from './GetInterviewQuestionDto';
import {
    GetInterviewQuestionDtoFromJSON,
    GetInterviewQuestionDtoFromJSONTyped,
    GetInterviewQuestionDtoToJSON,
} from './GetInterviewQuestionDto';

/**
 * Sections
 * @export
 * @interface GetInterviewTemplateSection
 */
export interface GetInterviewTemplateSection {
    /**
     * Section label
     * @type {string}
     * @memberof GetInterviewTemplateSection
     */
    label: string;
    /**
     * Section type
     * @type {string}
     * @memberof GetInterviewTemplateSection
     */
    type: string;
    /**
     * Questions
     * @type {Array<GetInterviewQuestionDto>}
     * @memberof GetInterviewTemplateSection
     */
    questions: Array<GetInterviewQuestionDto>;
}

/**
 * Check if a given object implements the GetInterviewTemplateSection interface.
 */
export function instanceOfGetInterviewTemplateSection(value: object): value is GetInterviewTemplateSection {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('questions' in value) || value['questions'] === undefined) return false;
    return true;
}

export function GetInterviewTemplateSectionFromJSON(json: any): GetInterviewTemplateSection {
    return GetInterviewTemplateSectionFromJSONTyped(json, false);
}

export function GetInterviewTemplateSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewTemplateSection {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'type': json['type'],
        'questions': ((json['questions'] as Array<any>).map(GetInterviewQuestionDtoFromJSON)),
    };
}

export function GetInterviewTemplateSectionToJSON(value?: GetInterviewTemplateSection | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
        'type': value['type'],
        'questions': ((value['questions'] as Array<any>).map(GetInterviewQuestionDtoToJSON)),
    };
}

