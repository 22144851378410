import React, { useLayoutEffect, useState } from 'react'
import 'react-day-picker/dist/style.css'
import { DayPicker, type Matcher } from 'react-day-picker'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import { availableDaysStyle, calendarStyles } from './calendar_styles/CustomCalendarStyles'
import { CustomCaption } from './calendar_styles/CalendarCustomCaption'
import { useDispatch, useSelector } from 'react-redux'
import { addTakerInterviewDate, getTakerInterviewTimesByDates } from '../../features/TakerInfoSlice'
import { updateGiverFlipperValue } from '../../features/GiverCalendarFlipperSlice'

interface TakerVerificationCalendarProps {
  availableDaysSinceToday?: number
  setDate: React.Dispatch<React.SetStateAction<Date>>
  setIsFlipped?: React.Dispatch<React.SetStateAction<boolean>>
}
export default function TakerVerificationCalendar (props: TakerVerificationCalendarProps) {
  const dispatch = useDispatch()
  const today = new Date()
  const lastDay = new Date(today)
  lastDay.setDate(today.getDate() + 10)
  const interviewTimesByDates = useSelector(getTakerInterviewTimesByDates)
  const [days, setDays] = useState<Date[] | undefined>([])

  useLayoutEffect(() => {
    if (interviewTimesByDates) {
      const selectedDaysWithNotEmptyTimes = interviewTimesByDates.filter(day => day.times.length > 0)
      const datesOnly = selectedDaysWithNotEmptyTimes.map(day => day.date)
      setDays(datesOnly)
    }
  }, [interviewTimesByDates])
  const availableDays = (startDate: Date, endDate: Date): Matcher | Matcher[] => {
    const result: Date[] = []
    const currentDate = new Date(startDate)
    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentDate <= endDate) {
      result.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return result
  }

  function handleOnDayClick (date: Date) {
    props.setDate(date)
    dispatch(addTakerInterviewDate(date))
    if (props.setIsFlipped) {
      props.setIsFlipped(true)
    } else {
      dispatch(updateGiverFlipperValue(true))
    }
  }

  return (
    <div className={'d-flex flex-column align-items-center'}>
      <style>{calendarStyles}</style>
      <span style={{ position: 'relative' }}>
        <ColoredText singleText={'Select a Day'}
          fontSize={FontModeValues.AVERAGE}
          fontWeight={400}/>
      </span>
      <div className={'d-flex justify-content-center align-items-center'}>
        <DayPicker
          showOutsideDays={false}
          weekStartsOn={1}
          fromDate={today}
          toDate={lastDay}
          selected={days}
          onDayClick={handleOnDayClick}
          mode="multiple"
          min={0}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today'
          }}
          components={{ Caption: CustomCaption }}
          modifiers={{ booked: availableDays(today, lastDay) }}
          modifiersStyles={{ booked: availableDaysStyle }}/>
      </div>
    </div>
  )
}
