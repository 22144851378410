/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginRequestDto,
  RefreshableAuthenticationResponseDto,
} from '../models/index';
import {
    LoginRequestDtoFromJSON,
    LoginRequestDtoToJSON,
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface LoginRequest {
    loginRequestDto: LoginRequestDto;
}

/**
 * 
 */
export class LoginControllerApi extends runtime.BaseAPI {

    /**
     * Retrieves refresh and access tokens
     * Request for login
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['loginRequestDto'] == null) {
            throw new runtime.RequiredError(
                'loginRequestDto',
                'Required parameter "loginRequestDto" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestDtoToJSON(requestParameters['loginRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves refresh and access tokens
     * Request for login
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
