/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcceptRequestDto
 */
export interface AcceptRequestDto {
    /**
     * Interview type
     * @type {string}
     * @memberof AcceptRequestDto
     */
    type: string;
    /**
     * Interview date
     * @type {Date}
     * @memberof AcceptRequestDto
     */
    acceptedDate: Date;
}

/**
 * Check if a given object implements the AcceptRequestDto interface.
 */
export function instanceOfAcceptRequestDto(value: object): value is AcceptRequestDto {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('acceptedDate' in value) || value['acceptedDate'] === undefined) return false;
    return true;
}

export function AcceptRequestDtoFromJSON(json: any): AcceptRequestDto {
    return AcceptRequestDtoFromJSONTyped(json, false);
}

export function AcceptRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'acceptedDate': (new Date(json['acceptedDate'])),
    };
}

export function AcceptRequestDtoToJSON(value?: AcceptRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'acceptedDate': ((value['acceptedDate']).toISOString()),
    };
}

