/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetICUserDto } from './GetICUserDto';
import {
    GetICUserDtoFromJSON,
    GetICUserDtoFromJSONTyped,
    GetICUserDtoToJSON,
} from './GetICUserDto';

/**
 * 
 * @export
 * @interface GetLatestUpcomingInterviewDto
 */
export interface GetLatestUpcomingInterviewDto {
    /**
     * Interview id
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    id: string;
    /**
     * Accepted language
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    acceptedLanguage: GetLatestUpcomingInterviewDtoAcceptedLanguageEnum;
    /**
     * Interview level
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    level: GetLatestUpcomingInterviewDtoLevelEnum;
    /**
     * Interview type
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    type: string;
    /**
     * Interview type name
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    name: string;
    /**
     * Interview type description
     * @type {string}
     * @memberof GetLatestUpcomingInterviewDto
     */
    description: string;
    /**
     * Accepted date
     * @type {Date}
     * @memberof GetLatestUpcomingInterviewDto
     */
    acceptedDate: Date;
    /**
     * 
     * @type {GetICUserDto}
     * @memberof GetLatestUpcomingInterviewDto
     */
    giver: GetICUserDto;
    /**
     * 
     * @type {GetICUserDto}
     * @memberof GetLatestUpcomingInterviewDto
     */
    taker: GetICUserDto;
}


/**
 * @export
 */
export const GetLatestUpcomingInterviewDtoAcceptedLanguageEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type GetLatestUpcomingInterviewDtoAcceptedLanguageEnum = typeof GetLatestUpcomingInterviewDtoAcceptedLanguageEnum[keyof typeof GetLatestUpcomingInterviewDtoAcceptedLanguageEnum];

/**
 * @export
 */
export const GetLatestUpcomingInterviewDtoLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type GetLatestUpcomingInterviewDtoLevelEnum = typeof GetLatestUpcomingInterviewDtoLevelEnum[keyof typeof GetLatestUpcomingInterviewDtoLevelEnum];


/**
 * Check if a given object implements the GetLatestUpcomingInterviewDto interface.
 */
export function instanceOfGetLatestUpcomingInterviewDto(value: object): value is GetLatestUpcomingInterviewDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('acceptedLanguage' in value) || value['acceptedLanguage'] === undefined) return false;
    if (!('level' in value) || value['level'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('acceptedDate' in value) || value['acceptedDate'] === undefined) return false;
    if (!('giver' in value) || value['giver'] === undefined) return false;
    if (!('taker' in value) || value['taker'] === undefined) return false;
    return true;
}

export function GetLatestUpcomingInterviewDtoFromJSON(json: any): GetLatestUpcomingInterviewDto {
    return GetLatestUpcomingInterviewDtoFromJSONTyped(json, false);
}

export function GetLatestUpcomingInterviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLatestUpcomingInterviewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'acceptedLanguage': json['acceptedLanguage'],
        'level': json['level'],
        'type': json['type'],
        'name': json['name'],
        'description': json['description'],
        'acceptedDate': (new Date(json['acceptedDate'])),
        'giver': GetICUserDtoFromJSON(json['giver']),
        'taker': GetICUserDtoFromJSON(json['taker']),
    };
}

export function GetLatestUpcomingInterviewDtoToJSON(value?: GetLatestUpcomingInterviewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'acceptedLanguage': value['acceptedLanguage'],
        'level': value['level'],
        'type': value['type'],
        'name': value['name'],
        'description': value['description'],
        'acceptedDate': ((value['acceptedDate']).toISOString()),
        'giver': GetICUserDtoToJSON(value['giver']),
        'taker': GetICUserDtoToJSON(value['taker']),
    };
}

