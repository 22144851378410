import { ReduxActionType } from '../enumeration/ReduxActionType'

export const GetGiverFlipperValue = (state: any) => {
  return state.isGiverCalendarFlipped
}

export const giverFlipperValueReducer = (state: boolean = false, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_GIVER_FLIPPER_VALUE:
      return action.payload
    default:
      return state
  }
}

export const updateGiverFlipperValue = (value: boolean) => {
  return {
    type: ReduxActionType.UPDATE_GIVER_FLIPPER_VALUE,
    payload: value
  }
}
