export const enum StandardInputModeValues {
  TEXT_AREA = 'TEXT_AREA',
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  OTP = 'OTP',
  ONLYREAD = 'ONLYREAD',
  COUNTRY = 'COUNTRY',
  DATE = 'DATE'
}

export const StandardInputModes: Record<StandardInputModeValues, any> = {
  [StandardInputModeValues.TEXT_AREA]: {
    type: 'text_area',
    validation: /^.{1,550}$/
  },
  [StandardInputModeValues.TEXT]: {
    type: 'text',
    validation: /^.{1,200}$/
  },
  [StandardInputModeValues.PASSWORD]: {
    type: 'password',
    validation: /^.*$/
  },
  [StandardInputModeValues.EMAIL]: {
    type: 'email',
    validation: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  },
  [StandardInputModeValues.FIRST_NAME]: {
    type: 'firstname',
    validation: /^[A-Za-z]+(?:[-\s'][A-Za-z]+)*$/
  },
  [StandardInputModeValues.LAST_NAME]: {
    type: 'lastname',
    validation: /^[A-Za-z]+(?:[-\s'][A-Za-z]+)*$/
  },
  [StandardInputModeValues.OTP]: {
    type: 'otp',
    validation: /^.*$/
  },
  [StandardInputModeValues.COUNTRY]: {
    type: 'text',
    validation: /^.{1,60}$/
  },
  [StandardInputModeValues.ONLYREAD]: {
    type: 'text',
    validation: /^.*$/
  },
  [StandardInputModeValues.DATE]: {
    type: 'date',
    validation: /\d{4}-\d{2}-\d{2}/
  }
}
