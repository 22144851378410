/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptRequestDto,
  GetInterviewDto,
  GetLatestUpcomingInterviewDto,
  InterviewCancellationDto,
  InterviewCreationDto,
  InterviewWithBusinessDto,
  InterviewsWithBusinessDto,
  RoomIdDto,
  StringWrapperResponseDto,
} from '../models/index';
import {
    AcceptRequestDtoFromJSON,
    AcceptRequestDtoToJSON,
    GetInterviewDtoFromJSON,
    GetInterviewDtoToJSON,
    GetLatestUpcomingInterviewDtoFromJSON,
    GetLatestUpcomingInterviewDtoToJSON,
    InterviewCancellationDtoFromJSON,
    InterviewCancellationDtoToJSON,
    InterviewCreationDtoFromJSON,
    InterviewCreationDtoToJSON,
    InterviewWithBusinessDtoFromJSON,
    InterviewWithBusinessDtoToJSON,
    InterviewsWithBusinessDtoFromJSON,
    InterviewsWithBusinessDtoToJSON,
    RoomIdDtoFromJSON,
    RoomIdDtoToJSON,
    StringWrapperResponseDtoFromJSON,
    StringWrapperResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface AcceptInterviewRequest {
    acceptRequestDto: AcceptRequestDto;
}

export interface CancelInterviewRequest {
    interviewCancellationDto: InterviewCancellationDto;
}

export interface CreateInterviewRequest {
    interviewCreationDto: InterviewCreationDto;
}

export interface GetInterviewByIDRequest {
    id: string;
}

export interface GetInterviewByTypeRequest {
    type: string;
}

export interface GetInterviewRoomByIdRequest {
    id: string;
}

export interface GetInterviewsRequest {
    type: string;
}

export interface GetSignedInterviewByIdRequest {
    id: string;
}

export interface GetWithBusinessRequest {
    type: string;
}

/**
 * 
 */
export class InterviewControllerApi extends runtime.BaseAPI {

    /**
     * Accepts an interview
     * Accept interview
     */
    async acceptInterviewRaw(requestParameters: AcceptInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['acceptRequestDto'] == null) {
            throw new runtime.RequiredError(
                'acceptRequestDto',
                'Required parameter "acceptRequestDto" was null or undefined when calling acceptInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/accept`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptRequestDtoToJSON(requestParameters['acceptRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Accepts an interview
     * Accept interview
     */
    async acceptInterview(requestParameters: AcceptInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.acceptInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancels an interview
     * Cancel interview
     */
    async cancelInterviewRaw(requestParameters: CancelInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewCancellationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewCancellationDto',
                'Required parameter "interviewCancellationDto" was null or undefined when calling cancelInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewCancellationDtoToJSON(requestParameters['interviewCancellationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Cancels an interview
     * Cancel interview
     */
    async cancelInterview(requestParameters: CancelInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.cancelInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInterviewRaw(requestParameters: CreateInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['interviewCreationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewCreationDto',
                'Required parameter "interviewCreationDto" was null or undefined when calling createInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewCreationDtoToJSON(requestParameters['interviewCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview
     * Create interview
     */
    async createInterview(requestParameters: CreateInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.createInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the interview by ID
     * Get interview by ID
     */
    async getInterviewByIDRaw(requestParameters: GetInterviewByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getInterviewByID().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getByID`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interview by ID
     * Get interview by ID
     */
    async getInterviewByID(requestParameters: GetInterviewByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.getInterviewByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the interview
     * Get interview by type
     */
    async getInterviewByTypeRaw(requestParameters: GetInterviewByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getInterviewByType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interview
     * Get interview by type
     */
    async getInterviewByType(requestParameters: GetInterviewByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.getInterviewByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInterviewRoomByIdRaw(requestParameters: GetInterviewRoomByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoomIdDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getInterviewRoomById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getRoomById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RoomIdDtoFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewRoomById(requestParameters: GetInterviewRoomByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoomIdDto> {
        const response = await this.getInterviewRoomByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInterviewSignPublicKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringWrapperResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getInterviewSignPublicKey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => StringWrapperResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewSignPublicKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringWrapperResponseDto> {
        const response = await this.getInterviewSignPublicKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the interviews with business
     * Get interviews with business
     */
    async getInterviewsRaw(requestParameters: GetInterviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewsWithBusinessDto>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getInterviews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getInterviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewsWithBusinessDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interviews with business
     * Get interviews with business
     */
    async getInterviews(requestParameters: GetInterviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewsWithBusinessDto> {
        const response = await this.getInterviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLatestUpcomingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLatestUpcomingInterviewDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getLatestUpcoming`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLatestUpcomingInterviewDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLatestUpcoming(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLatestUpcomingInterviewDto> {
        const response = await this.getLatestUpcomingRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSignedInterviewByIdRaw(requestParameters: GetSignedInterviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringWrapperResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSignedInterviewById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getSignedInterviewById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => StringWrapperResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getSignedInterviewById(requestParameters: GetSignedInterviewByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringWrapperResponseDto> {
        const response = await this.getSignedInterviewByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the interview with business
     * Get interview with business
     */
    async getWithBusinessRaw(requestParameters: GetWithBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewWithBusinessDto>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getWithBusiness().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/getWithBusiness`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewWithBusinessDtoFromJSON(jsonValue));
    }

    /**
     * Gets the interview with business
     * Get interview with business
     */
    async getWithBusiness(requestParameters: GetWithBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewWithBusinessDto> {
        const response = await this.getWithBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
