import React from 'react'
import { getTakerValidationCurrentPage } from '../../features/TakerValidationNextStepSlice'
import { useSelector } from 'react-redux'
import { TakerValidationRoute } from '../../enumeration/TakerValidationRoute'
import PersonalInfo from './VerificationFormComponents/PersonalInfo'
import EducationList from './VerificationFormComponents/EducationList'
import WorkExperienceList from './VerificationFormComponents/WorkExperienceList'
import UrlsList from './VerificationFormComponents/UrlsList'
import Meeting from './VerificationFormComponents/Meeting'
import Interviews from './VerificationFormComponents/Interviews'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { type VerificationModalEnums } from './VerifyModal'

function VerificationForm (props: { enumsValues: VerificationModalEnums, busyTimeStamps: string[] }) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const currentPage = useSelector(getTakerValidationCurrentPage)
  const editable = true
  return (
    <>
      <div
        style={{
          width: !isMobile ? '100%' : '100%',
          padding: !isMobile ? '10px 20px 0px 20px' : '10px 20px 10px 5px',
          overflowY: 'scroll'
        }}>
        {currentPage === TakerValidationRoute.PERSONAL_INFO ? <PersonalInfo languages={props.enumsValues.languages} genders={props.enumsValues.genders}/> : <div/>}
        {currentPage === TakerValidationRoute.EDUCATION ? <EducationList editable={editable}/> : <div/>}
        {currentPage === TakerValidationRoute.WORK_EXPERIENCE ? <WorkExperienceList editable={editable}/> : <div/>}
        {currentPage === TakerValidationRoute.URLS ? <UrlsList editable={editable} urlValues={props.enumsValues.urls}/> : <div/>}
        {currentPage === TakerValidationRoute.INTERVIEWS ? <Interviews
          editable={editable}
          levelValues={props.enumsValues.levels}
          typeValues={props.enumsValues.types}/> : <div/>}
        {currentPage === TakerValidationRoute.MEETING ? <Meeting busyTimeStamps={props.busyTimeStamps}/> : <div/>}
      </div>
    </>
  )
}

export default VerificationForm
