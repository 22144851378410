import React from 'react'
import { CircularProgress } from '@mui/material'
import { COLORS } from '../util/Consts'

function CircularLoading () {
  return (
    <div style={{ width: '100px', height: '100px' }}>
      <div style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'fixed' }}>
        <CircularProgress sx={{ color: COLORS.MAIN_COLOR }} size={60} />
      </div>
    </div>
  )
}

export default CircularLoading
