/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetPreProductionUserDto,
  PreProductionUserAllDto,
  PreProductionUserCreationDto,
} from '../models/index';
import {
    GetPreProductionUserDtoFromJSON,
    GetPreProductionUserDtoToJSON,
    PreProductionUserAllDtoFromJSON,
    PreProductionUserAllDtoToJSON,
    PreProductionUserCreationDtoFromJSON,
    PreProductionUserCreationDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface GetAllPreProductionUsersRequest {
    role?: GetAllPreProductionUsersRoleEnum;
}

export interface SignupNewUserRequest {
    preProductionUserCreationDto: PreProductionUserCreationDto;
}

/**
 * 
 */
export class PreProductionUserControllerApi extends runtime.BaseAPI {

    /**
     * Get all pre production user
     * Get all pre production user
     */
    async getAllPreProductionUsersRaw(requestParameters: GetAllPreProductionUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreProductionUserAllDto>> {
        const queryParameters: any = {};

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pre_production_user/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => PreProductionUserAllDtoFromJSON(jsonValue));
    }

    /**
     * Get all pre production user
     * Get all pre production user
     */
    async getAllPreProductionUsers(requestParameters: GetAllPreProductionUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreProductionUserAllDto> {
        const response = await this.getAllPreProductionUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Signup
     * Signup
     */
    async signupNewUserRaw(requestParameters: SignupNewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPreProductionUserDto>> {
        if (requestParameters['preProductionUserCreationDto'] == null) {
            throw new runtime.RequiredError(
                'preProductionUserCreationDto',
                'Required parameter "preProductionUserCreationDto" was null or undefined when calling signupNewUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pre_production_user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreProductionUserCreationDtoToJSON(requestParameters['preProductionUserCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPreProductionUserDtoFromJSON(jsonValue));
    }

    /**
     * Signup
     * Signup
     */
    async signupNewUser(requestParameters: SignupNewUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPreProductionUserDto> {
        const response = await this.signupNewUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAllPreProductionUsersRoleEnum = {
    Giver: 'GIVER',
    Taker: 'TAKER',
    GiverTaker: 'GIVER_TAKER',
    HrRecruiter: 'HR_RECRUITER'
} as const;
export type GetAllPreProductionUsersRoleEnum = typeof GetAllPreProductionUsersRoleEnum[keyof typeof GetAllPreProductionUsersRoleEnum];
