import { COLORS } from '../../../util/Consts'

export const calendarStyles =
  `
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: transparent;
  }
  .rdp-cell {
    padding: 2px;
  }
  .caption-class button {
    background: none;
    border: none;
    margin: 6px
  }
    .my-selected:not([disabled]) { 
    font-weight: bold; 
    background-color: ${COLORS.MAIN_COLOR};
    color: white;
    border: 10px solid black;
  }
  .my-selected:not([disabled]):hover {
    background-color: ${COLORS.MAIN_COLOR} !important;
}
`

export const availableDaysStyle = { border: `1.4px solid ${COLORS.MAIN_COLOR}` }
