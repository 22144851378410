/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewDto } from './GetInterviewDto';
import {
    GetInterviewDtoFromJSON,
    GetInterviewDtoFromJSONTyped,
    GetInterviewDtoToJSON,
} from './GetInterviewDto';

/**
 * 
 * @export
 * @interface InterviewWithBusinessDto
 */
export interface InterviewWithBusinessDto {
    /**
     * 
     * @type {GetInterviewDto}
     * @memberof InterviewWithBusinessDto
     */
    interview: GetInterviewDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof InterviewWithBusinessDto
     */
    busyTimestamps: Array<string>;
    /**
     * Duration in minutes
     * @type {number}
     * @memberof InterviewWithBusinessDto
     */
    durationInMinutes: number;
    /**
     * Step in minutes
     * @type {number}
     * @memberof InterviewWithBusinessDto
     */
    stepInMinutes: number;
}

/**
 * Check if a given object implements the InterviewWithBusinessDto interface.
 */
export function instanceOfInterviewWithBusinessDto(value: object): value is InterviewWithBusinessDto {
    if (!('interview' in value) || value['interview'] === undefined) return false;
    if (!('busyTimestamps' in value) || value['busyTimestamps'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('stepInMinutes' in value) || value['stepInMinutes'] === undefined) return false;
    return true;
}

export function InterviewWithBusinessDtoFromJSON(json: any): InterviewWithBusinessDto {
    return InterviewWithBusinessDtoFromJSONTyped(json, false);
}

export function InterviewWithBusinessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewWithBusinessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interview': GetInterviewDtoFromJSON(json['interview']),
        'busyTimestamps': json['busyTimestamps'],
        'durationInMinutes': json['durationInMinutes'],
        'stepInMinutes': json['stepInMinutes'],
    };
}

export function InterviewWithBusinessDtoToJSON(value?: InterviewWithBusinessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interview': GetInterviewDtoToJSON(value['interview']),
        'busyTimestamps': value['busyTimestamps'],
        'durationInMinutes': value['durationInMinutes'],
        'stepInMinutes': value['stepInMinutes'],
    };
}

