/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BankCardCreationDto } from './BankCardCreationDto';
import {
    BankCardCreationDtoFromJSON,
    BankCardCreationDtoFromJSONTyped,
    BankCardCreationDtoToJSON,
} from './BankCardCreationDto';

/**
 * 
 * @export
 * @interface InterviewBalancePurchaseRequestDto
 */
export interface InterviewBalancePurchaseRequestDto {
    /**
     * 
     * @type {BankCardCreationDto}
     * @memberof InterviewBalancePurchaseRequestDto
     */
    bankCard: BankCardCreationDto;
    /**
     * Interview balance card id
     * @type {string}
     * @memberof InterviewBalancePurchaseRequestDto
     */
    balanceCardId: string;
    /**
     * Remember card
     * @type {boolean}
     * @memberof InterviewBalancePurchaseRequestDto
     */
    rememberCard: boolean;
}

/**
 * Check if a given object implements the InterviewBalancePurchaseRequestDto interface.
 */
export function instanceOfInterviewBalancePurchaseRequestDto(value: object): value is InterviewBalancePurchaseRequestDto {
    if (!('bankCard' in value) || value['bankCard'] === undefined) return false;
    if (!('balanceCardId' in value) || value['balanceCardId'] === undefined) return false;
    if (!('rememberCard' in value) || value['rememberCard'] === undefined) return false;
    return true;
}

export function InterviewBalancePurchaseRequestDtoFromJSON(json: any): InterviewBalancePurchaseRequestDto {
    return InterviewBalancePurchaseRequestDtoFromJSONTyped(json, false);
}

export function InterviewBalancePurchaseRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewBalancePurchaseRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bankCard': BankCardCreationDtoFromJSON(json['bankCard']),
        'balanceCardId': json['balanceCardId'],
        'rememberCard': json['rememberCard'],
    };
}

export function InterviewBalancePurchaseRequestDtoToJSON(value?: InterviewBalancePurchaseRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bankCard': BankCardCreationDtoToJSON(value['bankCard']),
        'balanceCardId': value['balanceCardId'],
        'rememberCard': value['rememberCard'],
    };
}

