import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  backTakerValidationCurrentStep,
  doneTakerValidationCurrentStep,
  isRequestedForBack,
  isRequestedForTakerValidationNextStep
} from '../../../features/TakerValidationNextStepSlice'
import toast from 'react-hot-toast'
import Flipper from '../../flipper/Flipper'
import {
  addValidationTakerDates,
  clearTakerValidationData, deleteTakerInterviewDates, getTakerInterviewTimesByDates,
  getTakerValidationInfo
} from '../../../features/TakerInfoSlice'
import { AuthManager } from '../../../manager/AuthManager'
import TakerVerificationTimeRangeSelector from '../../../timeRangeSelector/TakerVerificationTimeRangeSelector'
import TakerVerificationCalendar from '../../calendar/TakerVerificationCalendar'
import { scrollTo } from '../utils'
import ApiManager from '../../../manager/ApiManager'
import { TakerControllerApi } from '../../../api/ic'

function Meeting (props: { busyTimeStamps: string[] }) {
  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const requestForBack = useSelector(isRequestedForBack)
  const dispatch = useDispatch()
  const [date, setDate] = useState<Date>(new Date())
  const timesByDates = useSelector(getTakerInterviewTimesByDates)
  const wholeValidationData = useSelector(getTakerValidationInfo)

  const takerApi = ApiManager.getInstance(TakerControllerApi)

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  useEffect(() => {
    let canGo = true
    if (!timesByDates.length) {
      canGo = false
    }
    if (requestedForNextStep) {
      dispatch(doneTakerValidationCurrentStep(canGo))
      if (!canGo) {
        toast.error('You need to select at least one date!')
        return
      }
      const allDates = timesByDates.flatMap((timesByDate) => {
        return timesByDate.times
      })

      const allIsoDates = allDates.map(date => date.toISOString())
      dispatch(addValidationTakerDates(allIsoDates))

      const allIsoStrings = allDates.map(date => date.toISOString())
      void takerApi.requestTakerValidation({
        takerValidationRequestDto: { ...wholeValidationData, dates: allIsoStrings }
      }).then(res => {
        dispatch(clearTakerValidationData())
        dispatch(deleteTakerInterviewDates())
        AuthManager.deleteAccessToken()
        AuthManager.setAccessToken(res.token)
      }).catch(() => {
        dispatch(clearTakerValidationData())
        dispatch(deleteTakerInterviewDates())
      })
    } else if (requestForBack) {
      dispatch(backTakerValidationCurrentStep(true))
    }
  }, [requestedForNextStep, requestForBack])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '50px',
        overflow: 'hidden'
      }}>
      <span id={'top'}></span>
      <div className={'d-flex flex-column justify-content-center align-items-center'}>
        <Flipper
          width={'400px'}
          height={'400px'}
          frontComponent={<TakerVerificationCalendar setDate={setDate}/>}
          backComponent={
            <TakerVerificationTimeRangeSelector
              date={date}
              initialTime={9}
              busyTimeStamps={props.busyTimeStamps}
              delayBetweenTimeBlocks={30}
              interviewDuration={60}/>
          }/>
      </div>
    </div>
  )
}

export default Meeting
