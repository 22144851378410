import React, { useState } from 'react'
import UrlsList from '../VerifyModal/VerificationFormComponents/UrlsList'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import { COLORS, STATIC_TEXTS } from '../../util/Consts'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import InformationBanner from './InformationBanner'
import { type EnumTypeDto, type ICURLDto } from '../../api/ic'

interface takerUrlsProps {
  urls?: ICURLDto[]
  editable?: boolean
  urlValues?: EnumTypeDto[]
}

function UrlSection (props: takerUrlsProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [localUrls, setLocalUrls] = useState(props.urls ?? [])
  return (
    <div
      style = {{
        padding: isMobile ? '25px' : '25px 50px 25px 50px',
        border: '2px solid #D9D9D9',
        borderRadius: '10px'
      }}>
      {!props.editable && <InformationBanner singleText={STATIC_TEXTS.READ_ONLY_SECTION_INFO}/>}
      <ColoredText
        singleText='URLs'
        fontSize= {FontModeValues.EXTRA_LARGE}
        fontWeight={300}
        color = {COLORS.MAIN_COLOR}/>
      <div
        className= {`d-flex flex-column justify-content-${isMobile ? 'center' : 'between'} flex-wrap mt-3`}>
        <UrlsList
          urlValues={props.urlValues}
          editable={props.editable}
          type={'notModal'}
          urls={localUrls}
          setUrls={setLocalUrls}/>
      </div>
    </div>
  )
}

export default UrlSection
