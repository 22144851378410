import React, { useEffect, useLayoutEffect, useState } from 'react'
import VideoComponent from './Video'
import microphone from '../svg/resources/microphone.svg'
import videoCamera from '../svg/resources/videoCamera.svg'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import SmallDropdown from '../dropdown/SmallDropdown'
import { InputModeValues } from '../modes/InputModes'
import toast from 'react-hot-toast'
import interview_mic from '../svg/resources/interview_mic.svg'
import interview_cam from '../svg/resources/interview_cam.svg'
import interview_speaker from '../svg/resources/interview_speaker.svg'
import blocked_warn from '../svg/resources/blocked_video_mic_warn.svg'
import cameraOff from '../svg/resources/cameraOff.svg'
import microphoneOff from '../svg/resources/microphoneOff.svg'
import { useDispatch, useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { updateInterviewProcessDevices } from '../../features/InterviewProcessSlice'
import { checkMicPermission, checkVideoPermission } from '../../util/VideoMicUtils'
import MicVideoBlockedModal from './MicVideoBlockedModal'

interface videProps {
  width?: string
  fullName?: string
  height?: string
}

function SmallVideo (props: videProps) {
  const width = props.width ?? '600px'
  const height = props.height ?? '600px'
  const fullName = props.fullName ?? 'John Smith'
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [cameraList, setCameraList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedCamera, setSelectedCamera] = useState<string>('')
  const [microphoneList, setMicrophoneList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('')
  const [speakerList, setSpeakerList] = useState<Array<{ label: string, deviceId: string }>>([])
  const [selectedSpeaker, setSelectedSpeaker] = useState<string>('')
  const [muteButton, setMuteButton] = useState(false)
  const [audioLocalStream, setAudioLocalStream] = useState<MediaStream | null>(null)
  const [videoLocalStream, setVideoLocalStream] = useState<MediaStream | null>(null)
  const [microphoneFlag, setMicrophoneFlag] = useState<boolean>(true)
  const [videoButton, setVideoButton] = useState(false)
  const [anim, setAnim] = useState(false)
  const dispatch = useDispatch()

  // video, mic permissions
  const [hasVideoPermission, setHasVideoPermission] = useState(false)
  const [hasAudioPermission, setHasAudioPermission] = useState(false)
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false)
  const [blockedItem, setBlockedItem] = useState<'camera' | 'mic'>('camera')

  useLayoutEffect(() => {
    void updateMediaStream()
  }, [selectedCamera,
    selectedMicrophone,
    selectedSpeaker,
    videoButton,
    muteButton])

  const updateMediaStream = async () => {
    const camera = cameraList.find(obj => obj.label === selectedCamera)
    const cameraDeviceId = camera ? camera.deviceId : null
    const microphone = microphoneList.find(obj => obj.label === selectedMicrophone)
    const microphoneDeviceId = microphone ? microphone.deviceId : null
    const speaker = speakerList.find(obj => obj.label === selectedSpeaker)
    const speakerDeviceId = speaker ? speaker.deviceId : null
    dispatch(updateInterviewProcessDevices(cameraDeviceId, microphoneDeviceId, speakerDeviceId, videoButton, muteButton))

    try {
      const micConstraints = {
        audio: { deviceId: microphoneDeviceId ? { exact: microphoneDeviceId } : undefined }
      }
      const stream = await navigator.mediaDevices.getUserMedia(micConstraints)
      setAudioLocalStream(stream)
    } catch (err: any) {
      console.error('Error accessing media devices:', err.name)
    }

    try {
      const cameraConstraints = {
        video: { deviceId: cameraDeviceId ? { exact: cameraDeviceId } : undefined }
      }
      if (!videoButton) {
        return
      }
      const stream = await navigator.mediaDevices.getUserMedia(cameraConstraints)
      setVideoLocalStream(stream)
    } catch (err: any) {
      console.error('Error accessing media devices:', err.name)
    }
  }

  useEffect(() => {
    setMicrophoneFlag(true)
    setTimeout(() => {
      setMicrophoneFlag(false)
    }, 2000)
  }, [muteButton])

  async function makeVideo (cameraList: OT.Device[]) {
    void checkVideoPermission().then((hasAccess) => {
      setHasVideoPermission(hasAccess)
      setCameraList(prev => {
        if (cameraList.length !== prev.length || !cameraList.every(cam => prev.some(pCam => pCam.deviceId === cam.deviceId))) {
          return cameraList
        }
        return prev
      })
      if (!selectedCamera && cameraList.length) {
        setSelectedCamera(cameraList[0].label)
      }
    })
  }

  async function makeAudio (microphoneList: OT.Device[]) {
    void checkMicPermission().then((hasAccess) => {
      setHasAudioPermission(hasAccess)
      void navigator.mediaDevices.enumerateDevices().then((devices) => {
        const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput')
        if (!selectedSpeaker && audioOutputDevices.length) {
          setSelectedSpeaker(audioOutputDevices[0].label)
        }
        setSpeakerList(audioOutputDevices)
      })
      setMicrophoneList(prev => {
        if (microphoneList.length !== prev.length || !microphoneList.every(mic => prev.some(pMic => pMic.deviceId === mic.deviceId))) {
          return microphoneList
        }
        return prev
      })
      if (!selectedMicrophone && microphoneList.length) {
        setSelectedMicrophone(microphoneList[0].label)
      }
      setSpeakerList(prev => {
        if (speakerList.length !== prev.length || !speakerList.every(spk => prev.some(pSpk => pSpk.deviceId === spk.deviceId))) {
          return speakerList
        }
        return prev
      })
    })
  }

  async function getPermissions () {
    try {
      OT.getDevices((err, devices) => {
        if (err) {
          console.error('axperner', err)
          return
        }
        if (!devices) {
          return
        }
        const microphoneList = devices.filter((device): device is OT.Device & {
          kind: 'audioInput'
        } => device.kind === 'audioInput')

        const cameraList = devices.filter((device): device is OT.Device & {
          kind: 'videoInput'
        } => device.kind === 'videoInput')

        void makeVideo(cameraList)
        void makeAudio(microphoneList)
      })
      const audioConstraints = { audio: true }
      const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints)
      setAudioLocalStream(audioStream)

      const videoConstraints = { video: true }
      const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints)
      setVideoLocalStream(videoStream)

      setVideoButton(true)
      setMuteButton(true)
      setAnim(true)
    } catch (error) {
      setVideoButton(false)
      setMuteButton(false)
      console.error('Error accessing media devices:', error)
    }
  }
  useEffect(() => {
    void (
      getPermissions()
    )
    navigator.mediaDevices.ondevicechange = getPermissions
    return () => {
      if (audioLocalStream) {
        audioLocalStream.getTracks().forEach(track => { track.stop() })
      }
      if (videoLocalStream) {
        videoLocalStream.getTracks().forEach(track => { track.stop() })
      }
      setVideoLocalStream(null)
      setAudioLocalStream(null)
      navigator.mediaDevices.ondevicechange = null
    }
  }, [hasVideoPermission, hasAudioPermission])
  function toggleCamera () {
    if (!hasVideoPermission) {
      setIsBlockedModalOpen(true)
      setBlockedItem('camera')
      return
    }
    if (!videoLocalStream) {
      return // Modal opening
    }

    if (videoButton) {
      setVideoButton(false)
      toast.error('Off-camera? Your interview becomes a private exploration. Turn on your camera! Illuminate your journey. ')
      videoLocalStream?.getVideoTracks().forEach((track: MediaStreamTrack) => { track.stop() })
    } else {
      setVideoButton(true)
      void updateMediaStream()
    }
  }
  function toggleMicrophone () {
    if (!hasAudioPermission) {
      setIsBlockedModalOpen(true)
      setBlockedItem('mic')
      return
    }
    if (!audioLocalStream) {
      return // Modal opening
    }
    if (muteButton) {
      setMuteButton(false)
      audioLocalStream?.getAudioTracks().forEach((track: MediaStreamTrack) => { track.enabled = false })
    } else {
      setMuteButton(true)
      audioLocalStream?.getAudioTracks().forEach((track: MediaStreamTrack) => { track.enabled = true })
    }
  }

  return (
    <div
      style = {{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      className='position-relative'>
      <div className='d-flex flex-column position-relative'>
        <VideoComponent
          width={width}
          height={height}
          autoPlay
          muted
          style={{
            borderRadius: '10px',
            transform: 'scaleX(-1)'
          }}
          srcObject={videoLocalStream}/>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: '20px',
            zIndex: 2,
            left: '20px'
          }}>
          <ColoredText
            singleText= {fullName}
            color = 'white'
            fontWeight={700}
            fontSize= {FontModeValues.DEFAULT}/>
        </div>
        <div
          style = {{
            display: anim ? 'flex' : 'none',
            flexDirection: 'row',
            position: 'absolute',
            top: '40px',
            left: '50%',
            zIndex: 2,
            padding: '3px 5px 3px 5px',
            borderRadius: '5px',
            transform: 'translateX(-50%)',
            background: 'black',
            transitionDuration: '2s',
            opacity: microphoneFlag ? '1' : '0'
          }}>
          <img
            src = {microphone}
            alt = 'micro'
            onClick= {toggleMicrophone}/>
          <ColoredText
            singleText= {`${microphoneList.length ? selectedMicrophone.slice(0, 8) + '...' : 'Micraphone Ar...' + '...'} ${muteButton ? 'On' : 'Off'}`}
            color = 'white'
            fontSize= {FontModeValues.SMALL}/>
        </div>
        <div
          style = {{
            position: 'absolute',
            borderRadius: '10px',
            width: '100%',
            height: '100%',
            background: '#2E302F',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            zIndex: 0,
            visibility: videoButton ? 'hidden' : 'visible'
          }}>
          <ColoredText
            singleText={anim ? 'Camera is off' : '' }
            color = 'white'
            fontSize= {FontModeValues.LOGIN}/>
        </div>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            gap: '5px'
          }}>
          <div
            onClick= {toggleMicrophone}
            style = {{
              width: '40px',
              height: '40px',
              background: '#3C4043',
              opacity: hasAudioPermission ? '100%' : '50%',
              display: 'flex',
              padding: '5px 0 5px 0',
              borderRadius: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
            }}>
            <img src = {muteButton ? microphone : microphoneOff} alt = 'micro'/>
          </div>
          {!hasAudioPermission && <div style={{ position: 'absolute', top: -10, right: 37 }}>
            <img src={blocked_warn} alt={'blocked'}/>
          </div>}
          <div
            onClick= { toggleCamera }
            style = {{
              width: '40px',
              height: '40px',
              background: '#3C4043',
              opacity: hasVideoPermission ? '100%' : '50%',
              display: 'flex',
              padding: '5px 0 5px 0',
              borderRadius: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)'
            }}>
            <img src = {videoButton ? videoCamera : cameraOff} alt = 'video'/>
          </div>
          {!hasVideoPermission && <div style={{ position: 'absolute', top: -10, right: -8 }}>
            <img src={blocked_warn} alt={'blocked'}/>
          </div>}
        </div>
      </div>
      <div
        className={`d-flex flex-row justify-content-evenly ${isMobile ? 'gap-1' : 'gap-1'} flex-wrap`}
        style = {{
          marginTop: '10px',
          width: '100%',
          gap: '10px'
        }}>
        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!hasAudioPermission)}
          sizeMode= {InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          selected={selectedMicrophone}
          leftIconHeight={'25px'}
          leftIconWidth={'25px'}
          logoMode='light'
          setIsSelected={setSelectedMicrophone}
          leftIcon = {interview_mic}
          fontColor={!hasAudioPermission ? 'gray' : 'white'}
          items = {microphoneList.map(device => { return device.label })}
          eachItemStyles={{
            color: 'white'
          }}/>

        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!hasVideoPermission)}
          sizeMode= {InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          selected={selectedCamera}
          logoMode='light'
          fontColor={!hasVideoPermission ? 'gray' : 'white'}
          setIsSelected={setSelectedCamera}
          leftIcon={interview_cam}
          items = {cameraList.map(device => { return device.label })}
          eachItemStyles={{
            color: 'white'
          }}/>

        <SmallDropdown
          disabledBgColor={'#3C4043'}
          disabled={Boolean(!hasAudioPermission)}
          sizeMode= {InputModeValues.MEDIUM}
          backgroundColor={'#3C4043'}
          leftIconHeight={'25px'}
          leftIconWidth={'25px'}
          fontColor={!hasAudioPermission ? 'gray' : 'white'}
          selected={selectedSpeaker}
          leftIcon={interview_speaker}
          logoMode='light'
          setIsSelected={setSelectedSpeaker}
          items = {speakerList.map(device => { return device.label })}
          eachItemStyles={{
            color: 'white'
          }}/>
      </div>
      <MicVideoBlockedModal
        mode={blockedItem}
        isOpen={isBlockedModalOpen}
        setIsOpen={setIsBlockedModalOpen}/>
    </div>
  )
}

export default SmallVideo
