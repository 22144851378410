import React, { type CSSProperties, useLayoutEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { GiverHomePageRouteType } from '../enumeration/GiverHomePageRouteTypes'
import SVGHandler from '../components/svg/SVGHandler'
import giver_page_arrow from '../components/svg/resources/giver_page_arrow.svg'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { useNavigate } from 'react-router'
import { AuthManager } from '../manager/AuthManager'
import { Badge } from '@mui/material'
import { GetICUserDtoRoleEnum as ICUserRole } from '../api/ic'

interface InterviewBarProps {
  setExplore: React.Dispatch<React.SetStateAction<boolean>>
  setUpcoming: React.Dispatch<React.SetStateAction<boolean>>
  setHistory: React.Dispatch<React.SetStateAction<boolean>>
  Explore: boolean
  Upcoming: boolean
  History: boolean
  isUpcomingBlank: boolean
  isHistoryBlank: boolean
}
function InterviewBar (props: InterviewBarProps) {
  const [activeButton, setActiveButton] = useState(0)
  const navigate = useNavigate()
  const jwtToken = AuthManager.getAccessTokenBody() ?? {}

  useLayoutEffect(() => {
    if (window.location.href.includes('/homepage/history')) {
      setActiveButton(2)
    } else if (window.location.href.includes('/homepage/interview')) {
      setActiveButton(1)
    } else {
      setActiveButton(0)
    }
  }, [window.location.href])

  const buttonStyle = (index: number): CSSProperties => {
    return {
      textDecoration: 'none',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontFamily: 'Inter,sans-serif',
      marginLeft: '40px',
      color: index === activeButton ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.85)',
      cursor: index === activeButton ? 'not-allowed' : 'pointer'
    }
  }

  return (
    <div style={{ width: '100%' }} className={'container-md'}>
      <div
        className={'container-md'}>
        <Navbar
          style={{ borderRadius: '10px', background: '#F2F2F2' }}>
          <Container>
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => {
                  navigate(NavigationRoute.HOME_PAGE)
                }}
                style={buttonStyle(GiverHomePageRouteType.Explore)}>
                Explore
                {(props.isUpcomingBlank && props.Upcoming) && <div
                  className={'d-flex flex-row'}
                  style={{ position: 'absolute' }}>
                  <div
                    style={{ paddingTop: '40px', position: 'absolute' }}
                    className={'d-flex flex-row'}>
                    <SVGHandler
                      svg={giver_page_arrow}
                      inner_style={{ position: 'absolute' }}/>
                  </div>
                  <div
                    style={{
                      paddingTop: '110px',
                      paddingLeft: '220px',
                      color: '#000',
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '36px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      overflow: 'hidden'
                    }}>
                    <p style={{ marginRight: '8px' }}>
                      No Upcoming interviews
                    </p>
                    <p>Explore world of knowledge…</p>
                  </div>
                </div>}
              </Nav.Link>
              {jwtToken.role === ICUserRole.Taker &&
                <div style={{ position: 'relative' }}>
                  <Badge
                    style={{ position: 'absolute', top: '12px', right: '2px' }}
                    color='secondary'
                    variant="dot"/>
                  <Nav.Link
                    onClick={() => {
                      navigate(NavigationRoute.HOME_PAGE + '/interview')
                    }}
                    // TODO this switching mechanism does not work
                    style={buttonStyle(GiverHomePageRouteType.TakeInterview)}>
                    Take Interview
                  </Nav.Link>
                </div>}
              <Nav.Link
                onClick={() => {
                  navigate(NavigationRoute.HOME_PAGE + '/history')
                }}
                style={buttonStyle(GiverHomePageRouteType.History)}>
                History
                {(props.isHistoryBlank && props.History) && <div
                  className={'d-flex flex-row'}
                  style={{ position: 'absolute' }}>
                  <div
                    style={{ paddingTop: '40px', position: 'absolute' }}
                    className={'d-flex flex-row'}>
                    <SVGHandler svg={giver_page_arrow} inner_style={{ position: 'absolute' }}/>
                  </div>
                  <div
                    style={{
                      paddingTop: '110px',
                      paddingLeft: '220px',
                      color: '#000',
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '36px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                      overflow: 'hidden'
                    }}>
                    <p style={{ marginRight: '8px' }}>
                      Make your History today
                    </p>
                    <p>Explore world of interviews…</p>
                  </div>
                </div>}
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>
    </div>
  )
}

export default InterviewBar
