import React, { useEffect, useLayoutEffect, useState } from 'react'
import Url from './Url'
import { useDispatch, useSelector } from 'react-redux'
import { addUrl, getUrlList, updateUrlList } from '../../../features/TakerInfoSlice'
import {
  backTakerValidationCurrentStep,
  doneTakerValidationCurrentStep,
  isRequestedForBack,
  isRequestedForTakerValidationNextStep
} from '../../../features/TakerValidationNextStepSlice'
import toast from 'react-hot-toast'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import trash from '../../svg/resources/trash.png'
import { COLORS } from '../../../util/Consts'
import { scrollTo } from '../utils'
import { type EnumTypeDto, type ICURLDto } from '../../../api/ic'

interface UrlListProps {
  type?: 'Modal' | 'notModal'
  urls?: ICURLDto[] | undefined
  urlValues?: EnumTypeDto[]
  editable?: boolean
  setUrls?: React.Dispatch<React.SetStateAction<ICURLDto[]>>
}

function UrlsList (props: UrlListProps) {
  const type = props.type ?? 'Modal'
  const urlList = useSelector(getUrlList)
  const [localList, setLocalList] = useState<ICURLDto[]>(type === 'Modal' ? JSON.parse(JSON.stringify(urlList)) : props.urls)
  const [trueValues, setTrueValues] = useState(Array.from({ length: localList.length }, () => false))
  const [triggerValidate, setTriggerValidate] = useState(false)
  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const requestForBack = useSelector(isRequestedForBack)
  const dispatch = useDispatch()
  const editable = props.editable ?? true

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  const deleteUrl = (index: number) => {
    const newList = localList.filter((_, key) => key !== index)
    setLocalList(newList)
    if (props.setUrls) {
      props.setUrls(newList)
    }
    const newTrueValues = trueValues.filter((_, key) => key !== index)

    setTrueValues(newTrueValues)
  }
  const handleAdd = () => {
    setTriggerValidate(true)
    let canAdd = true
    for (const trueValue of trueValues) {
      if (!trueValue) {
        canAdd = false
        setTriggerValidate(true)
      }
    }
    for (const urlDto of localList) {
      if (!urlDto.key || !urlDto.value) {
        canAdd = false
        break
      }
    }
    if (canAdd) {
      if (type === 'Modal') {
        dispatch(updateUrlList([...localList]))
        const newUrl: ICURLDto = {
          key: undefined,
          value: ''
        }
        dispatch(addUrl(newUrl))
        setLocalList((prev: ICURLDto[]) => [...prev, newUrl])
      } else {
        setLocalList(prevState => {
          const newUrl: ICURLDto = {
            key: undefined,
            value: ''
          }
          const newLocalList = [...prevState]
          newLocalList.push(newUrl)
          if (props.setUrls) {
            props.setUrls(newLocalList)
          }
          return newLocalList
        })
      }
    } else {
      toast.error('Fill required fields')
    }
  }

  useEffect(() => {
    if (type === 'Modal') {
      let canGo = true
      for (const urlDto of localList) {
        if ((urlDto.key && !urlDto.value) || (!urlDto.key && urlDto.value)) {
          canGo = false
          break
        }
      }
      if (requestedForNextStep) {
        for (const trueValue of trueValues) {
          if (!trueValue) {
            canGo = false
            setTriggerValidate(true)
          }
        }
        dispatch(doneTakerValidationCurrentStep(canGo))
        if (!canGo) {
          toast.error('Fill all required fields')
          return
        }
        dispatch(updateUrlList([...localList]))
      } else if (requestForBack) {
        dispatch(updateUrlList([...localList]))
        dispatch(backTakerValidationCurrentStep(true))
      }
    }
    if (type === 'notModal') {
      if (props.setUrls) {
        props.setUrls(localList)
      }
    }
  }, [
    requestedForNextStep,
    requestForBack,
    localList])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}>
      <span id={'top'}></span>
      {localList.map((item, index) => {
        return (
          <div key={index} className={'d-flex flex-row gap-2 '}>
            <div style={{ width: '100%' }}>
              <Url
                key={index}
                triggerValidate={triggerValidate}
                setTriggerValidate={setTriggerValidate}
                values={props.urlValues ?? []}
                idx={index}
                obj={item}
                setTrueValues={setTrueValues}
                trueValues={trueValues}
                localList={localList}
                editable={props.editable}
                setLocalList={setLocalList}
              />
            </div>
            {(localList.length !== 1 && props.editable) &&
            <span style={{ paddingTop: '32px' }}>
              <img
                src={trash}
                alt={trash}
                height={'24px'}
                width={'24px'}
                onClick={() => {
                  deleteUrl(index)
                }} style={{ cursor: 'pointer' }}/>
            </span>
            }

          </div>
        )
      })}
      {(editable && type !== 'notModal') &&
      <div onClick={() => {
        if (localList.length < 15) {
          handleAdd()
        }
      }
          }
        style={{
          fontFamily: 'Inter, sans-serif',
          color: localList.length >= 15 ? '#BDBDBD' : COLORS.MAIN_COLOR,
          cursor: localList.length >= 15 ? 'not-allowed' : 'pointer',
          width: 'fit-content',
          userSelect: 'none'
        }}>
        Add +
      </div>}
    </div>
  )
}

export default UrlsList
