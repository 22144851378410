/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Interview bank card
 * @export
 * @interface BankCardCreationDto
 */
export interface BankCardCreationDto {
    /**
     * Bank card number
     * @type {string}
     * @memberof BankCardCreationDto
     */
    num: string;
    /**
     * CVV code
     * @type {number}
     * @memberof BankCardCreationDto
     */
    cvv: number;
    /**
     * Expire date
     * @type {string}
     * @memberof BankCardCreationDto
     */
    expireDate: string;
    /**
     * Card holder
     * @type {string}
     * @memberof BankCardCreationDto
     */
    cardHolder: string;
    /**
     * 
     * @type {boolean}
     * @memberof BankCardCreationDto
     */
    _default?: boolean;
}

/**
 * Check if a given object implements the BankCardCreationDto interface.
 */
export function instanceOfBankCardCreationDto(value: object): value is BankCardCreationDto {
    if (!('num' in value) || value['num'] === undefined) return false;
    if (!('cvv' in value) || value['cvv'] === undefined) return false;
    if (!('expireDate' in value) || value['expireDate'] === undefined) return false;
    if (!('cardHolder' in value) || value['cardHolder'] === undefined) return false;
    return true;
}

export function BankCardCreationDtoFromJSON(json: any): BankCardCreationDto {
    return BankCardCreationDtoFromJSONTyped(json, false);
}

export function BankCardCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankCardCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'num': json['num'],
        'cvv': json['cvv'],
        'expireDate': json['expireDate'],
        'cardHolder': json['cardHolder'],
        '_default': json['default'] == null ? undefined : json['default'],
    };
}

export function BankCardCreationDtoToJSON(value?: BankCardCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'num': value['num'],
        'cvv': value['cvv'],
        'expireDate': value['expireDate'],
        'cardHolder': value['cardHolder'],
        'default': value['_default'],
    };
}

