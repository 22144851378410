/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceCardTagEditDto
 */
export interface BalanceCardTagEditDto {
    /**
     * Tag text
     * @type {string}
     * @memberof BalanceCardTagEditDto
     */
    text?: string;
    /**
     * Tag type
     * @type {string}
     * @memberof BalanceCardTagEditDto
     */
    type?: BalanceCardTagEditDtoTypeEnum;
    /**
     * Tag ID
     * @type {string}
     * @memberof BalanceCardTagEditDto
     */
    id: string;
}


/**
 * @export
 */
export const BalanceCardTagEditDtoTypeEnum = {
    Balance: 'BALANCE',
    BalanceCard: 'BALANCE_CARD'
} as const;
export type BalanceCardTagEditDtoTypeEnum = typeof BalanceCardTagEditDtoTypeEnum[keyof typeof BalanceCardTagEditDtoTypeEnum];


/**
 * Check if a given object implements the BalanceCardTagEditDto interface.
 */
export function instanceOfBalanceCardTagEditDto(value: object): value is BalanceCardTagEditDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function BalanceCardTagEditDtoFromJSON(json: any): BalanceCardTagEditDto {
    return BalanceCardTagEditDtoFromJSONTyped(json, false);
}

export function BalanceCardTagEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceCardTagEditDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'type': json['type'] == null ? undefined : json['type'],
        'id': json['id'],
    };
}

export function BalanceCardTagEditDtoToJSON(value?: BalanceCardTagEditDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'type': value['type'],
        'id': value['id'],
    };
}

