/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshableAuthenticationResponseDto
 */
export interface RefreshableAuthenticationResponseDto {
    /**
     * Access token which should be stored in the cookies
     * @type {string}
     * @memberof RefreshableAuthenticationResponseDto
     */
    token: string;
    /**
     * Refresh token, which should be stored in browser (not in cookies)
     * @type {string}
     * @memberof RefreshableAuthenticationResponseDto
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the RefreshableAuthenticationResponseDto interface.
 */
export function instanceOfRefreshableAuthenticationResponseDto(value: object): value is RefreshableAuthenticationResponseDto {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    return true;
}

export function RefreshableAuthenticationResponseDtoFromJSON(json: any): RefreshableAuthenticationResponseDto {
    return RefreshableAuthenticationResponseDtoFromJSONTyped(json, false);
}

export function RefreshableAuthenticationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshableAuthenticationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'refreshToken': json['refreshToken'],
    };
}

export function RefreshableAuthenticationResponseDtoToJSON(value?: RefreshableAuthenticationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'refreshToken': value['refreshToken'],
    };
}

