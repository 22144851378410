import React, { useState } from 'react'
import MarginWrapper from '../util/MarginWrapper'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import StandardInput from '../input/StandardInput'
import { InputModeValues } from '../modes/InputModes'
import { LoginPage } from '../../enumeration/LoginPage'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { AuthManager } from '../../manager/AuthManager'
import { useSelector } from 'react-redux'
import { getLoginUser } from '../../features/LoginUserSlice'
import Timer from '../Timer/Timer'
import { COLORS } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import ApiManager from '../../manager/ApiManager'
import { type LoginRequestDto, VerificationControllerApi, VerificationRequestDtoTypeEnum as AuthRole } from '../../api/ic'
import { type ReduxActionType } from '../../enumeration/ReduxActionType'

interface verifyResetEmailProps {
  pageChangeAction: (newValue: LoginPage) => { type: ReduxActionType, payload: LoginPage }
}

function VerifyResetEmail (props: verifyResetEmailProps) {
  const loginUser: LoginRequestDto = useSelector(getLoginUser)
  const [code, setCode] = useState('')
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [isValidOtp, setIsValidOtp] = useState(false)
  const [loading, setLoading] = useState(false)

  const verificationApi = ApiManager.getInstance(VerificationControllerApi)

  const verifyEmail = async () => {
    setLoading(true)
    await verificationApi.verify({
      verificationRequestDto: {
        email: loginUser.email,
        otp: parseInt(code),
        type: AuthRole.RoleForgotPassword
      }
    })
      .then(res => {
        AuthManager.setAccessToken(res.token)
        props.pageChangeAction(LoginPage.RESET_PASSWORD)
      }).catch(() => {})
    setLoading(false)
  }

  return (
    <>
      <div
        className={'d-flex flex-column justify-content-center align-items-center'}>
        <MarginWrapper
          bottom={'60px'}>
          <></>
        </MarginWrapper>
        <StandardInput
          sizeMode={InputModeValues.LARGE}
          type={StandardInputModeValues.OTP}
          label={'Check your email to verify with 6 digit code.'}
          placeHolder={'******'}
          maxLength={6}
          inputValue={code}
          backgroundColor={'rgba(246, 246, 246, 1)'}
          setInputValue={setCode}
          triggerValidate={triggerValidate}
          setTriggerValidate={setTriggerValidate}
          setIsValid={setIsValidOtp}/>
        <MarginWrapper
          top={'10px'}>
          <StandardButton
            sizeMode={ButtonModeValues.EXTRA_LARGE}
            text={'Continue'}
            textFontWeight={700}
            setTriggerValidate={setTriggerValidate}
            isValid={isValidOtp}
            isLoading={loading}
            onClick={verifyEmail}/>
        </MarginWrapper>
        <MarginWrapper
          top = {'50px'}>
          <Timer
            isResettable={true}
            text={'Resend Code'}
            color={COLORS.MAIN_COLOR}
            fontSize={FontModeValues.DEFAULT}
            textDecorationAfterTimerIsZero={'underline'}
            startValue={25}
            textWhenTimerIsZero={'Send code again'}/>
        </MarginWrapper>
      </div>

    </>
  )
}

export default VerifyResetEmail
