/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InterviewAddConnectionRequestDTO,
  InterviewJoiningRequestDTO,
  InterviewJoiningResponseDTO,
  InterviewTerminateConnectionRequestDTO,
  TransferResponseDTO,
} from '../models/index';
import {
    InterviewAddConnectionRequestDTOFromJSON,
    InterviewAddConnectionRequestDTOToJSON,
    InterviewJoiningRequestDTOFromJSON,
    InterviewJoiningRequestDTOToJSON,
    InterviewJoiningResponseDTOFromJSON,
    InterviewJoiningResponseDTOToJSON,
    InterviewTerminateConnectionRequestDTOFromJSON,
    InterviewTerminateConnectionRequestDTOToJSON,
    TransferResponseDTOFromJSON,
    TransferResponseDTOToJSON,
} from '../models/index';

export interface InterviewVideoControllerAddConnectionRequest {
    interviewAddConnectionRequestDTO: InterviewAddConnectionRequestDTO;
}

export interface InterviewVideoControllerIsTransferRequest {
    interviewJoiningRequestDTO: InterviewJoiningRequestDTO;
}

export interface InterviewVideoControllerJoinRequest {
    interviewJoiningRequestDTO: InterviewJoiningRequestDTO;
}

export interface InterviewVideoControllerRemoveConnectionRequest {
    interviewAddConnectionRequestDTO: InterviewAddConnectionRequestDTO;
}

export interface InterviewVideoControllerTerminateConnectionRequest {
    interviewTerminateConnectionRequestDTO: InterviewTerminateConnectionRequestDTO;
}

/**
 * 
 */
export class InterviewVideoControllerApi extends runtime.BaseAPI {

    /**
     * Join to interview
     */
    async interviewVideoControllerAddConnectionRaw(requestParameters: InterviewVideoControllerAddConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['interviewAddConnectionRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'interviewAddConnectionRequestDTO',
                'Required parameter "interviewAddConnectionRequestDTO" was null or undefined when calling interviewVideoControllerAddConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/addConnection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewAddConnectionRequestDTOToJSON(requestParameters['interviewAddConnectionRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerAddConnection(requestParameters: InterviewVideoControllerAddConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.interviewVideoControllerAddConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerIsTransferRaw(requestParameters: InterviewVideoControllerIsTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TransferResponseDTO>> {
        if (requestParameters['interviewJoiningRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'interviewJoiningRequestDTO',
                'Required parameter "interviewJoiningRequestDTO" was null or undefined when calling interviewVideoControllerIsTransfer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/isTransfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewJoiningRequestDTOToJSON(requestParameters['interviewJoiningRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransferResponseDTOFromJSON(jsonValue));
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerIsTransfer(requestParameters: InterviewVideoControllerIsTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TransferResponseDTO> {
        const response = await this.interviewVideoControllerIsTransferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerJoinRaw(requestParameters: InterviewVideoControllerJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewJoiningResponseDTO>> {
        if (requestParameters['interviewJoiningRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'interviewJoiningRequestDTO',
                'Required parameter "interviewJoiningRequestDTO" was null or undefined when calling interviewVideoControllerJoin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/join`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewJoiningRequestDTOToJSON(requestParameters['interviewJoiningRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewJoiningResponseDTOFromJSON(jsonValue));
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerJoin(requestParameters: InterviewVideoControllerJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewJoiningResponseDTO> {
        const response = await this.interviewVideoControllerJoinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerRemoveConnectionRaw(requestParameters: InterviewVideoControllerRemoveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['interviewAddConnectionRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'interviewAddConnectionRequestDTO',
                'Required parameter "interviewAddConnectionRequestDTO" was null or undefined when calling interviewVideoControllerRemoveConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/removeConnection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewAddConnectionRequestDTOToJSON(requestParameters['interviewAddConnectionRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerRemoveConnection(requestParameters: InterviewVideoControllerRemoveConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.interviewVideoControllerRemoveConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerTerminateConnectionRaw(requestParameters: InterviewVideoControllerTerminateConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['interviewTerminateConnectionRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'interviewTerminateConnectionRequestDTO',
                'Required parameter "interviewTerminateConnectionRequestDTO" was null or undefined when calling interviewVideoControllerTerminateConnection().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/terminateConnection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewTerminateConnectionRequestDTOToJSON(requestParameters['interviewTerminateConnectionRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerTerminateConnection(requestParameters: InterviewVideoControllerTerminateConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.interviewVideoControllerTerminateConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
