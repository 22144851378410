import React, { type LegacyRef, useEffect, useRef, useState } from 'react'
import { Box, Slider } from '@mui/material'
import AvatarEditor from 'react-avatar-editor'
import { COLORS } from '../../util/Consts'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import drag_and_drop from '../svg/resources/drag_and_drop.jpg'
import { CropperType } from '../../enumeration/CropperType'
interface ImgCropperProps {
  setImgAvailable?: React.Dispatch<React.SetStateAction<boolean>>
  setCropping: React.Dispatch<React.SetStateAction<boolean>>
  cropping: boolean
  cropRef: LegacyRef<any> | undefined
  cropperType?: CropperType
  triggerInputWithoutModal?: boolean
  setTriggerInputWithoutModal?: React.Dispatch<React.SetStateAction<boolean>>
  height?: string
  width?: string
}

function ImgCropper (props: ImgCropperProps) {
  const [src, setSrc] = useState<string | null>(null)
  const [slideValue, setSlideValue] = useState(10)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const cropperType = props.cropperType ?? CropperType.WITH_MODAL
  const handleInputClick = (e: React.MouseEvent) => {
    e.preventDefault()
    inputRef.current?.click()
  }

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.onload = (event) => {
        if (inputRef.current) {
          inputRef.current.value = ''
        }
        setSrc(event.target?.result as string)
        props.setCropping(true)
        if (props.setImgAvailable) {
          props.setImgAvailable(true)
        }
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files.length > 0) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setSrc(event.target?.result as string)
        props.setCropping(true)
        if (props.setImgAvailable) {
          props.setImgAvailable(true)
        }
      }
      reader.readAsDataURL(files[0])
    }
  }
  useEffect(() => {
    if (props.triggerInputWithoutModal && props.setTriggerInputWithoutModal) {
      inputRef.current?.click()
      props.setTriggerInputWithoutModal(false)
    }
  }, [props.triggerInputWithoutModal])
  return (
    // TODO make components for each type of imgCropper
    <>
      {/* with modal */}
      { cropperType === CropperType.WITH_MODAL &&
      <span
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        {props.cropping
          ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div
                className={'d-flex flex-column align-items-center'}>
                <AvatarEditor
                  ref={props.cropRef}
                  image={src ?? ''}
                  style={{ width: '100%', height: '100%' }}
                  border={50}
                  borderRadius={150}
                  color={[0,
                    0,
                    0,
                    0.72]}
                  scale={slideValue / 10}
                  rotate={0}
                  width={300}
                  height={300}/>

                {/* MUI Slider */}
                <Slider
                  min={10}
                  max={50}
                  sx={{
                    margin: '0 auto',
                    width: '80%',
                    color: COLORS.MAIN_COLOR,
                    marginTop: '30px',
                    '& .MuiSlider-thumb': {
                      color: COLORS.MAIN_COLOR,
                      boxShadow: `0 0 0 0px ${COLORS.MAIN_COLOR} !important`
                    },
                    '& .MuiSlider-thumb:hover': {
                      color: COLORS.MAIN_COLOR,
                      boxShadow: `0 0 0 2px ${COLORS.MAIN_COLOR} !important`
                    }
                  }}
                  size="medium"
                  defaultValue={slideValue}
                  value={slideValue}
                  onChange={(e, newValue) => {
                    setSlideValue(newValue as number)
                    return e
                  }}/>
              </div>
            </Box>
            )
          : (
            <a onClick={handleInputClick} style={{ cursor: 'pointer' }}>
              <div
                style={{
                  minHeight: '300px',
                  minWidth: '300px',
                  border: `5px dashed ${COLORS.MAIN_COLOR}`,
                  borderRadius: '10px',
                  cursor: 'pointer'
                }}
                className={'d-flex flex-column justify-content-center align-items-center'}>
                <img
                  style={{ userSelect: 'none' }}
                  src={drag_and_drop}
                  height={102}
                  width={106}
                  draggable={false}
                  alt={'Drag And Drop'}/>
                <span
                  style={{
                    color: COLORS.MAIN_COLOR,
                    fontFamily: 'inter,sans-serif',
                    fontWeight: 400,
                    fontSize: '12px',
                    userSelect: 'none'
                  }}>
                  Upload your Image
                </span>
              </div>
            </a>
            )}
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleImgChange}
          hidden={true} />
        </span>
      }
      {/* without modal */}
      {
        cropperType === CropperType.WITHOUT_MODAL &&
          <span
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {props.cropping
              ? (
                <div
                  className={'d-flex flex-column align-items-center'}>
                  <AvatarEditor
                    ref={props.cropRef}
                    image={src ?? ''}
                    border={0}
                    style={{ borderRadius: '60px' }}
                    borderRadius={120}
                    color={[0,
                      0,
                      0,
                      0.3]}
                    scale={slideValue / 10}
                    rotate={0}
                    width={props.width ? parseFloat(props.width) : 120}
                    height={props.height ? parseFloat(props.height) : props.width ? parseFloat(props.width) : 120 }/>
                  {/* MUI Slider */}
                  <Slider
                    min={10}
                    max={50}
                    sx={{
                      width: '80%',
                      color: COLORS.MAIN_COLOR,
                      marginTop: '10px',
                      '& .MuiSlider-thumb': {
                        color: COLORS.MAIN_COLOR,
                        boxShadow: `0 0 0 0px ${COLORS.MAIN_COLOR} !important`
                      },
                      '& .MuiSlider-thumb:hover': {
                        color: COLORS.MAIN_COLOR,
                        boxShadow: `0 0 0 2px ${COLORS.MAIN_COLOR} !important`
                      }
                    }}
                    size="medium"
                    defaultValue={slideValue}
                    value={slideValue}
                    onChange={(e, newValue) => {
                      setSlideValue(newValue as number)
                      return e
                    }}/>
                </div>
                )
              : null
            }
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleImgChange}
              hidden={true} />
            </span>
            }
    </>
  )
}

export default ImgCropper
