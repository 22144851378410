import { ReduxActionType } from '../enumeration/ReduxActionType'
import { groupDatesFromIsoStrings } from '../util/Helper'
import {
  type EducationDto,
  type GetInterviewTypeDto,
  type ICURLDto,
  type TakerInterviewTypeDto,
  type TakerValidationRequestDto, type ValidationInfoResponseDto,
  type WorkExperienceDto
} from '../api/ic'

export const getTakerInterviewTimesByDates = (state: any): Array<{ date: Date, times: Date[] }> => {
  return state.takerInfo.timesByDate
}
export const getTakerInterviewInfo = (state: any): { dto: TakerValidationRequestDto, timesByDate: Array<{ date: Date, times: Date[] }> } => {
  return state.takerInfo
}
export const getTakerValidationInfo = (state: any) => {
  const urls = state.takerInfo.dto.urls
  const filteredUrls = urls.filter((urlDto: ICURLDto) => urlDto.key)
  return { ...state.takerInfo.dto, urls: filteredUrls }
}
export const getTakerInterviewTypes = (state: any) => {
  return state.takerInfo.dto.interviewTypes
}
export const getTakerValidationPersonalInfo = (state: any): TakerValidationRequestDto | null => {
  if (Object.keys(state.takerInfo.dto).length) {
    return state.takerInfo?.dto
  }
  return null
}
export const getEducationList = (state: any): EducationDto[] => {
  const educations = state.takerInfo.dto.educations
  return educations?.length ? educations : []
}

export const getExperienceList = (state: any): WorkExperienceDto[] => {
  const experiences = state.takerInfo.dto.workExperiences
  return experiences?.length ? experiences : []
}

export const getUrlList = (state: any): ICURLDto[] => {
  const urls = state.takerInfo.dto.urls
  return urls?.length ? urls : []
}

const initialData: Partial<TakerValidationRequestDto> = {
  educations: [{
    name: '',
    degree: '',
    location: '',
    fromDate: '',
    toDate: '',
    description: '',
    stillStudying: false
  }],
  workExperiences: [{
    name: '',
    position: '',
    location: '',
    fromDate: '',
    toDate: '',
    description: '',
    stillWorking: false
  }],
  urls: [
    {
      key: '',
      value: ''
    }
  ],
  interviewTypes: [{ type: undefined, level: undefined }]
}
export const takerInfoReducer = (state: {
  dto: Partial<TakerValidationRequestDto>
  timesByDate: Array<{ date: Date, times: Date[] }>
} = {
  dto: initialData,
  timesByDate: []
}, action: any) => {
  let eduList: EducationDto[]
  let expList: WorkExperienceDto[]
  let urlList: ICURLDto[]
  let interviewTypes: TakerInterviewTypeDto[]
  let dateToChangeIndex
  let existingDateIndex
  let updatedState
  switch (action.type) {
    case ReduxActionType.SET_TAKER_VALIDATION_INITIAL_STATE: {
      const payload: ValidationInfoResponseDto = action.payload
      const personalInformation = payload.personalInformation
      return {
        ...state,
        dto: {
          ...state.dto,
          firstName: personalInformation?.firstname,
          lastName: personalInformation?.lastname,
          aboutMe: personalInformation?.aboutMe,
          country: personalInformation?.country,
          preferredLanguages: personalInformation?.preferredLanguages ?? [],
          dateOfBirth: personalInformation?.dateOfBirth,
          gender: personalInformation?.gender,
          educations: payload.educations?.length ? payload.educations : [{
            name: '',
            degree: '',
            location: '',
            fromDate: undefined,
            toDate: undefined,
            description: '',
            stillStudying: false
          }],
          workExperiences: payload.workExperiences?.length ? payload.workExperiences : [{
            name: '',
            position: '',
            location: '',
            fromDate: undefined,
            toDate: undefined,
            description: '',
            stillWorking: false
          }],
          interviewTypes: payload.requestedInterviewTypes?.length ? payload.requestedInterviewTypes : [{ type: undefined, level: undefined }],
          urls: payload.urls?.length ? payload.urls : [{ key: '', value: '' }]
        },
        timesByDate: [...state.timesByDate,
          ...groupDatesFromIsoStrings(
            payload.requestDates ?? []
          )
        ]
      }
    }
    case ReduxActionType.ADD_TAKER_VALIDATION_EDUCATION:
      eduList = state.dto.educations ?? []
      return { ...state, dto: { ...state.dto, educations: [...eduList, { ...action.payload }] } }
    case ReduxActionType.UPDATE_TAKER_VALIDATION_EDUCATIONS:
      return { ...state, dto: { ...state.dto, educations: [...action.payload] } }
    case ReduxActionType.ADD_TAKER_VALIDATION_EXPERIENCE:
      expList = state.dto.workExperiences ?? []
      return { ...state, dto: { ...state.dto, workExperiences: [...expList, { ...action.payload }] } }
    case ReduxActionType.UPDATE_TAKER_VALIDATION_EXPERIENCES:
      return { ...state, dto: { ...state.dto, workExperiences: [...action.payload] } }

    case ReduxActionType.ADD_TAKER_VALIDATION_INTERVIEW_TYPE:
      interviewTypes = state.dto.interviewTypes ?? []

      return { ...state, dto: { ...state.dto, interviewTypes: [...interviewTypes, action.payload] } }

    case ReduxActionType.REMOVE_TAKER_VALIDATION_INTERVIEW_TYPE:
      interviewTypes = state.dto.interviewTypes ?? []
      interviewTypes = interviewTypes.filter(e => e.type !== action.payload.interviewType)
      return { ...state, dto: { ...state.dto, interviewTypes: [...interviewTypes] } }

    case ReduxActionType.ADD_TAKER_VALIDATION_URL:
      urlList = state.dto.urls ?? []
      return { ...state, dto: { ...state.dto, urls: [...urlList, { ...action.payload }] } }

    case ReduxActionType.ADD_TAKER_VALIDATION_DATES:
      return { ...state, dto: { ...state.dto, dates: action.payload } }

    case ReduxActionType.UPDATE_TAKER_VALIDATION_URLS:
      return { ...state, dto: { ...state.dto, urls: [...action.payload] } }
    case ReduxActionType.UPDATE_TAKER_VALIDATION_INTERVIEWS:
      return { ...state, dto: { ...state.dto, interviewTypes: [...action.payload] } }
    case ReduxActionType.UPDATE_USER_PERSONAL_INFO:
      return {
        ...state,
        dto: {
          ...state.dto,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          country: action.payload.country,
          dateOfBirth: action.payload.dateOfBirth,
          gender: action.payload.gender === '' ? null : action.payload.gender,
          aboutMe: action.payload.aboutMe,
          preferredLanguages: action.payload.preferredLanguages
        }
      }

    case ReduxActionType.ADD_TAKER_INTERVIEW_DATE:
      existingDateIndex = state.timesByDate.findIndex((selectedDay) => selectedDay.date.getDate() === action.payload.getDate())
      if (existingDateIndex !== -1) {
        return state
      } else {
        return { ...state, timesByDate: [...state.timesByDate, { date: action.payload, times: [] }] }
      }
    case ReduxActionType.SET_TAKER_INTERVIEW_TIMES:
      if (!action.payload[0]) {
        dateToChangeIndex = state.timesByDate.findIndex(timesByDate =>
          timesByDate.date.getTime() === action.date.getTime()
        )
        updatedState = [...state.timesByDate]
        updatedState[dateToChangeIndex] = {
          date: [...state.timesByDate][dateToChangeIndex].date,
          times: []
        }
        return { ...state, timesByDate: [...updatedState] }
      }
      dateToChangeIndex = state.timesByDate.findIndex(obj =>
        obj.date.getTime() === action.date.getTime()
      )
      if (dateToChangeIndex === -1) {
        // If the date to change is not found, return the state unchanged
        if (action.payload.length && action.date) {
          updatedState = [...state.timesByDate]
          // Update the times for the specific date
          updatedState.push(
            {
              date: action.date,
              times: action.payload
            }
          )
          return { ...state, timesByDate: [...updatedState] }
        }
        return state
      }
      // Create a new array to avoid mutating the original state
      updatedState = [...state.timesByDate]
      // Update the times for the specific date
      updatedState[dateToChangeIndex] = {
        date: updatedState[dateToChangeIndex].date,
        times: action.payload
      }
      return { ...state, timesByDate: [...updatedState] }

    case ReduxActionType.DELETE_TAKER_INTERVIEW_TEMP_DATES:
      return { ...state, timesByDate: [] }

    case ReduxActionType.CLEAR_TAKER_VALIDATION_DATA:
      return { dto: initialData, timesByDate: [] }
    default:
      return state
  }
}

export const addEducation = (education: EducationDto) => {
  return {
    type: ReduxActionType.ADD_TAKER_VALIDATION_EDUCATION,
    payload: education
  }
}

export const setInitialState = (dto: ValidationInfoResponseDto) => {
  return {
    type: ReduxActionType.SET_TAKER_VALIDATION_INITIAL_STATE,
    payload: dto
  }
}

export const updateEducationList = (educations: EducationDto[]) => {
  return {
    type: ReduxActionType.UPDATE_TAKER_VALIDATION_EDUCATIONS,
    payload: educations
  }
}

export const addExperience = (experiences: WorkExperienceDto) => {
  return {
    type: ReduxActionType.ADD_TAKER_VALIDATION_EXPERIENCE,
    payload: experiences
  }
}

export const updateExperienceList = (experiences: WorkExperienceDto[]) => {
  return {
    type: ReduxActionType.UPDATE_TAKER_VALIDATION_EXPERIENCES,
    payload: experiences
  }
}

export const addUrl = (url: ICURLDto) => {
  return {
    type: ReduxActionType.ADD_TAKER_VALIDATION_URL,
    payload: url
  }
}

export const addValidationTakerDates = (dates: string[]) => {
  return {
    type: ReduxActionType.ADD_TAKER_VALIDATION_DATES,
    payload: dates
  }
}

export const addTakerValidationInterviewType = (interviewType: Partial<GetInterviewTypeDto>) => {
  return {
    type: ReduxActionType.ADD_TAKER_VALIDATION_INTERVIEW_TYPE,
    payload: interviewType
  }
}

export const removeTakerValidationInterviewType = (interviewType: GetInterviewTypeDto) => {
  return {
    type: ReduxActionType.REMOVE_TAKER_VALIDATION_INTERVIEW_TYPE,
    payload: interviewType
  }
}

export const updateUrlList = (urls: ICURLDto[]) => {
  return {
    type: ReduxActionType.UPDATE_TAKER_VALIDATION_URLS,
    payload: urls
  }
}

export const updateInterviewTypeList = (dtos: TakerInterviewTypeDto[]) => {
  return {
    type: ReduxActionType.UPDATE_TAKER_VALIDATION_INTERVIEWS,
    payload: dtos
  }
}

export const clearTakerValidationData = () => {
  return {
    type: ReduxActionType.CLEAR_TAKER_VALIDATION_DATA
  }
}

export const updateTakerValidationPersonalInfo = (value: Partial<TakerValidationRequestDto>) => {
  return {
    type: ReduxActionType.UPDATE_USER_PERSONAL_INFO,
    payload: value
  }
}

export const addTakerInterviewDate = (value: Date) => {
  return {
    type: ReduxActionType.ADD_TAKER_INTERVIEW_DATE,
    payload: value
  }
}

export const deleteTakerInterviewDates = () => {
  return {
    type: ReduxActionType.DELETE_TAKER_INTERVIEW_TEMP_DATES
  }
}

export const setTakerTimesForDate = (times: Date[], date: Date) => {
  return {
    type: ReduxActionType.SET_TAKER_INTERVIEW_TIMES,
    payload: times,
    date
  }
}
