/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreProductionUserCreationDto
 */
export interface PreProductionUserCreationDto {
    /**
     * Pre-production user email
     * @type {string}
     * @memberof PreProductionUserCreationDto
     */
    email: string;
    /**
     * Pre-production user role
     * @type {string}
     * @memberof PreProductionUserCreationDto
     */
    role: PreProductionUserCreationDtoRoleEnum;
}


/**
 * @export
 */
export const PreProductionUserCreationDtoRoleEnum = {
    Giver: 'GIVER',
    Taker: 'TAKER',
    GiverTaker: 'GIVER_TAKER',
    HrRecruiter: 'HR_RECRUITER'
} as const;
export type PreProductionUserCreationDtoRoleEnum = typeof PreProductionUserCreationDtoRoleEnum[keyof typeof PreProductionUserCreationDtoRoleEnum];


/**
 * Check if a given object implements the PreProductionUserCreationDto interface.
 */
export function instanceOfPreProductionUserCreationDto(value: object): value is PreProductionUserCreationDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function PreProductionUserCreationDtoFromJSON(json: any): PreProductionUserCreationDto {
    return PreProductionUserCreationDtoFromJSONTyped(json, false);
}

export function PreProductionUserCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreProductionUserCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'role': json['role'],
    };
}

export function PreProductionUserCreationDtoToJSON(value?: PreProductionUserCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'role': value['role'],
    };
}

