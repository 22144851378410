import React, { type CSSProperties } from 'react'
import ColoredText from '../text/ColoredText'
import { BeatLoader } from 'react-spinners'
import { HEIGHT_MODES, HeightModeValues } from '../modes/HeightMode'
import { WIDTH_MODES, WidthModeValues } from '../modes/WidthMode'
import { COLORS, KEYBOARD_KEYS } from '../../util/Consts'
import { BUTTON_MODES, type ButtonModeValues } from '../modes/ButtonMode'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface StandardButtonProps {
  style?: CSSProperties
  textFontSize?: string
  textFontFamily?: string
  textColor?: string
  backgroundColorMode?: 'light' | 'dark'
  textFontWeight?: number
  text?: string | undefined
  widthMode?: WidthModeValues
  heightMode?: HeightModeValues
  scalarHeightMode?: boolean
  gradient?: boolean
  backgroundColor?: string
  onClick?: any
  clickable?: boolean
  isLoading?: boolean
  sizeMode?: ButtonModeValues
  setTriggerValidate?: React.Dispatch<React.SetStateAction<boolean>>
  isValid?: boolean
  borderRadius?: string
}

function StandardButton (props: StandardButtonProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const onClick = props.onClick ?? (() => {})
  let height, width

  if (props.sizeMode) {
    if (BUTTON_MODES[props.sizeMode]?.mobile) {
      if (isMobile) {
        height = BUTTON_MODES[props.sizeMode].mobile.height
        width = BUTTON_MODES[props.sizeMode].mobile.width
      } else {
        height = BUTTON_MODES[props.sizeMode].browser.height
        width = BUTTON_MODES[props.sizeMode].browser.width
      }
    } else {
      height = BUTTON_MODES[props.sizeMode].height
      width = BUTTON_MODES[props.sizeMode].width
    }
  } else {
    height = isMobile
      ? HEIGHT_MODES[props.heightMode ?? HeightModeValues.DEFAULT].mobile
      : HEIGHT_MODES[props.heightMode ?? HeightModeValues.DEFAULT].browser

    width = isMobile
      ? WIDTH_MODES[props.widthMode ?? WidthModeValues.DEFAULT].mobile
      : WIDTH_MODES[props.widthMode ?? WidthModeValues.DEFAULT].browser
  }
  const clickable = props?.clickable === undefined ? true : props.clickable
  const textColor = props.textColor ?? 'white'
  const backgroundColorMode = props.backgroundColorMode ?? 'light'
  const validInputs = props.isValid ?? true
  const handleOnclick = () => {
    if (clickable) {
      if (props.setTriggerValidate) {
        props.setTriggerValidate(true)
      }
      if (validInputs) {
        onClick()
      }
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === KEYBOARD_KEYS.ENTER) {
      handleOnclick()
    }
  }
  return (
    <div style={{ cursor: clickable ? 'pointer' : 'not-allowed' }}>
      <div
        autoFocus={true}
        onClick={handleOnclick}
        tabIndex={0}
        style={{
          display: 'flex',
          width,
          height,
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
          borderRadius: props.borderRadius ? props.borderRadius : '5px',
          paddingLeft: width === 'auto' ? '10px' : '',
          paddingRight: width === 'auto' ? '10px' : '',
          background: clickable ? props.backgroundColor ? props.backgroundColor : resolveColor(props, backgroundColorMode) : '#BDBDBD',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
          pointerEvents: props.isLoading || !clickable ? 'none' : 'auto',
          ...props.style
        }}
        onKeyDown={handleKeyDown}>
        {props.isLoading
          ? <BeatLoader color={textColor}/>
          : (<ColoredText
              color={textColor}
              whiteSpace={'nowrap'}
              fontWeight={props.textFontWeight ?? 500}
              fontFamily={props.textFontFamily}
              fontSize={props.textFontSize}
              userSelect={'none'}
              cursor={clickable ? 'pointer' : 'not-allowed'}
              singleText={props.text ? props.text : 'Click'}/>)}
      </div>
    </div>
  )
}

const resolveColor = (props: StandardButtonProps, mode: 'light' | 'dark') => {
  if (props.gradient) {
    return 'linear-gradient(90deg, #880CE8 0%, #B616B4 100%)'
  }
  return mode === 'light' ? COLORS.MAIN_COLOR : COLORS.MAIN_COLOR_DARK
}

StandardButton.defaultProps = {
  text: 'Click'
}

export default StandardButton
