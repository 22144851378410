import React, { useEffect, useMemo, useState } from 'react'
import { FontModeValues } from '../modes/FontMode'
import ColoredText from '../text/ColoredText'

interface TimerProps {
  mode?: 'Increase' | 'Decrease'
  startValue?: number
  setValue?: React.Dispatch<React.SetStateAction<number>>
  color?: string
  fontSize?: FontModeValues
  onClick?: () => void
  text?: string
  textWhenTimerIsZero?: string
  clickable?: boolean
  isResettable?: boolean
  textDecorationAfterTimerIsZero?: 'underline' | 'none' | 'auto'
}

function Timer (props: TimerProps) {
  const mode = props.mode ?? 'Decrease'
  const initialValue = mode === 'Decrease' ? props.startValue ?? 60 : 0
  const [startValue, setStartValue] = useState<number>(mode === 'Decrease' ? props.startValue ?? 60 : 0)
  const [resetFlag, setResetFlag] = useState<boolean>(false)
  const text = props.text ?? ''
  const clickable = props.clickable ?? true
  const isResettable = props.isResettable ?? false
  const onClick = props.onClick ?? (() => {})
  const textDecorationAfterTimerIsZero = props.textDecorationAfterTimerIsZero ?? 'none'

  const handleOnClick = () => {
    if (clickable && startValue === 0 && isResettable) {
      setStartValue(initialValue)
      setResetFlag(prevState => !prevState)
      onClick()
    } else if (clickable && startValue === 0 && !isResettable) { onClick() }
  }
  useEffect(() => {
    const timer = setInterval(() => {
      if (mode === 'Increase') {
        setStartValue(prev => prev + 1)
      } else {
        setStartValue(prev => {
          if (props.setValue) {
            props.setValue(prev - 1)
          }
          return prev - 1
        })
      }
    }, 1000)
    setTimeout(() => {
      clearInterval(timer)
    }, (props.startValue ?? 60) * 1000)
  }, [resetFlag])

  const minutes = useMemo(() => {
    return Math.floor(startValue / 60) < 10 ? '0' + Math.floor(startValue / 60) : Math.floor(startValue / 60)
  }, [startValue])

  const seconds = useMemo(() => {
    return (startValue - Math.floor(startValue / 60) * 60) < 10 ? '0' + (startValue - Math.floor(startValue / 60) * 60) : (startValue - Math.floor(startValue / 60) * 60)
  }, [startValue])

  return (
    <ColoredText
      color={props.color}
      fontSize= {props.fontSize ?? FontModeValues.DEFAULT}
      singleText = {startValue !== 0 ? text + ' ' + (startValue >= 60 ? minutes + ':' + seconds : '00' + ':' + seconds) : props.textWhenTimerIsZero ?? text + ' ' + (startValue >= 60 ? minutes + ':' + seconds : '00' + ':' + seconds)}
      onClick={handleOnClick}
      textDecoration={startValue !== 0 ? 'none' : textDecorationAfterTimerIsZero}
      cursor={(clickable && startValue === 0) ? 'pointer' : 'auto'}/>
  )
}

export default Timer
