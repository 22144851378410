import React, { useEffect, useState } from 'react'
import ColoredText from '../../components/text/ColoredText'
import { type NavigationRoute } from '../../enumeration/NavigationRoute'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'

interface SettingsActionInterface {
  title: string
  iconSrc: string
  navigationRoute?: NavigationRoute
  onClick?: () => any
}

function SettingAction (props: SettingsActionInterface) {
  const [selected, setSelected] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setSelected(location.pathname === props.navigationRoute)
  }, [location.pathname])

  const handleClick = () => {
    if (props.onClick) {
      void props.onClick().then(() => {
        if (props.navigationRoute) {
          navigate(props.navigationRoute)
        }
      }).catch(() => {})
    } else if (props.navigationRoute) {
      navigate(props.navigationRoute)
    }
  }

  return (
    <div
      onClick={handleClick}
      className={'d-flex flex-row align-items-center gap-1'}
      style={{
        cursor: 'pointer',
        userSelect: 'none',
        padding: '5px',
        backgroundColor: selected ? 'rgba(217,217,217,0.63)' : '#FFFFFF',
        borderRadius: '5px'
      }}>
      <img
        alt={props.title}
        src={props.iconSrc}
        width="23px"
        height="23px"/>
      <ColoredText
        fontSize={'16px'}
        singleText={props.title}/>
    </div>
  )
}

export default SettingAction
