import React, { useEffect } from 'react'
import { AuthManager } from '../../manager/AuthManager'
import GiverOrTakerSide from './interviewComponents/GiverOrTakerSide'
import { useParams } from 'react-router-dom'

function VideoChatPage () {
  const { interviewID } = useParams()

  const body = AuthManager.getAccessTokenBody()
  useEffect(() => {
    document.body.style.background = '#202124'
    return () => { document.body.style.background = 'white' }
  }, [])

  return (
    <div>
      {interviewID && <GiverOrTakerSide role={body?.role} interviewId={interviewID}/>}
    </div>
  )
}

export default VideoChatPage
