/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetInterviewBalanceCardDto,
  InterviewBalanceCardCreationDto,
  InterviewBalanceCardEditDto,
} from '../models/index';
import {
    GetInterviewBalanceCardDtoFromJSON,
    GetInterviewBalanceCardDtoToJSON,
    InterviewBalanceCardCreationDtoFromJSON,
    InterviewBalanceCardCreationDtoToJSON,
    InterviewBalanceCardEditDtoFromJSON,
    InterviewBalanceCardEditDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface CreateCardRequest {
    interviewBalanceCardCreationDto: InterviewBalanceCardCreationDto;
}

export interface DeleteCardRequest {
    id: string;
}

export interface EditTagRequest {
    interviewBalanceCardEditDto: InterviewBalanceCardEditDto;
}

export interface GetRequest {
    id?: string;
}

/**
 * 
 */
export class InterviewBalanceCardControllerApi extends runtime.BaseAPI {

    /**
     * Creates an interview balance card
     * Create interview balance card
     */
    async createCardRaw(requestParameters: CreateCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardDto>> {
        if (requestParameters['interviewBalanceCardCreationDto'] == null) {
            throw new runtime.RequiredError(
                'interviewBalanceCardCreationDto',
                'Required parameter "interviewBalanceCardCreationDto" was null or undefined when calling createCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/balanceCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewBalanceCardCreationDtoToJSON(requestParameters['interviewBalanceCardCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview balance card
     * Create interview balance card
     */
    async createCard(requestParameters: CreateCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardDto> {
        const response = await this.createCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an interview balance card
     * Delete interview balance card
     */
    async deleteCardRaw(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCard/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardDtoFromJSON(jsonValue));
    }

    /**
     * Deletes an interview balance card
     * Delete interview balance card
     */
    async deleteCard(requestParameters: DeleteCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardDto> {
        const response = await this.deleteCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an interview balance card
     * Create interview balance card
     */
    async editTagRaw(requestParameters: EditTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardDto>> {
        if (requestParameters['interviewBalanceCardEditDto'] == null) {
            throw new runtime.RequiredError(
                'interviewBalanceCardEditDto',
                'Required parameter "interviewBalanceCardEditDto" was null or undefined when calling editTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/balanceCard/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewBalanceCardEditDtoToJSON(requestParameters['interviewBalanceCardEditDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview balance card
     * Create interview balance card
     */
    async editTag(requestParameters: EditTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardDto> {
        const response = await this.editTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an interview balance card
     * Get interview balance card
     */
    async getRaw(requestParameters: GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardDto>> {
        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardDtoFromJSON(jsonValue));
    }

    /**
     * Gets an interview balance card
     * Get interview balance card
     */
    async get(requestParameters: GetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardDto> {
        const response = await this.getRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all interview balance cards
     * Get all interview balance cards
     */
    async getAllCardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewBalanceCardDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCard/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewBalanceCardDtoFromJSON));
    }

    /**
     * Gets all interview balance cards
     * Get all interview balance cards
     */
    async getAllCards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewBalanceCardDto>> {
        const response = await this.getAllCardsRaw(initOverrides);
        return await response.value();
    }

}
