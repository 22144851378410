import React, { useEffect, useState } from 'react'
import InterviewTicketCard from './InterviewTicketCard'
import { type GetInterviewBalanceCardDto } from '../../api/ic'

interface InterviewBalanceCardsProps {
  handleChange: (ticket: GetInterviewBalanceCardDto, type: 'fulfill') => void
  fulfillCards: GetInterviewBalanceCardDto[]
}
function InterviewBalanceCards (props: InterviewBalanceCardsProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  useEffect(() => {
    props.handleChange(props.fulfillCards[selectedIndex], 'fulfill')
  }, [selectedIndex])
  return (
    <>
      {props.fulfillCards.length > 0 ? <>
        {props.fulfillCards.map((card: GetInterviewBalanceCardDto, key: number) => {
          return (
            <InterviewTicketCard
              tags={card.tags}
              interviewCount={card.interviewCount}
              price={card.price}
              discountPrice={card.discountPrice}
              key={key}
              index={key}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}/>
          )
        })}
      </>
        : <label>No Fulfill Cards Available</label>}
    </>
  )
}

export default InterviewBalanceCards
