import React from 'react'
import ColoredText from '../../components/text/ColoredText'
import Line from '../../components/util/Line'
import { type NavigationRoute } from '../../enumeration/NavigationRoute'

interface SettingBodyInterface {
  title: string
  navigationRoute: NavigationRoute
  children: React.ReactNode
}

function SettingBody (props: SettingBodyInterface) {
  return (
    <div className={'d-flex flex-column gap-3'}>
      <div className={'d-flex flex-column gap-2'}>
        <ColoredText singleText={props.title} fontSize={'22px'}/>
        <Line lineWidth={'100%'}/>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  )
}

export default SettingBody
