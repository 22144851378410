import React, { useEffect, useState } from 'react'
import MultipleAutocomplete from './multipleAutocomplete'
import { type EnumTypeDto } from '../../api/ic'
import { arraysEqual } from './helper'

interface EnumValueDropDownProps {
  items: EnumTypeDto[]
  selectedValues: EnumTypeDto[]
  setSelectedValue: React.Dispatch<React.SetStateAction<EnumTypeDto[]>>
  label: string
  disabled?: boolean
  isRequired?: boolean
  width?: string
  error?: boolean
  onChange?: () => void
}
function EnumValueMultipleAutocomplete (props: EnumValueDropDownProps) {
  const [selectedValues, setSelectedValues] = useState(props.selectedValues ? props.selectedValues.map(option => option.name ?? '') : []) // TODO: ?? '' is not needed but need to understand what can i do with that
  const items: string[] = []
  props.items.forEach(e => {
    if (e.name) {
      items.push(e.name)
    }
  })

  useEffect(() => {
    if (!arraysEqual(props.selectedValues.map(option => option.name ?? ''), selectedValues)) {
      const objs = props.items.filter(obj => selectedValues.includes(obj.name))
      props.setSelectedValue(objs ?? [])
    }
  }, [selectedValues,
    props.items,
    props.selectedValues])
  return (
    <MultipleAutocomplete
      onChange={props.onChange}
      error={props.error}
      isRequired={props.isRequired}
      setSelectedValues={setSelectedValues}
      selectedValues={selectedValues}
      options={items}
      disabled={props.disabled}
      label={props.label}
      width={props.width ?? '100%'}
      labelFontSize="14px"/>
  )
}

export default EnumValueMultipleAutocomplete
