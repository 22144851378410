import React, { useEffect, useState } from 'react'
import StandardList from '../giver/StandardList'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import { WidthModeValues } from '../components/modes/WidthMode'
import MarginWrapper from '../components/util/MarginWrapper'
import { useNavigate } from 'react-router-dom'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { type GetInterviewBalanceCardDto, InterviewBalanceCardControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'

function AllBalanceCardTagsListPage () {
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)
  const [rows, setRows] = useState<GetInterviewBalanceCardDto[]>([])
  const navigate = useNavigate()
  const columns = [
    'checkbox',
    'id',
    'text',
    'interviewCount',
    'price',
    'discountPrice',
    'balanceType'
  ]

  const balanceCardApi = ApiManager.getInstance(InterviewBalanceCardControllerApi)

  useEffect(() => {
    void balanceCardApi.getAllCards()
      .then((res: GetInterviewBalanceCardDto[]) => {
        setRows(res)
      }).catch(() => {
      })
  }, [])

  const handleSelect = (id: string) => {
    setCurrentId(id)
  }

  const edit = () => {
    if (!currentId) {
      console.log('currentId is null')
      return
    }
    navigate(NavigationRoute.ADMIN_EDIT_INTERVIEW_BALANCE_CARD.replace('/:id', `/${String(currentId)}`))
  }

  const deleteCard = () => {
    if (!currentId) {
      console.log('currentId is null')
      return
    }
    void balanceCardApi.deleteCard({ id: currentId })
      .then(() => {
        window.location.reload()
      }).catch(() => {
      })
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'All cards'}/>
      <StandardList
        rows={rows}
        withArrow={true}
        handleCheckbox={handleSelect}
        SetIsUpcomingBlank={() => {
        }}
        columnNames={columns}
        deleteButton={false}/>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row'}>
          {rows.length
            ? <MarginWrapper left={'20px'}>
              <div className={'d-flex flex-row'}>
                <StandardButton
                  clickable={!!currentId}
                  text={'Edit'}
                  onClick={edit}
                  widthMode={WidthModeValues.EXTRA_SMALL}/>
                <MarginWrapper left={'20px'}>
                  <StandardButton
                    clickable={!!currentId}
                    text={'Delete'}
                    onClick={deleteCard}
                    widthMode={WidthModeValues.EXTRA_SMALL}/>
                </MarginWrapper>
              </div>
            </MarginWrapper>
            : 'No Tags'}
        </div>
      </MarginWrapper>
    </div>
  )
}

export default AllBalanceCardTagsListPage
