import React from 'react'
import ColoredText, { type ColoredTextItemConfig } from '../text/ColoredText'
import MarginWrapper from '../util/MarginWrapper'
import { FontModeValues } from '../modes/FontMode'
import { BUTTON_MODES, type ButtonModeValues } from '../modes/ButtonMode'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface ButtonWithSvgProps {
  text: ColoredTextItemConfig[]
  onclick?: () => void
  svg: string
  sizeMode: ButtonModeValues
}

function ButtonWithSvg (props: ButtonWithSvgProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  let height, width

  if (BUTTON_MODES[props.sizeMode]?.mobile) {
    if (isMobile) {
      height = BUTTON_MODES[props.sizeMode].mobile.height
      width = BUTTON_MODES[props.sizeMode].mobile.width
    } else {
      height = BUTTON_MODES[props.sizeMode].browser.height
      width = BUTTON_MODES[props.sizeMode].browser.width
    }
  } else {
    height = BUTTON_MODES[props.sizeMode].height
    width = BUTTON_MODES[props.sizeMode].width
  }
  return (
    <div
      style={{
        display: 'flex',
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
        userSelect: 'none',
        cursor: 'pointer'
      }}>
      <a
        onClick={props.onclick}
        style={{ textDecoration: 'none' }}>
        <div
          style={{ display: 'flex', alignItems: 'center' }}>
          <MarginWrapper
            right={'10px'}
            top={'-4px'}>
            <img
              src={props.svg}
              alt={'svg'}
              draggable={false}/>
          </MarginWrapper>
          <ColoredText
            fontSize={FontModeValues.SMALL}
            fontFamily={'Roboto, sans-serif'}
            fontWeight={500}
            whiteSpace={'nowrap'}
            color={'rgba(0, 0, 0, 0.54)'}
            texts={props.text}
            userSelect={'none'}
            cursor={'pointer'}/>
        </div>
      </a>
    </div>
  )
}

export default ButtonWithSvg
