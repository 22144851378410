import { ReduxActionType } from '../enumeration/ReduxActionType'
import { type LoginRequestDto } from '../api/ic'

export const getLoginUser = (state: any) => {
  return state.loginUser
}

export const loginUserReducer = (state: LoginRequestDto = { email: '', password: '' }, action: any) => {
  switch (action.type) {
    case ReduxActionType.ADD_LOGIN_USER_EMAIL:
      return { ...state, email: action.payload }
    case ReduxActionType.CLEAR_LOGIN_USER:
      return { email: '' }
    default:
      return state
  }
}

export const clearLoginUser = () => {
  return {
    type: ReduxActionType.CLEAR_LOGIN_USER
  }
}

export const addLoginUserEmail = (value: any) => {
  return {
    type: ReduxActionType.ADD_LOGIN_USER_EMAIL,
    payload: value
  }
}
