/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetBankCardDto } from './GetBankCardDto';
import {
    GetBankCardDtoFromJSON,
    GetBankCardDtoFromJSONTyped,
    GetBankCardDtoToJSON,
} from './GetBankCardDto';

/**
 * 
 * @export
 * @interface GetBankCardsDto
 */
export interface GetBankCardsDto {
    /**
     * 
     * @type {Array<GetBankCardDto>}
     * @memberof GetBankCardsDto
     */
    cards: Array<GetBankCardDto>;
    /**
     * Max allowed cards number
     * @type {number}
     * @memberof GetBankCardsDto
     */
    maxAllowedCardsNumber: number;
}

/**
 * Check if a given object implements the GetBankCardsDto interface.
 */
export function instanceOfGetBankCardsDto(value: object): value is GetBankCardsDto {
    if (!('cards' in value) || value['cards'] === undefined) return false;
    if (!('maxAllowedCardsNumber' in value) || value['maxAllowedCardsNumber'] === undefined) return false;
    return true;
}

export function GetBankCardsDtoFromJSON(json: any): GetBankCardsDto {
    return GetBankCardsDtoFromJSONTyped(json, false);
}

export function GetBankCardsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBankCardsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cards': ((json['cards'] as Array<any>).map(GetBankCardDtoFromJSON)),
        'maxAllowedCardsNumber': json['maxAllowedCardsNumber'],
    };
}

export function GetBankCardsDtoToJSON(value?: GetBankCardsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cards': ((value['cards'] as Array<any>).map(GetBankCardDtoToJSON)),
        'maxAllowedCardsNumber': value['maxAllowedCardsNumber'],
    };
}

