/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignupResponseDto
 */
export interface SignupResponseDto {
    /**
     * User email
     * @type {string}
     * @memberof SignupResponseDto
     */
    email: string;
}

/**
 * Check if a given object implements the SignupResponseDto interface.
 */
export function instanceOfSignupResponseDto(value: object): value is SignupResponseDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function SignupResponseDtoFromJSON(json: any): SignupResponseDto {
    return SignupResponseDtoFromJSONTyped(json, false);
}

export function SignupResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function SignupResponseDtoToJSON(value?: SignupResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

