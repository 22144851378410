import React from 'react'
import ColoredText from '../components/text/ColoredText'
import GiverUpcomingCard from './GiverUpcomingCard'
import { type GetInterviewDto } from '../api/ic'

interface GiverUpcomingCardsProps {
  upcomingInterviews: GetInterviewDto[]
  setTriggerUpcomingAndCurrentInterviewDataFetching: React.Dispatch<React.SetStateAction<boolean>>
  setIsMainModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function GiverUpcomingCards (props: GiverUpcomingCardsProps) {
  const list = props.upcomingInterviews
  list.sort((a, b) => {
    if (a.acceptedDate && b.acceptedDate) {
      if (a.acceptedDate > b.acceptedDate) {
        return 1
      } else if (a.acceptedDate < b.acceptedDate) {
        return -1
      }
    }
    return 0
  })
  return (
    <div style={{ marginBottom: list.length === 1 || list.length === 2 ? '100px' : 'auto' }}
      className={`d-flex flex-wrap justify-content-${!list.length ? 'center' : 'start'} align-items-center gap-4`}>
      {!list.length && (
        <ColoredText singleText={'No upcoming Interviews. request a new one'}/>
      )}
      {list.length > 0 && (
        <>
          {list.map((obj) => (
            <GiverUpcomingCard
              setTriggerUpcomingAndCurrentInterviewDataFetching={props.setTriggerUpcomingAndCurrentInterviewDataFetching}
              setIsMainModalOpen={props.setIsMainModalOpen}
              key={obj.id}
              interview={obj}
            />
          ))}
        </>
      )}

    </div>
  )
}

export default GiverUpcomingCards
