import { LogoModeValues } from './LogoModes'
import { ColorMode } from './ColorMode'

interface NavigationTypeInterface {
  mobile: NavigationTypeItemInterface
  browser: NavigationTypeItemInterface
  scrolled: NavigationTypeItemInterface
}

interface NavigationTypeItemInterface {
  logoMode: LogoModeValues
  borderButton?: boolean
  navigationProfile?: boolean
  borderButtonMode?: ColorMode
  position?: 'fixed' | 'static'
  backgroundColor?: string
  navItemsMode?: ColorMode
  boxShadow?: string
  interviewText?: boolean
  interviewTextMode?: ColorMode
}

export const enum NavigationModeValues {
  FLOATING = 'FLOATING',
  FLOATING_WITH_BORDERED_BUTTON = 'FLOATING_WITH_BORDERED_BUTTON',
  FIXED_MAIN = 'FIXED_MAIN',
  FIXED_MAIN_WITH_PLAN_AND_PROFILE = 'FIXED_MAIN_WITH_PLAN_AND_PROFILE',
  FLOATING_WITH_BORDERED_BUTTON_REVERSE = 'FLOATING_WITH_BORDERED_BUTTON_REVERSE',
  FIXED_WHITE_WITH_INTERVIEW_INFO = 'FIXED_WHITE_WITH_INTERVIEW_INFO'
}

export const NAVIGATION_MODES: Record<NavigationModeValues, NavigationTypeInterface> = {
  [NavigationModeValues.FLOATING]: {
    mobile: {
      logoMode: LogoModeValues.SMALL_DEFAULT
    },
    browser: {
      logoMode: LogoModeValues.DEFAULT
    },
    scrolled: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    }
  },
  [NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON]: {
    mobile: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      borderButton: true
    },
    browser: {
      logoMode: LogoModeValues.DEFAULT,
      borderButton: true
    },
    scrolled: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButton: true,
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    }
  },
  [NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON_REVERSE]: {
    mobile: {
      logoMode: LogoModeValues.SMALL_WHITE,
      borderButton: true,
      backgroundColor: '#8C0CF0'
    },
    browser: {
      logoMode: LogoModeValues.DEFAULT,
      borderButton: true

    },
    scrolled: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButton: true,
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    }
  },
  [NavigationModeValues.FIXED_MAIN_WITH_PLAN_AND_PROFILE]: {
    mobile: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
      navigationProfile: true
    },
    browser: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
      navigationProfile: true
    },
    scrolled: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
      navigationProfile: true
    }
  },
  [NavigationModeValues.FIXED_MAIN]: {
    mobile: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    },
    browser: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    },
    scrolled: {
      logoMode: LogoModeValues.SMALL_DEFAULT,
      backgroundColor: 'white',
      position: 'fixed',
      borderButtonMode: ColorMode.DARK,
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'
    }
  },
  [NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO]: {
    mobile: {
      logoMode: LogoModeValues.DEFAULT_SMALL_WHITE,
      backgroundColor: 'rgba(32, 33, 36, 1)',
      navItemsMode: ColorMode.LIGHT,
      interviewText: true,
      interviewTextMode: ColorMode.LIGHT
    },
    browser: {
      logoMode: LogoModeValues.DEFAULT_SMALL_WHITE,
      backgroundColor: 'rgba(32, 33, 36, 1)',
      navItemsMode: ColorMode.LIGHT,
      interviewTextMode: ColorMode.LIGHT,
      interviewText: true

    },
    scrolled: {
      logoMode: LogoModeValues.DEFAULT_SMALL_WHITE,
      backgroundColor: 'rgba(32, 33, 36, 1)',
      interviewTextMode: ColorMode.LIGHT,
      navItemsMode: ColorMode.LIGHT,
      interviewText: true
    }
  }
}
