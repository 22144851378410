import valid from 'card-validator'
import visa from '../components/svg/resources/visa.svg'
import master from '../components/svg/resources/master.svg'
import amex from '../components/svg/resources/amex.svg'
import default_icon from '../components/svg/resources/bank_card_default_icon.svg'

type CardType =
  | 'visa'
  | 'mastercard'
  | 'american-express'
  | 'diners-club'
  | 'discover'
  | 'jcb'
  | 'unionpay'
  | 'maestro'
  | 'elo'
  | 'mir'
  | 'hiper'
  | 'hipercard'

export const getCardType = (cardNumber: string): CardType | undefined => {
  const cardInfo = valid.number(cardNumber)
  if (cardInfo.card) {
    return cardInfo.card.type as CardType
  }
  return undefined
}

export const getCardImageByType = (type: CardType | undefined) => {
  switch (type) {
    case 'visa': return visa
    case 'mastercard': return master
    case 'american-express': return amex
  }
  return default_icon
}
