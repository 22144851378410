import React from 'react'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { COLORS } from '../../util/Consts'
import StandardTooltip from '../util/StandardTooltip'
import infoIcon from '../svg/resources/info.svg'
import { type ColoredTextItemConfig } from '../text/ColoredText'

const customStyles = {
  textField: {
    border: '0.5px solid #000', // Black border for the TextField
    borderRadius: '6px', // Optional: Add border radius for better aesthetics
    '& fieldset': {
      border: 'none' // Remove default border style
    }
  },
  selectedOption: {
    fontSize: '12px',
    color: 'black',
    backgroundColor: 'transparent', // Black background for selected options
    border: '0.5px solid rgba(0,0,0,0.5)',
    borderRadius: '8px'
  }
}

interface MultipleAutocompleteProps {
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
  selectedValues: string[]
  label?: string
  options: string[]
  labelFontSize?: string
  height?: string
  width?: string
  hintText?: ColoredTextItemConfig[]
  isRequired?: boolean
  disabled?: boolean
  error?: boolean
  onChange?: () => void
}

function MultipleAutocomplete (props: MultipleAutocompleteProps) {
  const isRequired = props.isRequired ?? false
  return (
    <div className={'d-flex flex-column justify-content-start'} style={{ width: props.width ?? '340px' }}>
      <span className={'d-flex flex-row align-items-center'}>
        <span style={{ fontSize: props.labelFontSize ?? '14px', marginBottom: '4px' }}>
          {props.label}
          {isRequired && <span style={{ color: 'red', fontSize: '14px', userSelect: 'none' }}>*</span>}
        </span>
        {props.hintText &&
        <StandardTooltip texts={props.hintText}>
          <img
            src={infoIcon}
            alt={'Info'}
            style={{ marginLeft: '2px', marginBottom: '4px' }}/>
        </StandardTooltip>}
      </span>
      <span style={{
        width: '100%',
        marginBottom: '10px',
        pointerEvents: props.disabled ? 'none' : 'auto',
        cursor: props.disabled ? 'not-allowed' : 'pointer'
      }}>
        <Autocomplete
          key={JSON.parse(JSON.stringify(props.selectedValues)).toString() + 'mui-autocomplete'}
          multiple
          disablePortal
          id="tags-outlined"
          options={props.options}
          sx={{
            '& .MuiAutocomplete-inputRoot': {
              padding: '2px 2px' // Adjust padding as needed
            }
          }}
          slotProps={{
            popper: {
              sx: {
                marginThreshold: null,
                paddingTop: '3px',
                borderRadius: '6px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                maxHeight: 180,
                zIndex: 100,
                maxWidth: props.width
              }
            },
            paper: {
              sx: {
                marginThreshold: null,
                overflow: 'hidden',
                width: props.width,
                '& .MuiAutocomplete-listbox': {
                  '& .MuiAutocomplete-option': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }
                }
              }
            }
          }}
          defaultValue={props.selectedValues}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              key={JSON.parse(JSON.stringify(props.selectedValues))}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: null // Remove the arrow icon
              }}
              placeholder={props.disabled ? '' : 'Add +'}
              disabled={props.disabled}
              sx={{
                ...customStyles.textField,
                '& input::placeholder': {
                  color: COLORS.MAIN_COLOR,
                  opacity: 1
                },
                background: props.disabled ? '#f5f5f5' : 'rgba(255, 255, 255, 0.80)',
                border: 'none',
                boxShadow: props.error ? '0px 0px 4px 0px #FF0001' : '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                input: { cursor: 'pointer' },
                paddingTop: '0px',
                paddingBottom: '0px'
              }}/>
          )}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
              const { key, ...restProps } = getTagProps({ index })

              // eslint-disable-next-line react/jsx-key
              return <>
                {props.disabled && (
                <Chip
                  label={option}
                  deleteIcon={<></>}
                  {...restProps}
                  key={option + '-' + key}
                  style={{ ...customStyles.selectedOption, color: 'black !important' }}/>
                )}
                {!props.disabled && (
                <Chip
                  label={option}
                  {...restProps}
                  key={option + '-' + key}
                  style={{ ...customStyles.selectedOption }}/>
                )}
              </>
            }
            )
          } }
          onChange={(e, v) => {
            props.setSelectedValues(v.length ? v : [])
            if (props.onChange) {
              props.onChange()
            }
          }}/>
      </span>
    </div>
  )
}

export default MultipleAutocomplete
