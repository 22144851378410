export const convertIsoIntoLocalDate = (IsoStrings: string[] | Set<string> | undefined): Date[] => {
  if (!IsoStrings) {
    return []
  }
  const IsoStringArray = Array.from(IsoStrings)
  return IsoStringArray.map(iso => new Date(iso))
}

export function getHHmmStringFromDate (date: Date): string {
  const hours = String(date.getHours()).padStart(2, '0') // Get hours and pad with leading zero if necessary
  const minutes = String(date.getMinutes()).padStart(2, '0') // Get minutes and pad with leading zero if necessary
  return `${hours}:${minutes}`
}
