import React from 'react'
import StandardList from './StandardList'
import python from '../components/svg/resources/python.svg'
import interviewer_face from '../components/svg/resources/interviewer_face.svg'

interface HistoryListProps {
  setIsHistoryBlank?: React.Dispatch<React.SetStateAction<boolean>>
  columnNames: string[]
}

function HistoryList (props: HistoryListProps) {
  // TODO fetch to backend and get row data which is something like this:
  const rows = [
    {
      id: 4,
      rate: 5,
      languages: ['Armenian', 'English'],
      interviewIcon: python,
      interviewName: 'python',
      interviewer: {
        id: '123',
        name: 'John Smith',
        image: interviewer_face
      },
      level: 'Advanced',
      recording_duration: '38:12',
      date: 'Aug 03 2023, 18:45'
    },
    {
      id: 2,
      rate: 4.2,
      languages: ['English'],
      interviewIcon: python,
      interviewName: 'python',
      interviewer: {
        id: '123',
        name: 'John Smith',
        image: interviewer_face
      },
      level: 'Intermediate',
      recording_duration: '42:17',
      date: 'Aug 02 2023, 18:45'
    },
    {
      id: 1,
      rate: 2.3,
      languages: ['Armenian',
        'English',
        'German',
        'French',
        'Chinese',
        'Russian'],
      interviewIcon: python,
      interviewName: 'python',
      interviewer: {
        id: '123',
        name: 'John Smith',
        image: interviewer_face
      },
      level: 'Advanced',
      recording_duration: '53:36',
      date: 'Aug 01 2023, 18:45'
    }
  ]

  return (
    <StandardList
      rows={rows}
      withArrow={true}
      SetIsUpcomingBlank={props.setIsHistoryBlank ?? (() => {})}
      columnNames={props.columnNames}
      deleteButton={false}/>
  )
}

export default HistoryList
