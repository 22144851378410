import React, { useEffect, useState } from 'react'
import StandardList from '../giver/StandardList'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import { WidthModeValues } from '../components/modes/WidthMode'
import MarginWrapper from '../components/util/MarginWrapper'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import ApiManager from '../manager/ApiManager'
import { type GetInterviewTypeDto, InterviewTypeControllerApi } from '../api/ic'

function AllInterviewTypesPage () {
  const [currentId, setCurrentId] = useState<string | undefined>(undefined)
  const [rows, setRows] = useState<GetInterviewTypeDto[]>([])
  const [availableButtonValue, setAvailableButtonValue] = useState('Select Row...')
  const navigate = useNavigate()

  const interviewTypeApi = ApiManager.getInstance(InterviewTypeControllerApi)

  useEffect(() => {
    void interviewTypeApi.getAllInterviewTypes()
      .then(res => { setRows(res) })
      .catch(() => {})
  }, [])

  const handleSelect = (id: any) => {
    setCurrentId(id)
  }

  useEffect(() => {
    if (currentId) {
      const currRow: any = getRowById(currentId)
      if (currRow.available) {
        setAvailableButtonValue('Make Unavailable')
      } else {
        setAvailableButtonValue('Make Available')
      }
    } else {
      setAvailableButtonValue('Select Row...')
    }
  }, [currentId])

  const getRowById = (currentId: string) => {
    let currRow: any = {}
    for (const row of rows) {
      if (row.id === currentId) {
        currRow = row
        break
      }
    }
    return currRow
  }

  const deleteType = async () => {
    if (!currentId) {
      toast('Missing Id to delete')
      return
    }

    await interviewTypeApi.deleteInterviewType({ id: currentId })
      .then(res => {
        toast.success(`Successfully deleted ${res.name}`)
        window.location.reload()
      }).catch(() => {})
  }

  const edit = () => {
    navigate(NavigationRoute.ADMIN_EDIT_INTERVIEW_TYPE.replace('/:id', currentId ? `/${String(currentId)}` : ''))
  }

  const makeAvailable = async () => {
    if (!currentId) {
      toast.error('Id is required')
      return
    }
    const currRow: GetInterviewTypeDto = getRowById(currentId)
    if (currRow.available) {
      await interviewTypeApi.changeAvailability({ id: currentId, availability: false }, currRow as RequestInit)
        .then(res => {
          toast.success(`${res.name} (${res.type}) is now unavailable`)
          window.location.reload()
        }).catch(() => {})
    } else {
      await interviewTypeApi.changeAvailability({ id: currentId, availability: true }, currRow as RequestInit)
        .then(res => {
          toast.success(`${res.name} (${res.type}) is now available`)
          window.location.reload()
        }).catch(() => {})
    }
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'See All Interviews'}/>
      <StandardList
        rows={rows}
        withArrow={true}
        handleCheckbox={handleSelect}
        SetIsUpcomingBlank={() => {
        }}
        columnNames={[
          'checkbox',
          'id',
          'interview_type_icon',
          'name',
          'type',
          'description',
          'available']}
        deleteButton={false}/>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row'}>
          <StandardButton
            clickable={!!currentId}
            text={availableButtonValue}
            onClick={makeAvailable}
            widthMode={WidthModeValues.EXTRA_SMALL}/>
          <MarginWrapper left={'20px'}>
            <StandardButton
              clickable={!!currentId}
              text={'Edit'}
              onClick={edit}
              widthMode={WidthModeValues.EXTRA_SMALL}/>
          </MarginWrapper>
          <MarginWrapper left={'20px'}>
            <StandardButton
              clickable={!!currentId}
              text={'Delete'}
              onClick={deleteType}
              widthMode={WidthModeValues.EXTRA_SMALL}/>
          </MarginWrapper>
        </div>
      </MarginWrapper>
    </div>
  )
}

export default AllInterviewTypesPage
