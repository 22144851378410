import React, { type CSSProperties } from 'react'
import { FONT_MODES, type FontModeValues, isFontModeValue } from '../modes/FontMode'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

export interface ColoredTextProps extends ColoredTextItem {
  centered?: boolean
  lineHeight?: number
  texts?: ColoredTextItemConfig[]
  parameters?: string[]
  coloredTextParameters?: ColoredTextItemConfig[][]
  singleText?: string
  textDecoration?: 'underline' | 'transparent' | 'none' | 'auto'
  maxLength?: number
}

export interface ColoredTextItem {
  color?: string
  fontSize?: FontModeValues | string
  fontFamily?: string
  fontWeight?: number
  whiteSpace?: 'nowrap' | 'normal'
  pointerEvents?: any
  userSelect?: 'none' | 'auto'
  onClick?: any
  cursor?: 'none' | 'auto' | 'pointer' | 'not-allowed' | 'inherit'
  textDecoration?: string
  id?: any
}

export interface ColoredTextItemConfig extends ColoredTextItem {
  newLine?: boolean
  separator?: string
  value?: string
  parameterIndex?: number
  stylePriority?: boolean
}

function getTextConfigs (props: ColoredTextProps, e: ColoredTextItemConfig, isMobile: boolean): CSSProperties {
  const baseFontSize = e.fontSize && isFontModeValue(e.fontSize)
    ? (isMobile ? FONT_MODES[e.fontSize].mobile : FONT_MODES[e.fontSize].browser)
    : e.fontSize ?? '16px'

  const finalFontSize = props.fontSize && isFontModeValue(props.fontSize)
    ? (isMobile ? FONT_MODES[props.fontSize].mobile : FONT_MODES[props.fontSize].browser)
    : props.fontSize ?? baseFontSize
  return {
    pointerEvents: props.pointerEvents ?? 'auto',
    fontSize: finalFontSize,
    fontFamily: props.fontFamily ?? e.fontFamily ?? 'Inter, sans-serif',
    fontWeight: props.fontWeight ?? e.fontWeight ?? 400,
    color: props.color ?? e.color ?? 'black',
    cursor: props.cursor ?? e.cursor,
    whiteSpace: props.whiteSpace ?? e.whiteSpace ?? 'normal',
    userSelect: props.userSelect ?? e.userSelect ?? 'auto',
    lineHeight: props.lineHeight ?? 0.5,
    textDecoration: props.textDecoration ?? e.textDecoration ?? 'none'
  }
}

function getTexts (props: ColoredTextProps) {
  let texts = props.texts ? props.texts : []
  if (props.singleText && texts && texts.length === 0) {
    texts = [{ value: props.singleText }]
  }
  return texts
}

function ColoredText (props: ColoredTextProps) {
  const texts = getTexts(props)
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  return (
    <div className={props.centered ? 'text-center' : ''}>
      {texts.map((e, index) =>
        <span
          key={index}>
          {resolveText(e, props, isMobile)}
          {resolveWordSeparator(e?.newLine, e?.separator)}
        </span>
      )}
    </div>
  )
}

function resolveText (e: ColoredTextItemConfig, props: ColoredTextProps, isMobile: boolean) {
  const maxLength = props.maxLength ?? Infinity
  const textTemplate = (e: ColoredTextItem, text: string | undefined) =>
    (<span
      id={props.id ?? 123123123}
      onClick={props.onClick ?? e.onClick ?? null}
      style={{ cursor: props.onClick || e.onClick ? 'pointer' : e.cursor ?? 'auto', ...getTextConfigs(props, e, isMobile) }}>
      {text && (text.length > maxLength) ? text.slice(0, maxLength) + '...' : text}
    </span>)

  const stringValues = props.parameters
  const coloredTextValues = props.coloredTextParameters

  if (e.parameterIndex !== undefined) {
    if (coloredTextValues) {
      return coloredTextValues[e.parameterIndex]
        .map((item, index) =>
          <span
            onClick={e?.onClick || null}
            key={index}>
            {textTemplate(e.stylePriority ? e : item, item.value)}
          </span>
        )
    } else if (stringValues) {
      return textTemplate(e, stringValues[e.parameterIndex])
    }
  }

  return textTemplate(e, e.value)
}

const resolveWordSeparator = (newLine: boolean | undefined, separator: string | undefined) => {
  return newLine ? (<br/>) : (<span>{separator ?? ' '}</span>)
}

export default ColoredText
