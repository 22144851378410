import React, { useEffect, useLayoutEffect, useState } from 'react'
import MarginWrapper from '../../util/MarginWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  addExperience,
  getExperienceList,
  updateExperienceList
} from '../../../features/TakerInfoSlice'
import {
  backTakerValidationCurrentStep,
  doneTakerValidationCurrentStep, isRequestedForBack,
  isRequestedForTakerValidationNextStep,
  isRequestForAdd, requestForAdd, requestForAddAccepted
} from '../../../features/TakerValidationNextStepSlice'
import toast from 'react-hot-toast'
import WorkExperience from './WorkExperince'
import ColoredText from '../../text/ColoredText'
import { COLORS } from '../../../util/Consts'
import { scrollTo } from '../utils'
import { type WorkExperienceDto } from '../../../api/ic'

interface listProps {
  type?: 'Modal' | 'notModal'
  workExperiences?: WorkExperienceDto[] | undefined
  editable?: boolean
  setLocalList?: React.Dispatch<React.SetStateAction<WorkExperienceDto[]>>
}

function WorkExperienceList (props: listProps) {
  const type = props.type ?? 'Modal'
  const experienceList: WorkExperienceDto[] = useSelector(getExperienceList)
  const [localList, setLocalList] = useState<WorkExperienceDto[]>(type === 'Modal' ? JSON.parse(JSON.stringify(experienceList)) : props.workExperiences)
  const dispatch = useDispatch()
  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const isRequestedForAdd = useSelector(isRequestForAdd)
  const [requestForDelete, setRequestedForDelete] = useState(false)
  const requestForBack = useSelector(isRequestedForBack)

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  useEffect(() => {
    if (type === 'Modal') {
      if (requestForDelete) {
        dispatch(updateExperienceList([...localList]))
        setRequestedForDelete(false)
      }
      let canGo = true
      let notValidDates = false
      for (const expDto of localList) {
        if (!expDto.name ||
          !expDto.position ||
          !expDto.location ||
          !expDto.fromDate ||
          (!expDto.toDate && !expDto.stillWorking) ||
          (
            (new Date(expDto.fromDate ?? '').getTime() > new Date(expDto.toDate ?? '').getTime()) && !expDto.stillWorking
          )
        ) {
          canGo = false
          if (new Date(expDto.fromDate ?? '').getTime() > new Date(expDto.toDate ?? '').getTime() && !expDto.stillWorking) {
            notValidDates = true
          }
          break
        }
      }
      if (requestedForNextStep) {
        dispatch(doneTakerValidationCurrentStep(canGo))
        if (!canGo) {
          let errorMessage = 'Fill all required fields.'
          if (notValidDates) {
            errorMessage = errorMessage + '\nPlease provide valid Dates about your work experience'
          }
          if (!notValidDates) {
            toast.error('Fill all required fields.')
            return
          }
          toast.error(errorMessage)
          return
        }
        dispatch(updateExperienceList([...localList]))
      } else if (isRequestedForAdd) {
        dispatch(requestForAddAccepted())
        if (canGo) {
          dispatch(updateExperienceList([...localList]))
          const newExperience: WorkExperienceDto = {
            name: '',
            position: '',
            location: '',
            fromDate: '',
            toDate: '',
            description: '',
            stillWorking: false
          }
          dispatch(addExperience(newExperience))
          setLocalList((prev: WorkExperienceDto[]) => [...prev, newExperience])
        } else {
          let errorMessage = 'Fill all required fields.'
          if (notValidDates) {
            errorMessage = errorMessage + '\nPlease provide valid Dates about your work experience'
          }
          if (!notValidDates) {
            toast.error('Fill all required fields.')
            return
          }
          toast.error(errorMessage)
          return
        }
      } else if (requestForBack) {
        dispatch(updateExperienceList([...localList]))
        dispatch(backTakerValidationCurrentStep(true))
      }
    }
    if (type === 'notModal') {
      if (props.setLocalList) {
        props.setLocalList(localList)
      }
    }
  }, [
    requestedForNextStep,
    isRequestedForAdd,
    requestForDelete,
    requestForBack,
    localList])

  return (
    <div className={'d-flex flex-column justify-content-start'}>
      <span id={'top'}></span>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: props.type === 'Modal' ? 'center' : ''
      }}>
        {localList?.map((obj: WorkExperienceDto, idx: number) => {
          return (
            <MarginWrapper key={idx} top={'15px'}>
              <WorkExperience
                isFirst={idx === 0 && localList.length === 1}
                idx={idx}
                obj={obj}
                setRequestedForDelete={setRequestedForDelete}
                type={type}
                setLocalList={setLocalList}
                localList={localList}
                triggerValidate={isRequestedForAdd || requestedForNextStep}
                editable={props.editable}
                key={idx}/>
            </MarginWrapper>
          )
        })}
      </div>
      {(props.editable && type !== 'notModal') &&
      <MarginWrapper top={'5px'} bottom={'10px'}>
        <ColoredText
          singleText={'Add +'}
          color={localList.length >= 10 ? '#BDBDBD' : COLORS.MAIN_COLOR}
          cursor={localList.length >= 10 ? 'not-allowed' : 'pointer'}
          onClick={() => {
            if (localList.length < 10) {
              dispatch(requestForAdd())
            }
          }
        }/>
      </MarginWrapper>
      }

    </div>
  )
}

export default WorkExperienceList
