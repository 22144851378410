import React, { useState } from 'react'
import StandardInput from '../components/input/StandardInput'
import StandardButton from '../components/buttons/StandardButton'
import MarginWrapper from '../components/util/MarginWrapper'
import AdminHeader from '../components/admin/AdminHeader'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import { InputModeValues } from '../components/modes/InputModes'
import { HINTS } from '../util/Consts'
import SmallDropdown from '../components/dropdown/SmallDropdown'
import ApiManager from '../manager/ApiManager'
import {
  type BalanceCardTagCreationDto,
  type BalanceCardTagEditDto,
  GetInterviewBalanceCardTagDtoTypeEnum as TagType,
  InterviewBalanceCardTagControllerApi
} from '../api/ic'

function CreateEditInterviewBalanceCardTagPage () {
  const [id, setId] = useState('')
  const [text, setText] = useState('')
  const [type, setType] = useState<TagType>()

  const balanceTagApi = ApiManager.getInstance(InterviewBalanceCardTagControllerApi)

  useState(() => {
    const { id } = useParams()
    if (id) {
      void balanceTagApi.getTag({ id })
        .then(res => {
          setId(res.id)
          setText(res.text)
          setType(res.type)
        }).catch(() => {
        })
    }
  })

  const createEdit = async () => {
    if (!text || !type) {
      toast.error('Fill all information')
      return
    }
    if (id) {
      const body: BalanceCardTagEditDto = { id, text, type }

      await balanceTagApi.editTag1({
        balanceCardTagEditDto: body
      }).then(async res => {
        toast.success(`Successfully created/edited interview type ${res.text}`)
      }).catch(() => {})
    } else {
      const body: BalanceCardTagCreationDto = { text, type }

      await balanceTagApi.createTag({
        balanceCardTagCreationDto: body
      }).then(async res => {
        toast.success(`Successfully created/edited interview type ${res.text}`)
      }).catch(() => {})
    }
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'Adding new Balance Card Tag'}/>
      <MarginWrapper top={'40px'}>
        <StandardInput
          label={'Id'}
          inputValue={id}
          setInputValue={setId}
          type={StandardInputModeValues.ONLYREAD}
          placeHolder={'id'}/>
        <StandardInput
          label={'Text'}
          inputValue={text}
          setInputValue={setText}
          placeHolder={'Tag Text'}/>
        <SmallDropdown
          label={'Tag Type'}
          items={Object.values(TagType)}
          selected={type}
          setIsSelected={setType}
          sizeMode={InputModeValues.LARGE}
          hintMessage={HINTS.COUNTRY}/>
        <MarginWrapper top={'20px'}>
          <StandardButton onClick={createEdit}/>
        </MarginWrapper>
      </MarginWrapper>
    </div>
  )
}

export default CreateEditInterviewBalanceCardTagPage
