import React, { useLayoutEffect, useRef, useState } from 'react'
import { type SectionValue } from './InterviewModal'

interface UnderlinedTextProps {
  setSectionValue: React.Dispatch<React.SetStateAction<any>>
  initialSection: number | SectionValue
  isRequestAvailable?: boolean
  type?: string
  firstWord?: string
  secondWord?: string
  isOnEditPart?: boolean
  setIsOnEditPart?: React.Dispatch<React.SetStateAction<boolean>>
}

function UnderlinedText (props: UnderlinedTextProps) {
  const type = props.type ?? 'giver'

  const leftTextRef = useRef<HTMLSpanElement>(null)
  const [leftTextWidth, setLeftTextWidth] = useState(0)

  const rightTextRef = useRef<HTMLSpanElement>(null)
  const [rightTextWidth, setRightTextWidth] = useState(0)
  const [initiallyRender, setInitiallyRender] = useState(true)
  const [underlineStyle, setUnderlineStyle] = useState<any>()

  useLayoutEffect(() => {
    if (leftTextRef.current) {
      setLeftTextWidth(leftTextRef.current.offsetWidth)
    }
    if (rightTextRef.current) {
      setRightTextWidth(rightTextRef.current.offsetWidth)
    }
    if (leftTextWidth > 0 && rightTextWidth > 0) {
      setUnderlineStyle({
        left: props.initialSection === 1 ? '0%' : `${leftTextWidth + 20}px`,
        width: props.initialSection === 1 ? `${leftTextWidth}px` : `${rightTextWidth}px`
      })
      setInitiallyRender(false)
    }
  }, [leftTextWidth, rightTextWidth])
  const handleClick = (buttonNumber: number) => {
    if (buttonNumber === 1) {
      setUnderlineStyle({
        left: '0%',
        width: `${leftTextWidth}px`
      })
      if (props.setIsOnEditPart) {
        props.setIsOnEditPart(false)
      }
    } else {
      setUnderlineStyle({
        left: `${leftTextWidth + 20}px`,
        width: `${rightTextWidth}px`
      })
    }
    props.setSectionValue(buttonNumber)
  }
  return (

    <div style={{ display: 'flex', position: 'relative', width: 'fit-content', gap: '20px' }}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => { handleClick(1) }}
      >
        {props.firstWord ? <span ref={leftTextRef}>{props.firstWord}</span> : <span ref={leftTextRef}>Upcomings</span>}
      </span>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => { handleClick(props.isRequestAvailable && !props.isOnEditPart ? 3 : 2) }}
      >
        {props.secondWord ? <span ref={rightTextRef}>{props.secondWord}</span> : type === 'giver' ? <span ref={rightTextRef}>New Request</span> : <span ref={rightTextRef}>Take New One</span>}
      </span>
      <div
        style={{
          height: '1.5px',
          backgroundColor: 'black',
          position: 'absolute',
          bottom: '-4px',
          transition: initiallyRender ? 'none' : 'left 0.3s ease, width 0.3s ease',
          ...underlineStyle
        }}
      />
    </div>
  )
}

export default UnderlinedText
