import React from 'react'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import MarginWrapper from '../util/MarginWrapper'
import { useSelector } from 'react-redux'
import { getTakerValidationCurrentPage } from '../../features/TakerValidationNextStepSlice'

interface routerProps {
  routeNames?: string[]
}

function VerifyModalRouter (props: routerProps) {
  const routeNames = props.routeNames ?? ['Personal Information',
    'Education',
    'Work Experience',
    'URLs',
    'Interview',
    'Meeting']
  const currentPage = useSelector(getTakerValidationCurrentPage)
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '28%',
          borderRight: '2px solid #D9D9D9',
          paddingRight: '10px',
          userSelect: 'none'
        }}>
        {
          routeNames.map((el, idx) => (
            <div
              key = {idx}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px'
              }}>
              <div
                style={{
                  backgroundColor: currentPage >= idx ? '#880CE8' : '#D9D9D9',
                  borderRadius: '50%',
                  width: '10px',
                  height: '10px'
                }}></div>
              {
                  idx !== routeNames.length - 1
                    ? <div
                        style={{
                          backgroundColor: currentPage > idx ? '#880CE8' : '#D9D9D9',
                          width: '1.7px',
                          height: '20px',
                          position: 'absolute',
                          marginLeft: '4px',
                          marginTop: '35px'
                        }}></div>
                    : null
                }
              <div
                style={{
                  background: currentPage >= idx ? '#880CE8' : 'white',
                  width: '170px',
                  height: '35px',
                  borderRadius: '5px',
                  color: currentPage >= idx ? 'white' : 'black',
                  padding: '9px 0 6px 7px',
                  fontFamily: 'Inter, sans-serif',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap'
                }}>
                {el}
              </div>
            </div>
          ))
        }

        <MarginWrapper
          top={'10px'}>
          <ColoredText
            centered={false}
            lineHeight={1}
            fontSize={FontModeValues.EXTRA_SMALL}
            texts={COLORED_TEXT_VALUES.VERIFY_INFO}
            whiteSpace={'nowrap'}/>
        </MarginWrapper>
      </div>
    </>
  )
}

export default VerifyModalRouter
