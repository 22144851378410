import React, { useLayoutEffect, useState } from 'react'
import StandardInput from '../../input/StandardInput'
import { InputModeValues } from '../../modes/InputModes'
import EnumValueDropDown from '../../dropdown/EnumValueDropDown'
import { findObjectFromKey } from '../../../util/Helper'
import { convertJavaRegexToJS } from './helper'
import { type EnumTypeDto, type ICURLDto } from '../../../api/ic'

interface verificationUrlProps {
  idx: number
  obj: ICURLDto
  values: EnumTypeDto[]
  setLocalList: React.Dispatch<React.SetStateAction<ICURLDto[]>>
  localList: ICURLDto[]
  triggerValidate?: boolean
  setTriggerValidate?: React.Dispatch<React.SetStateAction<boolean>>
  editable?: boolean
  setTrueValues?: React.Dispatch<React.SetStateAction<boolean[]>>
  trueValues?: boolean[]
}

function Url (props: verificationUrlProps) {
  const [name, setName] = useState<EnumTypeDto | undefined>(findObjectFromKey(props.values, props.obj))
  const [url, setUrl] = useState(props.obj.value ?? '')
  const [isValid, setIsValid] = useState(true)
  const editable = props.editable ?? true
  useLayoutEffect(() => {
    if (props.obj.key !== name?.key) {
      setName(findObjectFromKey(props.values, props.obj))
    }
    setUrl(props.obj.value ?? '')
  }, [props.obj,
    props.obj.key,
    props.obj.value,
    props.localList,
    props.localList.length,
    props.idx])

  useLayoutEffect(() => {
    const newObj: ICURLDto = {
      key: name?.key,
      value: url
    }
    props.setLocalList((prev: ICURLDto[]) => {
      const newList = [...prev]
      newList[props.idx] = newObj
      return newList
    })
  }, [name, url])
  useLayoutEffect(() => {
    if (props.setTrueValues && props.trueValues) {
      const trueValues = [...props.trueValues]
      trueValues[props.idx] = isValid
      props.setTrueValues(trueValues)
    }
  }, [isValid])

  return (
    <>
      <div className={'d-flex flex-row'} style={{ gap: '10px' }}>
        <div style={{ width: '25%' }}>
          <EnumValueDropDown
            showOptionWhenDisabled={!editable}
            disabled={!editable}
            items={props.values}
            selectedValue={name}
            setSelectedValue={setName}
            label={'Name'}
            sizeMode={InputModeValues.MAX_WIDTH_2}/>
        </div>
        <div style={{ width: '70%' }}>
          <StandardInput
            label='URL'
            setTriggerValidate={props.setTriggerValidate}
            triggerValidate={props.triggerValidate && Boolean(name?.key)}
            setIsValid={setIsValid}
            regex={convertJavaRegexToJS(props.values.filter(enumDto => enumDto.key === props.obj.key)[0]?.metadata?.regex.toString())}
            inputValue= {url}
            width={'60%'}
            disabled={!editable}
            sizeMode={InputModeValues.MAX_WIDTH_2}
            setInputValue={setUrl}
            placeHolder='https://example.com'/>
        </div>
      </div>

    </>
  )
}
export default Url
