import React from 'react'
import { SINGLE_TEXT_VALUES } from '../../util/Consts'
import ColoredText from '../text/ColoredText'

interface CompanyNameFooterProps {
  orientation?: 'left' | 'right' | 'center'
  position?: any
}

function CompanyNameFooter (props: CompanyNameFooterProps) {
  const position = props.position ?? 'absolute'
  const orientation = props.orientation ?? 'center'
  return (
    (<div
      style={{
        width: '100%',
        position,
        zIndex: 100,
        bottom: 0,
        display: 'inline-flex',
        justifyContent: orientation === 'left' ? 'start' : orientation === 'right' ? 'end' : 'center',
        boxSizing: 'border-box',
        padding: '20px 50px 20px 50px'
      }}>
      <ColoredText
        texts={SINGLE_TEXT_VALUES.COMPANY_NAME}
        centered={true}
        userSelect={'none'}/>
    </div>)
  )
}

export default CompanyNameFooter
