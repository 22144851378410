import React, { useState } from 'react'
import StandardButton from '../buttons/StandardButton'

import StandardInput from './StandardInput'
import { useDispatch, useSelector } from 'react-redux'
import { updatePageValue } from '../../features/PageValueSlice'
import { SignupPage } from '../../enumeration/SignupPage'
import MarginWrapper from '../util/MarginWrapper'
import { InputModeValues } from '../modes/InputModes'
import { ButtonModeValues } from '../modes/ButtonMode'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { AuthManager } from '../../manager/AuthManager'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import ApiManager from '../../manager/ApiManager'
import { ICExceptionDtoReasonEnum, type ResponseError, VerificationControllerApi, VerificationRequestDtoTypeEnum } from '../../api/ic'

interface FourDigitInputProps {
  isLoading: boolean
  setVerificationLoading: React.Dispatch<React.SetStateAction<boolean>>
  email: string
}

function VerificationInput (props: FourDigitInputProps) {
  const [inputValues, setInputValues] = useState('')
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [isValidOtp, setIsValidOtp] = useState(false)

  const dispatch = useDispatch()
  const verificationApi = ApiManager.getInstance(VerificationControllerApi)

  const continueVerifyingMail = async () => {
    props.setVerificationLoading(true)
    await verificationApi.verify({
      verificationRequestDto: {
        email: props.email,
        otp: parseInt(inputValues),
        type: VerificationRequestDtoTypeEnum.RolePreSignup
      }
    })
      .then(res => {
        props.setVerificationLoading(false)
        AuthManager.setAccessToken(res.token)
        dispatch(updatePageValue(SignupPage.NAME_SURNAME))
      }).catch((err: ResponseError) => {
        props.setVerificationLoading(false)
        if (err.response.reason === ICExceptionDtoReasonEnum.TooManyAttemptsException) {
          dispatch(updatePageValue(SignupPage.MAIL_SUBMIT))
        }
      })
  }

  return (
    <div
      className={'d-flex flex-column'}>
      <StandardInput
        sizeMode={InputModeValues.LARGE}
        inputValue={inputValues}
        label={''}
        placeHolder={'Verify your email'}
        type = {StandardInputModeValues.OTP}
        errorMessage={'Verification code is incorrect'}
        setInputValue={setInputValues}
        maxLength={6}
        setTriggerValidate={setTriggerValidate}
        triggerValidate={triggerValidate}
        setIsValid={setIsValidOtp}/>
      <MarginWrapper
        top={isMobile ? '24px' : '48px'}>
        <StandardButton
          sizeMode={ButtonModeValues.EXTRA_LARGE}
          text={'Confirm'}
          clickable={inputValues.length === 6}
          backgroundColorMode={'dark'}
          isLoading={props.isLoading}
          setTriggerValidate={setTriggerValidate}
          isValid={isValidOtp}
          onClick={continueVerifyingMail}/>
      </MarginWrapper>
    </div>
  )
}

export default VerificationInput
