/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewBalanceCardDto } from './GetInterviewBalanceCardDto';
import {
    GetInterviewBalanceCardDtoFromJSON,
    GetInterviewBalanceCardDtoFromJSONTyped,
    GetInterviewBalanceCardDtoToJSON,
} from './GetInterviewBalanceCardDto';
import type { BalanceDto } from './BalanceDto';
import {
    BalanceDtoFromJSON,
    BalanceDtoFromJSONTyped,
    BalanceDtoToJSON,
} from './BalanceDto';

/**
 * 
 * @export
 * @interface InterviewBalanceAndCardDto
 */
export interface InterviewBalanceAndCardDto {
    /**
     * Fulfill
     * @type {Array<GetInterviewBalanceCardDto>}
     * @memberof InterviewBalanceAndCardDto
     */
    interviewBalanceCardCreationDtoes?: Array<GetInterviewBalanceCardDto>;
    /**
     * Interview balance dtos
     * @type {Array<BalanceDto>}
     * @memberof InterviewBalanceAndCardDto
     */
    interviewBalanceDtoes?: Array<BalanceDto>;
}

/**
 * Check if a given object implements the InterviewBalanceAndCardDto interface.
 */
export function instanceOfInterviewBalanceAndCardDto(value: object): value is InterviewBalanceAndCardDto {
    return true;
}

export function InterviewBalanceAndCardDtoFromJSON(json: any): InterviewBalanceAndCardDto {
    return InterviewBalanceAndCardDtoFromJSONTyped(json, false);
}

export function InterviewBalanceAndCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewBalanceAndCardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interviewBalanceCardCreationDtoes': json['interviewBalanceCardCreationDtoes'] == null ? undefined : ((json['interviewBalanceCardCreationDtoes'] as Array<any>).map(GetInterviewBalanceCardDtoFromJSON)),
        'interviewBalanceDtoes': json['interviewBalanceDtoes'] == null ? undefined : ((json['interviewBalanceDtoes'] as Array<any>).map(BalanceDtoFromJSON)),
    };
}

export function InterviewBalanceAndCardDtoToJSON(value?: InterviewBalanceAndCardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interviewBalanceCardCreationDtoes': value['interviewBalanceCardCreationDtoes'] == null ? undefined : ((value['interviewBalanceCardCreationDtoes'] as Array<any>).map(GetInterviewBalanceCardDtoToJSON)),
        'interviewBalanceDtoes': value['interviewBalanceDtoes'] == null ? undefined : ((value['interviewBalanceDtoes'] as Array<any>).map(BalanceDtoToJSON)),
    };
}

