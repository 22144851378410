/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferResponseDTO
 */
export interface TransferResponseDTO {
    /**
     * Is transfer
     * @type {boolean}
     * @memberof TransferResponseDTO
     */
    isTransfer: boolean;
}

/**
 * Check if a given object implements the TransferResponseDTO interface.
 */
export function instanceOfTransferResponseDTO(value: object): value is TransferResponseDTO {
    if (!('isTransfer' in value) || value['isTransfer'] === undefined) return false;
    return true;
}

export function TransferResponseDTOFromJSON(json: any): TransferResponseDTO {
    return TransferResponseDTOFromJSONTyped(json, false);
}

export function TransferResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'isTransfer': json['isTransfer'],
    };
}

export function TransferResponseDTOToJSON(value?: TransferResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isTransfer': value['isTransfer'],
    };
}

