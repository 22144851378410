import React from 'react'
import Background from '../components/svg/Background'
import CircleBorderedButton from '../components/buttons/CircleBorderedButton'
import { COLORED_TEXT_VALUES, NAVIGATION_ITEMS } from '../util/Consts'
import ColoredText from '../components/text/ColoredText'
import SVGHandler from '../components/svg/SVGHandler'
import welcomePageBackground from '../components/svg/resources/welcome_page_background.svg'
import welcomeBottomBackground from '../components/svg/resources/welcome_page_background_right_bottom.svg'
import interviewers from '../components/svg/resources/interviewers.svg'
import purpleCircles from '../components/svg/resources/purple_circles.svg'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import MarginWrapper from '../components/util/MarginWrapper'
import CompanyNameFooter from '../components/util/CompanyNameFooter'
import { ColorMode } from '../components/modes/ColorMode'
import DualCards from '../components/DualCards/DualCards'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../features/DeviceTypeInformationSlice'
import { NavigationRoute } from '../enumeration/NavigationRoute'

function WelcomePage () {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const isLandscape = useSelector(GetDeviceTypeInformation).isLandscape

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation
        navigationMode={NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON}
        mobileNavigationMode={NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON_REVERSE}
        mobileBorderButtonMode={isMobile ? ColorMode.LIGHT : ColorMode.DARK}
        navItems={[NAVIGATION_ITEMS.ABOUT, NAVIGATION_ITEMS.CONTACT_US]}
        borderButtonText={'Log in'}
        borderButtonRoute={NavigationRoute.LOG_IN}
        mobileNavItems={[]}
        height={'120px'}/>
      <Background
        svg={welcomePageBackground}
        rate={1.56}/>
      <div
        className={`\`${(isMobile && isLandscape) ? 'container-fluid' : 'container'}`}>
        <div
          className={'d-flex flex-column justify-content-center align-items-center'}
          style={{
            marginRight: isMobile ? '' : '120px',
            position: (isMobile && isLandscape) ? 'relative' : 'static'
          }}>
          <MarginWrapper top={isMobile ? '30px' : ''}>
            <ColoredText
              centered={true}
              lineHeight={1.2}
              fontSize={isMobile ? '9vw' : '4vw'}
              fontWeight={700}
              texts={COLORED_TEXT_VALUES.WELCOME_TEXT}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <ColoredText
              centered={true}
              lineHeight={0.8}
              texts={COLORED_TEXT_VALUES.GET_STARTED_SMALL_TEXT}/>
          </MarginWrapper>
          <div style={{ marginTop: '35px' }}>
            <CircleBorderedButton
              width={isMobile ? '80vw' : '320px'}/>
          </div>
          <DualCards
            id = '2'
            takeMessages={['Shape Talent 🎓: Mentor the future.',
              'Innovate Interviews 🔍: Discover true potential.',
              'Revolutionize Hiring 🌐: Move beyond CVs.']}
            giveMessages={['Show Your Skills 🌟: Ditch CVs, shine in real-time.',
              'Show Your Skills 🌟: Ditch CVs, shine in real-time.',
              'Engage & Grow 🚀: Boost confidence and skills.']}/>
          {(isMobile && !isLandscape) && <>
            <SVGHandler
              svg={interviewers}
              currentX = '35%'
              currentY= {'-500px'}
              inner_style={{
                width: '100px',
                zIndex: '-1'
              }}/>
            <SVGHandler
              svg={purpleCircles}
              currentX='5%'
              currentY={'-650px'}
              inner_style={{
                width: '100px',
                zIndex: '-1'
              }}/>
          </>}
        </div>
      </div>
      {(isMobile && isLandscape) &&
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100vw',
          height: '50vh',
          marginTop: '30vh'
        }}>
        <SVGHandler
          svg={interviewers}
          currentX='60%'
          currentY= {'500px'}
          inner_style={{
            width: '100px'
          }}/>
        <SVGHandler
          svg={purpleCircles}
          currentX='10%'
          currentY={'360px'}
          inner_style={{
            width: '100px'
          }}/>
      </div>}
      {!isMobile && <CompanyNameFooter orientation={'left'} position={'fixed'}/>}
      {!isMobile && <img src={welcomeBottomBackground} alt = 'svg'/>}
    </div>
  )
}

export default WelcomePage
