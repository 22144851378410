import React from 'react'
// import { SessionManager } from '../api/SessionManager'
// import { ICUserRole } from '../api/enums/ICUserRole'
// import { ICUserAPIClient } from '../api/clients/ICUserAPIClient'

function Init () {
  // useEffect(() => {
  //   const sessionTokenBody = SessionManager.getSessionTokenBody(true)
  //   if (sessionTokenBody && sessionTokenBody.role === ICUserRole.ROLE_GIVER) {
  //     void ICUserAPIClient.getTakerStatus().then(res => {
  //       console.log('handle this data', res.data)
  //     })
  //   }
  // })

  return (
    <div></div>
  )
}

export default Init
