import React, { useEffect, useState } from 'react'
import { updateGiverFlipperValue } from '../features/GiverCalendarFlipperSlice'
import { useDispatch, useSelector } from 'react-redux'
import TextWithLeftArrow from '../components/text/TextWithLeftArrow'
import MarginWrapper from '../components/util/MarginWrapper'
import selectedIcon from '../components/svg/resources/Done_round_purple.svg'
import { getTakerInterviewTimesByDates, setTakerTimesForDate } from '../features/TakerInfoSlice'
import ColoredText from '../components/text/ColoredText'
import { FontModeValues } from '../components/modes/FontMode'
import { getHHmmStringFromDate } from './time_range_selector_functions/CustomCalendarFunctions'
import { filterTimeBlocksByInitialTime } from '../util/Helper'

export interface TimeBlock {
  startTime: Date
  endTime: Date
  selected: boolean
}

interface TakerVerificationTimeRangeSelectorProps {
  initialTime: number
  interviewDuration: number
  delayBetweenTimeBlocks: number
  date: Date
  backOnClick?: () => void
  setIsFlipped?: React.Dispatch<React.SetStateAction<boolean>>
  busyTimeStamps?: string[] | undefined
}

const TakerVerificationTimeRangeSelector: React.FC<TakerVerificationTimeRangeSelectorProps> = (
  {
    initialTime,
    interviewDuration,
    date,
    backOnClick,
    delayBetweenTimeBlocks,
    setIsFlipped,
    busyTimeStamps
  }) => {
  const dispatch = useDispatch()
  const busyTimeDates = busyTimeStamps ?? []
  const busyTimeDateObject = busyTimeDates.map(isoString => new Date(isoString).getTime())
  const timesByDates = useSelector(getTakerInterviewTimesByDates) // {Date: [Date,Date]}
  const [TimeBlocks, setTimeBlocks] = useState<TimeBlock[]>([])

  useEffect(() => {
    const timeBlocks = generateTimeBlocks(initialTime, date, timesByDates)
    setTimeBlocks(timeBlocks)
  }, [date])
  function generateTimeBlocks (initialTime: number, date: Date, timesByDates: Array<{
    date: Date
    times: Date[]
  }>): TimeBlock[] {
    const blocks: TimeBlock[] = []
    let initialStartTime = new Date(date)
    const initialStartTimeClone = new Date(date)
    const finalEndTime = new Date(initialStartTimeClone.setDate(initialStartTimeClone.getDate() + 1))
    let timesByDate = null
    if (timesByDates?.length) {
      timesByDate = timesByDates.filter(obj =>
        obj.date.getTime() === date.getTime())
    }
    const times = timesByDate?.length ? timesByDate[0].times : []

    // eslint-disable-next-line no-unmodified-loop-condition
    while (initialStartTime.getTime() <= finalEndTime.getTime()) {
      const startTime = new Date(initialStartTime)
      const startTimeClone = new Date(initialStartTime)
      const endTime = new Date(startTimeClone.setMinutes(startTimeClone.getMinutes() + interviewDuration))
      if (endTime > finalEndTime) {
        return filterTimeBlocksByInitialTime(initialTime, date, blocks)
      }
      let alreadySelectedTime: Date[] = []
      if (times?.length) {
        alreadySelectedTime = times.filter((time) => {
          return time.getTime() === startTime.getTime()
        })
      }
      if (new Date().getTime() > startTime.getTime()) {
        initialStartTime = new Date(initialStartTime.setMinutes(initialStartTime.getMinutes() + delayBetweenTimeBlocks))
      } else if (busyTimeDates.length && busyTimeDateObject?.includes(startTime.getTime())) {
        initialStartTime = new Date(initialStartTime.setMinutes(initialStartTime.getMinutes() + delayBetweenTimeBlocks))
      } else if (alreadySelectedTime.length) {
        blocks.push({
          startTime: alreadySelectedTime[0],
          endTime,
          selected: true
        })
        initialStartTime = new Date(initialStartTime.setMinutes(initialStartTime.getMinutes() + delayBetweenTimeBlocks))
      } else {
        blocks.push({
          startTime,
          endTime,
          selected: false
        })
        initialStartTime = new Date(initialStartTime.setMinutes(initialStartTime.getMinutes() + delayBetweenTimeBlocks))
      }
    }
    return filterTimeBlocksByInitialTime(initialTime, date, blocks)
  }

  function toggleBlockSelection (index: number): void {
    setTimeBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks]
      newBlocks[index] = { ...newBlocks[index], selected: !newBlocks[index].selected }
      const filteredBlocks = newBlocks.filter((block) => block.selected)
      const filteredBlocksTimes = filteredBlocks.map(block => block.startTime)
      dispatch(setTakerTimesForDate(filteredBlocksTimes, date))
      return newBlocks
    })
  }

  return (
    <div className={'d-flex flex-column justify-content-center'}>
      <MarginWrapper bottom={'10px'}>
        <TextWithLeftArrow is_not_moved={false} fontsize={'24px'}
          text={`Time for ${date?.toDateString()}`}
          is_loading={false}
          onClick={() => {
            if (setIsFlipped) {
              setIsFlipped(false)
            } else {
              dispatch(updateGiverFlipperValue(false)) // WARNING BECAUSE OF THIS
            }
            if (backOnClick) {
              backOnClick()
            }
          }}/>
      </MarginWrapper>

      <div className={'d-inline-flex flex-column align-items-center'}
        style={{ maxHeight: '350px', overflowY: 'scroll' }}>
        {TimeBlocks.length
          ? (
              TimeBlocks.map((block, index) => (
                <div key={index} className={'row d-flex align-items-center'}
                  style={{
                    width: '200px',
                    minHeight: '40px',
                    height: '40px',
                    border: '1.4px solid black',
                    borderRadius: '6px',
                    margin: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    toggleBlockSelection(index)
                  }}>
                  {block.selected && (
                  <span className={'col-3'}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={selectedIcon} alt={'selected'}
                      draggable={false} style={{ userSelect: 'none' }}/>
                  </span>
                  )}
                  {!block.selected && (
                  <span className={'col-3'}
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}></span>
                  )}
                  <span className={'col-6 text-nowrap'} style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    userSelect: 'none'
                  }}>
                    {`${getHHmmStringFromDate(block.startTime)} - ${getHHmmStringFromDate(block.endTime)}`}
                  </span>
                  {block.selected && (
                  <span className={'col-3'} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  </span>
                  )}
                  {!block.selected && (
                  <span className={'col-3'} style={{ flex: '3' }}></span>
                  )}
                </div>
              ))
            )
          : (
            <div style={{
              width: '200px',
              minHeight: '40px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <ColoredText
                singleText={'No Dates For Today'}
                color={'gray'}
                fontSize={FontModeValues.LARGE}/>
            </div>
            )}
      </div>
    </div>
  )
}

export default TakerVerificationTimeRangeSelector
