export const checkVideoPermission = async () => {
  return await navigator.mediaDevices.getUserMedia({ video: true })
    .then((stream) => {
      stream.getTracks().forEach(track => {
        track.stop()
      })
      return true
    })
    .catch(() => {
      return false
    })
}

export const checkMicPermission = async () => {
  return await navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      stream.getTracks().forEach(track => {
        track.stop()
      })
      return true
    })
    .catch(() => {
      return false
    })
}
