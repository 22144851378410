import React, { useEffect, useState } from 'react'
import StandardInput from '../../input/StandardInput'
import { StandardInputModeValues } from '../../modes/StandardInputMode'
import { InputModeValues } from '../../modes/InputModes'
import { COLORS } from '../../../util/Consts'
import down from '../../svg/resources/ExpandDown.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import trash from '../../svg/resources/trash.png'
import { formatIsoForCalendar } from '../../../util/Helper'
import { type EducationDto } from '../../../api/ic'

interface educationProps {
  idx: number
  obj: EducationDto
  isFirst: boolean
  setLocalList: React.Dispatch<React.SetStateAction<EducationDto[]>>
  localList: EducationDto[]
  triggerValidate: boolean
  editable?: boolean
  setRequestedForDelete?: React.Dispatch<React.SetStateAction<boolean>>
}

function Education (props: educationProps) {
  const [open, setOpen] = useState((props.idx === props.localList.length - 1 || props.localList.length === 1))
  const [name, setName] = useState(props.obj.name ?? '')
  const [degree, setDegree] = useState(props.obj.degree ?? '')
  const [location, setLocation] = useState(props.obj.location ?? '')
  const [from, setFrom] = useState<string | undefined>(props.obj.fromDate ?? undefined)
  const [to, setTo] = useState<string | undefined>(props.obj.toDate ?? undefined)
  const [description, setDescription] = useState(props.obj.description ?? '')
  const [stillStudying, setStillStudying] = useState(props.obj.stillStudying ?? false)
  const editable = props.editable ?? true
  useEffect(() => {
    setName(props.obj.name ?? '')
    setDegree(props.obj.degree ?? '')
    setLocation(props.obj.location ?? '')
    setFrom(props.obj.fromDate ?? undefined)
    setTo(props.obj.toDate ?? undefined)
    setDescription(props.obj.description ?? '')
    setStillStudying(props.obj.stillStudying ?? false)
  }, [props.obj, props.localList])

  useEffect(() => {
    setOpen((props.idx === props.localList.length - 1 || props.localList.length === 1))
  }, [props.localList.length])
  useEffect(() => {
    const obj = { ...props.obj } // Create a copy of the obj to avoid mutating props directly
    obj.name = name
    obj.degree = degree
    obj.location = location
    obj.fromDate = from ?? ''
    obj.toDate = to ?? ''
    obj.description = description
    obj.stillStudying = stillStudying
    props.setLocalList((prev: EducationDto[]) => {
      const newList = [...prev]
      newList[props.idx] = obj
      return newList
    })
  }, [name,
    degree,
    location,
    from,
    to,
    description,
    stillStudying
  ])
  const isAfter = (): boolean => {
    if (to && from) {
      if (new Date(from).getTime() > new Date(to).getTime()) {
        return true
      }
    }
    return false
  }
  const deleteEducation = () => {
    const newEducationList = props.localList.filter((_, index) => index !== props.idx)
    props.setLocalList(newEducationList)
    if (props.setRequestedForDelete) {
      props.setRequestedForDelete(true)
    }
  }
  return (
    <>
      {!props.isFirst && props.obj &&
      <div
        onClick={() => { if (!open) setOpen(prev => !prev) }}
        style = {{
          cursor: !open ? 'pointer' : 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          border: '1px solid #D9D9D9',
          borderRadius: '5px',
          padding: '5px',
          borderBottom: open ? '0px' : '1px solid #D9D9D9',
          borderBottomLeftRadius: open ? '0px' : '5px',
          borderBottomRightRadius: open ? '0px' : '5px',
          color: '#000'
        }}>
        <div>{name}</div>
        <div className={'d-flex flex-row align-content-center'}>
          {(props.localList.length !== 1 && props.editable) &&
          <span className={'me-2'}>
            <img
              src={trash}
              alt={trash}
              height={'20px'}
              width={'20px'}
              onClick={deleteEducation}
              style={{ cursor: 'pointer' }}/>
          </span>
          }
          <div onClick={() => { setOpen(prev => !prev) }}
            style = {{ rotate: !open ? '180deg' : '0deg', transitionDuration: '0.2s', cursor: 'pointer' }}>
            <img src = {down} alt = 'down'/>
          </div>
        </div>
      </div>}
      {open &&
        <div style={!props.isFirst
          ? {
              border: '1px solid #D9D9D9',
              borderTop: '0px',
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              padding: '10px'
            }
          : {}}>
          <div
            style = {{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '10px',
              justifyContent: 'space-between'
            }}>
            <div
              style = {{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '30px'
              }}>
              <StandardInput
                isRequired={true}
                type= {StandardInputModeValues.TEXT}
                label='University/Organization'
                placeHolder='University/Organization'
                inputValue={name}
                disabled={!editable}
                triggerValidate={props.triggerValidate}
                sizeMode= {InputModeValues.TINY_BIG}
                setInputValue={setName}/>
              <StandardInput
                isRequired={true}
                type= {StandardInputModeValues.TEXT}
                label='Degree/Specialization'
                placeHolder='Degree/Specialization'
                inputValue={degree}
                disabled={!editable}
                triggerValidate={props.triggerValidate}
                sizeMode= {InputModeValues.TINY_BIG}
                setInputValue={setDegree}/>
            </div>
            <div style = {{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <StandardInput
                isRequired={true}
                type= {StandardInputModeValues.TEXT}
                label='Location'
                placeHolder='Yerevan'
                inputValue={location}
                disabled={!editable}
                triggerValidate={props.triggerValidate}
                sizeMode= {InputModeValues.TINY_BIG}
                setInputValue={setLocation}/>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <StandardInput
                  type= {StandardInputModeValues.DATE}
                  label='From'
                  showDateWhenDisabled={!editable}
                  disabled={!editable}
                  isRequired={true}
                  placeHolder=''
                  triggerDisableError={stillStudying || !isAfter()}
                  triggerValidate={props.triggerValidate}
                  triggerEnableError={isAfter() && !stillStudying}
                  inputValue={formatIsoForCalendar(from) ?? ''}
                  sizeMode= {InputModeValues.EXTRA_SMALL}
                  setInputValue={(date: Date | undefined | string) => {
                    if (typeof date === 'string' || date === undefined) {
                      setFrom(undefined)
                      return
                    }
                    setFrom(date.toISOString())
                  }}/>
                <StandardInput
                  type= {StandardInputModeValues.DATE}
                  label='To'
                  showDateWhenDisabled={!editable}
                  isRequired={!stillStudying}
                  triggerEnableError={isAfter() && !stillStudying}
                  triggerDisableError={stillStudying}
                  disabled={stillStudying || !editable}
                  triggerValidate={stillStudying ? undefined : props.triggerValidate}
                  placeHolder=''
                  inputValue={formatIsoForCalendar(to)}
                  sizeMode= {InputModeValues.EXTRA_SMALL}
                  setInputValue={(date: Date | undefined | string) => {
                    if (typeof date === 'string' || date === undefined) {
                      setTo(undefined)
                      return
                    }
                    setTo(date.toISOString())
                  }}/>
              </div>
              <form
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <input
                  style={{ accentColor: COLORS.MAIN_COLOR }}
                  disabled={!editable}
                  type = "checkbox"
                  checked ={stillStudying}
                  onChange={() => { setStillStudying(prevState => !prevState) }}/>
                <label style={{ userSelect: 'none' }}
                  onClick={() => { setStillStudying(prevState => !prevState) }}>
                  Still studying
                </label>
              </form>
            </div>
          </div>
          <StandardInput
            type= {StandardInputModeValues.TEXT_AREA}
            label='Education details'
            placeHolder=''
            disabled={!editable}
            inputValue={description}
            sizeMode= {InputModeValues.TEXT_AREA_MODAL}
            setInputValue={setDescription}/>
        </div>
      }
    </>
  )
}

export default Education
