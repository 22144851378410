/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ICURLDto } from './ICURLDto';
import {
    ICURLDtoFromJSON,
    ICURLDtoFromJSONTyped,
    ICURLDtoToJSON,
} from './ICURLDto';
import type { WorkExperienceDto } from './WorkExperienceDto';
import {
    WorkExperienceDtoFromJSON,
    WorkExperienceDtoFromJSONTyped,
    WorkExperienceDtoToJSON,
} from './WorkExperienceDto';
import type { EducationDto } from './EducationDto';
import {
    EducationDtoFromJSON,
    EducationDtoFromJSONTyped,
    EducationDtoToJSON,
} from './EducationDto';
import type { BalanceDto } from './BalanceDto';
import {
    BalanceDtoFromJSON,
    BalanceDtoFromJSONTyped,
    BalanceDtoToJSON,
} from './BalanceDto';
import type { TakerRejectionDto } from './TakerRejectionDto';
import {
    TakerRejectionDtoFromJSON,
    TakerRejectionDtoFromJSONTyped,
    TakerRejectionDtoToJSON,
} from './TakerRejectionDto';

/**
 * Taker
 * @export
 * @interface GetICUserDto
 */
export interface GetICUserDto {
    /**
     * User id
     * @type {string}
     * @memberof GetICUserDto
     */
    id: string;
    /**
     * User email
     * @type {string}
     * @memberof GetICUserDto
     */
    email: string;
    /**
     * First name
     * @type {string}
     * @memberof GetICUserDto
     */
    firstName: string;
    /**
     * Last name
     * @type {string}
     * @memberof GetICUserDto
     */
    lastName: string;
    /**
     * About me
     * @type {string}
     * @memberof GetICUserDto
     */
    aboutMe?: string;
    /**
     * Date of birth
     * @type {Date}
     * @memberof GetICUserDto
     */
    dob?: Date;
    /**
     * Country
     * @type {string}
     * @memberof GetICUserDto
     */
    country?: string;
    /**
     * User preferred languages
     * @type {Array<string>}
     * @memberof GetICUserDto
     */
    preferredLanguages: Array<GetICUserDtoPreferredLanguagesEnum>;
    /**
     * Gender
     * @type {string}
     * @memberof GetICUserDto
     */
    gender?: GetICUserDtoGenderEnum;
    /**
     * User balances
     * @type {Array<BalanceDto>}
     * @memberof GetICUserDto
     */
    balances?: Array<BalanceDto>;
    /**
     * User roles
     * @type {string}
     * @memberof GetICUserDto
     */
    role: GetICUserDtoRoleEnum;
    /**
     * Taker status
     * @type {string}
     * @memberof GetICUserDto
     */
    takerStatus: GetICUserDtoTakerStatusEnum;
    /**
     * User urls
     * @type {Array<ICURLDto>}
     * @memberof GetICUserDto
     */
    urls?: Array<ICURLDto>;
    /**
     * User educations
     * @type {Array<EducationDto>}
     * @memberof GetICUserDto
     */
    educations?: Array<EducationDto>;
    /**
     * User work experiences
     * @type {Array<WorkExperienceDto>}
     * @memberof GetICUserDto
     */
    workExperiences?: Array<WorkExperienceDto>;
    /**
     * 
     * @type {TakerRejectionDto}
     * @memberof GetICUserDto
     */
    takerRejection?: TakerRejectionDto;
}


/**
 * @export
 */
export const GetICUserDtoPreferredLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type GetICUserDtoPreferredLanguagesEnum = typeof GetICUserDtoPreferredLanguagesEnum[keyof typeof GetICUserDtoPreferredLanguagesEnum];

/**
 * @export
 */
export const GetICUserDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type GetICUserDtoGenderEnum = typeof GetICUserDtoGenderEnum[keyof typeof GetICUserDtoGenderEnum];

/**
 * @export
 */
export const GetICUserDtoRoleEnum = {
    Giver: 'ROLE_GIVER',
    Taker: 'ROLE_TAKER',
    Admin: 'ROLE_ADMIN'
} as const;
export type GetICUserDtoRoleEnum = typeof GetICUserDtoRoleEnum[keyof typeof GetICUserDtoRoleEnum];

/**
 * @export
 */
export const GetICUserDtoTakerStatusEnum = {
    None: 'NONE',
    RequestedForValidation: 'REQUESTED_FOR_VALIDATION',
    Verified: 'VERIFIED',
    Rejected: 'REJECTED',
    AcceptedForInterview: 'ACCEPTED_FOR_INTERVIEW'
} as const;
export type GetICUserDtoTakerStatusEnum = typeof GetICUserDtoTakerStatusEnum[keyof typeof GetICUserDtoTakerStatusEnum];


/**
 * Check if a given object implements the GetICUserDto interface.
 */
export function instanceOfGetICUserDto(value: object): value is GetICUserDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('preferredLanguages' in value) || value['preferredLanguages'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('takerStatus' in value) || value['takerStatus'] === undefined) return false;
    return true;
}

export function GetICUserDtoFromJSON(json: any): GetICUserDto {
    return GetICUserDtoFromJSONTyped(json, false);
}

export function GetICUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetICUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'aboutMe': json['aboutMe'] == null ? undefined : json['aboutMe'],
        'dob': json['dob'] == null ? undefined : (new Date(json['dob'])),
        'country': json['country'] == null ? undefined : json['country'],
        'preferredLanguages': json['preferredLanguages'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'balances': json['balances'] == null ? undefined : ((json['balances'] as Array<any>).map(BalanceDtoFromJSON)),
        'role': json['role'],
        'takerStatus': json['takerStatus'],
        'urls': json['urls'] == null ? undefined : ((json['urls'] as Array<any>).map(ICURLDtoFromJSON)),
        'educations': json['educations'] == null ? undefined : ((json['educations'] as Array<any>).map(EducationDtoFromJSON)),
        'workExperiences': json['workExperiences'] == null ? undefined : ((json['workExperiences'] as Array<any>).map(WorkExperienceDtoFromJSON)),
        'takerRejection': json['takerRejection'] == null ? undefined : TakerRejectionDtoFromJSON(json['takerRejection']),
    };
}

export function GetICUserDtoToJSON(value?: GetICUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'aboutMe': value['aboutMe'],
        'dob': value['dob'] == null ? undefined : ((value['dob']).toISOString()),
        'country': value['country'],
        'preferredLanguages': value['preferredLanguages'],
        'gender': value['gender'],
        'balances': value['balances'] == null ? undefined : ((value['balances'] as Array<any>).map(BalanceDtoToJSON)),
        'role': value['role'],
        'takerStatus': value['takerStatus'],
        'urls': value['urls'] == null ? undefined : ((value['urls'] as Array<any>).map(ICURLDtoToJSON)),
        'educations': value['educations'] == null ? undefined : ((value['educations'] as Array<any>).map(EducationDtoToJSON)),
        'workExperiences': value['workExperiences'] == null ? undefined : ((value['workExperiences'] as Array<any>).map(WorkExperienceDtoToJSON)),
        'takerRejection': TakerRejectionDtoToJSON(value['takerRejection']),
    };
}

