import { ReduxActionType } from '../enumeration/ReduxActionType'
import { TakerValidationRoute } from '../enumeration/TakerValidationRoute'

export const isRequestedForTakerValidationNextStep = (state: any): boolean => {
  return state.isTakerValidationSubmitted.requestForNextStep
}

export const isRequestForAdd = (state: any): boolean => {
  return state.isTakerValidationSubmitted.requestForAdd
}

export const isRequestedForBack = (state: any): boolean => {
  return state.isTakerValidationSubmitted.requestForBack
}

export const canGoForTakerValidation = (state: any): boolean => {
  return state.isTakerValidationSubmitted.canGo
}

export const canGoBackForTakerValidation = (state: any): boolean => {
  return state.isTakerValidationSubmitted.canGoBack
}

export const getTakerValidationCurrentPage = (state: any): TakerValidationRoute => {
  return state.isTakerValidationSubmitted.currentPageValue ?? TakerValidationRoute.PERSONAL_INFO
}

export const takerValidationNextStepReducer = (
  state: {
    requestForNextStep: boolean
    canGo: boolean
    canGoBack: boolean
    requestForAdd: boolean
    requestForBack: boolean
    currentPageValue: TakerValidationRoute
  } = {
    requestForNextStep: false,
    canGo: false,
    canGoBack: false,
    requestForAdd: false,
    requestForBack: false,
    currentPageValue: TakerValidationRoute.PERSONAL_INFO
  },
  action: any) => {
  let newVal
  switch (action.type) {
    case ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_NEXT_STEP:
      return {
        ...state,
        requestForNextStep: true
      }
    case ReduxActionType.TAKER_VALIDATION_OPEN_NEXT_PAGE:
      newVal = state.currentPageValue
      if (newVal === undefined) {
        newVal = TakerValidationRoute.PERSONAL_INFO
      }
      return {
        ...state,
        canGo: false,
        currentPageValue: newVal + 1
      }
    case ReduxActionType.TAKER_VALIDATION_OPEN_PREVIOUS_PAGE:
      newVal = state.currentPageValue
      if (newVal === undefined) {
        newVal = TakerValidationRoute.PERSONAL_INFO
      }
      return {
        ...state,
        canGoBack: false,
        currentPageValue: newVal - 1
      }
    case ReduxActionType.TAKER_VALIDATION_CLEAR_PAGE_VALUE:
      return {}
    case ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_ADD:
      return {
        ...state,
        requestForAdd: true
      }
    case ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_ADD_ACCEPTED:
      return {
        ...state,
        requestForAdd: false
      }
    case ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_BACK:
      return {
        ...state,
        requestForBack: true
      }
    case ReduxActionType.TAKER_VALIDATION_CAN_GO:
      return {
        ...state,
        requestForNextStep: false,
        canGo: action.payload
      }
    case ReduxActionType.TAKER_VALIDATION_CAN_GO_BACK:
      return {
        ...state,
        requestForBack: false,
        canGoBack: action.payload
      }
    default:
      return state
  }
}

export const goNextPage = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_OPEN_NEXT_PAGE
  }
}

export const goPreviousPage = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_OPEN_PREVIOUS_PAGE
  }
}

export const clearPageValue = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_CLEAR_PAGE_VALUE
  }
}

export const requestNextStepForTakerValidation = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_NEXT_STEP
  }
}

export const requestForAdd = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_ADD
  }
}

export const requestForAddAccepted = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_ADD_ACCEPTED
  }
}

export const requestForBack = () => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_REQUEST_FOR_BACK
  }
}

export const doneTakerValidationCurrentStep = (canGo: boolean) => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_CAN_GO,
    payload: canGo
  }
}

export const backTakerValidationCurrentStep = (canGoBack: boolean) => {
  return {
    type: ReduxActionType.TAKER_VALIDATION_CAN_GO_BACK,
    payload: canGoBack
  }
}
