import React, { type ReactElement } from 'react'

interface AdminSectionProps {
  children: ReactElement[] | ReactElement
}

function AdminSection (props: AdminSectionProps) {
  return (
    <div style={{
      border: '2px solid #D9D9D9',
      borderRadius: '5px',
      paddingTop: '30px',
      paddingBottom: '30px',
      padding: '10px'
    }} className={'d-flex flex-column justify-content-center align-items-center'}>
      {props.children}
    </div>
  )
}

export default AdminSection
