import { ReduxActionType } from '../enumeration/ReduxActionType'
interface DeviceTypeInformation {
  isMobile: boolean
  isLandscape: boolean
  innerWidth: number
  innerHeight: number
}
export const GetDeviceTypeInformation = (state: any) => {
  return state.deviceInformation
}

export const deviceTypeInformationReducer = (state: DeviceTypeInformation = { isMobile: false, isLandscape: false, innerWidth: window.innerWidth, innerHeight: window.innerHeight }, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_SCREEN_TYPE:
      return { ...state, isMobile: action.payload }
    case ReduxActionType.UPDATE_SCREEN_INNER_WIDTH:
      return { ...state, innerWidth: action.payload }
    case ReduxActionType.UPDATE_SCREEN_INNER_HEIGHT:
      return { ...state, innerHeight: action.payload }
    case ReduxActionType.UPDATE_SCREEN_ORIENTATION:
      return { ...state, isLandscape: action.payload }
    default:
      return state
  }
}

export const updateScreenType = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_SCREEN_TYPE,
    payload: value
  }
}
export const updateScreenInnerWidth = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_SCREEN_INNER_WIDTH,
    payload: value
  }
}
export const updateScreenInnerHeight = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_SCREEN_INNER_HEIGHT,
    payload: value
  }
}
export const updateScreenOrientation = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_SCREEN_ORIENTATION,
    payload: value
  }
}
