import React from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES, COLORS } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import ic_square from '../svg/resources/square_logo.svg'
import AboutSection from './AboutSection'
import MarginWrapper from '../util/MarginWrapper'
import upstairs from '../svg/resources/upstairs.svg'
import flag from '../svg/resources/flag.svg'
import SVGHandler from '../svg/SVGHandler'
import AboutFooter from './AboutFooter'

function AboutUs () {
  return (
    <>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_MAIN}
        mobileNavItems={[]}/>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 0'
        }}>
        <div style={{
          marginLeft: '110px',
          marginBottom: '20px'
        }}>
          <ColoredText
            singleText='About us'
            color = {COLORS.MAIN_COLOR}
            fontSize= {FontModeValues.HEADER_BIG}
            fontWeight={500}
            whiteSpace='nowrap'/>
        </div>
        <div style={{
          background: '#F8F8F8',
          padding: '100px 150px'
        }}>
          <ColoredText
            texts={COLORED_TEXT_VALUES.ABOUT_US}
            fontSize= {FontModeValues.AVERAGE}
            lineHeight={1.2}/>
        </div>
        <SVGHandler svg = {ic_square}
          currentX='950px'
          currentY='610px'/>
        <div className='d-flex justify-content-center mt-3'>
          <div style = {{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 50px',
            width: '70%',
            border: '2px solid #d9d9d9',
            borderRadius: '10px',
            gap: '15px',
            height: 'fit-content'
          }}>
            <AboutSection />
          </div>
        </div>
        <div className='d-flex justify-content-center mt-3'>
          <div style = {{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 50px',
            width: '70%',
            border: '2px solid #d9d9d9',
            borderRadius: '10px',
            gap: '15px',
            position: 'relative',
            height: '850px'
          }}>
            <ColoredText
              singleText='Our mission'
              color = {COLORS.MAIN_COLOR}
              fontSize= {FontModeValues.HEADER_BIG}/>
            <MarginWrapper top = {'50px'}>
              <ColoredText
                texts={COLORED_TEXT_VALUES.OUR_MISSION}
                fontSize={FontModeValues.AVERAGE}/>
            </MarginWrapper>
            <img src = {upstairs} alt = 'upstairs'
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}/>
          </div>
        </div>
        <div className='d-flex justify-content-center mt-3'>
          <div style = {{
            display: 'flex',
            flexDirection: 'column',
            padding: '30px 50px',
            width: '70%',
            border: '2px solid #d9d9d9',
            borderRadius: '10px',
            gap: '15px',
            position: 'relative',
            height: '850px'
          }}>
            <ColoredText
              singleText='Why Interview Center?'
              color = {COLORS.MAIN_COLOR}
              fontSize= {FontModeValues.HEADER_BIG}
            />
            <MarginWrapper top = {'50px'}>
              <ColoredText
                texts={COLORED_TEXT_VALUES.WHY_INTERVIEW_CENTER}
                fontSize={FontModeValues.AVERAGE}/>
            </MarginWrapper>
            <img src = {flag} alt = 'flag'
              style={{
                position: 'absolute',
                bottom: '30px',
                right: '50px'
              }}/>
          </div>
        </div>
      </div>
      <AboutFooter />
    </>
  )
}

export default AboutUs
