import React, { type CSSProperties } from 'react'
import { Link } from 'react-router-dom'

interface linkProps {
  href: any
  style: CSSProperties
  children: React.ReactNode
  onClick?: any
}

function CustomLink (props: linkProps) {
  const [hovered, setHovered] = React.useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }
  return (
    (
      <Link
        to = {props.href}
        onClick={props.onClick}
        className = 'customLinks'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style = {{
          ...props.style,
          backgroundColor: hovered ? '#eeeeee' : 'transparent',
          transition: 'background-color 0.2s ease',
          padding: '3px 10px 3px 6px',
          borderRadius: '4px'
        }}>
        {props.children}
      </Link>
    )
  )
}

export default CustomLink
