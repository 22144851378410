import React, { useEffect } from 'react'
import OT from '@opentok/client'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteSessionAndTokenAndAppId,
  GetInterviewAppId,
  GetInterviewJWT,
  GetInterviewSession
} from '../../features/InterviewJWTSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiManager from '../../manager/ApiManager'
import { InterviewVideoControllerApi } from '../../api/video'
import toast from 'react-hot-toast'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { JWTManager } from '../../manager/JWTManager'
import { updateInterviewFinishedProcess } from '../../features/InterviewProcessSlice'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

function VonageMeeting (props: { setSession: any, setSubscriberAvailable: any, setPublisher: any, setSubscriber: any, setJWT: any, subscriber: OT.Subscriber | null }) {
  const appId = useSelector(GetInterviewAppId)
  const sessionId = useSelector(GetInterviewSession)
  const url = window.location.pathname
  const interviewId = url.split('/')[2]

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const token = query.get('token')
  const tokenPayload = JWTManager.extractPayload(token ?? '')

  const jwt = useSelector(GetInterviewJWT)
  props.setJWT(jwt)

  const interviewVideoApi = ApiManager.getInstance(InterviewVideoControllerApi)
  const interviewJWT = useSelector(GetInterviewJWT)

  useEffect(() => {
    if (!appId || !sessionId || !token) {
      navigate(NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId), { replace: true })
      return
    }

    const session = OT.initSession(appId, sessionId)
    props.setSession(session)

    // Make publisher based on session
    const publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      name: 'Levon Matshkalyan',
      style: { buttonDisplayMode: 'on', backgroundImageURI: 'none', nameDisplayMode: 'on' },
      showControls: false
    }, (error) => {
      if (error) {
        console.error('Publisher initialization failed:', error)
        return
      }
      props.setPublisher(publisher)
    })

    session.connect(token, (err) => {
      if (err) {
        console.error('Error connecting:', err)
        return
      }

      session.publish(publisher, (publishErr) => {
        if (publishErr) {
          console.error('Error publishing:', publishErr)
        }
      })

      publisher.on('streamDestroyed', function (event) {
        event.preventDefault()
        props.setSubscriber(null)
        props.setSubscriberAvailable(false)
      })

      session.on('connectionCreated', (event) => {
        const connectionData = event.connection.data
        if (tokenPayload && connectionData === tokenPayload?.connection_data) {
          void interviewVideoApi.interviewVideoControllerAddConnection({
            interviewAddConnectionRequestDTO: {
              bearerToken: interviewJWT,
              connectionId: event.connection.connectionId,
              token
            }
          }).then(() => {
            toast.success('Connected successfully, enjoy it')
          }).catch(() => {
            navigate(NavigationRoute.INTERVIEW_JOIN.replace(':id', interviewId), { replace: true })
          })
        }
      })
    })

    session.on('sessionDisconnected', () => {
      navigate(NavigationRoute.INTERVIEW_JOIN.replace('/:id/', `/${interviewId}/`), { replace: true })
    })

    session.on('connectionDestroyed', function (event) {
      console.log('A participant disconnected:', event.connection)
      props.setSubscriber(null)
      props.setSubscriberAvailable(false)
      // Handle disconnection logic here, such as notifying the remaining participant.
    })
    session.on('streamCreated', (event) => {
      const subscriber = session.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        style: { buttonDisplayMode: 'on', backgroundImageURI: 'none', nameDisplayMode: 'on' },
        showControls: false
      }, (error) => {
        if (error) {
          console.error('Error subscribing:', error)
        }
      })
      props.setSubscriber(subscriber)
      props.setSubscriberAvailable(subscriber.stream?.hasVideo)
      // Add event listeners for videoEnabled and videoDisabled events
      subscriber.on('videoEnabled', () => {
        // You can handle this event as needed
        props.setSubscriberAvailable(true)
        console.log('worked enab')
      })

      subscriber.on('videoDisabled', () => {
        props.setSubscriberAvailable(false)
        console.log('worked disab')
      })

      subscriber.on('streamDestroyed', () => {
        props.setSubscriber(null) // Reset the subscriber when the stream is destroyed
        props.setSubscriberAvailable(false)
      })
    })
    return () => {
      // clearTimeout(connectionTimeout)
      props.setSubscriber(null)
      props.setSubscriberAvailable(false)
      if (props.subscriber) {
        session.unsubscribe(props.subscriber)
      }
      dispatch(deleteSessionAndTokenAndAppId())
      if (session) {
        session.disconnect()
        session.off()
        if (props.subscriber) {
          props.setSubscriber(null)
        }
        void interviewVideoApi.interviewVideoControllerTerminateConnection({
          interviewTerminateConnectionRequestDTO: {
            bearerToken: interviewJWT
          }
        }).then(() => {
          dispatch(updateInterviewFinishedProcess(true))
          // navigate(NavigationRoute.INTERVIEW_FEEDBACK.replace('/:id/', `/${interviewId}/`), { replace: true })
          toast.success('Successfully terminated connection')
        }).catch(() => {})
      }
    }
  }, [])

  return (
    <span hidden={true}></span>
  )
}

export default VonageMeeting
