/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BalanceCardTagCreationDto,
  BalanceCardTagEditDto,
  GetInterviewBalanceCardTagDto,
} from '../models/index';
import {
    BalanceCardTagCreationDtoFromJSON,
    BalanceCardTagCreationDtoToJSON,
    BalanceCardTagEditDtoFromJSON,
    BalanceCardTagEditDtoToJSON,
    GetInterviewBalanceCardTagDtoFromJSON,
    GetInterviewBalanceCardTagDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface CreateTagRequest {
    balanceCardTagCreationDto: BalanceCardTagCreationDto;
}

export interface DeleteTagRequest {
    id: string;
}

export interface EditTag1Request {
    balanceCardTagEditDto: BalanceCardTagEditDto;
}

export interface GetTagRequest {
    id: string;
}

/**
 * 
 */
export class InterviewBalanceCardTagControllerApi extends runtime.BaseAPI {

    /**
     * Creates an interview balance card tag
     * Create interview balance card tag
     */
    async createTagRaw(requestParameters: CreateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardTagDto>> {
        if (requestParameters['balanceCardTagCreationDto'] == null) {
            throw new runtime.RequiredError(
                'balanceCardTagCreationDto',
                'Required parameter "balanceCardTagCreationDto" was null or undefined when calling createTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/balanceCardTag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceCardTagCreationDtoToJSON(requestParameters['balanceCardTagCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardTagDtoFromJSON(jsonValue));
    }

    /**
     * Creates an interview balance card tag
     * Create interview balance card tag
     */
    async createTag(requestParameters: CreateTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardTagDto> {
        const response = await this.createTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an interview balance card tag
     * Delete interview balance card tag
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardTagDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCardTag/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardTagDtoFromJSON(jsonValue));
    }

    /**
     * Deletes an interview balance card tag
     * Delete interview balance card tag
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardTagDto> {
        const response = await this.deleteTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits an interview balance card tag
     * Edit interview balance card tag
     */
    async editTag1Raw(requestParameters: EditTag1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardTagDto>> {
        if (requestParameters['balanceCardTagEditDto'] == null) {
            throw new runtime.RequiredError(
                'balanceCardTagEditDto',
                'Required parameter "balanceCardTagEditDto" was null or undefined when calling editTag1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/balanceCardTag/edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceCardTagEditDtoToJSON(requestParameters['balanceCardTagEditDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardTagDtoFromJSON(jsonValue));
    }

    /**
     * Edits an interview balance card tag
     * Edit interview balance card tag
     */
    async editTag1(requestParameters: EditTag1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardTagDto> {
        const response = await this.editTag1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all interview balance card tags
     * Get all interview balance card tags
     */
    async getAllTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInterviewBalanceCardTagDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCardTag/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterviewBalanceCardTagDtoFromJSON));
    }

    /**
     * Gets all interview balance card tags
     * Get all interview balance card tags
     */
    async getAllTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInterviewBalanceCardTagDto>> {
        const response = await this.getAllTagsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets an interview balance card tag
     * Get interview balance card tag
     */
    async getTagRaw(requestParameters: GetTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewBalanceCardTagDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/interview/balanceCardTag/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewBalanceCardTagDtoFromJSON(jsonValue));
    }

    /**
     * Gets an interview balance card tag
     * Get interview balance card tag
     */
    async getTag(requestParameters: GetTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewBalanceCardTagDto> {
        const response = await this.getTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
