/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewTemplateSection } from './GetInterviewTemplateSection';
import {
    GetInterviewTemplateSectionFromJSON,
    GetInterviewTemplateSectionFromJSONTyped,
    GetInterviewTemplateSectionToJSON,
} from './GetInterviewTemplateSection';

/**
 * 
 * @export
 * @interface GetInterviewQuestionTemplateDto
 */
export interface GetInterviewQuestionTemplateDto {
    /**
     * Interview question template id
     * @type {string}
     * @memberof GetInterviewQuestionTemplateDto
     */
    id: string;
    /**
     * Dont ask again
     * @type {boolean}
     * @memberof GetInterviewQuestionTemplateDto
     */
    dontAskAgain: boolean;
    /**
     * Last answer question
     * @type {string}
     * @memberof GetInterviewQuestionTemplateDto
     */
    currentQuestionId: string;
    /**
     * InterviewId
     * @type {string}
     * @memberof GetInterviewQuestionTemplateDto
     */
    interviewId: string;
    /**
     * 
     * @type {Array<GetInterviewTemplateSection>}
     * @memberof GetInterviewQuestionTemplateDto
     */
    sections: Array<GetInterviewTemplateSection>;
}

/**
 * Check if a given object implements the GetInterviewQuestionTemplateDto interface.
 */
export function instanceOfGetInterviewQuestionTemplateDto(value: object): value is GetInterviewQuestionTemplateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('dontAskAgain' in value) || value['dontAskAgain'] === undefined) return false;
    if (!('currentQuestionId' in value) || value['currentQuestionId'] === undefined) return false;
    if (!('interviewId' in value) || value['interviewId'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    return true;
}

export function GetInterviewQuestionTemplateDtoFromJSON(json: any): GetInterviewQuestionTemplateDto {
    return GetInterviewQuestionTemplateDtoFromJSONTyped(json, false);
}

export function GetInterviewQuestionTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewQuestionTemplateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dontAskAgain': json['dontAskAgain'],
        'currentQuestionId': json['currentQuestionId'],
        'interviewId': json['interviewId'],
        'sections': ((json['sections'] as Array<any>).map(GetInterviewTemplateSectionFromJSON)),
    };
}

export function GetInterviewQuestionTemplateDtoToJSON(value?: GetInterviewQuestionTemplateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'dontAskAgain': value['dontAskAgain'],
        'currentQuestionId': value['currentQuestionId'],
        'interviewId': value['interviewId'],
        'sections': ((value['sections'] as Array<any>).map(GetInterviewTemplateSectionToJSON)),
    };
}

