import React, { useEffect, useState } from 'react'
import LineTextLine from '../util/LineTextLine'
import StandardInput from '../input/StandardInput'
import { COLORED_TEXT_VALUES, COLORS, SINGLE_TEXT_VALUES } from '../../util/Consts'
import ColoredText from '../text/ColoredText'
import StandardButton from '../buttons/StandardButton'
import ButtonWithSvg from '../buttons/ButtonWithSvg'
import google_icon from '../svg/resources/google_icon.svg'
import TextWithLeftArrow from '../text/TextWithLeftArrow'
import { useDispatch, useSelector } from 'react-redux'
import { updateInputValue } from '../../features/standartInputSlice'
import { GetPageValue, updatePageValue } from '../../features/PageValueSlice'
import { LoginPage } from '../../enumeration/LoginPage'
import MarginWrapper from '../util/MarginWrapper'
import { InputModeValues } from '../modes/InputModes'
import { ButtonModeValues } from '../modes/ButtonMode'
import SendCode from './SendCode'
import VerifyResetEmail from './VerifyResetEmail'
import ResetPassword from './ResetPassword'
import { FONT_MODES, FontModeValues } from '../modes/FontMode'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { AuthManager } from '../../manager/AuthManager'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { addLoginUserEmail, clearLoginUser, getLoginUser } from '../../features/LoginUserSlice'
import { SignupPage } from '../../enumeration/SignupPage'
import { useNavigate } from 'react-router-dom'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import { LoginControllerApi, type LoginRequestDto } from '../../api/ic'
import ApiManager from '../../manager/ApiManager'

function Login () {
  // TODO: add loading logic
  const isLoading = false
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [password, setPassword] = useState('')
  const [isValidMail, setIsValidMail] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pageValue = useSelector(GetPageValue)
  const loginUser: LoginRequestDto = useSelector(getLoginUser)
  const [mail, setMail] = useState(loginUser.email)
  const loginApi = ApiManager.getInstance(LoginControllerApi)

  const handlePasswordValueChange = (pass: string) => {
    setPassword(pass)
  }
  useEffect(() => {
    dispatch(addLoginUserEmail(mail))
  }, [mail])

  const handlePageValueChange = (newValue: LoginPage) => {
    return dispatch(updatePageValue(newValue))
  }

  const pageChangeLogic = () => {
    const pageValue = useSelector(GetPageValue)

    if (pageValue === LoginPage.FORGOT_PASSWORD) {
      return LoginPage.MAIN_PAGE
    } else if (pageValue === LoginPage.VERIFICATION) {
      return LoginPage.FORGOT_PASSWORD
    } else if (pageValue === LoginPage.RESET_PASSWORD) {
      return LoginPage.VERIFICATION
    } else {
      return LoginPage.MAIN_PAGE
    }
  }

  const forgotPassword = () => {
    handlePageValueChange(LoginPage.FORGOT_PASSWORD)
  }

  const pageClosed = () => {
    if (pageValue === LoginPage.RESET_PASSWORD) {
      dispatch(updateInputValue(''))
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', pageClosed)
    return () => {
      window.removeEventListener('beforeunload', pageClosed)
    }
  }, [pageValue])

  const handleButtonClick = () => {
    alert('SIGN IN WITH GOOGLE') // TODO: replace with endpoint
  }

  const login = async () => {
    setLoading(true)
    await loginApi.login({
      loginRequestDto: { email: loginUser.email, password }
    }).then(res => {
      setLoading(false)
      AuthManager.setTokens(res)
      navigate(NavigationRoute.HOME_PAGE)
      dispatch(clearLoginUser())
    }).catch(() => {
      setLoading(false)
    })
    setLoading(false)
  }
  return (
    <>
      <div
        className={'d-flex flex-column justify-content-center align-items-center'}>
        <TextWithLeftArrow
          fontsize={pageValue === LoginPage.MAIN_PAGE ? '70px' : isMobile ? FONT_MODES[FontModeValues.LOGIN].mobile : FONT_MODES[FontModeValues.LOGIN].browser}
          page_to_be_moved={pageChangeLogic()}
          is_not_moved={(pageValue === SignupPage.MAIL_SUBMIT || pageValue === LoginPage.RESET_PASSWORD)}
          text = {pageValue === LoginPage.MAIN_PAGE
            ? 'Login'
            : pageValue === LoginPage.RESET_PASSWORD
              ? 'Reset password'
              : 'Forgot Password'}
          is_loading={isLoading}/>
        {pageValue === LoginPage.MAIN_PAGE &&
          <>
            <span
              style={{ marginTop: '38px', width: '320px' }}
              className={'d-flex flex-column justify-content-center align-items-center'}>
              <form autoComplete={'on'}>
                <StandardInput
                  sizeMode={InputModeValues.LARGE}
                  label={'Personal email'}
                  type = {StandardInputModeValues.EMAIL}
                  placeHolder={'Enter your email address…'}
                  inputValue={mail}
                  backgroundColor={'rgba(246, 246, 246, 1)'}
                  setInputValue={setMail}
                  setTriggerValidate={setTriggerValidate}
                  triggerValidate={triggerValidate}
                  setIsValid={setIsValidMail}
                  autocomplete={'username'}/>
                <MarginWrapper
                  top='10px'>
                  <StandardInput
                    sizeMode={InputModeValues.LARGE}
                    label={'Password'}
                    type = {StandardInputModeValues.PASSWORD}
                    placeHolder={'Abc123$'}
                    inputValue={password}
                    showPassword={true}
                    backgroundColor={'rgba(246, 246, 246, 1)'}
                    setInputValue={handlePasswordValueChange}
                    setTriggerValidate={setTriggerValidate}
                    triggerValidate={triggerValidate}
                    setIsValid={setIsValidPassword}
                    autocomplete={'password'}
                  />
                </MarginWrapper>
              </form>
              <div
                className={'d-flex justify-content-start'}
                style = {{ marginTop: '10px', width: '100%' }}>
                <ColoredText
                  singleText='Forgot password?'
                  color={COLORS.MAIN_COLOR}
                  fontSize={FontModeValues.SMALL}
                  onClick={forgotPassword}
                  textDecoration='underline'
                  cursor={'pointer'}/>
              </div>
              <MarginWrapper
                top={'10px'}>
                <StandardButton
                  sizeMode={ButtonModeValues.EXTRA_LARGE}
                  text={'Continue with email'}
                  backgroundColorMode={'dark'}
                  textFontWeight={700}
                  setTriggerValidate={setTriggerValidate}
                  onClick={login}
                  isLoading={loading}
                  isValid={(isValidMail && isValidPassword)}/>
              </MarginWrapper>
              <MarginWrapper
                top={'10px'}>
                <LineTextLine
                  text={'or'}/>
              </MarginWrapper>
              <MarginWrapper
                top={'10px'}
                bottom={'16px'}>
                <ButtonWithSvg
                  onclick={handleButtonClick}
                  text={SINGLE_TEXT_VALUES.CONTINUE_WITH_GOOGLE}
                  svg={google_icon}
                  sizeMode={ButtonModeValues.EXTRA_LARGE}/>
              </MarginWrapper>
              <ColoredText
                texts={COLORED_TEXT_VALUES.DONT_HAVE_ACCOUNT}
                centered={true}
                fontSize={FontModeValues.DEFAULT}/>
            </span>
            <span
              style={{
                width: '80%',
                marginTop: '10px'
              }}>
              <ColoredText
                texts={COLORED_TEXT_VALUES.SECURITY_TEXT}
                centered={true}/>
            </span>
          </>}
        {pageValue === LoginPage.FORGOT_PASSWORD && <SendCode pageChangeAction={handlePageValueChange}/>}
        {pageValue === LoginPage.VERIFICATION && <VerifyResetEmail pageChangeAction={handlePageValueChange}/>}
        {pageValue === LoginPage.RESET_PASSWORD && <ResetPassword/>}
      </div>
    </>
  )
}

export default Login
