import React from 'react'
import StandardButton from '../components/buttons/StandardButton'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import MarginWrapper from '../components/util/MarginWrapper'
import ColoredText from '../components/text/ColoredText'
import { WidthModeValues } from '../components/modes/WidthMode'
import AdminSection from '../components/admin/AdminSection'
import { useNavigate } from 'react-router-dom'

function AdminPage () {
  const navigate = useNavigate()
  const handleCreationClick = () => {
    navigate(NavigationRoute.ADMIN_ADD_INTERVIEW_TYPE)
  }

  const handleSeeClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_INTERVIEWS)
  }

  const handleTakersAllListClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_TAKER)
  }

  const handleRequestedTakersListClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_REQUESTED_TAKER)
  }

  const handleRejectedTakersListClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_REJECTED_TAKER)
  }

  const handleInterviewBalanceCardCreationClick = () => {
    navigate(NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD)
  }

  const handleInterviewBalanceCardTagCreationClick = () => {
    navigate(NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD_TAG)
  }

  const handleInterviewBalanceCardTagAllClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARD_TAGS)
  }

  const handleInterviewBalanceCardListClick = () => {
    navigate(NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARDS)
  }

  const handleQuestionInsertionClick = () => {
    navigate(NavigationRoute.ADMIN_INTERVIEW_QUESTION_INSERTION)
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <MarginWrapper top={'40px'}>
        <ColoredText singleText={'IC Admin Page'} fontSize={'25px'}/>
      </MarginWrapper>

      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Interview Actions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleCreationClick}
              text={'Create Interview Type'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleSeeClick}
              text={'See All Interview Types'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Takers Actions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleTakersAllListClick}
              text={'See All Takers'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleRequestedTakersListClick}
              text={'See Taker requests'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleRejectedTakersListClick}
              text={'See rejected Takers'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Interview Balance Tag Actions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleInterviewBalanceCardTagCreationClick}
              text={'Create Card Tag'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleInterviewBalanceCardTagAllClick}
              text={'See All Card Tags'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Interview Balance Card Actions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleInterviewBalanceCardCreationClick}
              text={'Create Balance card'}/>
          </MarginWrapper>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleInterviewBalanceCardListClick}
              text={'See All Cards'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
      <MarginWrapper top={'20px'}>
        <AdminSection>
          <ColoredText singleText={'Interview Questions'}/>
          <MarginWrapper top={'10px'}>
            <StandardButton
              widthMode={WidthModeValues.SMALL}
              onClick={handleQuestionInsertionClick}
              text={'Insert new'}/>
          </MarginWrapper>
        </AdminSection>
      </MarginWrapper>
    </div>
  )
}

export default AdminPage
