/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetPreProductionUserDto } from './GetPreProductionUserDto';
import {
    GetPreProductionUserDtoFromJSON,
    GetPreProductionUserDtoFromJSONTyped,
    GetPreProductionUserDtoToJSON,
} from './GetPreProductionUserDto';

/**
 * 
 * @export
 * @interface PreProductionUserAllDto
 */
export interface PreProductionUserAllDto {
    /**
     * Pre-production user list
     * @type {Array<GetPreProductionUserDto>}
     * @memberof PreProductionUserAllDto
     */
    dataList?: Array<GetPreProductionUserDto>;
    /**
     * Total count
     * @type {number}
     * @memberof PreProductionUserAllDto
     */
    totalCount: number;
}

/**
 * Check if a given object implements the PreProductionUserAllDto interface.
 */
export function instanceOfPreProductionUserAllDto(value: object): value is PreProductionUserAllDto {
    if (!('totalCount' in value) || value['totalCount'] === undefined) return false;
    return true;
}

export function PreProductionUserAllDtoFromJSON(json: any): PreProductionUserAllDto {
    return PreProductionUserAllDtoFromJSONTyped(json, false);
}

export function PreProductionUserAllDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreProductionUserAllDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dataList': json['dataList'] == null ? undefined : ((json['dataList'] as Array<any>).map(GetPreProductionUserDtoFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function PreProductionUserAllDtoToJSON(value?: PreProductionUserAllDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dataList': value['dataList'] == null ? undefined : ((value['dataList'] as Array<any>).map(GetPreProductionUserDtoToJSON)),
        'totalCount': value['totalCount'],
    };
}

