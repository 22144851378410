import React, { useEffect, useState } from 'react'
import StandardInput from '../components/input/StandardInput'
import StandardButton from '../components/buttons/StandardButton'
import MarginWrapper from '../components/util/MarginWrapper'
import AdminHeader from '../components/admin/AdminHeader'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import SmallDropdown from '../components/dropdown/SmallDropdown'
import { HINTS } from '../util/Consts'
import { InputModeValues } from '../components/modes/InputModes'
import EnumValueMultipleAutocomplete from '../components/multipleAutocomplete/EnumValueMultipleAutocomplete'
import ApiManager from '../manager/ApiManager'
import {
  type EnumTypeDto,
  GetInterviewBalanceCardDtoBalanceTypeEnum,
  InterviewBalanceCardControllerApi,
  type InterviewBalanceCardCreationDto, type InterviewBalanceCardEditDto,
  InterviewBalanceCardTagControllerApi
} from '../api/ic'

function CreateEditInterviewBalanceCardPage () {
  const [idVal, setIdVal] = useState('')
  const [interviewCount, setInterviewCount] = useState('')
  const [price, setPrice] = useState('')
  const [discountPrice, setDiscountPrice] = useState('')
  const [tags, setTags] = useState<EnumTypeDto[]>([])
  const [availableTags, setAvailableTags] = useState<EnumTypeDto[]>([])
  const [balanceType, setBalanceType] = useState<GetInterviewBalanceCardDtoBalanceTypeEnum>()
  const { id } = useParams()

  const balanceCardTagApi = ApiManager.getInstance(InterviewBalanceCardTagControllerApi)
  const balanceCardApi = ApiManager.getInstance(InterviewBalanceCardControllerApi)

  useEffect(() => {
    if (id) {
      void balanceCardApi.get({ id })
        .then(res => {
          setIdVal(res.id)
          setInterviewCount(res.interviewCount.toString())
          setPrice(res.price.toString())
          setDiscountPrice(res.discountPrice.toString())
          setBalanceType(res.balanceType)
          setTags((res.tags ?? []).map(e => {
            return {
              key: e.id,
              name: e.text
            }
          }))
        }).catch(() => {})
    }
    void balanceCardTagApi.getAllTags()
      .then(res => {
        setAvailableTags(
          res.map(e => {
            return {
              key: e.id,
              name: e.text
            }
          })
        )
      })
  }, [])

  const createEdit = async () => {
    if (!interviewCount || !price || !discountPrice || !balanceType) {
      toast.error('Fill all information')
      return
    }

    const body: InterviewBalanceCardCreationDto = {
      interviewCount: +interviewCount,
      price: +price,
      discountPrice: +discountPrice,
      balanceType,
      tagIds: (tags ?? []).map(e => e.key)
    }

    if (id) {
      const editBody = body as InterviewBalanceCardEditDto
      editBody.id = id
      await balanceCardApi.editTag({
        interviewBalanceCardEditDto: editBody
      }).then(async res => {
        toast.success(`Successfully created interview card with type ${res.balanceType}`)
      }).catch(() => {})
    } else {
      await balanceCardApi.createCard({
        interviewBalanceCardCreationDto: body
      }).then(async res => {
        toast.success(`Successfully created interview card with type ${res.balanceType}`)
      }).catch(() => {})
    }
  }

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'Adding new Interview Balance Card'}/>

      <MarginWrapper top={'40px'}>
        <StandardInput
          label={'Id'}
          inputValue={idVal}
          setInputValue={setIdVal}
          type={StandardInputModeValues.ONLYREAD}
          placeHolder={'id'}/>
        <StandardInput
          label={'Interview Count'}
          inputValue={interviewCount}
          setInputValue={setInterviewCount}
          placeHolder={'2'}/>
        <StandardInput
          label={'Price'}
          inputValue={price}
          setInputValue={setPrice}
          placeHolder={'23'}/>
        <StandardInput
          label={'Discount'}
          inputValue={discountPrice}
          setInputValue={setDiscountPrice}
          placeHolder={'4'}/>
        {availableTags && <EnumValueMultipleAutocomplete
          key={'tags-autocomplete'}
          label={'Tags'}
          selectedValues={tags}
          setSelectedValue={setTags}
          isRequired={true}
          items={availableTags}/>}
        <SmallDropdown
          label={'Balance Type'}
          items={Object.values(GetInterviewBalanceCardDtoBalanceTypeEnum)}
          selected={balanceType}
          sizeMode={InputModeValues.LARGE}
          setIsSelected={setBalanceType}
          hintMessage={HINTS.COUNTRY}/>
        <MarginWrapper top={'20px'}>
          <StandardButton onClick={createEdit}/>
        </MarginWrapper>
      </MarginWrapper>
    </div>
  )
}

export default CreateEditInterviewBalanceCardPage
