/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewCreationDto
 */
export interface InterviewCreationDto {
    /**
     * Interview id
     * @type {string}
     * @memberof InterviewCreationDto
     */
    id: string;
    /**
     * Preferred Languages
     * @type {Array<string>}
     * @memberof InterviewCreationDto
     */
    languages: Array<InterviewCreationDtoLanguagesEnum>;
    /**
     * Level
     * @type {string}
     * @memberof InterviewCreationDto
     */
    level: InterviewCreationDtoLevelEnum;
    /**
     * Type
     * @type {string}
     * @memberof InterviewCreationDto
     */
    type: string;
    /**
     * Requested dates by giver
     * @type {Array<Date>}
     * @memberof InterviewCreationDto
     */
    requestedDatesByGiver: Array<Date>;
    /**
     * Balance type
     * @type {string}
     * @memberof InterviewCreationDto
     */
    balanceType: InterviewCreationDtoBalanceTypeEnum;
    /**
     * Is public or private interview
     * @type {boolean}
     * @memberof InterviewCreationDto
     */
    isPublic: boolean;
}


/**
 * @export
 */
export const InterviewCreationDtoLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type InterviewCreationDtoLanguagesEnum = typeof InterviewCreationDtoLanguagesEnum[keyof typeof InterviewCreationDtoLanguagesEnum];

/**
 * @export
 */
export const InterviewCreationDtoLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type InterviewCreationDtoLevelEnum = typeof InterviewCreationDtoLevelEnum[keyof typeof InterviewCreationDtoLevelEnum];

/**
 * @export
 */
export const InterviewCreationDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type InterviewCreationDtoBalanceTypeEnum = typeof InterviewCreationDtoBalanceTypeEnum[keyof typeof InterviewCreationDtoBalanceTypeEnum];


/**
 * Check if a given object implements the InterviewCreationDto interface.
 */
export function instanceOfInterviewCreationDto(value: object): value is InterviewCreationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('languages' in value) || value['languages'] === undefined) return false;
    if (!('level' in value) || value['level'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('requestedDatesByGiver' in value) || value['requestedDatesByGiver'] === undefined) return false;
    if (!('balanceType' in value) || value['balanceType'] === undefined) return false;
    if (!('isPublic' in value) || value['isPublic'] === undefined) return false;
    return true;
}

export function InterviewCreationDtoFromJSON(json: any): InterviewCreationDto {
    return InterviewCreationDtoFromJSONTyped(json, false);
}

export function InterviewCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'languages': json['languages'],
        'level': json['level'],
        'type': json['type'],
        'requestedDatesByGiver': json['requestedDatesByGiver'],
        'balanceType': json['balanceType'],
        'isPublic': json['isPublic'],
    };
}

export function InterviewCreationDtoToJSON(value?: InterviewCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'languages': value['languages'],
        'level': value['level'],
        'type': value['type'],
        'requestedDatesByGiver': value['requestedDatesByGiver'],
        'balanceType': value['balanceType'],
        'isPublic': value['isPublic'],
    };
}

