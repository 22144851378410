import { ReduxActionType } from '../enumeration/ReduxActionType'

export const GetPageValue = (state: any) => {
  return state.pageValue
}

export const pageValueReducer = (state: number = 0, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_OR_GET_PAGE_VALUE:
      return action.payload
    default:
      return state
  }
}

export const updatePageValue = (value: any) => {
  return {
    type: ReduxActionType.UPDATE_OR_GET_PAGE_VALUE,
    payload: value
  }
}
