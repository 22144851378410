import React from 'react'

interface LineProps {
  lineWidth: any | 'auto'
  color?: string
}

function Line (props: LineProps) {
  return (
    <div
      style={{
        width: props.lineWidth === 'auto' ? '' : props.lineWidth, // TODO generic
        margin: props.lineWidth === 'auto' ? '0 auto' : '',
        height: '1px',
        background: props.color ?? '#D9D9D9'
      }}/>
  )
}

export default Line
