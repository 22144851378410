/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BankCardCreationDto,
  GetBankCardDto,
  GetBankCardsDto,
} from '../models/index';
import {
    BankCardCreationDtoFromJSON,
    BankCardCreationDtoToJSON,
    GetBankCardDtoFromJSON,
    GetBankCardDtoToJSON,
    GetBankCardsDtoFromJSON,
    GetBankCardsDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface AddNewCardRequest {
    bankCardCreationDto: BankCardCreationDto;
}

export interface DeleteBankCardRequest {
    id: string;
}

export interface MakeDefaultRequest {
    id: string;
}

/**
 * 
 */
export class BankCardControllerApi extends runtime.BaseAPI {

    /**
     * Add bank card
     * Add bank card
     */
    async addNewCardRaw(requestParameters: AddNewCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankCardDto>> {
        if (requestParameters['bankCardCreationDto'] == null) {
            throw new runtime.RequiredError(
                'bankCardCreationDto',
                'Required parameter "bankCardCreationDto" was null or undefined when calling addNewCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bankCard/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankCardCreationDtoToJSON(requestParameters['bankCardCreationDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankCardDtoFromJSON(jsonValue));
    }

    /**
     * Add bank card
     * Add bank card
     */
    async addNewCard(requestParameters: AddNewCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankCardDto> {
        const response = await this.addNewCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove bank card
     * Remove bank card
     */
    async deleteBankCardRaw(requestParameters: DeleteBankCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankCardDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteBankCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bankCard/{id}/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankCardDtoFromJSON(jsonValue));
    }

    /**
     * Remove bank card
     * Remove bank card
     */
    async deleteBankCard(requestParameters: DeleteBankCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankCardDto> {
        const response = await this.deleteBankCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all bank card
     * Get all bank card
     */
    async getAllUserBankCardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankCardsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bankCard/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankCardsDtoFromJSON(jsonValue));
    }

    /**
     * Get all bank card
     * Get all bank card
     */
    async getAllUserBankCards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankCardsDto> {
        const response = await this.getAllUserBankCardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Make default bank card
     * Make default bank card
     */
    async makeDefaultRaw(requestParameters: MakeDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankCardDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling makeDefault().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bankCard/{id}/makeDefault`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankCardDtoFromJSON(jsonValue));
    }

    /**
     * Make default bank card
     * Make default bank card
     */
    async makeDefault(requestParameters: MakeDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankCardDto> {
        const response = await this.makeDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
