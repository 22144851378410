import React from 'react'
import { COLORED_TEXT_VALUES, COLORS } from '../util/Consts'
import StandardTooltip from '../components/util/StandardTooltip'
import infoIcon from '../components/svg/resources/info.svg'
import ColoredText from '../components/text/ColoredText'
import { FontModeValues } from '../components/modes/FontMode'
import MarginWrapper from '../components/util/MarginWrapper'

interface InterviewPrivacyRadioSelectorProps {
  setPrivacyValue: React.Dispatch<React.SetStateAction<string>>
  privacyValue: string
  error?: boolean
  onChange?: () => void
  isRequired?: boolean
}

function InterviewPrivacyRadioSelector (props: InterviewPrivacyRadioSelectorProps) {
  const isRequired = props.isRequired ?? false
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPrivacyValue(event.target.value)
    if (props.onChange) {
      props.onChange()
    }
  }
  const css =
`
  .custom-input {
    box-shadow: 0px 0px 4px 0px #FF0001;
    border-radius: 10px;
    height: 13px;
    width: 13px;
    position: relative;
  }
`
  return (
    <div className={'d-flex flex-column'}>
      <style>{css}</style>
      <span className={'d-flex flex-row'}>
        <MarginWrapper bottom={'5px'}>
          <ColoredText
            fontSize={FontModeValues.DEFAULT}
            singleText={'Access to interview'}
            fontWeight={400}/>
        </MarginWrapper>
        {isRequired && <span style={{ color: 'red', fontSize: '14px', userSelect: 'none' }}>*</span>}
        <span className={'ms-1'}>
          <StandardTooltip texts={COLORED_TEXT_VALUES.PRIVATE_PUBLIC}>
            <img
              src={infoIcon}
              alt={'Info'}
              style={{ marginLeft: '2px', marginBottom: '4px' }}/>
          </StandardTooltip>
        </span>
      </span>
      <span className={'d-flex align-items-center gap-1'}>
        <span onClick={() => {
          props.setPrivacyValue('public')
          if (props.onChange) {
            props.onChange()
          }
        }
        } className={`${props.error && 'custom-input'} d-flex justify-content-center align-items-center`}>
          <input
            hidden={props.error}
            id="public"
            style={{
              accentColor: COLORS.MAIN_COLOR
            }}
            type="radio"
            value="public"
            onClick={props.onChange}
            checked={props.privacyValue === 'public'}
            onChange={handleRadioChange}/>
        </span>
        <label htmlFor="public" style={{ marginBottom: '1px', color: '#000' }}>
          Public
          <label htmlFor="public" style={{ color: 'rgba(0,0,0,0.5)' }}>(Recommend)</label>
        </label>
      </span>
      <span className={'d-flex align-items-center gap-1'}>
        <span onClick={() => {
          props.setPrivacyValue('private')
          if (props.onChange) {
            props.onChange()
          }
        }
        } className={`${props.error && 'custom-input'} d-flex justify-content-center align-items-center`}>
          <input
            hidden={props.error}
            id="private"
            style={{
              accentColor: COLORS.MAIN_COLOR
            }}
            type="radio"
            value="private"
            checked={props.privacyValue === 'private'}
            onClick={props.onChange}
            onChange={handleRadioChange}/>
        </span>
        <label htmlFor="private" style={{ marginBottom: '1px', color: '#000' }}>
          Private
        </label>
      </span>
    </div>
  )
}

export default InterviewPrivacyRadioSelector
