/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * List of available languages in the system
 * @export
 * @interface EnumTypeDto
 */
export interface EnumTypeDto {
    /**
     * Enum name
     * @type {string}
     * @memberof EnumTypeDto
     */
    name: string;
    /**
     * Enum key
     * @type {string}
     * @memberof EnumTypeDto
     */
    key: string;
    /**
     * Enum metadata
     * @type {{ [key: string]: object; }}
     * @memberof EnumTypeDto
     */
    metadata?: { [key: string]: object; };
}

/**
 * Check if a given object implements the EnumTypeDto interface.
 */
export function instanceOfEnumTypeDto(value: object): value is EnumTypeDto {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function EnumTypeDtoFromJSON(json: any): EnumTypeDto {
    return EnumTypeDtoFromJSONTyped(json, false);
}

export function EnumTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumTypeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
    };
}

export function EnumTypeDtoToJSON(value?: EnumTypeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'key': value['key'],
        'metadata': value['metadata'],
    };
}

