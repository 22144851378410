import React, { useState } from 'react'
import InterviewTypeImg from '../components/img/InterviewTypeImg'
import { COLORS } from '../util/Consts'
import { FONT_MODES } from '../components/modes/FontMode'
import InterviewModal from './InterviewModal'
import { useDispatch, useSelector } from 'react-redux'
import { addGiverInterviewType } from '../features/GiverInterviewSlice'
import ColoredText from '../components/text/ColoredText'
import selectedIcon from '../components/svg/resources/selected.svg'
import { GetDeviceTypeInformation } from '../features/DeviceTypeInformationSlice'
import InterviewStatus from './InterviewStatus'
import { type GetInterviewTypeDto } from '../api/ic'

interface InterviewCardsInterface {
  loading: boolean
  obj: GetInterviewTypeDto
  status?: 'Upcoming' | 'Requested' | undefined
  mode?: 'small'
  selectable?: boolean
  onSelect?: (obj: GetInterviewTypeDto) => void
  onDeSelect?: (obj: GetInterviewTypeDto) => void
  selected?: boolean
  setTriggerInterviewRequest: React.Dispatch<React.SetStateAction<boolean>>
}

function InterviewCard (props: InterviewCardsInterface) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [isMainModalOpen, setIsMainModalOpen] = useState(false)
  const [isOnLanguageLevelPrivacyPart, setIsOnLanguageLevelPrivacyPart] = useState(true)
  const [isOnCalendarPart, setIsOnCalendarPart] = useState(false)
  const [isOnBalancePart, setIsOnBalancePart] = useState(false)
  const [isOnCardPart, setIsOnCardPart] = useState(false)
  const [isOnCardSelectionPart, setIsOnCardSelectionPart] = useState(false)
  const [isOnFinalPart, setIsOnFinalPart] = useState(false)
  const [selected, setSelected] = useState(props.selected ?? false)
  const dispatch = useDispatch()
  // TODO: when expired token handle it in APIClient and show it to user and refresh the page
  const handleModalOpen = () => {
    let interviewType = 'UNKNOWN'
    if (props.obj.type) {
      interviewType = props.obj.type
    }
    dispatch(addGiverInterviewType(interviewType))
    setIsMainModalOpen(true)
  }

  const handleClick = () => {
    if (props.selectable) {
      if (selected) {
        if (props.onDeSelect) {
          props.onDeSelect(props.obj)
        }
      } else {
        if (props.onSelect) {
          props.onSelect(props.obj)
        }
      }
      setSelected(!selected)
    } else {
      handleModalOpen()
    }
  }

  return (
    <>
      <div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <a onClick={handleClick} style={{ cursor: 'pointer', textDecoration: 'none' }}>
          {props.mode === 'small' &&
            <div
              className="d-flex flex-column align-items-center"
              style={{
                borderRadius: '7px',
                minHeight: '140px',
                maxHeight: '140px',
                background: '#FAFAFA',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                userSelect: 'none',
                border: selected ? '3px solid #880CE8' : 'none',
                position: 'relative'
              }}>
                {selected && <img alt = '+' style={{ position: 'absolute', top: '-12px', left: '-12px' }}
                  src={selectedIcon}/>}
              <InterviewTypeImg mode={props.mode} obj={props.obj}/>
              <div className={'d-flex flex-column align-items-center justify-content-center'}
                style={{
                  borderRadius: '5px',
                  width: '100%',
                  height: '70px',
                  background: '#880CE8'
                }}>
                <ColoredText
                  fontWeight={700}
                  singleText={props.obj.name}
                  color={'white'}/>
                <ColoredText
                  singleText={props.obj.description}
                  color={'white'}
                  fontSize={'12px'}/>
              </div>
            </div>
            }
          {props.mode !== 'small' &&
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{
              position: 'relative',
              minHeight: '100px',
              maxHeight: '100px',
              borderRadius: '10px',
              background: '#FAFAFA',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              userSelect: 'none'
            }}>
            {props.status && <InterviewStatus type={'card'} interviewStatusString={props.status}/>}
            <InterviewTypeImg obj={props.obj}/>
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                maxWidth: '100%',
                flex: 1,
                borderRadius: '5px 10px 10px 5px',
                padding: '10px',
                overflow: 'hidden',
                height: '100px',
                background: COLORS.MAIN_COLOR
              }}>
              <span className="text-nowrap text-center"
                style={{
                  color: '#FFF',
                  width: '200px',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: isMobile ? FONT_MODES.LARGE.mobile : FONT_MODES.LARGE.browser,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '1.2',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                {props.obj.name}
              </span>
              <span className="text-nowrap text-center"
                style={{
                  color: '#FFF',
                  fontFamily: 'Inter, sans-serif',
                  fontSize: FONT_MODES.SMALL.scalar,
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '1.5',
                  width: '200px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                {props.obj.description}
              </span>
            </div>
          </div>}
        </a>
      </div>
      <InterviewModal
        obj={props.obj}
        isMainModalOpen={isMainModalOpen}
        isOnCalendarPart={isOnCalendarPart}
        isOnFinalPart={isOnFinalPart}
        isOnLanguageLevelPrivacyPart={isOnLanguageLevelPrivacyPart}
        isOnBalancePart={isOnBalancePart}
        isOnCardAttachmentPart={isOnCardPart}
        isOnCardSelectionPart={isOnCardSelectionPart}
        setIsOnCardSelectionPart={setIsOnCardSelectionPart}
        setIsOnCardAttachmentPart={setIsOnCardPart}
        setIsMainModalOpen={setIsMainModalOpen}
        setIsOnBalancePart={setIsOnBalancePart}
        setIsOnLanguageLevelPrivacyPart={setIsOnLanguageLevelPrivacyPart}
        setIsOnCalendarPart={setIsOnCalendarPart}
        setIsOnFinalPart={setIsOnFinalPart}
        setTriggerInterviewRequest={props.setTriggerInterviewRequest}/>
    </>
  )
}

export default InterviewCard
