import React, { useLayoutEffect, useState } from 'react'
import 'react-day-picker/dist/style.css'
import { DayPicker } from 'react-day-picker'

import { CustomCaption } from './calendar_styles/CalendarCustomCaption'
import { availableDaysStyle, calendarStyles } from './calendar_styles/CustomCalendarStyles'

interface TakerInterviewCalendarProps {
  setDate?: React.Dispatch<React.SetStateAction<Date>>
  timesByAvailableDates: Array<{ date: Date, times: Date[] }>
  setAvailableDates: React.Dispatch<React.SetStateAction<Date[]>>
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>
  singleSelectedTime: Date | null
}
export default function TakerInterviewCalendar (props: TakerInterviewCalendarProps) {
  const [days, setDays] = useState<Date[] | undefined>([])
  const availableDays = (): Date[] => {
    const result: Date[] = []
    if (!props.timesByAvailableDates.length) {
      return result
    } else {
      const alreadyBookedDates: Date[] = []
      props.timesByAvailableDates.map(obj => alreadyBookedDates.push(obj.date))
      alreadyBookedDates.sort((a: Date, b: Date) => a.getTime() - b.getTime())
      return alreadyBookedDates
    }
  }

  const isDayDisabled = (day: Date): boolean => {
    return !availableDays().some(date => date.toDateString() === day.toDateString())
  }

  useLayoutEffect(() => {
    if (props.singleSelectedTime !== null && props.singleSelectedTime !== undefined) {
      setDays([props.singleSelectedTime])
    } else {
      setDays([])
    }
  }, [props.singleSelectedTime])
  const handleOnDayClick = (day: Date) => {
    if (props.setDate) {
      props.setDate(day)
    }
    if (props.timesByAvailableDates?.length) {
      const timesByDate = props.timesByAvailableDates.filter(groupedDate => groupedDate.date.getTime() === day.getTime()
      )
      if (timesByDate.length) {
        props.setAvailableDates(timesByDate[0].times)
      }
    }
    props.setIsFlipped(true)
  }

  return (
    <div>
      <style>{calendarStyles}</style>
      <div className={'d-flex justify-content-center align-items-center'}>
        <DayPicker
          showOutsideDays={false}
          weekStartsOn={1}
          fromMonth={availableDays().length ? availableDays()[0] : new Date() }
          toMonth={availableDays().length ? availableDays()[availableDays().length - 1] : new Date()}
          disabled={(day: Date) => isDayDisabled(day)}
          selected={days}
          onDayClick={handleOnDayClick}
          mode="multiple"
          min={0}
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today'
          }}
          components={{ Caption: CustomCaption }}
          modifiers={{ booked: availableDays() }}
          modifiersStyles={{ booked: availableDaysStyle }}/>
      </div>
    </div>
  )
}
