import React from 'react'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import Login from '../components/LoginPage/Login'
import CompanyNameFooter from '../components/util/CompanyNameFooter'

function LoginPage () {
  return (
    <div>
      <Navigation navigationMode={NavigationModeValues.FLOATING}/>
      <Login />
      <CompanyNameFooter orientation='left'/>
    </div>
  )
}

export default LoginPage
