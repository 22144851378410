/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewDto } from './GetInterviewDto';
import {
    GetInterviewDtoFromJSON,
    GetInterviewDtoFromJSONTyped,
    GetInterviewDtoToJSON,
} from './GetInterviewDto';
import type { TakerAvailableInterviewDatesDto } from './TakerAvailableInterviewDatesDto';
import {
    TakerAvailableInterviewDatesDtoFromJSON,
    TakerAvailableInterviewDatesDtoFromJSONTyped,
    TakerAvailableInterviewDatesDtoToJSON,
} from './TakerAvailableInterviewDatesDto';

/**
 * 
 * @export
 * @interface TakerInterviewsInfoDto
 */
export interface TakerInterviewsInfoDto {
    /**
     * Available interview dates for taker
     * @type {Array<TakerAvailableInterviewDatesDto>}
     * @memberof TakerInterviewsInfoDto
     */
    availableInterviewDates?: Array<TakerAvailableInterviewDatesDto>;
    /**
     * Upcoming interviews for taker
     * @type {Array<GetInterviewDto>}
     * @memberof TakerInterviewsInfoDto
     */
    upcomingInterviews?: Array<GetInterviewDto>;
}

/**
 * Check if a given object implements the TakerInterviewsInfoDto interface.
 */
export function instanceOfTakerInterviewsInfoDto(value: object): value is TakerInterviewsInfoDto {
    return true;
}

export function TakerInterviewsInfoDtoFromJSON(json: any): TakerInterviewsInfoDto {
    return TakerInterviewsInfoDtoFromJSONTyped(json, false);
}

export function TakerInterviewsInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TakerInterviewsInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'availableInterviewDates': json['availableInterviewDates'] == null ? undefined : ((json['availableInterviewDates'] as Array<any>).map(TakerAvailableInterviewDatesDtoFromJSON)),
        'upcomingInterviews': json['upcomingInterviews'] == null ? undefined : ((json['upcomingInterviews'] as Array<any>).map(GetInterviewDtoFromJSON)),
    };
}

export function TakerInterviewsInfoDtoToJSON(value?: TakerInterviewsInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'availableInterviewDates': value['availableInterviewDates'] == null ? undefined : ((value['availableInterviewDates'] as Array<any>).map(TakerAvailableInterviewDatesDtoToJSON)),
        'upcomingInterviews': value['upcomingInterviews'] == null ? undefined : ((value['upcomingInterviews'] as Array<any>).map(GetInterviewDtoToJSON)),
    };
}

