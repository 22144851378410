import React, { type CSSProperties } from 'react'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { COLORS } from '../util/Consts'
import { useNavigate } from 'react-router-dom'

interface NotFoundPageProps {
  style?: CSSProperties
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ style }) => {
  const navigate = useNavigate()
  const containerStyle: CSSProperties = {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    userSelect: 'none',
    ...style // Allow overriding styles through the style prop
  }

  const headingStyle: CSSProperties = {
    fontSize: '8em',
    margin: 0,
    color: '#2c3e50'
  }

  const subheadingStyle: CSSProperties = {
    fontSize: '1.5em',
    color: '#7f8c8d',
    marginLeft: '6px'
  }

  const textStyle: CSSProperties = {
    fontSize: '1.5em',
    color: '#7f8c8d',
    marginBottom: '10px'
  }

  const linkStyle: CSSProperties = {
    color: COLORS.MAIN_COLOR,
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginLeft: '4px'
  }

  return (
    <div
      style={containerStyle}>
      <h1
        style={headingStyle}>
        404
      </h1>
      <p
        style={subheadingStyle}>
        Not Found
      </p>
      <p
        style={textStyle}>
        Sorry, the page you are looking for does not exist.
      </p>
      <p
        style={textStyle}>
        Go back to
        <a
          onClick={() => { navigate(NavigationRoute.HOME_PAGE) }}
          style={linkStyle}>
          Home
        </a>
        .
      </p>
    </div>
  )
}

export default NotFoundPage
