export enum FontModeValues {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  CUSTOM_FONT = 'CUSTOM_FONT',
  HEADER_BIG = 'HEADER_BIG',
  LOGIN = 'LOGIN',
  AVERAGE = 'AVERAGE',
  EPIC_LARGE = 'EPIC_LARGE'
}

export const FONT_MODES: Record<FontModeValues, any> = {
  [FontModeValues.EXTRA_SMALL]: {
    browser: '12px',
    mobile: '12px',
    scalar: '12px'
  },
  [FontModeValues.SMALL]: {
    browser: '14px',
    mobile: '14px',
    scalar: '14px'

  },
  [FontModeValues.DEFAULT]: {
    browser: '16px',
    mobile: '16px',
    scalar: '16px'

  },
  [FontModeValues.LARGE]: {
    browser: '20px',
    mobile: '16px'
  },
  [FontModeValues.AVERAGE]: {
    browser: '24px',
    mobile: '16px'
  },
  [FontModeValues.EXTRA_LARGE]: {
    browser: '32px',
    mobile: '18px'
  },
  [FontModeValues.EPIC_LARGE]: {
    browser: '48px',
    mobile: '22px'
  },
  [FontModeValues.HEADER_BIG]: {
    browser: '70px',
    mobile: '48px'
  },
  [FontModeValues.CUSTOM_FONT]: {
    browser: 'auto',
    mobile: 'auto'
  },
  [FontModeValues.LOGIN]: {
    browser: '40px',
    mobile: '32px'
  }
}

export const isFontModeValue = (value: string): value is FontModeValues => {
  return Object.values(FontModeValues).includes(value as FontModeValues)
}
