import React from 'react'
import { getCardImageByType, getCardType } from '../../util/BankCardUtils'

interface CardTypeImgInterface {
  cardNumber: string
}

function CardTypeImg (props: CardTypeImgInterface) {
  const cardType = getCardType(props.cardNumber)
  const imgSrc = getCardImageByType(cardType)
  return (
    <img
      style={{ height: '30px', width: '30px' }}
      src={imgSrc}
      alt={cardType}/>
  )
}

export default CardTypeImg
