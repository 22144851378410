import React, { type ReactElement } from 'react'

interface MarginWrapperProps {
  top?: string
  bottom?: string
  left?: string
  right?: string
  all?: string
  children: ReactElement[] | ReactElement
}

function MarginWrapper (props: MarginWrapperProps) {
  // TODO maybe default in case of empty
  const all = props.all ? { margin: props.all } : {}
  return (
    <div
      style={{
        marginTop: props.top,
        marginBottom: props.bottom,
        marginLeft: props.left,
        marginRight: props.right,
        ...all
      }}>
      {props.children}
    </div>
  )
}

export default MarginWrapper
