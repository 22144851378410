/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewBalancePurchaseWithBankCardIdRequestDto
 */
export interface InterviewBalancePurchaseWithBankCardIdRequestDto {
    /**
     * Interview bank card id
     * @type {string}
     * @memberof InterviewBalancePurchaseWithBankCardIdRequestDto
     */
    bankCardId: string;
    /**
     * Interview balance card id
     * @type {string}
     * @memberof InterviewBalancePurchaseWithBankCardIdRequestDto
     */
    balanceCardId: string;
}

/**
 * Check if a given object implements the InterviewBalancePurchaseWithBankCardIdRequestDto interface.
 */
export function instanceOfInterviewBalancePurchaseWithBankCardIdRequestDto(value: object): value is InterviewBalancePurchaseWithBankCardIdRequestDto {
    if (!('bankCardId' in value) || value['bankCardId'] === undefined) return false;
    if (!('balanceCardId' in value) || value['balanceCardId'] === undefined) return false;
    return true;
}

export function InterviewBalancePurchaseWithBankCardIdRequestDtoFromJSON(json: any): InterviewBalancePurchaseWithBankCardIdRequestDto {
    return InterviewBalancePurchaseWithBankCardIdRequestDtoFromJSONTyped(json, false);
}

export function InterviewBalancePurchaseWithBankCardIdRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewBalancePurchaseWithBankCardIdRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bankCardId': json['bankCardId'],
        'balanceCardId': json['balanceCardId'],
    };
}

export function InterviewBalancePurchaseWithBankCardIdRequestDtoToJSON(value?: InterviewBalancePurchaseWithBankCardIdRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bankCardId': value['bankCardId'],
        'balanceCardId': value['balanceCardId'],
    };
}

