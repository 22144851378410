import React, { useEffect, useState } from 'react'
import TextWithLeftArrow from '../components/text/TextWithLeftArrow'
import selectedIcon from '../components/svg/resources/Done_round_purple.svg'
import ColoredText from '../components/text/ColoredText'
import { FontModeValues } from '../components/modes/FontMode'
import { type TimeBlock } from './TakerVerificationTimeRangeSelector'
import { getHHmmStringFromDate } from './time_range_selector_functions/CustomCalendarFunctions'

interface TimeRangeSelectorProps {
  interviewDuration: number
  date: Date
  backOnClick?: () => void
  availableDates?: Date[]
  setIsFlipped: React.Dispatch<React.SetStateAction<boolean>>
  singleSelectedTime: Date | null | undefined
  setSingleSelectedTime: React.Dispatch<React.SetStateAction< null | Date>>
}

const TakerInterviewTimeRangeSelector: React.FC<TimeRangeSelectorProps> = (
  {
    interviewDuration,
    date,
    backOnClick,
    availableDates,
    setIsFlipped,
    singleSelectedTime,
    setSingleSelectedTime
  }) => {
  const [TimeBlocks, setTimeBlocks] = useState<TimeBlock[]>([])
  useEffect(() => {
    const timeBlocks = generateTimeBlocks(availableDates ?? [])
    setTimeBlocks(timeBlocks)
  }, [date])

  function generateTimeBlocks (availableTimes: Date[]): TimeBlock[] {
    const blocks: TimeBlock[] = []
    if (availableTimes?.length) {
      for (date of availableTimes) {
        const startTimeClone = new Date(date)
        const endTime = new Date(startTimeClone.setMinutes(startTimeClone.getMinutes() + interviewDuration))
        if (date.getTime() === singleSelectedTime?.getTime()) {
          blocks.push({
            startTime: singleSelectedTime,
            endTime,
            selected: true
          })
        } else {
          blocks.push({
            startTime: date,
            endTime,
            selected: false
          })
        }
      }
    }
    return blocks
  }

  function toggleBlockSelection (index: number): void {
    setTimeBlocks((prevBlocks) => {
      let newBlocks = [...prevBlocks]
      newBlocks = prevBlocks.map(block => { return { ...block, selected: false } })
      newBlocks[index] = { ...newBlocks[index], selected: !prevBlocks[index].selected }
      if (newBlocks[index].selected) {
        setSingleSelectedTime(newBlocks[index].startTime)
      } else {
        setSingleSelectedTime(null)
      }
      return newBlocks
    })
  }

  return (
    <div className={'d-flex flex-column justify-content-center'}>
      <TextWithLeftArrow is_not_moved={false} fontsize={'24px'}
        text={`Time for ${date?.toDateString()}`}
        is_loading={false}
        onClick={() => {
          setIsFlipped(false)
          if (backOnClick) {
            backOnClick()
          }
        }}/>
      <div className={'d-inline-flex flex-column align-items-center'}
        style={{ maxHeight: '300px', overflowY: 'scroll' }}>
        {TimeBlocks.length
          ? (
              TimeBlocks.map((block, index) => (
                <div key={index} className={'row d-flex align-items-center'}
                  style={{
                    width: '200px',
                    minHeight: '40px',
                    height: '40px',
                    border: '1.4px solid black',
                    borderRadius: '6px',
                    margin: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    toggleBlockSelection(index)
                  }}>
                  {block.selected && (
                  <span className={'col-3'}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={selectedIcon} alt={'selected'}
                      draggable={false} style={{ userSelect: 'none' }}/>
                  </span>
                  )}
                  {!block.selected && (
                  <span className={'col-3'}
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}></span>
                  )}
                  <span className={'col-6 text-nowrap'} style={{
                    flex: '6',
                    fontSize: '18px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    userSelect: 'none'
                  }}>
                    {`${getHHmmStringFromDate(block.startTime)} - ${getHHmmStringFromDate(block.endTime)}`}
                  </span>
                  {block.selected && (
                  <span className={'col-3'} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                  </span>
                  )}
                  {!block.selected && (
                  <span className={'col-3'} style={{ flex: '3' }}></span>
                  )}
                </div>
              ))
            )
          : (
            <div style={{
              width: '200px',
              minHeight: '40px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <ColoredText
                singleText={'No Dates For Today'}
                color={'gray'}
                fontSize={FontModeValues.LARGE}/>
            </div>
            )}
      </div>
    </div>
  )
}

export default TakerInterviewTimeRangeSelector
