import React, { type ReactElement, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import ColoredText, { type ColoredTextItem } from '../text/ColoredText'
import { ClickAwayListener } from '@mui/material'
import { FontModeValues } from '../modes/FontMode'

interface StandardTooltipProps {
  children: ReactElement
  texts: ColoredTextItem[]
  style?: any
}

function StandardTooltip (props: StandardTooltipProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <span style={{ userSelect: 'none', cursor: 'pointer' }}>
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false)
        }}>
        <Tooltip
          open={isOpen}
          componentsProps={{
            tooltip: {
              sx: {
                ...props.style,
                backgroundColor: 'white',
                boxShadow: '0px 0px 20px 2px rgba(0, 0, 0, 0.25)',
                padding: '10px',
                borderRadius: '10px'
              }
            }
          }}
          title={(
            <ColoredText
              lineHeight={1.3}
              fontSize={FontModeValues.SMALL}
              texts={props.texts}/>
            )}
          placement={'top'}>
          <div
            onClick={() => { setIsOpen(prevState => !prevState) }}>
            {props.children}
          </div>
        </Tooltip>
      </ClickAwayListener>
    </span>
  )
}

export default StandardTooltip
