import React from 'react'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import { useSelector } from 'react-redux'
import { getTakerValidationCurrentPage } from '../../features/TakerValidationNextStepSlice'
import { TakerValidationRoute } from '../../enumeration/TakerValidationRoute'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

function VerifyHeader () {
  const currentPage = useSelector(getTakerValidationCurrentPage)
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  let title = 'Verification Process'
  if (isMobile) {
    switch (currentPage) {
      case TakerValidationRoute.PERSONAL_INFO:
        title = 'Personal Information'
        break
      case TakerValidationRoute.EDUCATION:
        title = 'Education'
        break
      case TakerValidationRoute.WORK_EXPERIENCE:
        title = 'Work Experience'
        break
      case TakerValidationRoute.URLS:
        title = 'URLs'
        break
      case TakerValidationRoute.INTERVIEWS:
        title = 'Interviews'
        break
      case TakerValidationRoute.MEETING:
        title = 'Meeting with IC'
        break
      default:
        title = 'Verification Process'
    }
  }

  return (
    <div style={{ paddingTop: '10px' }}>
      <ColoredText
        fontSize = {FontModeValues.EXTRA_LARGE}
        fontWeight={300}
        singleText= {title}/>
    </div>
  )
}

export default VerifyHeader
