/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewDto } from './GetInterviewDto';
import {
    GetInterviewDtoFromJSON,
    GetInterviewDtoFromJSONTyped,
    GetInterviewDtoToJSON,
} from './GetInterviewDto';
import type { EnumTypeDto } from './EnumTypeDto';
import {
    EnumTypeDtoFromJSON,
    EnumTypeDtoFromJSONTyped,
    EnumTypeDtoToJSON,
} from './EnumTypeDto';

/**
 * 
 * @export
 * @interface InterviewsWithBusinessDto
 */
export interface InterviewsWithBusinessDto {
    /**
     * 
     * @type {GetInterviewDto}
     * @memberof InterviewsWithBusinessDto
     */
    interview: GetInterviewDto;
    /**
     * Upcoming interviews
     * @type {Array<GetInterviewDto>}
     * @memberof InterviewsWithBusinessDto
     */
    upcomingInterviews?: Array<GetInterviewDto>;
    /**
     * Preferred languages
     * @type {Array<EnumTypeDto>}
     * @memberof InterviewsWithBusinessDto
     */
    preferredLanguages: Array<EnumTypeDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InterviewsWithBusinessDto
     */
    busyTimestamps: Array<string>;
    /**
     * Available languages
     * @type {Array<EnumTypeDto>}
     * @memberof InterviewsWithBusinessDto
     */
    availableLanguages: Array<EnumTypeDto>;
    /**
     * Duration in minutes
     * @type {number}
     * @memberof InterviewsWithBusinessDto
     */
    durationInMinutes: number;
    /**
     * Step in minutes
     * @type {number}
     * @memberof InterviewsWithBusinessDto
     */
    stepInMinutes: number;
}

/**
 * Check if a given object implements the InterviewsWithBusinessDto interface.
 */
export function instanceOfInterviewsWithBusinessDto(value: object): value is InterviewsWithBusinessDto {
    if (!('interview' in value) || value['interview'] === undefined) return false;
    if (!('preferredLanguages' in value) || value['preferredLanguages'] === undefined) return false;
    if (!('busyTimestamps' in value) || value['busyTimestamps'] === undefined) return false;
    if (!('availableLanguages' in value) || value['availableLanguages'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('stepInMinutes' in value) || value['stepInMinutes'] === undefined) return false;
    return true;
}

export function InterviewsWithBusinessDtoFromJSON(json: any): InterviewsWithBusinessDto {
    return InterviewsWithBusinessDtoFromJSONTyped(json, false);
}

export function InterviewsWithBusinessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewsWithBusinessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'interview': GetInterviewDtoFromJSON(json['interview']),
        'upcomingInterviews': json['upcomingInterviews'] == null ? undefined : ((json['upcomingInterviews'] as Array<any>).map(GetInterviewDtoFromJSON)),
        'preferredLanguages': ((json['preferredLanguages'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'busyTimestamps': json['busyTimestamps'],
        'availableLanguages': ((json['availableLanguages'] as Array<any>).map(EnumTypeDtoFromJSON)),
        'durationInMinutes': json['durationInMinutes'],
        'stepInMinutes': json['stepInMinutes'],
    };
}

export function InterviewsWithBusinessDtoToJSON(value?: InterviewsWithBusinessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'interview': GetInterviewDtoToJSON(value['interview']),
        'upcomingInterviews': value['upcomingInterviews'] == null ? undefined : ((value['upcomingInterviews'] as Array<any>).map(GetInterviewDtoToJSON)),
        'preferredLanguages': ((value['preferredLanguages'] as Array<any>).map(EnumTypeDtoToJSON)),
        'busyTimestamps': value['busyTimestamps'],
        'availableLanguages': ((value['availableLanguages'] as Array<any>).map(EnumTypeDtoToJSON)),
        'durationInMinutes': value['durationInMinutes'],
        'stepInMinutes': value['stepInMinutes'],
    };
}

