import { ReduxActionType } from '../enumeration/ReduxActionType'
import { type SignupDto } from '../api/ic'

export const getSignupUser = (state: any) => {
  return state.signupUser
}

export const signupUserReducer = (state: Partial<SignupDto> = {}, action: any) => {
  switch (action.type) {
    case ReduxActionType.ADD_SIGNUP_USER_EMAIL:
      return { ...state, email: action.payload }
    case ReduxActionType.ADD_SIGNUP_USER_FIRST_NAME:
      return { ...state, firstName: action.payload }
    case ReduxActionType.ADD_SIGNUP_USER_LAST_NAME:
      return { ...state, lastName: action.payload }
    case ReduxActionType.ADD_SIGNUP_USER_PASSWORD:
      return { ...state, password: action.payload }
    case ReduxActionType.CLEAR_SIGNUP_USER:
      return { }
    default:
      return state
  }
}

export const clearSignupUser = () => {
  return {
    type: ReduxActionType.CLEAR_SIGNUP_USER
  }
}

export const addSignupUserEmail = (value: any) => {
  return {
    type: ReduxActionType.ADD_SIGNUP_USER_EMAIL,
    payload: value
  }
}

export const addSignupUserFirstName = (value: any) => {
  return {
    type: ReduxActionType.ADD_SIGNUP_USER_FIRST_NAME,
    payload: value
  }
}

export const addSignupUserLastName = (value: any) => {
  return {
    type: ReduxActionType.ADD_SIGNUP_USER_LAST_NAME,
    payload: value
  }
}

export const addSignupUserPassword = (value: any) => {
  return {
    type: ReduxActionType.ADD_SIGNUP_USER_PASSWORD,
    payload: value
  }
}
