export const enum WidthModeValues {
  DOUBLE_EXTRA_SMALL = 'DOUBLE_EXTRA_SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
  LARGE_VW = 'LARGE_VW',
  EXTRA_LARGE = 'EXTRA_LARGE',
  EXTRA_LARGE_VW = 'EXTRA_LARGE_VW',
  DROPDOWN = 'DROPDOWN'
}

// TODO change all with steps for all modes

export const WIDTH_MODES: Record<WidthModeValues, any> = {
  [WidthModeValues.DOUBLE_EXTRA_SMALL]: {
    browser: '90px',
    mobile: '240px'
  },
  [WidthModeValues.EXTRA_SMALL]: {
    browser: '180px',
    mobile: '240px'
  },
  [WidthModeValues.SMALL]: {
    browser: '220px',
    mobile: '240px'
  },
  [WidthModeValues.DEFAULT]: {
    browser: '320px',
    mobile: '240px'
  },
  [WidthModeValues.LARGE]: {
    browser: '550px',
    mobile: '240px'
  },
  [WidthModeValues.LARGE_VW]: {
    browser: '50vw',
    mobile: '50vw'
  },
  [WidthModeValues.EXTRA_LARGE]: {
    browser: '800px',
    mobile: '320px',
    scalar: '800px'
  },
  [WidthModeValues.EXTRA_LARGE_VW]: {
    browser: '70vw',
    mobile: '70vw'
  },
  [WidthModeValues.DROPDOWN]: {
    browser: '180px',
    mobile: '320px'
  }
}
