import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetGiverFlipperValue } from '../../features/GiverCalendarFlipperSlice'

interface FlipCardProps {
  frontComponent: React.ReactNode
  backComponent: React.ReactNode
  width?: string
  height?: string
  flipped?: boolean
}

const Flipper: React.FC<FlipCardProps> = ({
  frontComponent,
  backComponent,
  width,
  height,
  flipped
}) => {
  const frontRef = useRef<HTMLDivElement>(null)
  const backRef = useRef<HTMLDivElement>(null)
  const [cardWidth, setCardWidth] = useState(0)
  const [cardHeight, setCardHeight] = useState(0)
  let isFlipped: boolean
  if (flipped !== undefined) {
    isFlipped = flipped
  } else {
    isFlipped = useSelector(GetGiverFlipperValue)
  }
  useEffect(() => {
    const frontWidth = frontRef.current?.offsetWidth || 0
    const frontHeight = frontRef.current?.offsetHeight || 0
    const backWidth = backRef.current?.offsetWidth || 0
    const backHeight = backRef.current?.offsetHeight || 0
    const maxWidth = Math.max(frontWidth, backWidth)
    const maxHeight = Math.max(frontHeight, backHeight)
    setCardWidth(maxWidth)
    setCardHeight(maxHeight)
  }, [frontComponent, backComponent])

  const flipStyles: React.CSSProperties = {
    width: width || 'auto',
    height: height || 'auto',
    position: 'relative',
    perspective: '1000px',
    transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    transformStyle: 'preserve-3d',
    transform: isFlipped ? 'rotateY(180deg) scaleX(-1)' : 'rotateY(0deg)'
  }

  const contentStyles: React.CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transformStyle: 'preserve-3d',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const frontOpacity = isFlipped ? 0 : 1
  const backOpacity = isFlipped ? 1 : 0

  return (
    <div style={flipStyles}>
      <div style={{ ...contentStyles, width: cardWidth, height: cardHeight }}>
        <div ref={frontRef} style={{ ...contentStyles, opacity: frontOpacity, zIndex: isFlipped ? -1 : 1 }}>
          {frontComponent}
        </div>
        <div ref={backRef} style={{ ...contentStyles, opacity: backOpacity, zIndex: isFlipped ? 1 : -1 }}>
          {backComponent}
        </div>
      </div>
    </div>
  )
}

export default Flipper
