/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewBalanceCardTagDto } from './GetInterviewBalanceCardTagDto';
import {
    GetInterviewBalanceCardTagDtoFromJSON,
    GetInterviewBalanceCardTagDtoFromJSONTyped,
    GetInterviewBalanceCardTagDtoToJSON,
} from './GetInterviewBalanceCardTagDto';

/**
 * 
 * @export
 * @interface GetInterviewBalanceCardDto
 */
export interface GetInterviewBalanceCardDto {
    /**
     * Tag ID
     * @type {string}
     * @memberof GetInterviewBalanceCardDto
     */
    id: string;
    /**
     * Interview count
     * @type {number}
     * @memberof GetInterviewBalanceCardDto
     */
    interviewCount: number;
    /**
     * Interviewer price
     * @type {number}
     * @memberof GetInterviewBalanceCardDto
     */
    price: number;
    /**
     * Interviewer discount price
     * @type {number}
     * @memberof GetInterviewBalanceCardDto
     */
    discountPrice: number;
    /**
     * Balance type
     * @type {string}
     * @memberof GetInterviewBalanceCardDto
     */
    balanceType: GetInterviewBalanceCardDtoBalanceTypeEnum;
    /**
     * Tag ids to attach
     * @type {Array<GetInterviewBalanceCardTagDto>}
     * @memberof GetInterviewBalanceCardDto
     */
    tags?: Array<GetInterviewBalanceCardTagDto>;
}


/**
 * @export
 */
export const GetInterviewBalanceCardDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type GetInterviewBalanceCardDtoBalanceTypeEnum = typeof GetInterviewBalanceCardDtoBalanceTypeEnum[keyof typeof GetInterviewBalanceCardDtoBalanceTypeEnum];


/**
 * Check if a given object implements the GetInterviewBalanceCardDto interface.
 */
export function instanceOfGetInterviewBalanceCardDto(value: object): value is GetInterviewBalanceCardDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('interviewCount' in value) || value['interviewCount'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('discountPrice' in value) || value['discountPrice'] === undefined) return false;
    if (!('balanceType' in value) || value['balanceType'] === undefined) return false;
    return true;
}

export function GetInterviewBalanceCardDtoFromJSON(json: any): GetInterviewBalanceCardDto {
    return GetInterviewBalanceCardDtoFromJSONTyped(json, false);
}

export function GetInterviewBalanceCardDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewBalanceCardDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'interviewCount': json['interviewCount'],
        'price': json['price'],
        'discountPrice': json['discountPrice'],
        'balanceType': json['balanceType'],
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(GetInterviewBalanceCardTagDtoFromJSON)),
    };
}

export function GetInterviewBalanceCardDtoToJSON(value?: GetInterviewBalanceCardDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'interviewCount': value['interviewCount'],
        'price': value['price'],
        'discountPrice': value['discountPrice'],
        'balanceType': value['balanceType'],
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(GetInterviewBalanceCardTagDtoToJSON)),
    };
}

