/* eslint-disable react/react-in-jsx-scope */
import { type VideoHTMLAttributes, useEffect, useRef } from 'react'

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject: MediaStream | null
}

export default function Video ({ srcObject, ...props }: PropsType) {
  const refVideo = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (srcObject) {
      if (!refVideo.current) return
      refVideo.current.srcObject = srcObject
      if (refVideo.current) {
        refVideo.current.style.height = '100%'
        refVideo.current.style.width = '100%'
        refVideo.current.style.objectFit = 'cover'
      }
      return () => {
        srcObject?.getVideoTracks().forEach((track) => { track.stop() })
        srcObject?.getAudioTracks().forEach((track) => { track.stop() })
      }
    }
  }, [srcObject])

  return (
    <div style={{ width: props.width, height: props.height }}>
      <video
        ref = {refVideo}
        style={{ objectFit: 'cover' }}
        width={'100%'}
        height={''}
        {...props}
          />
    </div>
  )
}
