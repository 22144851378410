import { jwtDecode } from 'jwt-decode'

export class JWTManager {
  private constructor () {
  }

  public static extractPayload (token: string): Record<string, any> | null {
    try {
      const decoded = jwtDecode(token)
      return decoded as Record<string, any>
    } catch (error) {
      return null
    }
  }
}
