/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonalInformationResponseDto
 */
export interface PersonalInformationResponseDto {
    /**
     * First name
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    firstname: string;
    /**
     * Last name
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    lastname: string;
    /**
     * Email
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    email: string;
    /**
     * Country
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    country?: string;
    /**
     * Date of birth
     * @type {Date}
     * @memberof PersonalInformationResponseDto
     */
    dateOfBirth?: Date;
    /**
     * Gender
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    gender?: PersonalInformationResponseDtoGenderEnum;
    /**
     * About me
     * @type {string}
     * @memberof PersonalInformationResponseDto
     */
    aboutMe?: string;
    /**
     * Preferred languages
     * @type {Array<string>}
     * @memberof PersonalInformationResponseDto
     */
    preferredLanguages: Array<PersonalInformationResponseDtoPreferredLanguagesEnum>;
}


/**
 * @export
 */
export const PersonalInformationResponseDtoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER'
} as const;
export type PersonalInformationResponseDtoGenderEnum = typeof PersonalInformationResponseDtoGenderEnum[keyof typeof PersonalInformationResponseDtoGenderEnum];

/**
 * @export
 */
export const PersonalInformationResponseDtoPreferredLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type PersonalInformationResponseDtoPreferredLanguagesEnum = typeof PersonalInformationResponseDtoPreferredLanguagesEnum[keyof typeof PersonalInformationResponseDtoPreferredLanguagesEnum];


/**
 * Check if a given object implements the PersonalInformationResponseDto interface.
 */
export function instanceOfPersonalInformationResponseDto(value: object): value is PersonalInformationResponseDto {
    if (!('firstname' in value) || value['firstname'] === undefined) return false;
    if (!('lastname' in value) || value['lastname'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('preferredLanguages' in value) || value['preferredLanguages'] === undefined) return false;
    return true;
}

export function PersonalInformationResponseDtoFromJSON(json: any): PersonalInformationResponseDto {
    return PersonalInformationResponseDtoFromJSONTyped(json, false);
}

export function PersonalInformationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalInformationResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstname': json['firstname'],
        'lastname': json['lastname'],
        'email': json['email'],
        'country': json['country'] == null ? undefined : json['country'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'gender': json['gender'] == null ? undefined : json['gender'],
        'aboutMe': json['aboutMe'] == null ? undefined : json['aboutMe'],
        'preferredLanguages': json['preferredLanguages'],
    };
}

export function PersonalInformationResponseDtoToJSON(value?: PersonalInformationResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'email': value['email'],
        'country': value['country'],
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString()),
        'gender': value['gender'],
        'aboutMe': value['aboutMe'],
        'preferredLanguages': value['preferredLanguages'],
    };
}

