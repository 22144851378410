import React, { useCallback, useEffect, useState } from 'react'
import Navigation from '../navigation/Navigation'
import { NavigationModeValues } from '../modes/NavigationMode'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import { Rating } from '@mui/material'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { useNavigate } from 'react-router-dom'

function InterviewReviewPage () {
  const [rate, setRate] = useState<number | null>(0)
  const navigate = useNavigate()
  useEffect(() => {
    document.body.style.background = 'rgba(32, 33, 36, 1)'
  }, [])

  const returnToHome = useCallback(() => {
    navigate(NavigationRoute.HOME_PAGE)
  }, [])
  const submitFeedback = useCallback(() => {
    navigate(NavigationRoute.HOME_PAGE)
  }, [])

  return (
    <div>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
        interviewText={'12:00 / interview Center Java Script'}
        mobileNavItems={[]}
        height = {'100px'}/>
      <div
        style = {{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 100px)',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px'
        }}>
        <ColoredText
          texts = {COLORED_TEXT_VALUES.REVIEW_TEXT}
          fontSize= {FontModeValues.EXTRA_LARGE}
          color='white'
          centered = {true}/>
        <Rating
          name="simple-controlled"
          value={rate}
          max = {5}
          style = {{
            fontSize: '50px',
            gap: '5px'
          }}
          size= {'large'}
          onChange={(event, newValue) => {
            setRate(newValue)
            return event
          }}/>
        <div
          style={{
            marginTop: '20px'
          }}
          className='d-flex flex-row align-items-center'>
          <StandardButton
            style={{
              background: 'white',
              boxShadow: 'none'
            }}
            textColor='black'
            textFontWeight={500}
            textFontSize= {FontModeValues.LARGE}
            sizeMode= {ButtonModeValues.REVIEW_BUTTON}
            onClick={() => { returnToHome() }}
            text = {'Return to home screen'}/>
          <StandardButton
            style={{
              background: 'rgba(32, 33, 36, 1)',
              boxShadow: 'none'
            }}
            textColor='#AEC3FF'
            onClick={submitFeedback}
            textFontSize= {FontModeValues.LARGE}
            textFontWeight={500}
            sizeMode= {ButtonModeValues.REVIEW_BUTTON}
            text = {'Submit feedback'}/>
        </div>
        <ColoredText
          fontSize= {FontModeValues.AVERAGE}
          texts={COLORED_TEXT_VALUES.DONT_FORGET_FEEDBACK}/>
      </div>
    </div>
  )
}

export default InterviewReviewPage
