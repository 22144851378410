import React, { useState } from 'react'
import ColoredText from '../components/text/ColoredText'
import { COLORS } from '../util/Consts'
import StandardButton from '../components/buttons/StandardButton'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import { Popover } from '@mui/material'
import { capitalizeFirstLetter } from '../util/Helper'
import StandardModal from '../components/modal/StandardModal'
import { FontModeValues } from '../components/modes/FontMode'
import toast from 'react-hot-toast'
import InterviewCancellationRadio from './InterviewCancellationRadio'
import { SectionValue } from './InterviewModal'
import Line from '../components/util/Line'
import { type GetInterviewDto, InterviewControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'

interface GiverRequestedCardProps {
  interview: GetInterviewDto
  setTriggerUpcomingAndCurrentInterviewDataFetching: React.Dispatch<React.SetStateAction<boolean>>
  setIsMainModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSectionValue: React.Dispatch<React.SetStateAction<SectionValue>>
  setIsOnEditPart: React.Dispatch<React.SetStateAction<boolean>>
}
function GiverRequestedCard (props: GiverRequestedCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [anchorFullName, setAnchorFullName] = React.useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [cancellationReason, SetCancellationReason] = React.useState<string>('')
  const [otherMessage, setOtherMessage] = useState<string>('')

  const interviewObj = props.interview
  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (props.interview.languages?.length !== 1) {
      setAnchorFullName(event.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchorFullName(null)
  }

  const openFullName = Boolean(anchorFullName)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'cancel-interview' : undefined

  return (
    <div
      style={{
        width: '100%',
        padding: '8px 12px 8px 12px',
        display: 'flex',
        marginBottom: '120px',
        flexDirection: 'column',
        justifyContent: 'start',
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative'
      }}>
      <div className={'d-flex flex-column mb-3'}>
        <div className={'d-inline-flex flex-row align-items-center justify-content-between'} style={{
          margin: '2px 0px 10px 0px'
        }}>
          <div style={{ paddingTop: '4px' }}>
            <ColoredText singleText={'Open Request'}
              color={'black'}
              lineHeight={1}/>
          </div>
          <div style={{
            fontWeight: 'bold',
            fontSize: '16px',
            userSelect: 'none',
            cursor: 'pointer'
          }}
            onClick={handleClick}>
            ...
          </div>
          <Popover
            id={id}
            disableRestoreFocus
            open={open}
            anchorEl={anchorEl}
            slotProps={{
              paper: {
                sx: {
                  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)'
                }
              }
            }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <div
              onClick={() => { setIsOpen(true) }}
              style={{
                padding: '5px 10px 5px 10px',
                userSelect: 'none',
                cursor: 'pointer'
              }}>
              Cancel
            </div>
          </Popover>
        </div>
        <Line lineWidth={'100%'}/>
      </div>
      <div className={'d-flex flex-row justify-content-between'}>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
          }}>
          <ColoredText singleText={'Languages'} color = {COLORS.MAIN_COLOR}/>
          <ColoredText singleText={'Level'} color = {COLORS.MAIN_COLOR}/>
          <ColoredText singleText={'Access'} color = {COLORS.MAIN_COLOR}/>
        </div>
        <div
          style = {{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: 'right'
          }}>
          <span>
            <span
              aria-owns={openFullName ? 'show-full-name' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <ColoredText
                whiteSpace={'nowrap'}
                singleText={props.interview?.languages && props.interview?.languages?.length >= 2
                  ? capitalizeFirstLetter(props.interview?.languages[0].toLowerCase()) + ', ' + capitalizeFirstLetter(props.interview?.languages[1].toLowerCase()) + '...'
                  : props.interview?.languages && props.interview?.languages?.length === 1
                    ? capitalizeFirstLetter(props.interview?.languages[0].toLowerCase())
                    : 'Not Specified'}/>
            </span>
            <Popover
              id="show-full-name"
              sx={{
                pointerEvents: 'none'
              }}
              slotProps={{
                paper: {
                  sx: {
                    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)'
                  }
                }
              }}
              open={openFullName}
              anchorEl={anchorFullName}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus>
              <ColoredText singleText={props.interview?.languages?.length
                ? props.interview.languages.map(
                  lang => capitalizeFirstLetter(lang.toLowerCase())
                ).join(', ') : undefined
              }/>
            </Popover>
          </span>
          <ColoredText singleText={interviewObj?.level ? `${capitalizeFirstLetter(interviewObj.level.toLowerCase())}` : 'Unknown'}/>
          <ColoredText singleText={props.interview?.isPublic ? 'Public' : 'Private'}/>
          <div className={'d-flex flex-row gap-2 align-items-center justify-content-end'}>

          </div>
        </div>
      </div>
      <StandardButton
        text= {'Edit'}
        clickable={true}
        sizeMode= {ButtonModeValues.MAX_WIDTH}
        onClick={() => { props.setSectionValue(SectionValue.NEW_REQUEST); props.setIsOnEditPart(true) }}
        backgroundColorMode='light'/>
      <StandardModal isOpen={isOpen} closeWhenClickingOutOfModal={true}
        title={'Interview Cancellation'}
        width={`${(screen.availWidth * 38 / 100)}px`}
        height={`${(screen.availHeight * 48 / 100)}px`}
        footerComponent={
          <>
            <ColoredText singleText={'Discard'} cursor={'pointer'}
              onClick={ () => { setIsOpen(false) } } />
            <StandardButton
              clickable={(cancellationReason !== '' && cancellationReason !== 'Other') || (cancellationReason === 'Other' && otherMessage !== '')}
              text={'Cancel'}
              sizeMode={ButtonModeValues.SMALL}
              onClick={ () => {
                const reason = cancellationReason === 'Other' ? otherMessage : cancellationReason
                void interviewApi.cancelInterview({
                  interviewCancellationDto: { interviewId: interviewObj.id, reason }
                })
                  .then(() => {
                    toast.success('Successfully cancelled interview')
                    setIsOpen(false)
                    props.setTriggerUpcomingAndCurrentInterviewDataFetching(true)
                  }).catch(() => { props.setTriggerUpcomingAndCurrentInterviewDataFetching(true) })
              }} />
          </>}>
        <>
          <div style={{ marginBottom: '80px' }} className={'d-flex flex-column gap-1'}>
            <ColoredText singleText={'Reason'} fontSize={FontModeValues.LARGE}/>
            <div className={'mt-1'}>
              <InterviewCancellationRadio
                otherMessage={otherMessage}
                setOtherMessage={setOtherMessage}
                selectedOption={cancellationReason}
                setSelection={SetCancellationReason}
                options={[
                  'Schedule Conflict',
                  'Health Issues',
                  'Not interested anymore',
                  'Technical Difficulties',
                  'Problem with taker or giver',
                  'Other']
                }/>
            </div>
          </div>
        </>
      </StandardModal>
    </div>
  )
}

export default GiverRequestedCard
