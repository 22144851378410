import React, { useEffect, useState } from 'react'
import StandardInput from '../input/StandardInput'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES, DROPDOWN_VALUES, HINTS } from '../../util/Consts'
import SmallDropdown from '../dropdown/SmallDropdown'
import StandardButton from '../buttons/StandardButton'
import toast from 'react-hot-toast'
import MarginWrapper from '../util/MarginWrapper'
import { ButtonModeValues } from '../modes/ButtonMode'
import { InputModeValues } from '../modes/InputModes'
import { FontModeValues } from '../modes/FontMode'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { Role } from '../../enumeration/Role'
import StandardModal from '../modal/StandardModal'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import ApiManager from '../../manager/ApiManager'
import { PreProductionUserControllerApi, PreProductionUserCreationDtoRoleEnum as PreProductionRole } from '../../api/ic'

function EmailRoleInput () {
  const [selected, setIsSelected] = useState(Role.GIVER)
  const [inputValue, setInputValue] = useState('')
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [isMailValid, setIsMailValid] = useState(false)
  const closeModal = () => {
    setModalIsOpen(false)
    setConfirmClose(true)
  }

  const preProductionSignupApi = ApiManager.getInstance(PreProductionUserControllerApi)

  const sendEmail = () => {
    setIsLoading(true)
    // TODO: change this enums
    let role: PreProductionRole
    switch (selected) {
      case Role.GIVER:
        role = PreProductionRole.Giver
        break
      case Role.TAKER:
        role = PreProductionRole.Taker
        break
      case Role.GIVER_TAKER:
        role = PreProductionRole.GiverTaker
        break
      case Role.HR_RECRUITER:
        role = PreProductionRole.HrRecruiter
        break
      default:
        throw Error('Index is out of range')
    }

    preProductionSignupApi.signupNewUser({
      preProductionUserCreationDto: {
        email: inputValue,
        role
      }
    }).then(res => {
      setIsLoading(false)
      if (res) {
        setModalIsOpen(true)
      } else {
        setRequestFailed(true)
      }
    }).catch(() => {
      setIsLoading(false)
      setRequestFailed(true)
    })
  }

  useEffect(() => {
    setRequestFailed(false)
    if (requestFailed) {
      toast.dismiss()
      toast.error(
        <ColoredText
          fontSize={FontModeValues.LARGE}
          texts={COLORED_TEXT_VALUES.SYSTEM_ERROR_MESSAGE}/>
      )
    }
  }, [requestFailed])

  return (
    <div
      className={'align-items-center text-center'}>
      <MarginWrapper
        top={confirmClose ? '20px' : '0'}>
        <ColoredText
          fontSize={confirmClose ? FontModeValues.LOGIN : FontModeValues.LARGE}
          parameters={[selected.valueOf()]}
          texts={confirmClose ? COLORED_TEXT_VALUES.THANKS_FOR_JOINING : COLORED_TEXT_VALUES.SEND_EMAIL_FOR_FREE_INTERVIEW}/>
      </MarginWrapper>
      {confirmClose
        ? (<div
            className={'d-flex flex-row justify-content-center align-items-center'}>
          <div
            className={'d-flex flex-column align-items-center mt-2'}>
            <ColoredText
              fontSize={FontModeValues.EXTRA_LARGE}
              fontWeight={500}
              texts={COLORED_TEXT_VALUES.WANT_ANOTHER_ROLE_EMAIL}/>
            <MarginWrapper top={'15px'}>
              <StandardButton
                onClick={() => { setConfirmClose(false) }}
                sizeMode={ButtonModeValues.DEFAULT}
                text={'Try more roles'}/>
            </MarginWrapper>
          </div>
        </div>)
        : (<div
            className={isMobile ? 'd-flex flex-column align-items-center' : 'd-flex flex-row align-items-center'}
            style={{ marginTop: isMobile ? '27px' : '17px' }}>
          <MarginWrapper
            bottom='12px'>
            <SmallDropdown
              label={'Role'}
              items={DROPDOWN_VALUES.ROLE}
              sizeMode= {InputModeValues.SMALL_DROPDOWN}
              selected={selected}
              setIsSelected={setIsSelected}
              hintMessage={HINTS.COUNTRY}/>
          </MarginWrapper>
          <MarginWrapper
            top={isMobile ? '10px' : ''}
            left={isMobile ? '' : '10px'}>
            <StandardInput
              sizeMode={InputModeValues.LARGER}
              label={'Email'}
              type = {StandardInputModeValues.EMAIL}
              placeHolder={'Enter your email address...'}
              errorMessage={'Input a valid email'}
              inputValue={inputValue}
              setInputValue={setInputValue}
              triggerValidate={triggerValidate}
              setTriggerValidate={setTriggerValidate}
              setIsValid={setIsMailValid}/>
          </MarginWrapper>
          <MarginWrapper
            top={'15.4px'}
            left={isMobile ? '' : '10px'}>
            <StandardButton
              sizeMode={ButtonModeValues.DEFAULT}
              text={'Send'}
              isLoading={isLoading}
              setTriggerValidate={setTriggerValidate}
              onClick={sendEmail}
              isValid={isMailValid}/>
          </MarginWrapper>
        </div>)}
      <StandardModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        closeWhenClickingOutOfModal={true}
        title={'Congrats!'}
        titleFontSize={FontModeValues.AVERAGE}
        titleColor={'black'}
        footerHeight={'60px'}
        footerComponent={
          <>
            {/* DIV FOR PLACING BUTTON TO THE END */}
            <div></div>
            <StandardButton
              onClick={closeModal}
              text='Great'
              sizeMode= {ButtonModeValues.SMALLEST}
              textFontSize= {FontModeValues.SMALL}/>
          </>
        }>
        <div>
          <ColoredText
            centered={true}
            fontSize={FontModeValues.LARGE}
            texts={COLORED_TEXT_VALUES.AD_PAGE_CONGRATS}
            parameters={[inputValue]}/>
        </div>
      </StandardModal>
    </div>
  )
}

export default EmailRoleInput
