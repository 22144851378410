import React from 'react'
import ColoredText from '../text/ColoredText'
import { COLORS } from '../../util/Consts'
import StandardModal from '../modal/StandardModal'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface congratsProps {
  complete: boolean
  onClose: () => void
}

function Congrats (props: congratsProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const handleOnClose = () => {
    props.onClose()
    window.location.reload()
  }
  return (
    <>
      <StandardModal isOpen={props.complete} closeWhenClickingOutOfModal={false}
        title={'Congrats'}
        titleFontWeight={500} titleColor={'black'}
        footerButtonText={'Great'}
        footerHeight={'50px'}
        width={!isMobile ? '30%' : '90%'}
        closeModal={handleOnClose}>
        <span className={'mt-2'}>
          <ColoredText
            singleText = {'We will contact you soon!'}
            color= {COLORS.MAIN_COLOR}
            centered = {true}
            fontWeight={600}/>
        </span>
      </StandardModal>
    </>
  )
}

export default Congrats
