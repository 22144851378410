import { ReduxActionType } from '../enumeration/ReduxActionType'
import { groupDatesFromIsoStrings } from '../util/Helper'
import { type GetInterviewDtoLevelEnum, type InterviewCreationDto, type InterviewCreationDtoLevelEnum } from '../api/ic'

export const getGiverInterview = (state: any): InterviewCreationDto => {
  return state.giverInterviewObject.dto
}
export const getGiverInterviewTimesByDates = (state: any): Array<{ date: Date, times: Date[] }> => {
  return state.giverInterviewObject.timesByDate
}

export const giverInterviewReducer = (state: { dto: Partial<InterviewCreationDto>, timesByDate: Array<{ date: Date, times: Date[] }> } = { dto: {}, timesByDate: [] }, action: any) => {
  let dateToChangeIndex
  let updatedState
  let existingDateIndex
  let dto
  let groupedDatesFromIsoStrings

  switch (action.type) {
    case ReduxActionType.UPDATE_INTERVIEW_LEVEL:
      return { ...state, dto: { ...state.dto, level: action.payload } }
    case ReduxActionType.UPDATE_INTERVIEW_LANGUAGES:
      return { ...state, dto: { ...state.dto, languages: [...action.payload] } }
    case ReduxActionType.ADD_OR_UPDATE_INTERVIEW_TYPE:
      return { ...state, dto: { ...state.dto, type: action.payload } }
    case ReduxActionType.ADD_OR_UPDATE_INTERVIEW_PRIVACY:
      return { ...state, dto: { ...state.dto, isPublic: action.payload } }
    case ReduxActionType.ADD_DATE_TO_INTERVIEW:
      dto = { ...state.dto, dates: action.payload }
      return { ...state, dto }
    case ReduxActionType.ADD_INTERVIEW_DATE:
      existingDateIndex = state.timesByDate.findIndex((selectedDay) => selectedDay.date === action.payload)
      if (existingDateIndex !== -1) {
        return state
      } else {
        return { ...state, timesByDate: [...state.timesByDate, { date: action.payload, times: [] }] }
      }
    case ReduxActionType.SET_GIVER_INTERVIEW_TIMES:
      if (!action.payload[0]) {
        dateToChangeIndex = state.timesByDate.findIndex(timesByDate =>
          timesByDate.date.getTime() === action.date.getTime()
        )
        updatedState = [...state.timesByDate]
        updatedState[dateToChangeIndex] = {
          date: [...state.timesByDate][dateToChangeIndex].date,
          times: []
        }
        return { ...state, timesByDate: [...updatedState] }
      }

      dateToChangeIndex = state.timesByDate.findIndex(obj =>
        obj.date.getTime() === action.date.getTime()
      )
      if (dateToChangeIndex === -1) {
        // If the date to change is not found, return the state unchanged
        if (action.payload.length && action.date) {
          updatedState = [...state.timesByDate]
          // Update the times for the specific date
          updatedState.push(
            {
              date: action.date,
              times: action.payload
            }
          )
          return { ...state, timesByDate: [...updatedState] }
        }
        return state
      }
      // Create a new array to avoid mutating the original state
      updatedState = [...state.timesByDate]
      // Update the times for the specific date
      updatedState[dateToChangeIndex] = {
        date: updatedState[dateToChangeIndex].date,
        times: action.payload
      }
      return { ...state, timesByDate: [...updatedState] }
    case ReduxActionType.SET_GIVER_INTERVIEW_TIMES_FROM_ISO_STRINGS:
      groupedDatesFromIsoStrings = groupDatesFromIsoStrings(action.payload)
      return { ...state, timesByDate: [...state.timesByDate, ...groupedDatesFromIsoStrings] }

    case ReduxActionType.CLEAR_INTERVIEW_DATA:
      return { dto: {}, timesByDate: [] }
    default:
      return state
  }
}

export const clearGiverInterviewData = () => {
  return {
    type: ReduxActionType.CLEAR_INTERVIEW_DATA
  }
}

export const updateGiverInterviewLanguages = (value: string[]) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_LANGUAGES,
    payload: value
  }
}

export const addOrUpdateInterviewPrivacy = (value: boolean) => {
  return {
    type: ReduxActionType.ADD_OR_UPDATE_INTERVIEW_PRIVACY,
    payload: value
  }
}

export const updateGiverInterviewLevel = (value: InterviewCreationDtoLevelEnum | GetInterviewDtoLevelEnum | undefined) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_LEVEL,
    payload: value
  }
}

export const addGiverInterviewType = (value: string) => {
  return {
    type: ReduxActionType.ADD_OR_UPDATE_INTERVIEW_TYPE,
    payload: value
  }
}

export const addDateToInterview = (value: Date[]) => {
  return {
    type: ReduxActionType.ADD_DATE_TO_INTERVIEW,
    payload: value
  }
}

export const addInterviewDate = (value: Date) => {
  return {
    type: ReduxActionType.ADD_INTERVIEW_DATE,
    payload: value
  }
}

export const setGiverTimesByDate = (times: Date[], date: Date) => {
  return {
    type: ReduxActionType.SET_GIVER_INTERVIEW_TIMES,
    payload: times,
    date
  }
}

export const setGiverTimesByDateFromIsoStrings = (isoStrings: string[]) => {
  return {
    type: ReduxActionType.SET_GIVER_INTERVIEW_TIMES_FROM_ISO_STRINGS,
    payload: isoStrings
  }
}
