import React, { useState } from 'react'
import ColoredText, { type ColoredTextItemConfig } from '../text/ColoredText'
import { COLORED_TEXT_VALUES, COLORS } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import dropdown from '../svg/resources/down_main_color.svg'

interface rowsProps {
  open: boolean
  headerText: string
  mainText: ColoredTextItemConfig[]
}

function Row (props: rowsProps) {
  const [open, setOpen] = useState<boolean>(props.open ?? false)
  const [textOpen, setTextOpen] = useState<boolean>(props.open ?? false)

  function handleClick () {
    setOpen(prev => !prev)
    if (!open) {
      setTimeout(() => {
        setTextOpen(true)
      }, 300)
    } else {
      setTextOpen(false)
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div className='d-flex flex-row align-items-center gap-2'
        onClick={handleClick}
      >
        <ColoredText
          singleText={props.headerText}
          color= {COLORS.MAIN_COLOR}
          fontSize= {FontModeValues.AVERAGE}
            />
        <img src = {dropdown} alt = 'dropdown' />
      </div>
      <div style={{
        marginTop: open ? '30px' : '10px',
        // height: open ? '120px' : '0px',
        transitionDuration: '0.5s'
      }}>
        <div style={{
          display: textOpen ? 'block' : 'none'
        }}>
          <ColoredText
            texts={props.mainText}
            fontSize= {FontModeValues.LARGE}/>
        </div>
      </div>
    </div>
  )
}

function AboutSection () {
  const rows = [
    {
      header: 'About Us: Shaping Tomorrow\'s Workforce Today',
      main: COLORED_TEXT_VALUES.SHAPING_TOMORROWS
    },
    {
      header: 'Empowering Interviewees to Shine: The Giver Experience',
      main: COLORED_TEXT_VALUES.EMPOWERING_INTERVIEWS
    },
    {
      header: 'Takers: Where Friendly Pros Help You Succeed',
      main: COLORED_TEXT_VALUES.HELP_YOU_SUCCEED
    },
    {
      header: 'Connecting Talent with Opportunity: The Future of Hiring',
      main: COLORED_TEXT_VALUES.CONNECTING_TALENTS
    },
    {
      header: 'A Symphony of Questions: Unleashing Your Potential',
      main: COLORED_TEXT_VALUES.SYMPHONY_OF_QUESTIONS
    },
    {
      header: 'Revolutionizing Hiring: Shattering the CV Norm',
      main: COLORED_TEXT_VALUES.REVOLUTIONIZING_HIRING
    },
    {
      header: 'Your Story, Your Way: Shaping the Future of Career',
      main: COLORED_TEXT_VALUES.YOUR_STORY
    }
  ]
  return (
    <>
      {
        rows.map((el, idx) => (
          <Row
            mainText={el.main}
            headerText={el.header}
            open = {idx === 0}
            key = {idx}
          />
        ))
      }
    </>
  )
}

export default AboutSection
