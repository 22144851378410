import React, { useLayoutEffect, useRef, useState } from 'react'

interface GenericUnderlinedTextProps {
  setSectionValue: React.Dispatch<React.SetStateAction<string>>
  initialSection: string
  type?: string
  firstWord: string
  secondWord: string
}

function GenericUnderlinedText (props: GenericUnderlinedTextProps) {
  const leftTextRef = useRef<HTMLSpanElement>(null)
  const [leftTextWidth, setLeftTextWidth] = useState(0)

  const rightTextRef = useRef<HTMLSpanElement>(null)
  const [rightTextWidth, setRightTextWidth] = useState(0)
  const [initiallyRender, setInitiallyRender] = useState(true)
  const [underlineStyle, setUnderlineStyle] = useState<{ left: string, width: string }>()

  useLayoutEffect(() => {
    if (leftTextRef.current) {
      setLeftTextWidth(leftTextRef.current.offsetWidth)
    }
    if (rightTextRef.current) {
      setRightTextWidth(rightTextRef.current.offsetWidth)
    }
    if (leftTextWidth > 0 && rightTextWidth > 0) {
      setUnderlineStyle({
        left: props.initialSection === props.firstWord ? '0%' : `${leftTextWidth + 20}px`,
        width: props.initialSection === props.firstWord ? `${leftTextWidth}px` : `${rightTextWidth}px`
      })
      setInitiallyRender(false)
    }
  }, [leftTextWidth, rightTextWidth])

  const handleChangeSectionClick = (buttonNumber: number) => {
    if (buttonNumber === 1) {
      setUnderlineStyle({
        left: '0%',
        width: `${leftTextWidth}px`
      })
    } else {
      setUnderlineStyle({
        left: `${leftTextWidth + 20}px`,
        width: `${rightTextWidth}px`
      })
    }
    if (buttonNumber === 1) {
      props.setSectionValue(props.firstWord)
    } else {
      props.setSectionValue(props.secondWord)
    }
  }
  return (

    <div style={{ display: 'flex', position: 'relative', width: 'fit-content', gap: '20px' }}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => { handleChangeSectionClick(1) }}
      >
        <span ref={leftTextRef}>{props.firstWord}</span>
      </span>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => { handleChangeSectionClick(2) }}
      >
        <span ref={rightTextRef}>{props.secondWord}</span>
      </span>
      <div
        style={{
          height: '1.5px',
          backgroundColor: 'black',
          position: 'absolute',
          bottom: '-4px',
          transition: initiallyRender ? 'none' : 'left 0.3s ease, width 0.3s ease',
          ...underlineStyle
        }}
      />
    </div>
  )
}

export default GenericUnderlinedText
