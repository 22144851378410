import React, { useCallback, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { AuthManager } from '../../manager/AuthManager'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import MarginWrapper from '../util/MarginWrapper'
import VerifyModal from '../VerifyModal/VerifyModal'
import toast from 'react-hot-toast'
import { GetICUserDtoRoleEnum as ICUserRole, GetICUserDtoTakerStatusEnum as TakerStatus } from '../../api/ic'
import { NavigationRoute } from '../../enumeration/NavigationRoute'

function NavigationProfile () {
  const [show, setShow] = useState(false)
  const body = AuthManager.getAccessTokenBody()
  const takerValidationRequested = body?.takerStatus === TakerStatus.RequestedForValidation
  const takerValidationRejected = body?.takerStatus === TakerStatus.Rejected
  // TODO: make generic functional component for this functionality
  window.onload = function () { checkForToast('RequestAgain', 'true', 'Requested for validation successfully') }
  const removeQueryParameter = (query: any) => {
    if (history.replaceState) {
      const url = new URL(window.location.href)
      url.searchParams.delete(query)
      const newUrl = url.toString()
      history.replaceState({}, '', newUrl)
    }
  }
  const checkForToast = (query: string, value: string, message: string) => {
    const { search } = window.location
    const isToastNeeded = new URLSearchParams(search).get(query)
    if (isToastNeeded === value) {
      // The page was just reloaded, display the toast:
      toast.success(message)
      removeQueryParameter(query)
    }
  }

  const handleOpenModal = () => { setShow(true) }
  const handleClose = useCallback(() => {
    setShow(false)
  }, [])
  return (
    <div className={'d-flex d-row justify-content-center align-items-center'}>
      {/* TODO check if already requested for validation */}
      {body?.role === ICUserRole.Giver &&
      <MarginWrapper right={'22px'}>
        <StandardButton
          onClick= { handleOpenModal }
          clickable={!takerValidationRequested}
          gradient={takerValidationRequested}
          text={takerValidationRejected ? 'Request Again' : takerValidationRequested ? 'Taker validation pending...' : 'Become a Taker'}
          sizeMode={ButtonModeValues.EPIC_SMALL_AUTO} />
      </MarginWrapper>}
      <ProfileMenu
        imgURI={'DEFAULT'}
        firstname={body?.firstName ?? 'firstname'}
        lastname={body?.lastName ?? 'lastName'}
        inverse = {true}
        main = {[
          {
            value: 'Home',
            route: NavigationRoute.HOME_PAGE,
            color: 'black'
          },
          {
            value: 'Profile',
            route: NavigationRoute.PROFILE,
            color: 'black'
          }
        ]}
        secondary={[
          {
            color: 'black',
            value: 'Settings',
            route: NavigationRoute.SETTING_PAYMENT_CARD
          }
        ]}/>
      {(show)
        ? <VerifyModal
            setVerified={() => {}}
            onClose={handleClose}
            show={show}/>
        : <></>}
    </div>
  )
}

export default NavigationProfile
