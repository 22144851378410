import { JWTManager } from './JWTManager'
import Cookies from 'js-cookie'
import {
  AppControllerApi,
  AuthenticationControllerApi,
  type BaseAPI,
  type ICExceptionDto,
  ICExceptionDtoReasonEnum,
  type RefreshableAuthenticationResponseDto
} from '../api/ic'
import ApiManager from './ApiManager'
import { NavigationRoute } from '../enumeration/NavigationRoute'

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'

export class AuthManager {
  private constructor () {
  }

  public static setTokens (tokens: RefreshableAuthenticationResponseDto) {
    AuthManager.setAccessToken(tokens.token)
    AuthManager.setRefreshToken(tokens.refreshToken)
  }

  public static setAccessToken (token: string | undefined) {
    if (!token) {
      AuthManager.deleteAccessToken()
      window.location.reload()
      throw Error('Session token should not be null')
    }
    const payload: any = JWTManager.extractPayload(token)
    const exp = new Date(payload.expiresOn)

    Cookies.set(ACCESS_TOKEN, token, {
      expires: exp,
      path: '/',
      secure: true,
      sameSite: 'Strict'
    })
  }

  public static setRefreshToken (token: string | undefined) {
    localStorage.removeItem(REFRESH_TOKEN)
    if (token) {
      localStorage.setItem(REFRESH_TOKEN, token)
    }
  }

  public static getRefreshToken (): string | null {
    const token = localStorage.getItem(REFRESH_TOKEN)
    if (token) {
      const payload: any = JWTManager.extractPayload(token)
      const expDate = new Date(payload.expiresOn)
      if (new Date() > expDate) {
        localStorage.removeItem(REFRESH_TOKEN)
      }
    }

    return localStorage.getItem(REFRESH_TOKEN)
  }

  public static getAccessToken (): string | undefined {
    const cookies = document.cookie.split('; ')
    let token
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=')
      if (name === ACCESS_TOKEN) {
        token = value
        break
      }
    }
    return token
  }

  public static getAccessTokenBody () {
    const sessionToken = AuthManager.getAccessToken()
    if (!sessionToken) {
      return undefined
    }
    return JWTManager.extractPayload(sessionToken) ?? {}
  }

  public static deleteTokens () {
    AuthManager.deleteAccessToken()
    AuthManager.deleteRefreshToken()
  }

  public static deleteAccessToken () {
    const Cookies = document.cookie.split(';')
    for (let i = 0; i < Cookies.length; i++) {
      document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString()
    }
  }

  public static deleteRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN)
  }

  public static getUserRole () {
    const sessionToken = AuthManager.getAccessToken()
    if (!sessionToken) {
      return null
    }
    const tokenBody = JWTManager.extractPayload(sessionToken)
    if (tokenBody?.role) {
      return tokenBody.role
    }
  }

  public static async validateAuthRefresh (response: Response, baseApi: BaseAPI) {
    if (baseApi instanceof AuthenticationControllerApi || baseApi instanceof AppControllerApi) {
      return
    }
    if (!response) {
      return
    }
    await AuthManager.validateUserStatusChange(response, baseApi)
    // TODO handle role change
  }

  private static async validateUserStatusChange (response: Response, baseApi: BaseAPI) {
    if (baseApi instanceof AuthenticationControllerApi || baseApi instanceof AppControllerApi) {
      return
    }
    if (!response) {
      return
    }
    const customHeader = response.headers.get('X-ic-user-payload-changed')
    if (customHeader) {
      const refreshToken = AuthManager.getRefreshToken()
      if (refreshToken) {
        const authApi = ApiManager.getInstance(AuthenticationControllerApi)
        const res = await authApi
          .refreshToken({ authorization: refreshToken })
          .catch(e => { console.log('Could not obtain new token', e.message) })
        if (res) {
          AuthManager.setTokens(res)
        }
      }
    }
  }

  static handleInvalidAuthResponse (icException: ICExceptionDto) {
    if (icException.reason === ICExceptionDtoReasonEnum.SessionExpiredException ||
      icException.reason === ICExceptionDtoReasonEnum.InvalidSessionTokenException ||
      icException.reason === ICExceptionDtoReasonEnum.NoSessionIdProvidedInPayloadException
    ) {
      AuthManager.deleteTokens()
      location.href = NavigationRoute.LOG_IN
      return true
    }
    return false
  }
}
