import React, { useEffect, useLayoutEffect, useState } from 'react'
import ProfilePicture from '../../profilePicture/ProfilePicture'
import ColoredText from '../../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../../util/Consts'
import StandardInput from '../../input/StandardInput'
import { StandardInputModeValues } from '../../modes/StandardInputMode'
import { InputModeValues } from '../../modes/InputModes'
import MarginWrapper from '../../util/MarginWrapper'
import { CropperType } from '../../../enumeration/CropperType'
import { AuthManager } from '../../../manager/AuthManager'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getTakerValidationPersonalInfo, updateTakerValidationPersonalInfo } from '../../../features/TakerInfoSlice'
import {
  doneTakerValidationCurrentStep,
  isRequestedForTakerValidationNextStep
} from '../../../features/TakerValidationNextStepSlice'
import { findObjectFromKey, findObjectFromKeyWithoutUndefinedValue, formatDateForCalendar } from '../../../util/Helper'
import EnumValueDropDown from '../../dropdown/EnumValueDropDown'
import EnumValueMultipleAutocomplete from '../../multipleAutocomplete/EnumValueMultipleAutocomplete'
import { scrollTo } from '../utils'
import { type EnumTypeDto, type TakerValidationRequestDtoGenderEnum, type TakerValidationRequestDtoPreferredLanguagesEnum } from '../../../api/ic'

function PersonalInfo (props: { genders: EnumTypeDto[], languages: EnumTypeDto[] }) {
  const takerValidationPersonalInfo = useSelector(getTakerValidationPersonalInfo)
  const body = AuthManager.getAccessTokenBody() ?? {}
  const [objectAvailable, setObjectAvailable] = useState(false)
  const [firstName, setFirstName] = useState(takerValidationPersonalInfo?.firstName ?? 'firstname')
  const [lastName, setLastName] = useState(takerValidationPersonalInfo?.lastName ?? 'lastname')
  const [email, setEmail] = useState(body?.email ?? 'email')
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [about, setAbout] = useState('')
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [gender, setGender] = useState<EnumTypeDto | undefined>(undefined)
  const [selectedLanguages, setSelectedLanguages] = useState<EnumTypeDto[]>([])
  const [languageError, setLanguageError] = useState<boolean>(false)
  const [isFirstNameValid, setIsFirstNameValid] = useState(false)
  const [genderError, setGenderError] = useState(false)
  const [isDobValid, setIsDobValid] = useState(false)
  const [isLastNameValid, setIsLastNameValid] = useState(false)
  const [isCountryValid, setIsCountryValid] = useState(false)

  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  useEffect(() => {
    if (gender === undefined && requestedForNextStep) {
      setGenderError(true)
    }
    const canGo = (gender !== undefined && isDobValid && isFirstNameValid && isLastNameValid && isCountryValid) && selectedLanguages.length > 0
    if (requestedForNextStep) {
      dispatch(doneTakerValidationCurrentStep(canGo))
      if (!canGo) {
        if (!selectedLanguages.length) {
          setLanguageError(true)
          toast.error('You need to select at least one language')
          return
        }
        toast.error('Fill all required fields')
        return
      }
      dispatch(
        updateTakerValidationPersonalInfo({
          firstName,
          lastName,
          country,
          dateOfBirth: date,
          gender: gender?.key as TakerValidationRequestDtoGenderEnum,
          aboutMe: about,
          preferredLanguages: selectedLanguages.map(lang => lang.key as TakerValidationRequestDtoPreferredLanguagesEnum)
        })
      )
    }
  }, [requestedForNextStep])

  useEffect(() => {
    if (takerValidationPersonalInfo?.firstName || takerValidationPersonalInfo?.lastName || takerValidationPersonalInfo?.country) {
      setObjectAvailable(true)
      setFirstName(takerValidationPersonalInfo.firstName)
      setLastName(takerValidationPersonalInfo.lastName)
      setDate(takerValidationPersonalInfo.dateOfBirth)
      setCountry(takerValidationPersonalInfo.country)
      setAbout(takerValidationPersonalInfo.aboutMe ?? '')
      setSelectedLanguages(
        takerValidationPersonalInfo?.preferredLanguages?.length
          ? takerValidationPersonalInfo?.preferredLanguages.map(
            lang => findObjectFromKeyWithoutUndefinedValue(
              props.languages,
              { key: lang.valueOf() }
            ))
          : [])
      setGender(findObjectFromKey(props.genders, { key: takerValidationPersonalInfo?.gender?.valueOf() }))
    }
  }, [])

  return (
    <div style={{ overflowY: 'scroll', padding: '10px', paddingBottom: '10px' }}>
      <span id={'top'}></span>
      {objectAvailable && (
        <>
          <div className={'d-flex flex-column flex-wrap justify-content-between'}>
            <div className={'d-flex flex-row justify-content-between flex-wrap align-items-center'}>
              <div className={'d-flex flex-row align-items-center justify-content-between gap-2'}
                style={{
                  flexWrap: 'nowrap'
                }}>
                <ProfilePicture
                  inverse = {true}
                  name = {firstName}
                  surname={lastName}
                  width = '124.5px'
                  pictureSrc= {'DEFAULT'}
                  isChangeable = {false}
                  cropperType={CropperType.WITHOUT_MODAL}/>
                <ColoredText
                  centered = {true}
                  fontSize={'14px'}
                  texts={COLORED_TEXT_VALUES.PICK_PICTURE}/>
              </div>
              <div className={'d-flex flex-column flex-wrap'}>
                <StandardInput
                  type= {StandardInputModeValues.FIRST_NAME}
                  label='First name'
                  placeHolder='John'
                  isRequired={true}
                  setIsValid={setIsFirstNameValid}
                  inputValue={firstName}
                  triggerValidate={requestedForNextStep}
                  sizeMode= {InputModeValues.TINY_BIG}
                  setInputValue={setFirstName}/>
                <StandardInput
                  type= {StandardInputModeValues.LAST_NAME}
                  label='Last name'
                  placeHolder='Smith'
                  isRequired={true}
                  setIsValid={setIsLastNameValid}
                  inputValue={lastName}
                  triggerValidate={requestedForNextStep}
                  sizeMode= {InputModeValues.TINY_BIG}
                  setInputValue={setLastName}/>
              </div>
            </div>
            <div className={'d-flex flex-row flex-wrap justify-content-between'}>
              <div className={'d-flex flex-column flex-wrap '}>
                <StandardInput
                  type= {StandardInputModeValues.ONLYREAD}
                  label='Email'
                  placeHolder=''
                  inputValue={email}
                  sizeMode= {InputModeValues.TINY_BIG}
                  setInputValue={setEmail}/>
                <StandardInput
                  type={StandardInputModeValues.COUNTRY}
                  label={'Country'}
                  placeHolder={'Country'}
                  isRequired={true}
                  triggerValidate={requestedForNextStep}
                  setIsValid={setIsCountryValid}
                  sizeMode= {InputModeValues.TINY_BIG}
                  inputValue={country}
                  setInputValue={setCountry}/>
              </div>
              <div className={'d-flex flex-column flex-wrap'}>
                <StandardInput
                  type= {StandardInputModeValues.DATE}
                  label='Date of birth'
                  placeHolder=''
                  isRequired={true}
                  setIsValid={setIsDobValid}
                  triggerValidate={requestedForNextStep}
                  inputValue={formatDateForCalendar(date) ?? ''}
                  sizeMode= {InputModeValues.TINY_BIG}
                  setInputValue={setDate}/>
                <EnumValueDropDown
                  error = {genderError}
                  items={props.genders}
                  onChange={() => { setGenderError(false) }}
                  selectedValue={gender}
                  isRequired={true}
                  setSelectedValue={setGender}
                  label={'Gender'}
                  sizeMode={InputModeValues.TINY_BIG}/>
              </div>
            </div>
          </div>
          <MarginWrapper
            top = {'15px'}>
            <EnumValueMultipleAutocomplete
              error={languageError}
              onChange={() => { setLanguageError(false) }}
              label={'Languages'}
              selectedValues={selectedLanguages}
              setSelectedValue={setSelectedLanguages}
              width={'100%'}
              isRequired={true}
              items={props.languages}/>
          </MarginWrapper>
          <MarginWrapper
            top = {`${selectedLanguages.length > 2 ? selectedLanguages.length * 15 : 1}px`}>
            <StandardInput
              type= {StandardInputModeValues.TEXT_AREA}
              label='About you'
              placeHolder=''
              inputValue={about}
              sizeMode= {InputModeValues.TEXT_AREA_MODAL}
              setInputValue={setAbout}/>
          </MarginWrapper>
        </>
      )}

    </div>
  )
}

export default PersonalInfo
