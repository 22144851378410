import React, { useEffect, useLayoutEffect, useState } from 'react'
import Education from './Education'
import MarginWrapper from '../../util/MarginWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  addEducation,
  getEducationList,
  updateEducationList
} from '../../../features/TakerInfoSlice'
import {
  backTakerValidationCurrentStep,
  doneTakerValidationCurrentStep, isRequestedForBack,
  isRequestedForTakerValidationNextStep,
  isRequestForAdd, requestForAdd, requestForAddAccepted
} from '../../../features/TakerValidationNextStepSlice'
import toast from 'react-hot-toast'
import ColoredText from '../../text/ColoredText'
import { COLORS } from '../../../util/Consts'
import { scrollTo } from '../utils'
import { type EducationDto } from '../../../api/ic'

interface listProps {
  type?: 'Modal' | 'notModal'
  educationList?: EducationDto[] | undefined
  editable?: boolean
  setTriggerAddEducation?: React.Dispatch<React.SetStateAction<boolean>>
  triggerAddEducation?: boolean
  setLocalList?: React.Dispatch<React.SetStateAction<EducationDto[]>>
}

function EducationList (props: listProps) {
  const type = props.type ?? 'Modal'
  const educationList = useSelector(getEducationList)
  const [localList, setLocalList] = useState<EducationDto[]>(type === 'Modal' ? JSON.parse(JSON.stringify(educationList)) : props.educationList)
  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const isRequestedForAdd = useSelector(isRequestForAdd)
  const [requestForDelete, setRequestedForDelete] = useState(false)
  const requestForBack = useSelector(isRequestedForBack)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  useEffect(() => {
    if (type === 'Modal') {
      if (requestForDelete) {
        dispatch(updateEducationList([...localList]))
        setRequestedForDelete(false)
      }
      let canGo = true
      let notValidDates = false
      for (const educationDto of localList) {
        if (!educationDto.name ||
          !educationDto.degree ||
          !educationDto.location ||
          !educationDto.fromDate ||
          (!educationDto.toDate && !educationDto.stillStudying) ||
          (
            new Date(educationDto.fromDate ?? '').getTime() > new Date(educationDto.toDate ?? '').getTime() && !educationDto.stillStudying
          )
        ) {
          canGo = false
          if (new Date(educationDto.fromDate ?? '').getTime() > new Date(educationDto.toDate ?? '').getTime() && !educationDto.stillStudying) {
            notValidDates = true
          }
          break
        }
      }
      if (requestedForNextStep) {
        dispatch(doneTakerValidationCurrentStep(canGo))
        if (!canGo) {
          let errorMessage = 'Fill all required fields.'
          if (notValidDates) {
            errorMessage = errorMessage + '\nPlease provide valid Dates about your education'
          }
          if (!notValidDates) {
            toast.error('Fill all required fields.')
            return
          }
          toast.error(errorMessage)
          return
        }
        dispatch(updateEducationList([...localList]))
      } else if (isRequestedForAdd) {
        dispatch(requestForAddAccepted())
        if (canGo) {
          dispatch(updateEducationList([...localList]))
          const newEducation: EducationDto = {
            name: '',
            degree: '',
            location: '',
            fromDate: '',
            toDate: '',
            description: '',
            stillStudying: false
          }
          dispatch(addEducation(newEducation))
          setLocalList((prev: EducationDto[]) => [...prev, newEducation])
        } else {
          let errorMessage = 'Fill all required fields.'
          if (notValidDates) {
            errorMessage = errorMessage + '\nPlease provide valid Dates about your education'
          }
          if (!notValidDates) {
            toast.error('Fill all required fields.')
            return
          }
          toast.error(errorMessage)
        }
      } else if (requestForBack) {
        dispatch(updateEducationList([...localList]))
        dispatch(backTakerValidationCurrentStep(true))
      }
    }
  }, [
    requestedForNextStep,
    isRequestedForAdd,
    requestForBack,
    requestForDelete,
    localList,
    props.triggerAddEducation])

  return (
    <div className={'d-flex flex-column justify-content-start'}>
      <span id={'top'}></span>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: props.type === 'Modal' ? 'center' : ''
      }}>
        {localList?.map((obj: EducationDto, idx: number) => {
          return (
            <MarginWrapper key={idx} top={'15px'}>
              <Education
                isFirst={idx === 0 && localList.length === 1}
                idx={idx}
                obj={obj}
                setRequestedForDelete={setRequestedForDelete}
                setLocalList={setLocalList}
                localList={localList}
                triggerValidate={isRequestedForAdd || requestedForNextStep}
                editable={props.editable}
                key={idx}/>
            </MarginWrapper>
          )
        })
      }
      </div>
      {(props.editable && type !== 'notModal') &&
        <MarginWrapper top={'5px'} bottom={'10px'}>
          <ColoredText
            singleText={'Add +'}
            color={localList.length >= 10 ? '#BDBDBD' : COLORS.MAIN_COLOR}
            cursor={localList.length >= 10 ? 'not-allowed' : 'pointer'}
            onClick={() => {
              if (localList.length < 10) {
                dispatch(requestForAdd())
              }
            }
            }/>
        </MarginWrapper>
      }

    </div>
  )
}

export default EducationList
