import React, { type CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface BackgroundProps {
  style?: CSSProperties
  svg: any
  overflow?: 'hidden' | 'visible'
  rate?: number
}

function Background (props: BackgroundProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const screenHeight = (
    props.rate ? (props.rate * window.screen.availHeight) + 'px' : '100%'
  )
  return (
    <div>
      {!isMobile && (
        <div
          style={{
            zIndex: '-1',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            overflow: props.overflow ?? 'visible',
            overflowX: 'auto',
            overflowY: 'hidden',
            height: screenHeight,
            userSelect: 'none',
            top: '0',
            left: '0',
            ...props.style
          }}>
          <img
            src={props.svg}
            alt={'svg'}
            draggable={false}/>
        </div>
      )}
    </div>

  )
}

export default Background
