export const enum HeightModeValues {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

export const HEIGHT_MODES: Record<HeightModeValues, any> = {
  [HeightModeValues.SMALL]: {
    browser: '32px',
    mobile: '32px',
    scalar: '32px'
  },
  [HeightModeValues.DEFAULT]: {
    browser: '48px',
    mobile: '38px',
    scalar: '38px'
  },
  [HeightModeValues.MEDIUM]: {
    browser: '62px',
    mobile: '38px',
    scalar: '62px'
  },
  [HeightModeValues.LARGE]: {
    browser: '80px',
    mobile: '60px',
    scalar: '80px'
  },
  [HeightModeValues.EXTRA_LARGE]: {
    browser: '300px',
    mobile: '240px'
  }
}
