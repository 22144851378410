import React, { useEffect, useState } from 'react'
import ColoredText from '../text/ColoredText'
import { COLORS, STATIC_TEXTS } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import StandardInput from '../input/StandardInput'
import { StandardInputModeValues } from '../modes/StandardInputMode'
import { InputModeValues } from '../modes/InputModes'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import MarginWrapper from '../util/MarginWrapper'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import toast from 'react-hot-toast'
import {
  areListsWithObjectsEquivalent,
  checkDatesEqualityFromIso,
  enumTypesToEnumValues, enumTypeToEnum,
  findObjectFromKey,
  findObjectFromKeyWithoutUndefinedValue,
  formatDateToCalendar
} from '../../util/Helper'
import EnumValueDropDown from '../dropdown/EnumValueDropDown'
import EnumValueMultipleAutocomplete from '../multipleAutocomplete/EnumValueMultipleAutocomplete'
import InformationBanner from './InformationBanner'
import {
  type EnumTypeDto,
  PersonalInformationRequestDtoGenderEnum as Gender,
  PersonalInformationRequestDtoPreferredLanguagesEnum as Languages,
  ICUserControllerApi,
  type PersonalInformationResponseDto
} from '../../api/ic'
import ApiManager from '../../manager/ApiManager'

interface TakerPersonalInfoProps {
  ICUserInfo?: PersonalInformationResponseDto
  editable?: boolean
  genderValues?: EnumTypeDto[]
  languageValues?: EnumTypeDto[]
  setName?: React.Dispatch<React.SetStateAction<string>>
  setSurname?: React.Dispatch<React.SetStateAction<string>>
}

function PersonalInfoSection (props: TakerPersonalInfoProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [country, setCountry] = useState(props.ICUserInfo?.country ?? '')
  const [name, setName] = useState(props.ICUserInfo?.firstname ?? '')
  const [surname, setSurname] = useState(props.ICUserInfo?.lastname ?? '')
  const [languages, setLanguages] = useState<EnumTypeDto[]>(
    props.ICUserInfo?.preferredLanguages.length
      ? props.ICUserInfo?.preferredLanguages.map(
        lang => findObjectFromKeyWithoutUndefinedValue(
          props.languageValues ?? [],
          { key: lang.valueOf() }
        ))
      : []
  )
  useEffect(() => {
  }, [languages])
  const [initialLanguages, setInitialLanguages] = useState(
    props.ICUserInfo?.preferredLanguages?.length
      ? props.ICUserInfo?.preferredLanguages.map(
        lang => findObjectFromKeyWithoutUndefinedValue(
          props.languageValues ?? [],
          { key: lang.valueOf() }
        ))
      : [])
  const [date, setDate] = useState<Date | undefined>(props.ICUserInfo?.dateOfBirth)
  const [gender, setGender] = useState<EnumTypeDto | undefined>(findObjectFromKey(props.genderValues ?? [], { key: props.ICUserInfo?.gender?.valueOf() }))
  const [about, setAbout] = useState(props.ICUserInfo?.aboutMe ?? '')
  const editable = props.editable ?? true
  const [initialName, setInitialName] = useState(props.ICUserInfo?.firstname ?? '')
  const [initialSurname, setInitialSurname] = useState(props.ICUserInfo?.lastname ?? '')
  const [initialCountry, setInitialCountry] = useState(props.ICUserInfo?.country ?? '')
  const [initialDate, setInitialDate] = useState(props.ICUserInfo?.dateOfBirth)
  const [initialGender, setInitialGender] = useState(props.ICUserInfo?.gender ? props.ICUserInfo?.gender : undefined)
  const [initialAbout, setInitialAbout] = useState(props.ICUserInfo?.aboutMe ?? '')

  const userApi = ApiManager.getInstance(ICUserControllerApi)

  const canSave = (country !== initialCountry ||
      !checkDatesEqualityFromIso(date?.toUTCString(), initialDate?.toUTCString()) ||
      gender?.key !== initialGender ||
      about !== initialAbout ||
      !areListsWithObjectsEquivalent(languages, initialLanguages) ||
      name !== initialName ||
      surname !== initialSurname) &&
    languages.length !== 0
  // TODO: fix any
  const SavePersonalInfoChanges = async (requestBody: any) => {
    if (!requestBody?.preferredLanguages?.length) {
      toast.error('You can\'t deselect all languages')
      return
    }

    void userApi.setPersonalInformation({
      personalInformationRequestDto: requestBody
    }).then(res => {
      if (props.setSurname && props.setName) {
        props.setName(res.firstname)
        props.setSurname(res.lastname)
        setInitialName(res.firstname)
        setInitialSurname(res.lastname)
        setInitialAbout(res.aboutMe ?? '')
        setInitialGender(res.gender ?? undefined)
        setInitialCountry(res.country ?? '')
        setInitialDate(res.dateOfBirth ?? undefined)
        // TODO check if thi can be optimized
        setInitialLanguages(res.preferredLanguages.map(
          lang => findObjectFromKeyWithoutUndefinedValue(
            props.languageValues ?? [],
            { key: lang.valueOf() }
          )))
      }
      toast.success('Personal Information Updated Successfully')
    }).catch(() => {
    })
  }

  return (
    <div>
      {(props.ICUserInfo) && <div
        style={{
          border: '2px solid #D9D9D9',
          borderRadius: '10px',
          padding: !isMobile ? '25px 50px 25px 50px' : '25px'
        }}>
        {/* TODO being rendered, than removed */}
        {!props.editable && <InformationBanner singleText={STATIC_TEXTS.READ_ONLY_SECTION_INFO}/>}
        <MarginWrapper bottom='30px'>
          <ColoredText
            singleText='Personal Information'
            color={COLORS.MAIN_COLOR}
            centered={false}
            fontSize={FontModeValues.EXTRA_LARGE}/>
        </MarginWrapper>
        <div
          className={'d-flex flex-row justify-content-between gap-5 flex-wrap'}>
          <div className={'d-flex flex-column'}
            style={{
              gap: '10px',
              marginBottom: '20px'
            }}>
            <StandardInput
              setInputValue={setName}
              inputValue={name}
              placeHolder={'Name'}
              disabled={!editable}
              label={'Name'}
              sizeMode={InputModeValues.TINY_BIG}/>
            <StandardInput
              setInputValue={setSurname}
              inputValue={surname}
              disabled={!editable}
              placeHolder={'Surname'}
              label={'Surname'}
              sizeMode={InputModeValues.TINY_BIG}/>
            <StandardInput
              type={StandardInputModeValues.DATE}
              label='Date Of Birth'
              disabled={!editable}
              showDateWhenDisabled={!editable}
              placeHolder=''
              inputValue={formatDateToCalendar(date) ?? ''}
              sizeMode={InputModeValues.TINY_BIG}
              setInputValue={(date: Date | undefined | string) => {
                if (typeof date === 'string' || date === undefined) {
                  setDate(undefined)
                  return
                }
                setDate(date)
              }}/>
            <EnumValueDropDown
              items={props.genderValues ?? []}
              selectedValue={gender}
              setSelectedValue={setGender}
              disabled={!editable}
              showOptionWhenDisabled={!editable}
              label={'Gender'}
              sizeMode={InputModeValues.TINY_BIG}/>
          </div>
          <div className={'d-flex flex-column'}
            style={{
              gap: '10px',
              marginBottom: '20px',
              flexGrow: 1
            }}>
            <StandardInput
              sizeMode={InputModeValues.MAX_WIDTH_2}
              label='Country'
              inputValue={country}
              setInputValue={setCountry}
              disabled={!editable}
              placeHolder='Armenia'/>
            <EnumValueMultipleAutocomplete
              isRequired={true}
              error={!languages.length}
              label={'Languages'}
              selectedValues={languages}
              setSelectedValue={setLanguages}
              width={'100%'}
              disabled={!editable}
              items={props.languageValues ?? []}/>
            <MarginWrapper top={'10px'}>
              <StandardInput
                label='About me'
                type={StandardInputModeValues.TEXT_AREA}
                inputValue={about}
                setInputValue={setAbout}
                disabled={!editable}
                placeHolder=''
                sizeMode={InputModeValues.TAKER_TEXT_AREA}/>
            </MarginWrapper>
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px'
              }}>
              {editable && <StandardButton
                onClick={async () => {
                  const requestGender = enumTypeToEnum(gender, Gender)
                  const requestedLanguages = enumTypesToEnumValues(languages, Languages)
                  if (!requestGender || !requestedLanguages) {
                    toast.error('You should set your gender')
                  } else {
                    const requestBody = {
                      firstname: name,
                      lastname: surname,
                      country,
                      preferredLanguages: requestedLanguages,
                      dateOfBirth: date,
                      gender: requestGender ?? undefined,
                      aboutMe: about
                    }
                    await SavePersonalInfoChanges({ ...requestBody })
                  }
                }}
                clickable={canSave}
                text='Save'
                sizeMode={ButtonModeValues.SMALL}/>}
            </span>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default PersonalInfoSection
