import React, { useState } from 'react'
import { API_CLIENT_DATA } from '../../util/Consts'
import { type GetInterviewDto } from '../../api/ic'
import { Skeleton } from '@mui/material'

interface InterviewTypeImgProps {
  obj: Partial<GetInterviewDto>
  mini?: boolean
  mode?: 'small'
}

function InterviewTypeImg (props: InterviewTypeImgProps) {
  const [imageLoaded, setImageLoaded] = useState(false)

  const idPart = props.obj.id ? `id=${props.obj.id}` : ''
  const typePart = props.obj.type ? `type=${props.obj.type}` : ''

  const handleImageLoad = () => {
    setImageLoaded(true)
  }
  return (
    <div style={
      props.mode !== 'small'
        ? {
            paddingLeft: '10px',
            paddingRight: '10px'
          }
        : {
            paddingTop: '10px',
            paddingBottom: '10px'
          }
    }>
      {(!imageLoaded) && (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height={props.mini ? 30 : 60}
          width={props.mini ? 30 : 60}
          sx={{ borderRadius: '10px' }}/>
      )}
      <img
        style={{ borderRadius: '5px' }}
        src={`${API_CLIENT_DATA.HOST}/interviewType/image?${idPart}&${typePart}`}
        alt={`${props.obj.name}`}
        width={props.mini ? '30px' : '60px'}
        height={props.mini ? '30px' : '60px'}
        draggable={false}
        hidden={!imageLoaded}
        onLoad={handleImageLoad}/>
    </div>
  )
}
export default InterviewTypeImg
