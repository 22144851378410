/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Urls
 * @export
 * @interface ICURLDto
 */
export interface ICURLDto {
    /**
     * 
     * @type {string}
     * @memberof ICURLDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ICURLDto
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ICURLDto
     */
    value?: string;
}

/**
 * Check if a given object implements the ICURLDto interface.
 */
export function instanceOfICURLDto(value: object): value is ICURLDto {
    return true;
}

export function ICURLDtoFromJSON(json: any): ICURLDto {
    return ICURLDtoFromJSONTyped(json, false);
}

export function ICURLDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ICURLDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ICURLDtoToJSON(value?: ICURLDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'key': value['key'],
        'value': value['value'],
    };
}

