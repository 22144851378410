import React from 'react'
import Logo from '../svg/Logo'
import { LogoModeValues } from '../modes/LogoModes'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import SVGHandler from '../svg/SVGHandler'
import message from '../svg/resources/planemessage.svg'
import instagram from '../svg/resources/InstagramWithBorder.svg'
import whatsapp from '../svg/resources/WhatsUpWithBorder.svg'
import linkedin from '../svg/resources/LinkedInWithBorder.svg'
import MarginWrapper from '../util/MarginWrapper'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

function ContactUsLeftPart () {
  const innerWidth = useSelector(GetDeviceTypeInformation).innerWidth
  const innerHeight = useSelector(GetDeviceTypeInformation).innerHeight
  return (
    <div
      style = {{
        background: 'linear-gradient(180deg, #880CE8 0%, #BE6EFB 100%)',
        width: '35%',
        minHeight: '100vh',
        padding: '40px',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        position: 'relative'
      }}>
      <Logo type= {LogoModeValues.DEFAULT_WHITE}/>
      <SVGHandler
        outer_style={{ marginTop: '120px' }}
        width = {innerWidth * 0.25 + 'px'}
        svg = {message}/>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}>
        {/* maybe we need to change something here */}
        <MarginWrapper top={`calc(${innerHeight}px - 440px)`}>
          <ColoredText
            color='white'
            texts={COLORED_TEXT_VALUES.CHAT_TO_US}/>
        </MarginWrapper>
        <div>
          <ColoredText
            color = 'white'
            texts = {COLORED_TEXT_VALUES.SOCIAL_MEDIA}/>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '7px',
              marginTop: '5px',
              userSelect: 'none'
            }}>
            <span style={{ cursor: 'pointer' }}>
              <img
                src = {instagram}
                alt = 'insta'
                onClick={() => { alert('instagram link') }}
                draggable={false}/>
            </span>
            <span style={{ cursor: 'pointer' }}>
              <img
                src = {whatsapp}
                alt = 'whatsapp'
                onClick={() => { alert('whatsapp link') }}
                draggable={false}/>
            </span>
            <span style={{ cursor: 'pointer' }}>
              <img
                src = {linkedin}
                alt = 'linkedin'
                onClick={() => { alert('linkedin link') }}
                draggable={false}/>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsLeftPart
