/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringWrapperResponseDto
 */
export interface StringWrapperResponseDto {
    /**
     * Value
     * @type {string}
     * @memberof StringWrapperResponseDto
     */
    value: string;
}

/**
 * Check if a given object implements the StringWrapperResponseDto interface.
 */
export function instanceOfStringWrapperResponseDto(value: object): value is StringWrapperResponseDto {
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function StringWrapperResponseDtoFromJSON(json: any): StringWrapperResponseDto {
    return StringWrapperResponseDtoFromJSONTyped(json, false);
}

export function StringWrapperResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringWrapperResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function StringWrapperResponseDtoToJSON(value?: StringWrapperResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

