/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BalanceCardTagCreationDto
 */
export interface BalanceCardTagCreationDto {
    /**
     * Tag text
     * @type {string}
     * @memberof BalanceCardTagCreationDto
     */
    text: string;
    /**
     * Tag type
     * @type {string}
     * @memberof BalanceCardTagCreationDto
     */
    type: BalanceCardTagCreationDtoTypeEnum;
}


/**
 * @export
 */
export const BalanceCardTagCreationDtoTypeEnum = {
    Balance: 'BALANCE',
    BalanceCard: 'BALANCE_CARD'
} as const;
export type BalanceCardTagCreationDtoTypeEnum = typeof BalanceCardTagCreationDtoTypeEnum[keyof typeof BalanceCardTagCreationDtoTypeEnum];


/**
 * Check if a given object implements the BalanceCardTagCreationDto interface.
 */
export function instanceOfBalanceCardTagCreationDto(value: object): value is BalanceCardTagCreationDto {
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function BalanceCardTagCreationDtoFromJSON(json: any): BalanceCardTagCreationDto {
    return BalanceCardTagCreationDtoFromJSONTyped(json, false);
}

export function BalanceCardTagCreationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceCardTagCreationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'],
        'type': json['type'],
    };
}

export function BalanceCardTagCreationDtoToJSON(value?: BalanceCardTagCreationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'type': value['type'],
    };
}

