import React, { type CSSProperties } from 'react'

interface PurpleProps {
  outer_style?: CSSProperties
  inner_style?: CSSProperties
  svg: any
  id?: string | undefined
  position?: any
  currentX?: string
  currentY?: string
  width?: string
  static?: boolean
}

function SVGHandler (props: PurpleProps) {
  function style () {
    if (props.static) {
      return ({ cursor: 'pointer' })
    }
    return (
      {
        position: props.position ?? 'absolute',
        top: props.currentY ? `calc(${screen.availHeight}px - ${props.currentY})` : '0px',
        left: props.currentX ?? '0px'
      }
    )
  }
  return (
    <div
      style={{ ...props.outer_style, ...style() }}>
      <div
        style={{ userSelect: 'none', ...props.inner_style }}>
        <img
          id={props?.id ? props.id : ''}
          src={props.svg}
          width = {props.width ?? 'auto'}
          alt={'svg'}
          draggable={false}/>
      </div>
    </div>
  )
}

export default SVGHandler
