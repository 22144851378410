import { ReduxActionType } from '../enumeration/ReduxActionType'

export const GetTimerValue = (state: any) => {
  return state.timerValue
}

export const timerValueReducer = (state = 62, action: any) => {
  switch (action.type) {
    case ReduxActionType.GET_TIMER_VALUE:
      return action.payload
    default:
      return state
  }
}

export const updateTimerValue = (value: any = 62) => {
  return {
    type: ReduxActionType.GET_TIMER_VALUE,
    payload: value
  }
}
