/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginSessionDto
 */
export interface LoginSessionDto {
    /**
     * Session UUID
     * @type {string}
     * @memberof LoginSessionDto
     */
    uuid: string;
    /**
     * User ID
     * @type {string}
     * @memberof LoginSessionDto
     */
    userId: string;
    /**
     * Session expiration date
     * @type {Date}
     * @memberof LoginSessionDto
     */
    expirationDate: Date;
    /**
     * Browser
     * @type {string}
     * @memberof LoginSessionDto
     */
    browser: string;
    /**
     * Operating system
     * @type {string}
     * @memberof LoginSessionDto
     */
    operatingSystem: string;
}

/**
 * Check if a given object implements the LoginSessionDto interface.
 */
export function instanceOfLoginSessionDto(value: object): value is LoginSessionDto {
    if (!('uuid' in value) || value['uuid'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('expirationDate' in value) || value['expirationDate'] === undefined) return false;
    if (!('browser' in value) || value['browser'] === undefined) return false;
    if (!('operatingSystem' in value) || value['operatingSystem'] === undefined) return false;
    return true;
}

export function LoginSessionDtoFromJSON(json: any): LoginSessionDto {
    return LoginSessionDtoFromJSONTyped(json, false);
}

export function LoginSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginSessionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'userId': json['userId'],
        'expirationDate': (new Date(json['expirationDate'])),
        'browser': json['browser'],
        'operatingSystem': json['operatingSystem'],
    };
}

export function LoginSessionDtoToJSON(value?: LoginSessionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'userId': value['userId'],
        'expirationDate': ((value['expirationDate']).toISOString()),
        'browser': value['browser'],
        'operatingSystem': value['operatingSystem'],
    };
}

