/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewBalanceCardTagDto } from './GetInterviewBalanceCardTagDto';
import {
    GetInterviewBalanceCardTagDtoFromJSON,
    GetInterviewBalanceCardTagDtoFromJSONTyped,
    GetInterviewBalanceCardTagDtoToJSON,
} from './GetInterviewBalanceCardTagDto';

/**
 * User balances
 * @export
 * @interface BalanceDto
 */
export interface BalanceDto {
    /**
     * 
     * @type {string}
     * @memberof BalanceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceDto
     */
    balanceType?: BalanceDtoBalanceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BalanceDto
     */
    frozenBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceDto
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceDto
     */
    availableBalance?: number;
    /**
     * 
     * @type {Array<GetInterviewBalanceCardTagDto>}
     * @memberof BalanceDto
     */
    tags?: Array<GetInterviewBalanceCardTagDto>;
}


/**
 * @export
 */
export const BalanceDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type BalanceDtoBalanceTypeEnum = typeof BalanceDtoBalanceTypeEnum[keyof typeof BalanceDtoBalanceTypeEnum];


/**
 * Check if a given object implements the BalanceDto interface.
 */
export function instanceOfBalanceDto(value: object): value is BalanceDto {
    return true;
}

export function BalanceDtoFromJSON(json: any): BalanceDto {
    return BalanceDtoFromJSONTyped(json, false);
}

export function BalanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BalanceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'balanceType': json['balanceType'] == null ? undefined : json['balanceType'],
        'frozenBalance': json['frozenBalance'] == null ? undefined : json['frozenBalance'],
        'count': json['count'] == null ? undefined : json['count'],
        'availableBalance': json['availableBalance'] == null ? undefined : json['availableBalance'],
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(GetInterviewBalanceCardTagDtoFromJSON)),
    };
}

export function BalanceDtoToJSON(value?: BalanceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'balanceType': value['balanceType'],
        'frozenBalance': value['frozenBalance'],
        'count': value['count'],
        'availableBalance': value['availableBalance'],
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(GetInterviewBalanceCardTagDtoToJSON)),
    };
}

