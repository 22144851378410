import React from 'react'
import AboutUs from '../components/AboutUs/AboutUs'

function AboutUsPage () {
  return (
    <AboutUs />
  )
}

export default AboutUsPage
