import React, { useState } from 'react'
import Flipper from '../flipper/Flipper'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import InterviewTypeImg from '../img/InterviewTypeImg'
import toast from 'react-hot-toast'
import { capitalizeFirstLetter, groupDatesFromIsoStrings } from '../../util/Helper'
import TakerInterviewCalendar from '../calendar/TakerInterviewCalendar'
import TakerInterviewTimeRangeSelector from '../../timeRangeSelector/TakerInterviewTimeRangeSelector'
import ApiManager from '../../manager/ApiManager'
import { InterviewControllerApi, type TakerAvailableInterviewDatesDto } from '../../api/ic'

interface CardProps {
  obj: TakerAvailableInterviewDatesDto
  setUpdateTimes?: (val: boolean) => void
}

function TakerInterviewCard (props: CardProps) {
  const [date, setDate] = useState<Date>(new Date())
  const [availableDates, setAvailablDates] = useState<Date[]>([])
  const [flipped, setIsFlipped] = useState(false)
  const [singleSelectedTime, setSingleSelectedTime] = useState<Date | null>(null)
  const timesByAvailableDates = groupDatesFromIsoStrings([...props.obj.dates])

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)

  return (
    <div style = {{
      width: '400px',
      background: 'white',
      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
      padding: '10px 20px 10px 20px',
      borderRadius: '10px'
    }}>
      <div style = {{
        borderBottom: '2px solid #D9D9D9',
        gap: '20px',
        padding: '15px 0px 15px 0px'
      }} className='d-flex flex-row align-items-center'>
        <InterviewTypeImg obj={props.obj}/>
        <ColoredText
          lineHeight={1}
          fontWeight={300}
          singleText= {capitalizeFirstLetter(props.obj.type ? props.obj.type?.toLowerCase() : '')}
          fontSize = {FontModeValues.EXTRA_LARGE}/>
      </div>
      <div className={'d-flex flex-column justify-content-center align-items-center'}
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Flipper
          width={'400px'}
          height={'360px'}
          flipped={flipped}
          frontComponent={
            <TakerInterviewCalendar
              setDate={setDate}
              timesByAvailableDates={timesByAvailableDates}
              setAvailableDates={setAvailablDates}
              setIsFlipped={setIsFlipped}
              singleSelectedTime={singleSelectedTime}
            />}
          backComponent={
            <TakerInterviewTimeRangeSelector
              setSingleSelectedTime={setSingleSelectedTime}
              singleSelectedTime={singleSelectedTime}
              setIsFlipped={setIsFlipped}
              backOnClick={() => {}}
              date={date}
              interviewDuration={60}
              availableDates={availableDates}/>
          }/>
        {/* <Flipper */}
        {/*  width={'400px'} */}
        {/*  height={'360px'} */}
        {/*  flipped={flipped} */}
        {/*  frontComponent={ */}
        {/*    <CustomCalendar */}
        {/*      setIsFlipped={setIsFlipped} */}
        {/*      setDate={setDate} */}
        {/*      bookedDays={Array.from(props.obj.dates ?? [])} */}
        {/*      setAvailableBlockDates={setAvailableHours} */}
        {/*      singleSelectedTime={singleSelectedTimeBlock} */}
        {/*      type={'taker'}/>} */}
        {/*  backComponent={ */}
        {/*    <TimeRangeSelector */}
        {/*      setSingleSelectedTimeBlock={setSingleSelectedTimeBlock} */}
        {/*      setIsFlipped={setIsFlipped} */}
        {/*      singleChoice={true} */}
        {/*      backOnClick={() => {}} */}
        {/*      date={date} */}
        {/*      initialTime={9} */}
        {/*      type={'giver'} */}
        {/*      interviewDuration={60} */}
        {/*      delayBetweenTimeBlocks={30} */}
        {/*      availableBlocks={availableHours}/> */}
        {/*    }/> */}
      </div>
      <div style = {{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <StandardButton
          clickable={singleSelectedTime !== null}
          onClick={() => {
            if (singleSelectedTime && props.obj.type) {
              void interviewApi.acceptInterview({
                acceptRequestDto: {
                  type: props.obj.type,
                  acceptedDate: singleSelectedTime
                }
              })
                .then(() => {
                  toast.success('See your interviews in upcoming section')
                  setIsFlipped(false)
                  setSingleSelectedTime(null)
                  if (props.setUpdateTimes) {
                    props.setUpdateTimes(true)
                  }
                }).catch(() => {})
            }
          }}
          sizeMode= {ButtonModeValues.EXTRA_SMALL}
          text = 'Select'/>
      </div>
    </div>
  )
}

export default TakerInterviewCard
