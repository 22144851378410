import React, { useEffect, useState } from 'react'
import { BankCardControllerApi, type BankCardCreationDto, type GetBankCardDto } from '../../../api/ic'
import ApiManager from '../../../manager/ApiManager'
import AttachedCardList from '../../../components/payment/AttachedCardList'
import StandardModal from '../../../components/modal/StandardModal'
import CardAttachmentForm from '../../../components/payment/CardAttachmentForm'
import ColoredText from '../../../components/text/ColoredText'
import { FontModeValues } from '../../../components/modes/FontMode'
import StandardButton from '../../../components/buttons/StandardButton'
import { WidthModeValues } from '../../../components/modes/WidthMode'
import toast from 'react-hot-toast'
import MarginWrapper from '../../../components/util/MarginWrapper'

function SettingsPaymentCards () {
  const [cards, setCards] = useState<GetBankCardDto[] | undefined>(undefined)
  const [maxAllowedCardCount, setMaxAllowedCardCount] = useState<number>(3)
  const [addNewCardModal, setAddNewCardModal] = useState<boolean>(false)
  const [newBankCard, setNewBankCard] = useState<BankCardCreationDto | undefined>()
  const [createdCard, setCreadetCard] = useState<GetBankCardDto | undefined>()
  const bankCardApi = ApiManager.getInstance(BankCardControllerApi)

  useEffect(() => {
    void bankCardApi.getAllUserBankCards().then(res => {
      setCards(res.cards ?? [])
      setMaxAllowedCardCount(res.maxAllowedCardsNumber)
    }).catch(() => {})
  }, [])

  const handleSave = () => {
    // TODO make UI to show errors
    if (!newBankCard?.num || !newBankCard?.cardHolder || !newBankCard?.cvv || !newBankCard?.expireDate) {
      toast.error('Fill all required information')
      return
    }
    void bankCardApi.addNewCard({
      bankCardCreationDto: newBankCard
    }).then((res) => {
      toast.success('Successfully added new card')
      setCreadetCard(res)
      setAddNewCardModal(false)
    }).catch(() => {
      toast.error('Could not add new card, please try again')
      setAddNewCardModal(false)
    })
  }

  return (
    <div className={'d-flex flex-column gap-3'}>
      { cards && <AttachedCardList
        attachedBankCards={cards}
        maxAllowedCardsCount={maxAllowedCardCount}
        addedCard={createdCard}
        selectable={false}
        onAddClick={() => { setAddNewCardModal(true) }}
        makeDefaultAction={true}
        deletable={true}/>
      }
      { !cards && <AttachedCardList
        loading={true}
        skeletonCount={2}/>}
      <StandardModal
        isOpen={addNewCardModal}
        closeModal={() => { setAddNewCardModal(false) }}
        title={'New Card'}
        footerComponent={
          <div
            className={'d-flex flex-row justify-content-between align-items-center'}
            style={{ width: '100%' }}>
            <ColoredText
              cursor={'pointer'}
              fontSize={FontModeValues.DEFAULT}
              singleText={'Cancel'}
              onClick={() => { setAddNewCardModal(false) }}
              userSelect={'none'}/>
            <StandardButton
              widthMode={WidthModeValues.DOUBLE_EXTRA_SMALL}
              onClick={handleSave}
              text={'Save'}/>
          </div>
        }
      >
        {addNewCardModal &&
          <MarginWrapper top={'20px'}>
            <CardAttachmentForm
              setBankCard={setNewBankCard}
              needRememberMe={false}/>
          </MarginWrapper>}
      </StandardModal>
    </div>
  )
}

export default SettingsPaymentCards
