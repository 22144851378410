import { ReduxActionType } from '../enumeration/ReduxActionType'
export interface InterviewProcessDTO {
  interviewFinished: boolean
  selectedCamera: string | null
  selectedMicrophone: string | null
  selectedAudio: string | null
  enabledMicrophone: boolean
  enabledVideo: boolean
}
export const GetInterviewProcessInformation = (state: any) => {
  return state.interviewProcess
}

export const interviewProcessReducer = (state: InterviewProcessDTO = { interviewFinished: false, selectedCamera: null, selectedMicrophone: null, selectedAudio: null, enabledMicrophone: true, enabledVideo: true }, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_INTERVIEW_FINISHED_STATE:
      return { ...state, interviewFinished: action.payload }
    case ReduxActionType.UPDATE_INTERVIEW_PROCESS_DEVICES:
      return {
        ...state,
        selectedMicrophone: action.microphoneDeviceId,
        selectedAudio: action.speakerDeviceId,
        selectedCamera: action.cameraDeviceId,
        enabledVideo: action.enabledVideo,
        enabledMicrophone: action.enabledMicrophone
      }
    default:
      return state
  }
}

export const updateInterviewFinishedProcess = (isFinished: boolean) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_FINISHED_STATE,
    payload: isFinished
  }
}

export const updateInterviewProcessDevices = (
  cameraDeviceId: string | null,
  microphoneDeviceId: string | null,
  speakerDeviceId: string | null,
  enabledVideo: boolean,
  enabledMicrophone: boolean) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_PROCESS_DEVICES,
    cameraDeviceId,
    microphoneDeviceId,
    speakerDeviceId,
    enabledVideo,
    enabledMicrophone
  }
}
