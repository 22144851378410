import React from 'react'
import ContactUs from '../components/ContactUsPage/ContactUs'

function ContactUsPage () {
  return (
    <>
      <ContactUs />
    </>
  )
}

export default ContactUsPage
