import React, { useEffect, useState } from 'react'
import HomepageCard from './HomepageCard'
import { cardType } from '../../enumeration/cardType'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface DualProps {
  takeMessages: string[]
  giveMessages: string[]
  id?: string
}

function DualCards (props: DualProps) {
  const [height, setHeight] = useState('fit-content')
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  useEffect(() => {
    setHeight(Math.max(props.takeMessages.length, props.giveMessages.length) * 30 + 'px')
  }, [props.giveMessages, props.takeMessages])
  return (
    <>
      <div
        className={'container-fluid'}
        id = {props.id}
        style = {
        {
          paddingTop: '80px',
          position: 'relative',
          top: isMobile ? '0px' : '370px',
          justifyContent: 'space-evenly',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '50px',
          flexWrap: 'wrap',
          paddingBottom: '30px',
          width: '100vw'
        }
      }>
        <HomepageCard
          messages={props.giveMessages}
          height = {height}
          cardType={cardType.GIVE}
        />
        <HomepageCard
          messages={props.takeMessages}
          height = {height}
          cardType={cardType.TAKE}
        />
      </div>
    </>
  )
}

export default DualCards
