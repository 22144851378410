import React, { useEffect } from 'react'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import { useDispatch, useSelector } from 'react-redux'
import {
  canGoBackForTakerValidation,
  canGoForTakerValidation,
  clearPageValue,
  getTakerValidationCurrentPage,
  goNextPage,
  goPreviousPage,
  requestForBack,
  requestNextStepForTakerValidation
} from '../../features/TakerValidationNextStepSlice'
import { TakerValidationRoute } from '../../enumeration/TakerValidationRoute'

interface FooterProps {
  onClose: () => void
  handleComplete: () => void
}

function VerifyFooter (props: FooterProps) {
  const canGo = useSelector(canGoForTakerValidation)
  const canGoBack = useSelector(canGoBackForTakerValidation)
  const dispatch = useDispatch()
  const currentPage = useSelector(getTakerValidationCurrentPage)

  useEffect(() => {
    if (canGoBack) {
      dispatch(goPreviousPage())
    }
  }, [canGoBack])

  useEffect(() => {
    if (canGo) {
      if (currentPage === TakerValidationRoute.MEETING) {
        props.handleComplete()
        dispatch(clearPageValue())
        return
      }
      dispatch(goNextPage())
    }
  }, [canGo])

  return (
    <>
      <div
        style = {{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 1200,
          outline: 'none'
        }}>
        {currentPage === TakerValidationRoute.PERSONAL_INFO
          ? <div
              style = {{
                cursor: 'pointer',
                fontFamily: 'Inter, sans-serif',
                fontSize: '18px',
                color: 'black',
                userSelect: 'none'
              }}
              onClick={props.onClose}>
            Cancel
          </div>
          : <div className={'d-flex flex-row align-items-center gap-4'}>
            <div
              style = {{
                cursor: 'pointer',
                fontFamily: 'Inter, sans-serif',
                fontSize: '18px',
                color: 'black',
                userSelect: 'none'
              }}
              onClick={props.onClose}>
              Cancel
            </div>
            <StandardButton
              onClick={() => { dispatch(requestForBack()) }}
              text={'Back'}
              sizeMode= {ButtonModeValues.SMALL}/>
          </div>
          }
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '5px'
          }}>
          <StandardButton
            onClick={ () => { dispatch(requestNextStepForTakerValidation()) }}
            text={currentPage !== TakerValidationRoute.MEETING ? 'Next' : 'Complete'}
            sizeMode= {ButtonModeValues.SMALL}/>
        </div>
      </div>
    </>
  )
}

export default VerifyFooter
