import React from 'react'
import infoIcon from '../svg/resources/info.svg'
import ColoredText from '../text/ColoredText'

interface InformationBannerProps {
  singleText?: string
}

function InformationBanner (props: InformationBannerProps) {
  return (
    <div style={{
      backgroundColor: 'rgb(245, 245, 245)',
      borderRadius: '10px',
      height: '60px',
      width: '100%',
      marginBottom: '30px',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
    }}>
      <img
        style={{ alignItems: 'start', marginTop: '2px' }}
        src={infoIcon}
        height={'24px'}
        width={'24px'}
        alt={'Info'}/>
      <ColoredText singleText={props.singleText ?? 'Information banner'}/>
    </div>
  )
}

export default InformationBanner
