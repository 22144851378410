import { ReduxActionType } from '../enumeration/ReduxActionType'

export const GetInterviewJWT = (state: any) => {
  return state.interviewJWT.jwt
}

export const GetConnectionId = (state: any) => {
  return state.interviewJWT.connectionId
}

export const GetInterviewToken = (state: any) => {
  return state.interviewJWT.token
}

export const GetInterviewAppId = (state: any) => {
  return state.interviewJWT.appId
}

export const GetInterviewSession = (state: any) => {
  return state.interviewJWT.session
}

export const interviewJWTReducer = (state = { jwt: null, token: null, session: null, appId: null, connectionId: null }, action: any) => {
  switch (action.type) {
    case ReduxActionType.UPDATE_INTERVIEW_JWT:
      return { ...state, jwt: action.payload }
    case ReduxActionType.UPDATE_CONNECTION_ID:
      return { ...state, connectionId: action.payload }
    case ReduxActionType.UPDATE_INTERVIEW_SESSION:
      return { ...state, session: action.payload }
    case ReduxActionType.UPDATE_INTERVIEW_TOKEN:
      return { ...state, token: action.payload }
    case ReduxActionType.UPDATE_INTERVIEW_APP_ID:
      return { ...state, appId: action.payload }
    case ReduxActionType.DELETE_INTERVIEW_TOKEN_AND_SESSION_AND_ADD_ID:
      return { ...state, token: null, session: null, appId: null }
    default:
      return state
  }
}

export const updateInterviewJWT = (value: string | undefined) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_JWT,
    payload: value
  }
}

export const updateConnectionId = (value: string) => {
  return {
    type: ReduxActionType.UPDATE_CONNECTION_ID,
    payload: value
  }
}

export const updateInterviewSession = (value: string | undefined) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_SESSION,
    payload: value
  }
}

export const updateInterviewToken = (value: string | undefined) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_TOKEN,
    payload: value
  }
}

export const updateInterviewAppId = (value: string | undefined) => {
  return {
    type: ReduxActionType.UPDATE_INTERVIEW_APP_ID,
    payload: value
  }
}

export const deleteSessionAndTokenAndAppId = () => {
  return {
    type: ReduxActionType.DELETE_INTERVIEW_TOKEN_AND_SESSION_AND_ADD_ID
  }
}
