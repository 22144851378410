import React from 'react'
import { LOGO_MODES, type LogoModeValues } from '../modes/LogoModes'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { useNavigate } from 'react-router-dom'

interface LogoProps {
  type?: LogoModeValues
  clickable?: boolean
}

function Logo (props: LogoProps) {
  const logoConfigs = props.type ? LOGO_MODES[props.type] : LOGO_MODES.DEFAULT
  const navigate = useNavigate()
  const clickable = props.clickable === undefined ? true : props.clickable
  return (
    <div className={'d-flex flex-row gap-2 align-items-center'}
      style={{ userSelect: 'none', cursor: clickable ? 'pointer' : 'default' }}>
      <img
        style={{ height: logoConfigs.width ?? 'auto' }}
        src={logoConfigs.src}
        alt={'logo'}
        draggable={false}
        onClick={() => {
          if (clickable) {
            navigate(NavigationRoute.DEFAULT)
          }
        }}/>
    </div>
  )
}

export default Logo
