import React from 'react'
import { COLORS } from '../../util/Consts'
import { type GetInterviewBalanceCardTagDto } from '../../api/ic'

interface InterviewBalanceCardProps {
  interviewCount: number | string
  discountPrice?: number | string
  index: number
  price?: number | string
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
  selectedIndex: number
  tags?: GetInterviewBalanceCardTagDto[]
}

function InterviewTicketCard (props: InterviewBalanceCardProps) {
  const tags = props.tags ?? []
  return (
    <div
      key={props.index}
      onClick={() => { props.setSelectedIndex(props.index) }}
      className={'d-flex flex-column'}
      style={{
        width: '100%',
        minHeight: '64px',
        backgroundColor: props.index === props.selectedIndex ? COLORS.MAIN_COLOR : 'transparent',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        margin: props.index === 0 ? '0px 0px 10px 0px' : '10px 0px'
      }}>
      <div className={'d-flex flex-row justify-content-between align-items-center'}
        style={{ height: 'inherit', minHeight: '64px' }}>
        <div className={'d-flex align-items-center gap-1 flex-row ps-3'} style={{ height: 'inherit' }}>
          <label style={{ position: 'relative', display: 'inline-block', height: '20px' }}>
            <input
              checked={props.index === props.selectedIndex}
              onChange={() => { props.setSelectedIndex(props.index) }}
              style={{
                appearance: 'none',
                borderRadius: '50%',
                border: `1px solid ${COLORS.MAIN_COLOR}`,
                backgroundColor: props.index === props.selectedIndex ? 'white' : 'transparent',
                height: '20px',
                width: '20px',
                paddingLeft: '10px'
              }}
              type="checkbox"
            />
            {props.index === props.selectedIndex && (
              <span
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontWeight: 'bold',
                  height: 'inherit',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '14px',
                  color: COLORS.MAIN_COLOR
                }}
              >
                ✓
              </span>
            )}
          </label>
          <span className={'d-flex flex-column justify-content-evenly ps-2'}>
            <span className={'d-flex flex-row gap-1 align-items-center'} style={{ height: 'inherit' }}>
              <span style={{ fontSize: '20px', color: props.index === props.selectedIndex ? 'white' : COLORS.MAIN_COLOR_DARK, fontWeight: 'bold' }}>{props.interviewCount}</span>
              {' '}
              <span style={{ fontSize: '16px', color: props.index === props.selectedIndex ? 'white' : COLORS.MAIN_COLOR }}>Interviews</span>
            </span>
            <span
              className={'d-flex flex-row gap-1 text-nowrap flex-wrap'}
              style={{ maxWidth: '100%' }}>
              <>
                {tags.map(
                  (tag: GetInterviewBalanceCardTagDto, index: number) => {
                    return (
                      <span
                        key={index}
                        style={{
                          fontSize: '10px',
                          backgroundColor: props.index === props.selectedIndex ? 'white' : '#880CE873',
                          borderRadius: '5px',
                          padding: '0px 5px',
                          fontWeight: 'bold',
                          color: props.index === props.selectedIndex ? '#880CE873' : 'white'
                        }}>
                        {tag.text.length > 20 ? `${tag.text.slice(0, 20)}...` : tag.text}
                      </span>
                    )
                  }
                )}
              </>
            </span>
          </span>
        </div>
        {props.discountPrice && props.price && props.discountPrice !== 0.0 ? (
          <span className={'d-flex flex-row gap-2 align-items-center pe-3'}>
            <span style={{
              color: props.index === props.selectedIndex ? 'white' : COLORS.MAIN_COLOR_DARK,
              fontWeight: 'bold',
              fontFamily: 'Inter, sans-serif'
            }}>
              {props.discountPrice === 'FREE' &&
                props.discountPrice
                      }
              {props.discountPrice !== 'FREE' &&
              <>
                {' '}
                $
                {props.discountPrice}
                {' '}
              </>
                      }
            </span>
            <del
              style={{
                color: props.index === props.selectedIndex ? 'white' : COLORS.MAIN_COLOR_DARK,
                fontWeight: 'bold'
              }}>
              $
              {props.price}
            </del>
          </span>
        ) : <>
          {props.price && <span className={'pe-3'}
            style={{
              color: props.index === props.selectedIndex ? 'white' : COLORS.MAIN_COLOR_DARK,
              fontWeight: 'bold'
            }}>
            $
            {props.price}
          </span>}
        </>}
      </div>
    </div>
  )
}

export default InterviewTicketCard
