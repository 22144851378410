import React, { useCallback, useEffect, useState } from 'react'
import StandardButton from '../../buttons/StandardButton'
import { ButtonModeValues } from '../../modes/ButtonMode'
import { FontModeValues } from '../../modes/FontMode'
import InterviewQuestionsTemplate from './InterviewQuestionsTemplate'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../../features/DeviceTypeInformationSlice'
import { useNavigate } from 'react-router-dom'
import VonageMeeting from '../VonageMeeting'
import ProfilePicture from '../../profilePicture/ProfilePicture'
import { JWTManager } from '../../../manager/JWTManager'
import { AuthManager } from '../../../manager/AuthManager'
import { GetICUserDtoRoleEnum, type GetInterviewQuestionTemplateDto, InterviewQuestionControllerApi } from '../../../api/ic'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import Navigation from '../../navigation/Navigation'
import { NavigationModeValues } from '../../modes/NavigationMode'
import { Popover } from '@mui/material'
import { type Session, type Device } from '@opentok/client'
import SmallDropdown from '../../dropdown/SmallDropdown'
import {
  GetInterviewProcessInformation,
  type InterviewProcessDTO
} from '../../../features/InterviewProcessSlice'
import VideoMicComponent from './VideoMicComponent'
import interview_mic from '../../svg/resources/interview_mic.svg'
import interview_cam from '../../svg/resources/interview_cam.svg'
import interview_speaker from '../../svg/resources/interview_speaker.svg'
import ApiManager from '../../../manager/ApiManager'
import toast from 'react-hot-toast'
import ColoredText from '../../text/ColoredText'

interface sideProps {
  role: GetICUserDtoRoleEnum
  interviewId: string
}

function GiverOrTakerSide (props: sideProps) {
  const navigate = useNavigate()

  const [videoButton, setVideoButton] = useState(true)
  const [publisher, setPublisher] = useState<OT.Publisher | null>(null)
  const [subscriber, setSubscriber] = useState<OT.Subscriber | null>(null)
  const [jwt, setJWT] = useState<any>(null)
  const decriptedJWT = JWTManager.extractPayload(jwt)
  const body = AuthManager.getAccessTokenBody() ?? {}
  const interviewProcess: InterviewProcessDTO = useSelector(GetInterviewProcessInformation)
  if (interviewProcess.interviewFinished) {
    navigate(`/interview/${decriptedJWT?.interviewId}/join`, { replace: true })
  }
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const publisherFullName = (body.userId === decriptedJWT?.takerId) ? decriptedJWT?.takerFullname : decriptedJWT?.giverFullname
  const subscriberFullName = (body.userId === decriptedJWT?.takerId) ? decriptedJWT?.giverFullname : decriptedJWT?.takerFullname

  const [audioInputs, setAudioInputs] = useState<Array<Device & { kind: 'audioInput' }>>([])
  const [videoInputs, setVideoInputs] = useState<Array<Device & { kind: 'videoInput' }>>([])
  const [audioOutputs, setAudioOutputs] = useState<MediaDeviceInfo[]>([])
  const [selectedAudioInput, setSelectedAudioInput] = useState('')
  const [selectedVideoInput, setSelectedVideoInput] = useState('')
  const [selectedAudioOutput, setSelectedAudioOutput] = useState('')
  const [initiallySetDevices, setInitiallySetDevices] = useState(false)
  const [subscriberAvailable, setSubscriberAvailable] = useState<boolean>(false)
  const [session, setSession] = useState<Session | null>(null)
  console.log(subscriberAvailable)
  const interviewQuestionApi = ApiManager.getInstance(InterviewQuestionControllerApi)

  const [template, setTemplate] = useState<GetInterviewQuestionTemplateDto | undefined>(undefined)
  const [interviewCompleted, setInterviewCompleted] = useState<boolean>(false)

  const fetchTemplate = useCallback(async () => {
    await interviewQuestionApi.getTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res.dto)
      setInterviewCompleted(res.completed)
    }).catch(() => {
      // TODO make in a way to not toast the error case
    })
  }, [])

  useEffect(() => {
    if (session) {
      session.on('signal:templateGenerated', () => {
        void fetchTemplate()
      })
    }
  }, [session])

  useEffect(() => {
    void fetchTemplate()
  }, [])

  const fireGenerateEvent = () => {
    if (session) {
      session.signal(
        { type: 'templateGenerated' },
        function (error) {
          if (error) {
            // TODO handle error case, maybe block the next button
          }
        }
      )
    }
  }
  useEffect(() => {
    return () => {
      if (publisher) {
        // Disable the camera by unpublishing video
        publisher.publishAudio(false)
      }

      // Toggle camera based on whether video is currently published
      if (publisher) {
        // Disable the camera by unpublishing video
        publisher.publishVideo(false)
        setVideoButton(false)
      }
    }
  }, [])
  function setPublisherVideoInput (publisher: OT.Publisher | null, label: string | number, videoInputDevices: Device[] = []) {
    if (!publisher) {
      console.error('Publisher is not initialized.')
      return
    }
    if (videoInputDevices.length) {
      const foundDevice = videoInputDevices.filter(device => device.label === label)
      if (foundDevice.length) {
        // Re-initialize the publisher with the new audio input device ID
        publisher.setVideoSource(foundDevice[0].deviceId).then(() => {
        }).catch((error) => {
          console.error('Error setting audio input device:', error)
        })
      }
      return
    }
    const foundDevice = videoInputs.filter(device => device.label === label)
    if (foundDevice.length) {
      // Re-initialize the publisher with the new audio input device ID
      publisher.setVideoSource(foundDevice[0].deviceId).then(() => {
      }).catch((error) => {
        console.error('Error setting audio input device:', error)
      })
    }
  }

  function setPublisherAudioInput (publisher: OT.Publisher | null, label: string | number, audioInputDevices: Device[] = []) {
    if (!publisher) {
      console.error('Publisher is not initialized.')
      return
    }
    if (audioInputDevices.length) {
      const foundDevice = audioInputDevices.filter(device => device.label === label)
      if (foundDevice.length) {
        publisher.setAudioSource(foundDevice[0].deviceId).then(() => {
        }).catch((error) => {
          console.error('Error setting audio input device:', error)
        })
      }
      return
    }
    const foundDevice = audioInputs.filter(device => device.label === label)
    if (foundDevice.length) {
      publisher.setAudioSource(foundDevice[0].deviceId).then(() => {
      }).catch((error) => {
        console.error('Error setting audio input device:', error)
      })
    }
  }

  async function setPublisherAudioOutput (publisher: OT.Publisher | null, label: string | number, audioOutputDevices: MediaDeviceInfo[] = []) {
    if (!publisher) {
      console.error('Publisher is not initialized.')
      return
    }
    if (audioOutputDevices.length) {
      const foundDevice = audioOutputDevices.filter(device => device.label === label)
      if (foundDevice.length) {
        await OT.setAudioOutputDevice(foundDevice[0].deviceId)
      }
      return
    }
    const foundDevice = audioOutputs.filter(device => device.label === label)
    if (foundDevice.length) {
      await OT.setAudioOutputDevice(foundDevice[0].deviceId)
    }
  }

  useEffect(() => {
    if (!publisher) {
      return
    }
    const updateDevices = () => {
      OT.getDevices((err, devices) => {
        if (err) {
          console.error(err)
          return
        }
        if (!devices) {
          return
        }

        const audioInputs = devices.filter((device): device is OT.Device & { kind: 'audioInput' } => device.kind === 'audioInput')
        const videoInputs = devices.filter((device): device is OT.Device & { kind: 'videoInput' } => device.kind === 'videoInput')
        let audioOutputLabels: string[] = []
        let videoInputLabels: string[] = []
        let audioInputLabels: string[] = []
        let audioOutputIds: string[] = []
        let videoInputIds: string[] = []
        let audioInputIds: string[] = []
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput')
          setAudioOutputs(audioOutputDevices)
          audioOutputLabels = audioOutputDevices.map(device => device.label)
          if (!initiallySetDevices) {
            audioOutputIds = audioOutputDevices.map(device => device.deviceId)
            if (interviewProcess.selectedAudio && audioOutputIds.includes(interviewProcess.selectedAudio)) {
              const device = audioOutputDevices.filter(device => device.deviceId === interviewProcess.selectedAudio)
              if (device.length) {
                setSelectedAudioOutput(device[0].label)
                void setPublisherAudioOutput(publisher, device[0].label, audioOutputDevices)
              }
            } else if ((!selectedAudioOutput && audioOutputs.length) || (selectedAudioOutput && audioOutputs.length && !audioOutputLabels.includes(selectedAudioOutput))) {
              setSelectedAudioOutput(audioOutputs[0].label)
              void setPublisherAudioOutput(publisher, audioOutputs[0].label, audioOutputDevices)
            }
          } else {
            if ((!selectedAudioOutput && audioOutputDevices.length) || (selectedAudioOutput && audioOutputDevices.length && !audioOutputLabels.includes(selectedAudioOutput))) {
              setSelectedAudioOutput(audioOutputs[0].label)
              void setPublisherAudioOutput(publisher, audioOutputs[0].label, audioOutputDevices)
            }
          }
        }).catch((err) => {
          console.error('Error getting devices:', err)
        })
        videoInputLabels = videoInputs.map(device => device.label)
        audioInputLabels = audioInputs.map(device => device.label)
        setAudioInputs(audioInputs)
        setVideoInputs(videoInputs)

        if (!initiallySetDevices) {
          videoInputIds = videoInputs.map(device => device.deviceId)
          audioInputIds = audioInputs.map(device => device.deviceId)
          if (interviewProcess.selectedMicrophone && audioInputIds.includes(interviewProcess.selectedMicrophone)) {
            const device = audioInputs.filter(device => device.deviceId === interviewProcess.selectedMicrophone)
            if (device.length) {
              setSelectedAudioInput(device[0].label)
              setPublisherAudioInput(publisher, device[0].label, audioInputs)
            }
          } else if ((!selectedAudioInput && audioInputs.length) || (selectedAudioInput && audioInputs.length && !audioInputLabels.includes(selectedAudioInput))) {
            setSelectedAudioInput(audioInputs[0].label)
            setPublisherAudioInput(publisher, audioInputs[0].label, audioInputs)
          }

          if (interviewProcess.selectedCamera && videoInputIds.includes(interviewProcess.selectedCamera)) {
            const device = videoInputs.filter(device => device.deviceId === interviewProcess.selectedCamera)
            if (device.length) {
              setSelectedVideoInput(device[0].label)
              setPublisherVideoInput(publisher, device[0].label, videoInputs)
            }
          } else if ((!selectedVideoInput && videoInputs.length) || (selectedVideoInput && videoInputs.length && !videoInputLabels.includes(selectedVideoInput))) {
            setSelectedVideoInput(videoInputs[0].label)
            setPublisherVideoInput(publisher, videoInputs[0].label, videoInputs)
          }
          if (!initiallySetDevices) {
            if (!interviewProcess.enabledMicrophone) {
              const isAudioPublished = publisher.stream?.hasAudio ?? true

              // Toggle camera based on whether video is currently published
              if (isAudioPublished) {
                // Disable the camera by unpublishing video
                publisher.publishAudio(false)
              }
            }
            if (!interviewProcess.enabledVideo) {
              const isVideoPublished = publisher.stream?.hasVideo ?? videoButton
              // Toggle camera based on whether video is currently published
              if (isVideoPublished) {
                // Disable the camera by unpublishing video
                publisher.publishVideo(false)
                setVideoButton(false)
              }
            }
          }
          setInitiallySetDevices(true)
          return
        }
        if ((!selectedAudioInput && audioInputs.length) || (selectedAudioInput && audioInputs.length && !audioInputLabels.includes(selectedAudioInput))) {
          setSelectedAudioInput(audioInputs[0].label)
          setPublisherAudioInput(publisher, audioInputs[0].label, audioInputs)
        }
        if ((!selectedVideoInput && videoInputs.length) || (selectedVideoInput && videoInputs.length && !videoInputLabels.includes(selectedVideoInput))) {
          setSelectedVideoInput(videoInputs[0].label)
          setPublisherVideoInput(publisher, videoInputs[0].label, videoInputs)
        }
      })
    }

    // Initial device fetch
    updateDevices()

    // Listen for device changes
    navigator.mediaDevices.ondevicechange = updateDevices

    // Cleanup listener on component unmount
    return () => {
      navigator.mediaDevices.ondevicechange = null
    }
  }, [publisher])

  // MICROPHONE
  const [microphoneListAnchor, setMicrophoneListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleMicophoneListClick = (event: any) => {
    setMicrophoneListAnchor(event.currentTarget)
  }
  const handleMicrophoneListClose = () => {
    setMicrophoneListAnchor(null)
  }
  const microphoneListOpen = Boolean(microphoneListAnchor)
  const microphoneId = microphoneListOpen ? 'open-microphone-list' : undefined
  // VIDEO
  const [videoListAnchor, setVideoListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleVideoListClick = (event: any) => {
    setVideoListAnchor(event.currentTarget)
  }
  const handleVideoListClose = () => {
    setVideoListAnchor(null)
  }
  const videoListOpen = Boolean(videoListAnchor)
  const videoId = videoListOpen ? 'open-video-list' : undefined

  const getHeaderTextForInterview = () => {
    return `${new Date(decriptedJWT?.acceptedDate ?? '').toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })} / Interview Center ${decriptedJWT?.name}`
  }

  function handleGenerateTemplate () {
    void interviewQuestionApi.generateTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res)
      fireGenerateEvent()
    }).catch(() => {
      toast.error('Error while generating a template')
    })
  }

  async function toggleCamera () {
    if (publisher) {
      try {
        // Check if video is currently published
        const isVideoPublished = publisher.stream?.hasVideo ?? videoButton

        // Toggle camera based on whether video is currently published
        if (isVideoPublished) {
          // Disable the camera by unpublishing video
          publisher.publishVideo(false)
          setVideoButton(false)
        } else {
          // Enable the camera by publishing video
          publisher.publishVideo(true)
          setVideoButton(true)
        }

        // Update the state of videoButton to reflect the new camera state
      } catch (error) {
        // Handle any errors that occur while toggling the camera
        console.error('Error toggling camera:', error)
      }
    }
  }

  async function toggleMicrophone () {
    if (publisher) {
      try {
        // Check if video is currently published
        const isAudioPublished = publisher.stream?.hasAudio ?? true

        // Toggle camera based on whether video is currently published
        if (isAudioPublished) {
          // Disable the camera by unpublishing video
          publisher.publishAudio(false)
        } else {
          // Enable the camera by publishing video
          publisher.publishAudio(true)
        }

        // Update the state of videoButton to reflect the new camera state
      } catch (error) {
        // Handle any errors that occur while toggling the camera
        console.error('Error toggling camera:', error)
      }
    }
  }

  const popoverStyle = {
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#3C4043',
    borderRadius: '10px'
  }

  return (
    <>
      <span onClick={() => {
        if (publisher) {
          publisher.publishAudio(false)
          publisher.publishVideo(false)
          setVideoButton(false)
        }
      }}>
        <Navigation
          navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
          interviewText={getHeaderTextForInterview()}
          mobileNavItems={[]}
          height={'100px'}
      />
      </span>
      <div
        style={{
          gap: '20px',
          paddingBottom: '10px',
          width: '100%'
        }}
        className='d-flex flex-column justify-content-center'>
        <div className={`d-flex flex-row flex-wrap align-items-${!template ? 'center' : 'end'} justify-content-evenly`}
          style={{
            flexWrap: 'wrap-reverse',
            animationDuration: '1.5s'
          }}>
          <div
            className={'position-relative'}>
            {!interviewCompleted && template
              ? <InterviewQuestionsTemplate
                  subscriber={subscriber}
                  session={session}
                  template={template}
                  interviewCompleted={interviewCompleted}
                  setInterviewCompleted={setInterviewCompleted}
                  type={props.role}/>
              : props.role === GetICUserDtoRoleEnum.Taker && !interviewCompleted
                ? <StandardButton
                    text='Generate Template To Start'
                    style={{
                      background: 'white'
                    }}
                    clickable={subscriber !== null}
                    onClick={handleGenerateTemplate}
                    textColor='black'
                    sizeMode={ButtonModeValues.LARGE}/>
                : null}
            {interviewCompleted && <div>
              <ColoredText
                singleText={'You are done! The recording is saved!'}
                color={'white'}/>
            </div>}
          </div>
          <div
            className='d-flex flex-column justify-content-center'
            style={{
              gap: isMobile ? '30px' : '10px',
              padding: isMobile ? '10px 0px 10px 0px' : '0px'
            }}>
            <div className={'d-flex justify-content-center align-items-center'}
              style={{
                width: isMobile ? '400px' : '450px',
                height: isMobile ? '250px' : '250px',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                position: 'relative'
              }}>
              <span style={{
                position: 'absolute',
                bottom: '8%',
                left: '4%',
                zIndex: 1000,
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px'
              }}>
                {publisherFullName}
              </span>
              <div id={'publisher'} style={{
                position: 'absolute',
                zIndex: '1',
                display: (videoButton) ? 'block' : 'none',
                width: isMobile ? '400px' : '450px',
                height: '90%',
                borderRadius: '10px'
              }}></div>
              {((!videoButton && publisherFullName)) && <div>
                <ProfilePicture name={publisherFullName.split(' ')[0] ?? ''} surname={publisherFullName.split(' ')[1] ?? ''}
                  pictureSrc={'DEFAULT'} width={'100%'}/>
              </div>}
            </div>
            <div
              style={{
                width: subscriber !== null ? isMobile ? '400px' : '450px' : '0px',
                height: subscriber !== null ? isMobile ? '250px' : '250px' : '0px',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                position: 'relative'
              }}
              className={'d-flex justify-content-center align-items-center'}>
              <div id={'subscriber'}
                style={{
                  display: subscriberAvailable ? 'block' : 'none',
                  overflow: 'hidden',
                  position: 'absolute',
                  width: isMobile ? '400px' : '450px',
                  height: '90%',
                  borderRadius: '10px',
                  zIndex: '1'
                }}>
              </div>
              {(subscriberFullName && subscriber !== null) && (
              <>
                <span style={{
                  position: 'absolute',
                  bottom: '8%',
                  left: '4%',
                  zIndex: 1000,
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}>
                  {subscriberFullName}
                </span>
                <ProfilePicture
                  name={subscriberFullName.split(' ')[0] ?? ''}
                  surname={subscriberFullName.split(' ')[1] ?? ''}
                  pictureSrc={'DEFAULT'}
                  width={'100%'}/>
              </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            gap: '10px'
          }}
          className='d-flex flex-row justify-content-center'>
          <div className={'d-flex flex-row align-items-center gap-4'}
            style={{
              width: 'fit-content'
            }}>
            <div className={'d-flex flex-row align-items-start'}>
              <VideoMicComponent
                byDefaultEnabled={interviewProcess.enabledMicrophone}
                mode={'mic'}
                click={toggleMicrophone}
                arrowClick={handleMicophoneListClick}/>
              <div>
                <Popover
                  id={microphoneId}
                  disableRestoreFocus
                  open={microphoneListOpen}
                  anchorEl={microphoneListAnchor}
                  slotProps={{
                    paper: {
                      sx: {
                        ...popoverStyle
                      }
                    }
                  }}
                  onClose={handleMicrophoneListClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}>
                  <div className={'d-flex flex-row gap-2 p-3'}>
                    <SmallDropdown
                      onChange={(val: string | number) => {
                        setPublisherAudioInput(publisher, val)
                      }}
                      leftIcon={interview_mic}
                      leftIconHeight={'25px'}
                      leftIconWidth={'25px'}
                      fontColor='white'
                      logoMode='light'
                      backgroundColor={'#565A5D'}
                      borderRadius={'20px'}
                      items={audioInputs.map(micro => micro.label)}
                      selected={selectedAudioInput} setIsSelected={setSelectedAudioInput}
                      eachItemStyles={{
                        color: 'white'
                      }}/>
                    <SmallDropdown
                      onChange={(val: string | number) => {
                        void setPublisherAudioOutput(publisher, val)
                      }}
                      leftIcon={interview_speaker}
                      leftIconHeight={'25px'}
                      leftIconWidth={'25px'}
                      fontColor='white'
                      logoMode='light'
                      backgroundColor={'#565A5D'}
                      borderRadius={'20px'}
                      items={audioOutputs.map(micro => micro.label)}
                      selected={selectedAudioOutput} setIsSelected={setSelectedAudioOutput}
                      eachItemStyles={{
                        color: 'white'
                      }}/>
                  </div>
                </Popover>
              </div>
            </div>
            <div className={'d-flex flex-row align-items-start'}>
              <VideoMicComponent
                byDefaultEnabled={interviewProcess.enabledVideo}
                mode={'video'}
                click={async () => { await toggleCamera() } }
                arrowClick={handleVideoListClick}/>
              <Popover
                id={videoId}
                disableRestoreFocus
                open={videoListOpen}
                anchorEl={videoListAnchor}
                slotProps={{
                  paper: {
                    sx: {
                      ...popoverStyle
                    }
                  }
                }}
                onClose={handleVideoListClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}>
                <div className={'d-flex flex-row p-3'}>
                  <SmallDropdown onChange={(val: string | number) => {
                    setPublisherVideoInput(publisher, val)
                  }} items={videoInputs.map(video => video.label)}
                    leftIcon = {interview_cam}
                    leftIconHeight={'25px'}
                    leftIconWidth={'25px'}
                    fontColor='white'
                    logoMode='light'
                    backgroundColor={'#565A5D'}
                    borderRadius={'20px'}
                    selected={selectedVideoInput} setIsSelected={setSelectedVideoInput}
                    eachItemStyles={{
                      color: 'white'
                    }}/>
                </div>
              </Popover>
            </div>
            <div>
              <StandardButton
                text='Finish interview'
                style={{
                  background: 'red',
                  whiteSpace: 'nowrap'
                }}
                onClick={() => {
                  // disable video and audio after disconnecting
                  const isAudioPublished = publisher?.stream?.hasAudio ?? true
                  const isVideoPublished = publisher?.stream?.hasVideo ?? videoButton

                  if (isAudioPublished && publisher) {
                    // Disable the camera by unpublishing video
                    publisher.publishAudio(false)
                  }

                  // Toggle camera based on whether video is currently published
                  if (isVideoPublished && publisher) {
                    // Disable the camera by unpublishing video
                    publisher.publishVideo(false)
                    setVideoButton(false)
                  }

                  navigate(NavigationRoute.INTERVIEW_FEEDBACK.replace('/:id/', `/${props.interviewId}/`), { replace: true })
                }}
                textColor='white'
                sizeMode={ButtonModeValues.DEFAULT}
                textFontSize={FontModeValues.SMALL}/>
            </div>
          </div>
        </div>
        <VonageMeeting
          setSession={setSession}
          setSubscriberAvailable={setSubscriberAvailable}
          subscriber={subscriber}
          setPublisher={setPublisher}
          setSubscriber={setSubscriber}
          setJWT={setJWT}/>
      </div>
    </>
  )
}

export default GiverOrTakerSide
