import React, { useEffect, useState } from 'react'
import ColoredText, { type ColoredTextItem } from '../text/ColoredText'
import StandardTooltip from '../util/StandardTooltip'
import infoIcon from '../svg/resources/info.svg'
import { FontModeValues } from '../modes/FontMode'
import { useDispatch } from 'react-redux'
import { capitalizeFirstLetter } from '../../util/Helper'
interface StandardSlideChoiceValues {
  value: string
  isChosen: boolean
}
interface SlideChoiceProps {
  label: string
  coloredChoice?: string
  startColor?: string
  info?: ColoredTextItem[]
  singleChoice: boolean
  values: StandardSlideChoiceValues[]
  setSelection?: React.Dispatch<React.SetStateAction<any>>
  dispatchSelection?: any
  error?: boolean
  onChange?: () => void
  isRequired?: boolean
}
export function StandardSlideChoice (props: SlideChoiceProps) { // TODO when backend appears fix this prop
  const isRequired = props.isRequired ?? false
  const [mainColors, setMainColors] = useState([''])
  const [colors, setColors] = useState(['#FFF'])
  const dispatch = useDispatch()
  useEffect(() => {
    const newColors = []
    const color1 = [136,
      12,
      232]
    const color2 = [45,
      0,
      80]
    const steps = props.values.length
    const r1 = color1[0]
    const g1 = color1[1]
    const b1 = color1[2]
    const r2 = color2[0]
    const g2 = color2[1]
    const b2 = color2[2]
    for (let i = 0; i < steps; i++) {
      const r = Math.round(r1 + (r2 - r1) * i / (steps - 1))
      const g = Math.round(g1 + (g2 - g1) * i / (steps - 1))
      const b = Math.round(b1 + (b2 - b1) * i / (steps - 1))
      newColors.push(`rgb(${r}, ${g}, ${b})`)
    }
    setMainColors(newColors)
  }, [])

  useEffect(() => {
    if (!props.singleChoice) {
      setColors(() => props.values.map((el: any, idx: number) => el.isChosen ? mainColors[idx] : '#FFF'))
    } else {
      let flag = false
      const colorsArray: string[] = []
      props.values.forEach((el: StandardSlideChoiceValues, idx: number) => {
        if (el.isChosen) {
          if (!flag) {
            colorsArray.push(mainColors[idx])
            flag = true
          } else {
            colorsArray.push('#FFF')
            el.isChosen = false
          }
        } else {
          colorsArray.push('#FFF')
        }
      })
      setColors(colorsArray)
    }
  }, [props.values, mainColors])

  function handleClick (el: StandardSlideChoiceValues, idx: number) {
    const singleChoice = props.singleChoice ?? false
    if (singleChoice) {
      if (el.isChosen) {
        el.isChosen = false
        if (props.setSelection) {
          props.setSelection(null)
          if (props.onChange) {
            props.onChange()
          }
        }
        if (props.dispatchSelection) {
          dispatch(props.dispatchSelection(undefined))
        }
        setColors(prev => prev.map(() => {
          return '#FFF'
        }))
      } else {
        setColors(props.values.map((e: StandardSlideChoiceValues, i: number) => {
          if (idx === i) {
            e.isChosen = true
            if (props.setSelection) {
              props.setSelection(e.value)
            }
            if (props.onChange) {
              props.onChange()
            }
            if (props.dispatchSelection) {
              dispatch(props.dispatchSelection(el.value))
            }

            return mainColors[i]
          }
          e.isChosen = false
          return '#FFF'
        }))
      }
    } else {
      if (el.isChosen) {
        setColors(prev => prev.map((e, i) => idx === i ? '#FFF' : e))
        el.isChosen = false
      } else {
        setColors(prev => prev.map((e, i) => idx === i ? mainColors[idx] : e))
        el.isChosen = true
      }
    }
  }

  return (
    <div>
      <div
        className={'d-flex flex-row align-items-center'}
        style = {{ marginBottom: '5px' }}>
        <ColoredText
          fontSize = {FontModeValues.DEFAULT}
          singleText = {props.label}
          fontWeight = {400}/>
        {isRequired && <span style={{ color: 'red', fontSize: '14px', userSelect: 'none' }}>*</span>}
        {
      props.info &&
      <StandardTooltip
        texts={props.info}>
        <img
          src={infoIcon}
          alt={'Info'}
          style={{
            marginLeft: '5px',
            marginBottom: '2px',
            userSelect: 'none',
            cursor: 'pointer'
          }}
        />
      </StandardTooltip>
    }
      </div>
      <div
        style = {{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
        {
        props.values.map((el: any, idx: number) => (
          <div
            key = {idx}
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              width: 'fit-content',
              padding: idx ? '12px 12px 12px 30px' : '12px',
              borderRadius: '10px',
              // borderTop: props.error ? '1px solid red' : 'none',
              // borderBottom: props.error ? '1px solid red' : 'none',
              // borderLeft: props.error ? idx === 0 ? '1px solid red' : 'none' : 'none',
              // borderRight: props.error ? (idx === props.values.length - 1) ? '1px solid red' : 'none' : 'none',
              background: colors[idx],
              zIndex: 10 - idx,
              boxShadow: props.error ? '0px 0px 4px 0px #FF0001' : '1px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              display: 'inline-block',
              marginRight: '-20px'
            }}
            onClick = {() => { handleClick(el, idx) }}
            >
            <ColoredText
              userSelect={'none'}
              fontSize ={FontModeValues.DEFAULT}
              singleText = {capitalizeFirstLetter(el.value.toLowerCase())}
              fontWeight = {500}
              color = {colors[idx] === '#FFF' ? 'black' : 'white' }
              cursor = 'pointer'/>
          </div>
        ))
        }
      </div>
    </div>
  )
}
