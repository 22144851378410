import React, { useState } from 'react'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import toast from 'react-hot-toast'
import ApiManager from '../manager/ApiManager'
import { ImageUploaderControllerApi } from '../api/ic/apis/ImageUploaderControllerApi'
import { type ResponseError } from '../api/ic'
import MarginWrapper from '../components/util/MarginWrapper'

function InterviewQuestionInsertionPage () {
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const imageUploaderApi = ApiManager.getInstance(ImageUploaderControllerApi)

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files ? e?.target?.files[0] : null
    setSelectedImage(file)
  }

  const handleSave = async () => {
    if (selectedImage) {
      await imageUploaderApi.uploadImage({
        uploader: 'question_insertion',
        fileParamName: 'excelFile',
        file: selectedImage
      }).then(res => {
        toast.success(`Inserted ${res.insertedCount}, Updated ${res.updatedCount}, Skipped ${res.skippedCount}`)
      }).catch((e: ResponseError) => {
        toast.error(e.message)
      })
    } else {
      toast.error('Please select excel file to insert')
    }
  }

  return (
    <div className={'d-flex flex-column align-items-center'}>
      <AdminHeader title={'Insert Question'}/>
      <MarginWrapper top={'20px'}>
        <div className={'d-flex flex-row'} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <input type="file" onChange={handleImageChange} />
        </div>
        <StandardButton onClick={handleSave}/>
      </MarginWrapper>
    </div>
  )
}

export default InterviewQuestionInsertionPage
