/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthBodyDto } from './AuthBodyDto';
import {
    AuthBodyDtoFromJSON,
    AuthBodyDtoFromJSONTyped,
    AuthBodyDtoToJSON,
} from './AuthBodyDto';

/**
 * 
 * @export
 * @interface EntityDto
 */
export interface EntityDto {
    /**
     * ID
     * @type {string}
     * @memberof EntityDto
     */
    id: string;
    /**
     * Interview ID
     * @type {string}
     * @memberof EntityDto
     */
    interviewId: string;
    /**
     * Created At
     * @type {string}
     * @memberof EntityDto
     */
    createdAt: string;
    /**
     * Auth body
     * @type {AuthBodyDto}
     * @memberof EntityDto
     */
    authBody: AuthBodyDto;
}

/**
 * Check if a given object implements the EntityDto interface.
 */
export function instanceOfEntityDto(value: object): value is EntityDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('interviewId' in value) || value['interviewId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('authBody' in value) || value['authBody'] === undefined) return false;
    return true;
}

export function EntityDtoFromJSON(json: any): EntityDto {
    return EntityDtoFromJSONTyped(json, false);
}

export function EntityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'interviewId': json['interviewId'],
        'createdAt': json['createdAt'],
        'authBody': AuthBodyDtoFromJSON(json['authBody']),
    };
}

export function EntityDtoToJSON(value?: EntityDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'interviewId': value['interviewId'],
        'createdAt': value['createdAt'],
        'authBody': AuthBodyDtoToJSON(value['authBody']),
    };
}

