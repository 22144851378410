import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  backTakerValidationCurrentStep,
  doneTakerValidationCurrentStep,
  isRequestedForBack,
  isRequestedForTakerValidationNextStep
} from '../../../features/TakerValidationNextStepSlice'
import toast from 'react-hot-toast'
import {
  addTakerValidationInterviewType,
  getTakerInterviewTypes,
  updateInterviewTypeList
} from '../../../features/TakerInfoSlice'
import LanguageVerification from './LanguageVerification'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import trash from '../../svg/resources/trash.png'
import { COLORS } from '../../../util/Consts'
import { scrollTo } from '../utils'
import { type EnumTypeDto, type TakerInterviewTypeDto } from '../../../api/ic'

function Interviews (props: { levelValues: EnumTypeDto[], typeValues: EnumTypeDto[], editable: boolean }) {
  const requestedForNextStep = useSelector(isRequestedForTakerValidationNextStep)
  const requestForBack = useSelector(isRequestedForBack)
  const selectedInterviewTypes: TakerInterviewTypeDto[] = useSelector(getTakerInterviewTypes)
  const dispatch = useDispatch()
  const [localList, setLocalList] = useState<TakerInterviewTypeDto[]>(JSON.parse(JSON.stringify(selectedInterviewTypes)))
  const deleteInterviewType = (index: number) => {
    const newList = localList.filter((_, key) => key !== index)
    setLocalList(newList)
  }

  useLayoutEffect(() => {
    scrollTo('top')
  }, [])

  useEffect(() => {
    let canGo = true
    for (const interviewType of localList) {
      if (!interviewType.type || !interviewType.level) {
        canGo = false
        break
      }
    }
    if (requestedForNextStep) {
      dispatch(doneTakerValidationCurrentStep(canGo))
      if (!canGo) {
        toast.error('Fill all required fields')
        return
      }
      dispatch(updateInterviewTypeList([...localList]))
    } else if (requestForBack) {
      dispatch(updateInterviewTypeList([...localList]))
      dispatch(backTakerValidationCurrentStep(true))
    }
  }, [
    requestedForNextStep,
    requestForBack,
    localList])
  const handleAdd = () => {
    let canAdd = true
    for (const InterviewTypeDto of localList) {
      if (!InterviewTypeDto.type || !InterviewTypeDto.level) {
        canAdd = false
        break
      }
    }
    if (canAdd) {
      dispatch(updateInterviewTypeList([...localList]))

      // TODO review this logic
      const newInterviewType: Partial<TakerInterviewTypeDto> = {
        type: undefined,
        level: undefined
      }
      dispatch(addTakerValidationInterviewType(newInterviewType))
      setLocalList((prev: TakerInterviewTypeDto[]) => [...prev, newInterviewType])
    } else {
      toast.error('Fill required fields')
    }
  }
  return (

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}>
      <span id={'top'}></span>
      {localList.map((item, index) => {
        return (
          <div key={index} className={'d-flex flex-row gap-2 '}>
            <div style={{ width: '100%' }}>
              <LanguageVerification
                levelValues={props.levelValues}
                typeValues={props.typeValues}
                idx={index}
                obj={item}
                localList={localList}
                editable={props.editable}
                setLocalList={setLocalList}
              />
            </div>
            {localList.length !== 1 &&
            <span style={{ paddingTop: '32px' }}>
              <img
                src={trash}
                alt={trash}
                height={'24px'}
                width={'24px'}
                onClick={() => { deleteInterviewType(index) } } style={{ cursor: 'pointer' }}/>
            </span>
            }

          </div>
        )
      })}
      {props.editable && <div onClick={() => {
        if (props.typeValues.length !== localList.length) {
          handleAdd()
        }
      }
      }
        style={{
          color: props.typeValues.length !== localList.length ? COLORS.MAIN_COLOR : '#BDBDBD',
          fontFamily: 'Inter, sans-serif',
          cursor: props.typeValues.length !== localList.length ? 'pointer' : 'not-allowed',
          width: 'fit-content',
          userSelect: 'none'
        }}>
        Add +
      </div>}
    </div>
  )
}

export default Interviews
