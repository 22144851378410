import React, { useCallback, useEffect, useState } from 'react'
import { NavigationModeValues } from '../modes/NavigationMode'
import Navigation from '../navigation/Navigation'
import ColoredText from '../text/ColoredText'
import { COLORED_TEXT_VALUES } from '../../util/Consts'
import { FontModeValues } from '../modes/FontMode'
import info from '../svg/resources/info_interview.svg'
import StandardButton from '../buttons/StandardButton'
import { ButtonModeValues } from '../modes/ButtonMode'
import SmallVideo from './SmallVideo'
import Timer from '../Timer/Timer'
import StandardTooltip from '../util/StandardTooltip'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import InterviewLoading from './InterviewLoading'
import { AuthManager } from '../../manager/AuthManager'
import { NavigationRoute } from '../../enumeration/NavigationRoute'
import { getTimeDifference, isXMinutesBefore } from '../../util/Helper'
import {
  GetInterviewJWT, updateInterviewAppId,
  updateInterviewJWT,
  updateInterviewSession
} from '../../features/InterviewJWTSlice'
import ApiManager from '../../manager/ApiManager'
import { type GetInterviewDto, InterviewControllerApi } from '../../api/ic'
import { type InterviewJoiningResponseDTO, InterviewVideoControllerApi } from '../../api/video'
import { updateInterviewFinishedProcess } from '../../features/InterviewProcessSlice'
import toast from 'react-hot-toast'
import MicVideoBlockedModal from './MicVideoBlockedModal'
import { checkMicPermission, checkVideoPermission } from '../../util/VideoMicUtils'

function InterviewJoinPage () {
  const [isLoading, setIsLoading] = useState(true)
  const [timerValue, setTimerValue] = useState(999)
  const [timerValue3seconds, setTimerValue3seconds] = useState(999)
  const [joinButtonText, setJoinButtonText] = useState<string | undefined>(undefined)
  const [joinButtonLoading, setJoinButtonLoading] = useState<boolean>(false)
  const [isRequestedForInterviewJWT, setIsRequestedForInterviewJWT] = useState(false)
  const [blockedItem, setBlockedItem] = useState<'camera' | 'mic'>('camera')
  const [isBlockedModalOpen, setIsBlockedModalOpen] = useState(false)

  const interviewJWT = useSelector(GetInterviewJWT)
  const navigate = useNavigate()
  const body = AuthManager.getAccessTokenBody() ?? {}
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile

  const dispatch = useDispatch()
  dispatch(updateInterviewFinishedProcess(false))

  const url = window.location.pathname
  const interviewId = url.split('/')[2]
  const [interviewDTO, setInterviewDTO] = useState<null | GetInterviewDto>(null)
  const minutesBeforeInterviewIsAvailable: number = Number(process.env.REACT_APP_MINUTES_BEFORE_INTERVIEW_IS_AVAILABLE) || 30
  const minutesAfterInterviewIsAvailable: number = Number(process.env.REACT_APP_MINUTES_AFTER_INTERVIEW_IS_AVAILABLE) || 30
  const minutesBeforeToCallForInterviewSign: number = Number(process.env.REACT_APP_MINUTES_BEFORE_TO_CALL_FOR_INTERVIEW_SIGN) || 30
  const minutesAfterToCallForInterviewSign: number = Number(process.env.REACT_APP_MINUTES_AFTER_TO_CALL_FOR_INTERVIEW_SIGN) || 30

  const interviewApi = ApiManager.getInstance(InterviewControllerApi)
  const interviewVideoApi = ApiManager.getInstance(InterviewVideoControllerApi)

  // Video, audio permissions
  const [hasVideoPermission, setHasVideoPermission] = useState(false)
  const [hasAudioPermission, setHasAudioPermission] = useState(false)

  useEffect(() => {
    void checkVideoPermission().then(setHasVideoPermission)
    void checkMicPermission().then(setHasAudioPermission)
  }, [])

  const callToSS = useCallback(async () => {
    if (!hasAudioPermission) {
      setBlockedItem('mic')
      setIsBlockedModalOpen(true)
      return
    }
    if (interviewDTO?.isPublic && !hasVideoPermission) {
      setBlockedItem('camera')
      setIsBlockedModalOpen(true)
      return
    }
    setJoinButtonLoading(true)
    await interviewVideoApi.interviewVideoControllerJoin({
      interviewJoiningRequestDTO: { bearerToken: interviewJWT }
    }).then((res: InterviewJoiningResponseDTO) => {
      const entity = res.entity
      if (entity) {
        dispatch(updateInterviewSession(entity.authBody.sessionId))
        dispatch(updateInterviewAppId(res.appId))
        navigate(`/interview/${interviewId}?token=${entity.authBody.token}`)
      }
      setJoinButtonLoading(false)
    }).catch(() => {
      toast.error('Another connection is in progress, wait a little bit')
    }).finally(() => {
      setJoinButtonLoading(false)
    })
  }, [interviewDTO,
    dispatch,
    navigate,
    hasAudioPermission,
    hasVideoPermission
  ])

  useEffect(() => {
    if (timerValue3seconds - timerValue > 3) {
      setTimerValue3seconds(timerValue)
    }
  }, [timerValue])

  useEffect(() => {
    if ((timerValue3seconds < minutesBeforeToCallForInterviewSign * 60 && timerValue3seconds > minutesAfterToCallForInterviewSign * -60) && !isRequestedForInterviewJWT) { // TODO: change to 120
      setIsRequestedForInterviewJWT(true)
      void interviewApi.getSignedInterviewById({ id: interviewId })
        .then(res => {
          if (!res.value) {
            throw Error('Unable to join')
          }
          dispatch(updateInterviewJWT(res.value))
          void interviewVideoApi.interviewVideoControllerIsTransfer({
            interviewJoiningRequestDTO: {
              bearerToken: res.value
            }
          }).then(res => {
            if (res.isTransfer) {
              setJoinButtonText('Transfer call')
            } else {
              setJoinButtonText('Join interview')
            }
          }).catch(() => { setJoinButtonText('Join interview') })
        })
        .catch(() => {
          setIsRequestedForInterviewJWT(false)
          navigate({
            pathname: NavigationRoute.HOME_PAGE,
            search: createSearchParams({
              error_in_interview: 'true'
            }).toString()
          })
        })
    }
  }, [timerValue3seconds])

  useEffect(() => {
    void interviewApi.getInterviewByID({ id: interviewId })
      .then(res => {
        if (res.acceptedDate) {
          setInterviewDTO(res)
          const tenMinutesBefore = !isXMinutesBefore(res.acceptedDate.toUTCString(), 10)
          if (tenMinutesBefore && isXMinutesBefore(res.acceptedDate.toUTCString(), -5)) {
            navigate({
              pathname: NavigationRoute.HOME_PAGE,
              search: createSearchParams({
                error_in_interview: 'true'
              }).toString()
            })
            return
          } if (isXMinutesBefore(res.acceptedDate.toUTCString(), 30)) {
            const timeDifference = getTimeDifference(res.acceptedDate.toUTCString())
            setTimerValue(timeDifference > 0 ? timeDifference : 0)
          }
        }
      }).catch(() => {
        navigate({
          pathname: NavigationRoute.HOME_PAGE,
          search: createSearchParams({
            error_in_interview: 'true'
          }).toString()
        })
      })
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  const getHeaderTextForInterview = (interviewDTO: GetInterviewDto) => {
    const interviewDate = interviewDTO.acceptedDate ? new Date(interviewDTO.acceptedDate) : new Date()
    const dateStr = interviewDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    return `${dateStr} / Interview Center ${interviewDTO.name}`
  }

  return (
    <div style={{ backgroundColor: '#202124', width: '100%', height: '100%', minHeight: '100vh', overflowX: 'hidden' }}>
      {!isLoading && (
        <div>
          <Navigation
            navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
            interviewText={interviewDTO ? getHeaderTextForInterview(interviewDTO) : 'Interview Center'}
            mobileNavItems={[]}
            height = {'40px'}/>
          <div
            style = {{
              width: '100%',
              minHeight: 'calc(100vh - 40px)',
              flexWrap: 'wrap',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              gap: '50px',
              marginTop: isMobile ? '50px' : '0px'
            }}>
            <SmallVideo
              width = {!isMobile ? '600px' : '450px'} // TODO: create for mobile with percentages and test it
              height={!isMobile ? '350px' : '300px'}
              fullName={body.firstName + '  ' + body.lastName}/>
            <div
              style = {{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <ColoredText
                texts={COLORED_TEXT_VALUES.READY_TO_JOIN}
                centered = {true}/>
              <div
                style = {{
                  gap: '30px',
                  paddingTop: '50px'
                }}
                className='d-flex flex-column align-items-center'>
                <div
                  style={{
                    maxWidth: '200px',
                    width: '200px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  className= {'d-flex flex-row'}>
                  <ColoredText
                    singleText='Swift and Silent Feedback'
                    color = {'rgba(174, 195, 255, 1)'}
                    fontSize= {FontModeValues.SMALL}/>
                  <StandardTooltip
                    texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                    <img alt = {'info'} src = {info}/>
                  </StandardTooltip>
                </div>
                <div
                  style={{
                    maxWidth: '200px',
                    width: '200px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  className= {'d-flex flex-row'}>
                  <ColoredText
                    singleText='Time is of the Essence '
                    color = {'rgba(174, 195, 255, 1)'}
                    fontSize= {FontModeValues.SMALL}/>
                  <StandardTooltip
                    texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                    <img alt = {'info'} src = {info}/>
                  </StandardTooltip>
                </div>
                <div
                  style={{
                    maxWidth: '200px',
                    width: '200px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  className= {'d-flex flex-row'}>
                  <ColoredText
                    singleText='Here are the key points '
                    color = {'rgba(174, 195, 255, 1)'}
                    fontSize= {FontModeValues.SMALL}/>
                  <StandardTooltip
                    texts = {COLORED_TEXT_VALUES.WELCOME_TEXT}>
                    <img alt = {'info'} src = {info}/>
                  </StandardTooltip>
                </div>
                <div
                  style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    alignItems: 'center',
                    paddingTop: '20px'
                  }}>
                  <StandardButton
                    text= {joinButtonText}
                    sizeMode= {ButtonModeValues.DEFAULT}
                    textColor='black'
                    isLoading={!joinButtonText || joinButtonLoading}
                    clickable={(timerValue < minutesBeforeInterviewIsAvailable * 60 && timerValue > minutesAfterInterviewIsAvailable * -60)} // TODO: change to 120
                    textFontSize= {FontModeValues.LARGE}
                    textFontWeight={500}
                    style = {{
                      background: 'white'
                    }}
                    onClick={callToSS}
                  />
                  <Timer
                    mode = 'Decrease'
                    startValue = {timerValue}
                    setValue={setTimerValue}
                    color = 'white'
                    fontSize= {FontModeValues.SMALL}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <InterviewLoading/>
      )}
      <MicVideoBlockedModal
        mode={blockedItem}
        isOpen={isBlockedModalOpen}
        setIsOpen={setIsBlockedModalOpen}/>
    </div>
  )
}

export default InterviewJoinPage
