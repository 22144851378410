/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewTypeUpdateDto
 */
export interface InterviewTypeUpdateDto {
    /**
     * Interview Type id
     * @type {string}
     * @memberof InterviewTypeUpdateDto
     */
    id: string;
    /**
     * Interview Type name
     * @type {string}
     * @memberof InterviewTypeUpdateDto
     */
    name?: string;
    /**
     * Interview Type type
     * @type {string}
     * @memberof InterviewTypeUpdateDto
     */
    type?: string;
    /**
     * Interview Type description
     * @type {string}
     * @memberof InterviewTypeUpdateDto
     */
    description?: string;
}

/**
 * Check if a given object implements the InterviewTypeUpdateDto interface.
 */
export function instanceOfInterviewTypeUpdateDto(value: object): value is InterviewTypeUpdateDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function InterviewTypeUpdateDtoFromJSON(json: any): InterviewTypeUpdateDto {
    return InterviewTypeUpdateDtoFromJSONTyped(json, false);
}

export function InterviewTypeUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewTypeUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'type': json['type'] == null ? undefined : json['type'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function InterviewTypeUpdateDtoToJSON(value?: InterviewTypeUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'type': value['type'],
        'description': value['description'],
    };
}

