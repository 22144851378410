import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import App from './app'
import Init from './components/Init'
import { persistor, store } from './store/store'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import ScreenDetector from './components/responsiveness/ScreenDetector'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <div>
    <Init/>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ScreenDetector>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </ScreenDetector>
        <Toaster position={'top-right'}/>
      </PersistGate>
    </Provider>
  </div>
)
