import React from 'react'
import StandardModal from '../modal/StandardModal'
import ColoredText from '../text/ColoredText'
import blockedMicCamIcon from '../svg/resources/blocked_mic_camera_icon.svg'
import browserConfigIcon from '../svg/resources/browser_config_icon.svg'

interface MicVideoBlockedModalInterface {
  isOpen: boolean
  setIsOpen: any
  mode: 'camera' | 'mic'
}

function MicVideoBlockedModal (props: MicVideoBlockedModalInterface) {
  const mode = props.mode === 'camera' ? 'camera' : 'microphone'
  return (
    <div>
      <StandardModal
        isOpen={props.isOpen}
        closeX={true}
        closeModal={props.setIsOpen}
        needDividers={false}
        needTitle={false}
        footerComponent={<div></div>}>
        <div
          className={'d-flex flex-row justify-content-center align-items-center gap-5'}
          style={{ marginTop: '40px' }}>
          <div>
            <img src={blockedMicCamIcon} alt={'blocked'}/>
          </div>
          <div className={'d-flex flex-column gap-3'}>
            <ColoredText
              fontWeight={500}
              fontSize={'16px'}
              texts={[
                {
                  value: 'IC is blocked from using your',
                  newLine: true
                },
                {
                  value: mode
                }
              ]}/>
            <div>
              <div className={'d-flex flex-row gap-2'}>
                <ColoredText singleText={'1. Click the'}/>
                <img src={browserConfigIcon} alt={'config'}/>
                <ColoredText singleText={' page info icon in your'}/>
              </div>
              <ColoredText singleText={'browser’s address bar'}/>
            </div>
            <ColoredText singleText={`2. Turn on ${mode}`}/>
            <ColoredText singleText={'3. Reload the page'}/>
          </div>
        </div>
      </StandardModal>
    </div>
  )
}

export default MicVideoBlockedModal
