class ApiManager {
  private static instances: Record<string, any> = {}

  public static getInstance<T>(ApiClientClass: new () => T): T {
    const className = ApiClientClass.name
    if (!ApiManager.instances[className]) {
      ApiManager.instances[className] = new ApiClientClass()
    }
    return ApiManager.instances[className]
  }
}

export default ApiManager
