import React, { useLayoutEffect } from 'react'
import useScreenType from 'react-screentype-hook'
import { useDispatch } from 'react-redux'
import {
  updateScreenInnerHeight,
  updateScreenInnerWidth,
  updateScreenOrientation, updateScreenType
} from '../../features/DeviceTypeInformationSlice'

interface ScreenDetectorProps {
  children: React.ReactNode
}
export default function ScreenDetector (props: ScreenDetectorProps) {
  const dispatch = useDispatch()
  const screenType = useScreenType()
  window.addEventListener(
    'resize',
    () => {
      dispatch(updateScreenInnerWidth(window.innerWidth))
      dispatch(updateScreenInnerHeight(window.innerHeight))
    }
  )
  useLayoutEffect(() => {
    if (window.innerWidth >= window.innerHeight) {
      dispatch(updateScreenOrientation(true))
    } else {
      dispatch(updateScreenOrientation(false))
    }
  }, [window.innerWidth, window.innerHeight])

  useLayoutEffect(() => {
    dispatch(updateScreenType((screenType.isTablet || screenType.isMobile)))
  }, [screenType])

  return (<>{props.children}</>)
}
