import React, { useState, useEffect, useRef } from 'react'

interface VideoSegment {
  url: string
  startTimeOffset: number
  stopTimeOffset: number
}

interface Props {
  taker: VideoSegment[]
  giver: VideoSegment[]
}

const CustomComponent: React.FC = () => {
  return <div>Video Disabled</div>
}

const VideoSyncComponent: React.FC<Props> = ({ taker, giver }) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [currentSlideBarTime, setCurrentSlideBarTime] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const takerVideoRef = useRef<HTMLVideoElement>(null)
  const giverVideoRef = useRef<HTMLVideoElement>(null)

  // Smooth slider update
  useEffect(() => {
    let animationFrameId: number | undefined
    let intervalId: NodeJS.Timeout | undefined

    const updateSliderTime = () => {
      if (isPlaying) {
        // If the taker video is currently showing a video
        if (takerVideoRef.current && takerVideoRef.current.src !== '') {
          const newTime = takerVideoRef.current.currentTime * 1000
          setCurrentSlideBarTime(newTime)
          setCurrentTime(newTime)
          animationFrameId = requestAnimationFrame(updateSliderTime)
        } else {
          // Custom component fallback for slider update (e.g., for initial 3 seconds)
          intervalId = setInterval(() => {
            setCurrentTime((prev) => {
              const updatedTime = prev + 100 // advance by 100ms
              setCurrentSlideBarTime(updatedTime)
              return updatedTime
            })
          }, 100) // update every 100ms
        }
      }
    }

    if (isPlaying) {
      updateSliderTime()
    } else {
      if (intervalId) clearInterval(intervalId)
      if (animationFrameId) cancelAnimationFrame(animationFrameId)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
      if (animationFrameId) cancelAnimationFrame(animationFrameId)
    }
  }, [isPlaying])

  useEffect(() => {
    const playVideos = async () => {
      if (takerVideoRef.current && giverVideoRef.current) {
        try {
          if (takerVideoRef.current.paused) {
            await takerVideoRef.current.play()
          }
          if (giverVideoRef.current.paused) {
            await giverVideoRef.current.play()
          }
        } catch (error) {
          console.error('Error playing videos:', error)
        }
      }
    }

    if (isPlaying) {
      void playVideos()
    } else {
      if (takerVideoRef.current) takerVideoRef.current.pause()
      if (giverVideoRef.current) giverVideoRef.current.pause()
    }
  }, [isPlaying])

  // Update video time only if there's a significant difference
  const updateVideoTime = async (videoRef: React.RefObject<HTMLVideoElement>, segments: VideoSegment[]) => {
    const video = videoRef.current
    if (video) {
      const segment = segments.find(
        (seg) => currentTime >= seg.startTimeOffset && currentTime < seg.stopTimeOffset
      )
      if (segment) {
        try {
          const currentSegmentTime = (currentTime - segment.startTimeOffset) / 1000
          // Only update if there's a significant difference in current time
          if (Math.abs(video.currentTime - currentSegmentTime) > 0.2) {
            video.currentTime = currentSegmentTime
          }
          // Only update src if the video url has changed
          if (video.src !== segment.url) {
            video.pause()
            video.src = segment.url
            video.currentTime = currentSegmentTime
            if (isPlaying) {
              await video.play()
            }
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        video.pause()
      }
    }
  }

  useEffect(() => {
    void updateVideoTime(takerVideoRef, taker)
    void updateVideoTime(giverVideoRef, giver)
  }, [currentTime,
    isPlaying,
    taker,
    giver])

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
  }

  const handleSliderChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = Number(e.target.value)
    setCurrentSlideBarTime(newTime)
    setCurrentTime(newTime)
    // Debouncing: update video time after the slider change is complete
    if (!isPlaying) {
      await updateVideoTime(takerVideoRef, taker)
      await updateVideoTime(giverVideoRef, giver)
    }
  }

  const renderVideo = (segments: VideoSegment[], videoRef: React.RefObject<HTMLVideoElement>) => {
    const segment = segments.find(
      (seg) => currentTime >= seg.startTimeOffset && currentTime < seg.stopTimeOffset
    )

    if (!segment) return null

    if (segment.url === 'CUSTOM') {
      return <CustomComponent />
    }

    return (
      <video
        ref={videoRef}
        autoPlay
        width="200"
        height="200"
      />
    )
  }

  return (
    <div className={'d-flex flex-column'}>
      <div className={'d-flex flex-row gap-5'}>
        <h3>Taker</h3>
        <h3>Giver</h3>
      </div>
      <div className={'d-flex flex-row gap-3 align-items-center'}>
        <div>{renderVideo(taker, takerVideoRef)}</div>
        <div>{renderVideo(giver, giverVideoRef)}</div>
      </div>
      <div className={'d-flex flex-row gap-3'}>
        <button onClick={handlePlayPause}>{isPlaying ? 'Pause' : 'Start'}</button>
        <input
          type="range"
          min="0"
          max="30000"
          value={currentSlideBarTime}
          onChange={(event) => { void handleSliderChange(event) }}
        />
      </div>
    </div>
  )
}

export default VideoSyncComponent
