/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetICUserDto,
  GetInterviewDto,
  RefreshableAuthenticationResponseDto,
  TakerAvailableInterviewDatesDto,
  TakerInterviewsInfoDto,
  TakerRejectionDto,
  TakerValidationInterview,
  TakerValidationRequestDto,
  ValidationInfoResponseDto,
} from '../models/index';
import {
    GetICUserDtoFromJSON,
    GetICUserDtoToJSON,
    GetInterviewDtoFromJSON,
    GetInterviewDtoToJSON,
    RefreshableAuthenticationResponseDtoFromJSON,
    RefreshableAuthenticationResponseDtoToJSON,
    TakerAvailableInterviewDatesDtoFromJSON,
    TakerAvailableInterviewDatesDtoToJSON,
    TakerInterviewsInfoDtoFromJSON,
    TakerInterviewsInfoDtoToJSON,
    TakerRejectionDtoFromJSON,
    TakerRejectionDtoToJSON,
    TakerValidationInterviewFromJSON,
    TakerValidationInterviewToJSON,
    TakerValidationRequestDtoFromJSON,
    TakerValidationRequestDtoToJSON,
    ValidationInfoResponseDtoFromJSON,
    ValidationInfoResponseDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface RejectTakerRequest {
    takerRejectionDto: TakerRejectionDto;
}

export interface RequestTakerValidationRequest {
    takerValidationRequestDto: TakerValidationRequestDto;
}

export interface SetupInterviewRequest {
    takerValidationInterview: TakerValidationInterview;
}

export interface ValidateTakerRequest {
    userId: string;
}

/**
 * 
 */
export class TakerControllerApi extends runtime.BaseAPI {

    /**
     * Gets all takers
     * Get all takers
     */
    async getAllTakersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetICUserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/get/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetICUserDtoFromJSON));
    }

    /**
     * Gets all takers
     * Get all takers
     */
    async getAllTakers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetICUserDto>> {
        const response = await this.getAllTakersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets rejected takers
     * Get rejected takers
     */
    async getRejectedTakersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetICUserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/get/all/rejected`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetICUserDtoFromJSON));
    }

    /**
     * Gets rejected takers
     * Get rejected takers
     */
    async getRejectedTakers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetICUserDto>> {
        const response = await this.getRejectedTakersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets requested takers
     * Get requested takers
     */
    async getRequestedTakersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetICUserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/get/all/requested`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetICUserDtoFromJSON));
    }

    /**
     * Gets requested takers
     * Get requested takers
     */
    async getRequestedTakers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetICUserDto>> {
        const response = await this.getRequestedTakersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets available interview dates
     * Get available interview dates for taker
     */
    async getTakerAvailableInterviewDatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TakerAvailableInterviewDatesDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/getAvailableInterviewDates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TakerAvailableInterviewDatesDtoFromJSON));
    }

    /**
     * Gets available interview dates
     * Get available interview dates for taker
     */
    async getTakerAvailableInterviewDates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TakerAvailableInterviewDatesDto>> {
        const response = await this.getTakerAvailableInterviewDatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets taker interviews info
     * Get taker interviews info
     */
    async getTakerInterviewsInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TakerInterviewsInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/getTakerInterviewsInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => TakerInterviewsInfoDtoFromJSON(jsonValue));
    }

    /**
     * Gets taker interviews info
     * Get taker interviews info
     */
    async getTakerInterviewsInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TakerInterviewsInfoDto> {
        const response = await this.getTakerInterviewsInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets taker status
     * Get taker status
     */
    async getTakerStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gets taker status
     * Get taker status
     */
    async getTakerStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTakerStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets taker validation all info
     * Get taker validation all info
     */
    async getTakerValidationAllInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationInfoResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/validation/allInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationInfoResponseDtoFromJSON(jsonValue));
    }

    /**
     * Gets taker validation all info
     * Get taker validation all info
     */
    async getTakerValidationAllInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationInfoResponseDto> {
        const response = await this.getTakerValidationAllInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * Rejects taker
     * Reject taker
     */
    async rejectTakerRaw(requestParameters: RejectTakerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetICUserDto>> {
        if (requestParameters['takerRejectionDto'] == null) {
            throw new runtime.RequiredError(
                'takerRejectionDto',
                'Required parameter "takerRejectionDto" was null or undefined when calling rejectTaker().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/taker/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TakerRejectionDtoToJSON(requestParameters['takerRejectionDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetICUserDtoFromJSON(jsonValue));
    }

    /**
     * Rejects taker
     * Reject taker
     */
    async rejectTaker(requestParameters: RejectTakerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetICUserDto> {
        const response = await this.rejectTakerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Requests taker validation
     * Request taker validation
     */
    async requestTakerValidationRaw(requestParameters: RequestTakerValidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RefreshableAuthenticationResponseDto>> {
        if (requestParameters['takerValidationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'takerValidationRequestDto',
                'Required parameter "takerValidationRequestDto" was null or undefined when calling requestTakerValidation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/taker/validation/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TakerValidationRequestDtoToJSON(requestParameters['takerValidationRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshableAuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Requests taker validation
     * Request taker validation
     */
    async requestTakerValidation(requestParameters: RequestTakerValidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RefreshableAuthenticationResponseDto> {
        const response = await this.requestTakerValidationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Setups interview
     * Setup interview
     */
    async setupInterviewRaw(requestParameters: SetupInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInterviewDto>> {
        if (requestParameters['takerValidationInterview'] == null) {
            throw new runtime.RequiredError(
                'takerValidationInterview',
                'Required parameter "takerValidationInterview" was null or undefined when calling setupInterview().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/taker/setupInterview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TakerValidationInterviewToJSON(requestParameters['takerValidationInterview']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewDtoFromJSON(jsonValue));
    }

    /**
     * Setups interview
     * Setup interview
     */
    async setupInterview(requestParameters: SetupInterviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInterviewDto> {
        const response = await this.setupInterviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validates taker
     * Validate taker
     */
    async validateTakerRaw(requestParameters: ValidateTakerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetICUserDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling validateTaker().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/taker/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => GetICUserDtoFromJSON(jsonValue));
    }

    /**
     * Validates taker
     * Validate taker
     */
    async validateTaker(requestParameters: ValidateTakerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetICUserDto> {
        const response = await this.validateTakerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
