import logo from '../svg/resources/logo.svg'
import logo_white from '../svg/resources/logo_white.svg'

export const enum LogoModeValues {
  DEFAULT = 'DEFAULT',
  SMALL_DEFAULT = 'SMALL_DEFAULT',
  SMALL_WHITE = 'SMALL_WHITE',
  DEFAULT_WHITE = 'DEFAULT_WHITE',
  DEFAULT_SMALL_WHITE = 'DEFAULT_SMALL_WHITE'
}

interface LogoConfigs {
  src: string
  width?: string
}

export const LOGO_MODES: Record<LogoModeValues, LogoConfigs> = {
  [LogoModeValues.DEFAULT]: {
    src: logo,
    width: '60px'
  },
  [LogoModeValues.SMALL_DEFAULT]: {
    src: logo,
    width: '35px'
  },
  [LogoModeValues.SMALL_WHITE]: {
    src: logo_white
  },
  [LogoModeValues.DEFAULT_WHITE]: {
    src: logo_white,
    width: '60px'
  },
  [LogoModeValues.DEFAULT_SMALL_WHITE]: {
    src: logo_white,
    width: '50px'
  }
}
