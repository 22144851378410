import React from 'react'
import Line from './Line'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'

interface OrLineProps {
  fontSize?: string
  text: string
  lineWidth?: string
}

function LineTextLine (props: OrLineProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const fontSize = props.fontSize ?? '14px'
  const lineWidth = props.lineWidth ?? '137px'

  return (
    <div
      className={'d-flex flex-row align-items-center'}>
      <Line lineWidth={lineWidth}/>
      <div
        style={{
          color: 'rgba(0, 0, 0, 0.65)',
          fontSize: isMobile ? '12px' : fontSize, // TODO generic solution
          margin: '0px 10px 0px 10px'
        }}>
        {props.text}
      </div>
      <Line lineWidth={lineWidth}/>
    </div>
  )
}

export default LineTextLine
