import React from 'react'
import { useLocation } from 'react-router-dom'

interface SettingsActionInterface {
  children: React.ReactNode
}

function SettingActionBodyManager (props: SettingsActionInterface) {
  const location = useLocation()
  const filteredChild = React.Children.toArray(props.children).find(child => {
    if (React.isValidElement(child)) {
      return location.pathname === child.props.navigationRoute
    }
    return false
  })

  return (
    <div className={'d-flex flex-column gap-1'}>
      {filteredChild}
    </div>
  )
}

export default SettingActionBodyManager
