/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthenticationResponseDto,
  ForgotPasswordRequestDto,
  ForgotPasswordResponseDto,
  PasswordRecoverRequestDto,
} from '../models/index';
import {
    AuthenticationResponseDtoFromJSON,
    AuthenticationResponseDtoToJSON,
    ForgotPasswordRequestDtoFromJSON,
    ForgotPasswordRequestDtoToJSON,
    ForgotPasswordResponseDtoFromJSON,
    ForgotPasswordResponseDtoToJSON,
    PasswordRecoverRequestDtoFromJSON,
    PasswordRecoverRequestDtoToJSON,
} from '../models/index';
import { AuthManager } from "../../../manager/AuthManager";

export interface ForgotRequest {
    forgotPasswordRequestDto: ForgotPasswordRequestDto;
}

export interface RecoverRequest {
    passwordRecoverRequestDto: PasswordRecoverRequestDto;
}

/**
 * 
 */
export class ForgotPasswordControllerApi extends runtime.BaseAPI {

    /**
     * Generates OTP and sends email
     * Request for forgot password
     */
    async forgotRaw(requestParameters: ForgotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForgotPasswordResponseDto>> {
        if (requestParameters['forgotPasswordRequestDto'] == null) {
            throw new runtime.RequiredError(
                'forgotPasswordRequestDto',
                'Required parameter "forgotPasswordRequestDto" was null or undefined when calling forgot().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forgot/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestDtoToJSON(requestParameters['forgotPasswordRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => ForgotPasswordResponseDtoFromJSON(jsonValue));
    }

    /**
     * Generates OTP and sends email
     * Request for forgot password
     */
    async forgot(requestParameters: ForgotRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForgotPasswordResponseDto> {
        const response = await this.forgotRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recoverRaw(requestParameters: RecoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticationResponseDto>> {
        if (requestParameters['passwordRecoverRequestDto'] == null) {
            throw new runtime.RequiredError(
                'passwordRecoverRequestDto',
                'Required parameter "passwordRecoverRequestDto" was null or undefined when calling recover().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forgot/password/recover`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordRecoverRequestDtoToJSON(requestParameters['passwordRecoverRequestDto']),
        }, initOverrides);

        await AuthManager.validateAuthRefresh(response, this)

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async recover(requestParameters: RecoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticationResponseDto> {
        const response = await this.recoverRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
