import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/admin/AdminHeader'
import StandardButton from '../components/buttons/StandardButton'
import { WidthModeValues } from '../components/modes/WidthMode'
import MarginWrapper from '../components/util/MarginWrapper'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import StandardInput from '../components/input/StandardInput'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import { HeightModeValues } from '../components/modes/HeightMode'
import InfoSections from '../components/TakerProfil/InfoSections'
import TakerInterviewCalendar from '../components/calendar/TakerInterviewCalendar'
import TakerInterviewTimeRangeSelector from '../timeRangeSelector/TakerInterviewTimeRangeSelector'
import Flipper from '../components/flipper/Flipper'
import { groupDatesFromIsoStrings } from '../util/Helper'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import ApiManager from '../manager/ApiManager'
import { TakerControllerApi, type TakerRejectionDto } from '../api/ic'

function TakerDetailsPage () {
  const [reason, setReason] = useState('')
  const [takerRejection, setTakerRejection] = useState<TakerRejectionDto>()
  const [requestedDates, setRequestedDates] = useState<string[]>([])
  const [availableDates, setAvailablDates] = useState<Date[]>([])
  const timesByAvailableDates = groupDatesFromIsoStrings(Array.from(requestedDates ?? []))
  const [singleSelectedTime, setSingleSelectedTime] = useState<Date | null>(null)

  const [flipped, setIsFlipped] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())
  // const [currentID, setCurrentID] = useState('')
  const navigate = useNavigate()
  const { id } = useParams()

  const takerApi = ApiManager.getInstance(TakerControllerApi)

  const setUpInterviewWithTaker = async () => {
    if (!id || !singleSelectedTime) {
      toast.error('Id and the date are required to setup interview')
      return
    }
    await takerApi.setupInterview({
      takerValidationInterview: { giverToValidateId: id, acceptedDate: singleSelectedTime }
    })
      .catch(() => {})
  }
  const verify = async () => {
    if (!id) {
      toast.error('Id is required for verification')
      return
    }
    await takerApi.validateTaker({ userId: id })
      .then(res => {
        navigate(NavigationRoute.ADMIN)
        toast.success(`User ${res.firstName} ${res.lastName} is now verified`)
      }).catch(() => {})
  }

  const reject = async (reason: string) => {
    if (!id) {
      toast.error('Id is required for rejection')
      return
    }
    await takerApi.rejectTaker({
      takerRejectionDto: { userId: id, reason }
    })
      .then(res => {
        navigate(NavigationRoute.ADMIN)
        toast.success(`User ${res.firstName} ${res.lastName} is now rejected`)
      }).catch(() => {})
  }

  useEffect(() => {
    if (takerRejection) {
      setReason(takerRejection?.reason ?? '')
    }
  }, [takerRejection])

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'Taker Details'}/>
      <MarginWrapper top={'20px'}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style = {{
              width: '71vw',
              display: 'flex',
              flexDirection: 'column',
              gap: '50px'
            }}>
            <InfoSections
              setRequestedDates={setRequestedDates}
              userId={id}
              setTakerRejection={setTakerRejection}/>
            <div className={'d-flex flex-column justify-content-center align-items-center gap-2'}>
              <Flipper
                height={'400px'}
                flipped={flipped}
                frontComponent={
                  <TakerInterviewCalendar
                    setDate={setDate}
                    timesByAvailableDates={timesByAvailableDates}
                    setAvailableDates={setAvailablDates}
                    setIsFlipped={setIsFlipped}
                    singleSelectedTime={singleSelectedTime}
                />}
                backComponent={
                  <TakerInterviewTimeRangeSelector
                    setSingleSelectedTime={setSingleSelectedTime}
                    singleSelectedTime={singleSelectedTime}
                    setIsFlipped={setIsFlipped}
                    backOnClick={() => {}}
                    date={date}
                    interviewDuration={60}
                    availableDates={availableDates}/>
              }/>
              <StandardButton
                sizeMode={ButtonModeValues.CUSTOM_FONT}
                text={'Set Taker Verification Interview'}
                onClick={ async () => {
                  await setUpInterviewWithTaker()
                  setIsFlipped(false)
                  setSingleSelectedTime(null)
                }}
              />
            </div>
          </div>
        </div>
      </MarginWrapper>

      <MarginWrapper top={'50px'} bottom={'50px'}>
        <div className={'d-flex flex-column align-items-center'}>
          <StandardInput
            setInputValue={setReason}
            inputValue={reason}
            disabled={!id}
            type={StandardInputModeValues.TEXT_AREA}
            widthMode={WidthModeValues.LARGE}
            heightMode={HeightModeValues.EXTRA_LARGE}
            placeHolder={'e.g. Please add more about education'}
            label={'Rejection reason'}/>
          <MarginWrapper top={'20px'}>
            <div style={{ gap: '20px' }} className={'d-flex flex-row justify-content-center align-items-center'}>
              <StandardButton
                text={'Verify'}
                onClick={verify}
                widthMode={WidthModeValues.EXTRA_SMALL}/>
              <StandardButton
                text={'Reject'}
                onClick={async () => { await reject(reason) }}
                widthMode={WidthModeValues.EXTRA_SMALL}/>
            </div>
          </MarginWrapper>
        </div>
      </MarginWrapper>

    </div>
  )
}

export default TakerDetailsPage
