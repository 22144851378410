/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetInterviewQuestionTemplateDto } from './GetInterviewQuestionTemplateDto';
import {
    GetInterviewQuestionTemplateDtoFromJSON,
    GetInterviewQuestionTemplateDtoFromJSONTyped,
    GetInterviewQuestionTemplateDtoToJSON,
} from './GetInterviewQuestionTemplateDto';

/**
 * 
 * @export
 * @interface GetInterviewQuestionTemplateWrapperDto
 */
export interface GetInterviewQuestionTemplateWrapperDto {
    /**
     * 
     * @type {GetInterviewQuestionTemplateDto}
     * @memberof GetInterviewQuestionTemplateWrapperDto
     */
    dto?: GetInterviewQuestionTemplateDto;
    /**
     * Is template completed
     * @type {boolean}
     * @memberof GetInterviewQuestionTemplateWrapperDto
     */
    completed: boolean;
}

/**
 * Check if a given object implements the GetInterviewQuestionTemplateWrapperDto interface.
 */
export function instanceOfGetInterviewQuestionTemplateWrapperDto(value: object): value is GetInterviewQuestionTemplateWrapperDto {
    if (!('completed' in value) || value['completed'] === undefined) return false;
    return true;
}

export function GetInterviewQuestionTemplateWrapperDtoFromJSON(json: any): GetInterviewQuestionTemplateWrapperDto {
    return GetInterviewQuestionTemplateWrapperDtoFromJSONTyped(json, false);
}

export function GetInterviewQuestionTemplateWrapperDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewQuestionTemplateWrapperDto {
    if (json == null) {
        return json;
    }
    return {
        
        'dto': json['dto'] == null ? undefined : GetInterviewQuestionTemplateDtoFromJSON(json['dto']),
        'completed': json['completed'],
    };
}

export function GetInterviewQuestionTemplateWrapperDtoToJSON(value?: GetInterviewQuestionTemplateWrapperDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dto': GetInterviewQuestionTemplateDtoToJSON(value['dto']),
        'completed': value['completed'],
    };
}

