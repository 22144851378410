import React, { useEffect, useState } from 'react'
import visa from '../svg/resources/visa.svg'
import master from '../svg/resources/master.svg'
import amex from '../svg/resources/amex.svg'
import StandardInput from '../input/StandardInput'
import { InputModeValues } from '../modes/InputModes'
import paypal from '../svg/resources/paypal.svg'
import { COLORS } from '../../util/Consts'
import { type BankCardCreationDto } from '../../api/ic'

interface CardAttachmentFormInterface {
  setBankCard: (bankCard: BankCardCreationDto | undefined) => void
  needRememberMe?: boolean
}

function CardAttachmentForm ({ setBankCard, needRememberMe }: CardAttachmentFormInterface) {
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardType, setCardType] = useState<string>('')
  const [cardholder, setCardholder] = useState<string>('')
  const [cvv, setCvv] = useState<string>('')
  const [expiry, setExpiry] = useState<string>('')
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [rememberMe, setRememberMe] = useState(false)
  needRememberMe = needRememberMe === undefined ? true : needRememberMe

  useEffect(() => {
    const bankCard: BankCardCreationDto = {
      num: cardNumber,
      cvv: parseInt(cvv),
      expireDate: expiry,
      cardHolder: cardholder
    }
    if (needRememberMe) {
      bankCard._default = rememberMe
    }
    setBankCard(bankCard)
  }, [cardholder,
    cardNumber,
    cardType,
    cvv,
    expiry,
    rememberMe])
  const validateCardNumber = (number: string) => {
    const cleaned = number.replace(/\D/g, '')
    if (cleaned.length < 16) {
      setErrors(prev => ({ ...prev, cardNumber: 'Card number must be 16 digits.' }))
    } else {
      setErrors(prev => ({ ...prev, cardNumber: '' }))
    }
    return cleaned.length <= 16 ? cleaned : cleaned.substring(0, 16)
  }

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = validateCardNumber(e.target.value)
    setCardNumber(value.replace(/(.{4})/g, '$1 ').trim())
    setCardType(detectCardType(value.substring(0, 2)))
  }

  const validateExpiry = (value: string) => {
    if (value.length !== 5) {
      setErrors(prev => ({ ...prev, expiry: '' }))
      return
    }

    const [month, year] = value.split('/').map(Number)
    const currentYear = new Date().getFullYear() % 100 // Get last two digits of the current year
    const currentMonth = new Date().getMonth() + 1 // Months are zero-based

    if (isNaN(month) || isNaN(year) || month < 1 || month > 12) {
      setErrors(prev => ({ ...prev, expiry: 'Invalid expiry date.' }))
    } else if (year < currentYear || (year === currentYear && month < currentMonth)) {
      setErrors(prev => ({ ...prev, expiry: 'Expiry date cannot be in the past.' }))
    } else {
      setErrors(prev => ({ ...prev, expiry: '' }))
    }
  }

  const handleExpiryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value.replace(/\D/g, '').substring(0, 4)
    if (value.length >= 2) {
      let month = Number(value.substring(0, 2))
      if (month > 12) {
        month = 12
      }
      value = month.toString().padStart(2, '0') + value.substring(2)
    }
    if (value.length > 2) {
      value = value.substring(0, 2) + '/' + value.substring(2)
    }
    setExpiry(value)
    validateExpiry(value)
  }
  const detectCardType = (number: string): string => {
    const regexes: Record<string, RegExp> = {
      visa: /^4/,
      mastercard: /^5[1-5]/,
      amex: /^3[47]/
      // Add more card types as needed
    }

    // TODO change this part, and use the one from the <AttachedCard/> component
    for (const key in regexes) {
      if (regexes[key].test(number)) {
        if (key === 'visa') {
          return visa
        } else if (key === 'mastercard') {
          return master
        } else if (key === 'amex') {
          return amex
        } else {
          return ''
        }
      }
    }
    return ''
  }

  const handleCvvChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.replace(/\D/g, '').substring(0, 4)
    setCvv(value)
    if (value.length === 4) {
      setErrors(prev => ({ ...prev, cvv: 'CVV must be 3 or 4 digits.' }))
    } else {
      setErrors(prev => ({ ...prev, cvv: '' }))
    }
  }

  return (
    <form>
      <div className={'d-flex flex-column'}>
        <StandardInput
          label={'Cardholder Name'}
          placeHolder={'eg John Doe'}
          inputValue={cardholder}
          setInputValue={setCardholder}
          sizeMode={InputModeValues.MAX_WIDTH_2}
          maxLength={60}/>
      </div>
      <div className={'d-flex flex-column'} style={{ position: 'relative' }}>
        <img src={cardType} alt={cardType}
          style={{ position: 'absolute', right: '10px', bottom: '32px', zIndex: '1' }}/>
        <StandardInput
          setInputValue={() => {}}
          inputValue={cardNumber}
          onChange={handleCardNumberChange}
          placeHolder={'****  ****  ****  ****'}
          sizeMode={InputModeValues.MAX_WIDTH_2}
          label={'Card Number'}/>
      </div>
      <div className={'d-flex flex-row gap-1'} style={{ marginTop: '-20px', marginBottom: '10px' }}>
        <img src={master} alt={master}/>
        <img src={visa} alt={visa}/>
        <img src={paypal} alt={paypal}/>
        <img src={amex} alt={amex}/>
      </div>
      <div className={'d-flex flex-row justify-content-between gap-5'}>
        <div className={'d-flex flex-column'}>
          <StandardInput
            setInputValue={() => {}}
            inputValue={cvv}
            placeHolder={'***'}
            sizeMode={InputModeValues.MAX_WIDTH_2}
            onChange={handleCvvChange}
            label={'CVV'}/>
        </div>
        <div className={'d-flex flex-column'} style={{ height: '110px' }}>
          <StandardInput
            setInputValue={() => {}}
            inputValue={expiry}
            placeHolder={'MM/YY'}
            onChange={handleExpiryChange}
            sizeMode={InputModeValues.MAX_WIDTH_2}
            label={'Expiration Date (MM/YY)'}/>
          {errors.expiry &&
            <div style={{
              fontSize: '12px',
              marginTop: '-20px',
              color: '#FF0001',
              opacity: '0.5'
            }}>
              {errors.expiry}
            </div>
          }
        </div>
      </div>

      {needRememberMe &&
        <div
          className={'d-flex flex-row align-items-center gap-1'}
          style={{ height: '16px' }}>
          <input
            onChange={() => {
              setRememberMe(prevState => !prevState)
            }}
            type={'checkbox'}
            checked={rememberMe}
            style={{
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              border: 'none',
              height: '16px',
              width: '16px',
              accentColor: COLORS.MAIN_COLOR
            }}/>
          <label onClick={() => {
            setRememberMe(prevState => !prevState)
          }}>
            Remember the card
          </label>
        </div>}

    </form>
  )
}

export default CardAttachmentForm
