import React, { useState } from 'react'
import ColoredText from '../text/ColoredText'
import { FontModeValues } from '../modes/FontMode'
import { COLORS, STATIC_TEXTS } from '../../util/Consts'
import { useSelector } from 'react-redux'
import { GetDeviceTypeInformation } from '../../features/DeviceTypeInformationSlice'
import LanguageVerification from '../VerifyModal/VerificationFormComponents/LanguageVerification'
import InformationBanner from './InformationBanner'
import { type EnumTypeDto, type TakerInterviewTypeDto } from '../../api/ic'

interface takerUrlsProps {
  editable?: boolean
  interviewLevelValues?: EnumTypeDto[]
  interviewTypeValues?: EnumTypeDto[]
  interviewsInfo?: TakerInterviewTypeDto[]
}

function InterviewSection (props: takerUrlsProps) {
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const [localList, setLocalList] = useState(props.interviewsInfo ?? [])
  return (
    <div
      style = {{
        padding: isMobile ? '25px' : '25px 50px 25px 50px',
        border: '2px solid #D9D9D9',
        borderRadius: '10px'
      }}>
      {!props.editable && <InformationBanner singleText={STATIC_TEXTS.READ_ONLY_SECTION_INFO}/>}
      <ColoredText
        singleText='Interviews'
        fontSize= {FontModeValues.EXTRA_LARGE}
        fontWeight={300}
        color = {COLORS.MAIN_COLOR}/>
      <div
        className= {`d-flex flex-column justify-content-${isMobile ? 'center' : 'between'} flex-wrap mt-3`}>
        {localList.map((item, index) => {
          return (
            <div key={index} className={'d-flex flex-row gap-2 '}>
              <div style={{ width: '100%' }}>
                <LanguageVerification
                  levelValues={props.interviewLevelValues}
                  typeValues={props.interviewTypeValues}
                  idx={index}
                  obj={item} // TODO understand this part
                  localList={localList}
                  editable={props.editable}
                  setLocalList={setLocalList}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InterviewSection
