import React, { useState } from 'react'
import StandardInput from '../components/input/StandardInput'
import StandardButton from '../components/buttons/StandardButton'
import MarginWrapper from '../components/util/MarginWrapper'
import AdminHeader from '../components/admin/AdminHeader'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import InterviewTypeImg from '../components/img/InterviewTypeImg'
import { type GetInterviewTypeDto, InterviewTypeControllerApi } from '../api/ic'
import ApiManager from '../manager/ApiManager'
import { ImageUploaderControllerApi } from '../api/ic/apis/ImageUploaderControllerApi'

function CreateEditInterviewTypePage () {
  const [displayName, setDisplayName] = useState('')
  const [type, setType] = useState('')
  const [description, setDescription] = useState('')
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [isEdit, setIsEdit] = useState(false)
  const [currObj, setCurrObj] = useState<GetInterviewTypeDto>()

  const interviewTypeApi = ApiManager.getInstance(InterviewTypeControllerApi)
  const imageUploaderApi = ApiManager.getInstance(ImageUploaderControllerApi)

  useState(() => {
    const { id } = useParams()
    if (id) {
      void interviewTypeApi.getInterviewType({ id })
        .then(res => {
          setCurrObj(res)
          setDisplayName(res.name)
          setType(res.type)
          setDescription(res.description)
          setIsEdit(true)
        }).catch(() => {})
    }
  })

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files ? e?.target?.files[0] : null
    setSelectedImage(file)
  }

  const edit = async () => {
    if (!displayName || !type || !description) {
      toast.error('Fill all information')
      return
    }

    if (!currObj) {
      toast.error('The interview type had not been loaded appropriate')
      return
    }
    await interviewTypeApi.updateInterviewType({
      interviewTypeUpdateDto: { id: currObj.id, name: displayName, description }
    })
      .then(async res => {
        toast.success(`Successfully edited interview type ${res.name} (${res.type})`)
        setCurrObj(res)

        if (selectedImage) {
          await imageUploaderApi.uploadImage({
            id: res.id,
            type: res.type,
            uploader: 'interview_type',
            fileParamName: 'fileParamName',
            file: selectedImage
          })
            .then((res: GetInterviewTypeDto) => {
              toast.success(`Successfully edited image ${res.name} (${res.type})`)
              setCurrObj(res)
            }).catch(() => {})
        }
      }).catch(() => {})
  }

  const create = async () => {
    if (!displayName || !type || !description) {
      toast.error('Fill all information')
      return
    }
    await interviewTypeApi.createInterviewType({
      interviewTypeCreationDto: {
        name: displayName,
        type,
        description,
        isAvailable: false
      }
    })
      .then(async res => {
        toast.success(`Successfully created interview type ${res.name} (${res.type})`)
        if (selectedImage) {
          await imageUploaderApi.uploadImage({
            id: res.id,
            type: res.type,
            uploader: 'interview_type',
            fileParamName: 'fileParamName',
            file: selectedImage
          }).then(res => {
            toast.success(`Successfully added image ${res.name} (${res.type})`)
          }).catch(() => {})
        }
      }).catch(() => {})
  }
  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <AdminHeader title={'Adding new interview type'}/>

      <MarginWrapper top={'40px'}>
        <StandardInput
          label={'Display Name'}
          inputValue={displayName}
          setInputValue={setDisplayName}
          placeHolder={'Java'}/>
        <StandardInput
          label={'Interview Type'}
          inputValue={type}
          disabled={isEdit}
          textColor={isEdit ? 'grey' : 'black'}
          setInputValue={setType}
          placeHolder={'JAVA_BASICS'}/>
        <StandardInput
          label={'Description'}
          inputValue={description}
          setInputValue={setDescription}
          placeHolder={'Java Language Basics'}/>
        <div className={'d-flex flex-row'} style={{ marginTop: '10px', marginBottom: '10px' }}>
          {currObj && <InterviewTypeImg mini={true} obj={currObj}/>}
          <input type="file" onChange={handleImageChange} />
        </div>
        <MarginWrapper top={'20px'}>
          <StandardButton onClick={async () => {
            if (isEdit) { await edit() } else { await create() }
          }}/>
        </MarginWrapper>
      </MarginWrapper>
    </div>
  )
}

export default CreateEditInterviewTypePage
