import React, { useState } from 'react'
import { BankCardControllerApi, type GetBankCardDto } from '../../api/ic'
import { maskCardNumber } from '../../util/Helper'
import default_selection_icon from '../svg/resources/default_selection.svg'
import bank_card_selected from '../svg/resources/bank_card_radio_selected.svg'
import bank_card_unselected from '../svg/resources/bank_card_radio_uunselected.svg'
import close_icon from '../svg/resources/close_x_icon.svg'
import ColoredText from '../text/ColoredText'
import { COLORS } from '../../util/Consts'
import CardTypeImg from './CardTypeImg'
import ApiManager from '../../manager/ApiManager'
import toast from 'react-hot-toast'
import { type SkeletonSupported, type SkeletonSupportedInterface } from '../../util/common-types'
import { Skeleton } from '@mui/material'
import StandardModal from '../modal/StandardModal'
import StandardButton from '../buttons/StandardButton'
import { WidthModeValues } from '../modes/WidthMode'
import { FontModeValues } from '../modes/FontMode'
import MarginWrapper from '../util/MarginWrapper'

interface AttachedCardInterface extends SkeletonSupportedInterface {
  card: GetBankCardDto
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>
  isSelected?: boolean
  index: number
  deletable: boolean
  makeDefaultAction?: boolean
  makeDefaultCallback?: (dto: GetBankCardDto) => void
  deleteCallback?: (dto: GetBankCardDto) => void
  selectable: boolean
}

function AttachedCard (props: SkeletonSupported<AttachedCardInterface>) {
  const [makingDefault, setMakingDefault] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const bankCardApi = ApiManager.getInstance(BankCardControllerApi)
  const { height, width } = { height: '40px', width: '100%' }

  if (props.loading) {
    return (<div>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={height}
        width={width}
        sx={{ borderRadius: '10px' }}/>
    </div>)
  }

  const handleDelete = () => {
    void bankCardApi.deleteBankCard({
      id: props.card?.id
    }).then((res) => {
      setIsDeleteModalOpen(false)
      toast.success('Successfully deleted')
      if (props.deleteCallback) {
        props.deleteCallback(res)
      }
    }).catch(() => {
      setIsDeleteModalOpen(false)
      toast.success('Could not delete the card')
    })
  }

  const handleMakeDefault = async () => {
    if (makingDefault) {
      return
    }

    setMakingDefault(true)
    await bankCardApi.makeDefault({
      id: props.card.id
    }).then((res) => {
      if (res) {
        if (props.makeDefaultCallback) {
          props.makeDefaultCallback(res)
        }
        toast.success('Successfully updated')
        setMakingDefault(false)
      }
    }).catch(() => {})
  }

  return (
    <div className={'d-flex flex-column justify-content-evenly px-2 py-1'}
      onClick={() => {
        if (props.selectable && props.setSelectedIndex) {
          props.setSelectedIndex(props.index)
        }
      }}
      style={{
        height,
        width,
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        cursor: props.selectable ? 'pointer' : 'auto',
        backgroundColor: '#F4F4F4'
      }}>
      <div
        className={'d-flex flex-row justify-content-between'}
        style={{
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
      >
        <div className={'d-flex gap-3 flex-row align-items-center'}>
          {props.selectable && <img
            src={props.isSelected ? bank_card_selected : bank_card_unselected}
            alt={'Selected'}
            style={{ height: '17px', width: '17px' }}/>}
          <div className={'d-flex gap-2 flex-row align-items-center'}>
            <span style={{ userSelect: 'none' }}>
              <CardTypeImg cardNumber={props.card.num}/>
            </span>
            <span style={{ userSelect: 'none' }}>
              {
              maskCardNumber(props.card.num)
            }
            </span>
          </div>
        </div>
        {props.card._default &&
          <div className={'d-flex flex-row gap-2 align-items-center'} style={{ cursor: 'auto' }}>
            <img
              src={default_selection_icon}
              style={{ height: '20px', width: '20px' }}
              alt='Default'/>
            <ColoredText
              singleText={'Default'}
              color={COLORS.MAIN_COLOR}
              userSelect={'none'} />
          </div>
        }
        {
          (!props.card._default && props.makeDefaultAction) && (
            <ColoredText
              singleText={'Make default'}
              color={makingDefault ? 'grey' : '#8F6BAB'}
              userSelect={'none'}
              cursor={'pointer'}
              onClick={handleMakeDefault}
              textDecoration={'underline'}/>
          )
        }
        { props.deletable &&
          <div
            style={{ cursor: 'pointer', userSelect: 'none' }}
            onClick={() => { setIsDeleteModalOpen(true) }}>
            <img src={close_icon} alt={'Close'} />
          </div>}
      </div>
      <StandardModal
        title={'Confirmation'}
        isOpen={isDeleteModalOpen}
        needDividers={false}
        footerComponent={
          <div
            className={'d-flex flex-row justify-content-between align-items-center'}
            style={{ width: '100%' }}>
            <ColoredText
              cursor={'pointer'}
              fontSize={FontModeValues.DEFAULT}
              singleText={'Cancel'}
              onClick={() => { setIsDeleteModalOpen(false) }}
              userSelect={'none'}/>
            <StandardButton
              widthMode={WidthModeValues.DOUBLE_EXTRA_SMALL}
              onClick={handleDelete}
              text={'Delete'}/>
          </div>
      }>
        <MarginWrapper top={'20px'}>
          <ColoredText singleText={'Are you sure you want to delete the card?'}/>
        </MarginWrapper>
      </StandardModal>
    </div>
  )
}

export default AttachedCard
