import React from 'react'
import { COLORS } from '../util/Consts'

interface RadioSelectionProps {
  options: string[]
  setSelection: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
}
const RadioSelection = (props: RadioSelectionProps) => {
  const handleOptionChange = (event: any) => {
    const value = event.target.value
    if (props.setSelection) {
      props.setSelection(value)
    }
  }

  return (
    <div className={'d-flex flex-column gap-2'}>
      {props.options.map(option => (
        <label key={option} className={'d-flex flex-row align-items-center'}>
          <input
            style={{ accentColor: COLORS.MAIN_COLOR }}
            type="radio"
            value={option}
            checked={props.selectedOption === option}
            onChange={handleOptionChange}
          />
          <span className={'ms-2'}>{option}</span>
        </label>
      ))}
    </div>
  )
}

export default RadioSelection
