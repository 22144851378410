import React from 'react'
import RadioSelection from './RadioSelection'
import StandardInput from '../components/input/StandardInput'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import { InputModeValues } from '../components/modes/InputModes'

interface InterviewCancellationRadioProps {
  setSelection: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
  options: string[]
  otherMessage: string
  setOtherMessage: React.Dispatch<React.SetStateAction<string>>
}

function InterviewCancellationRadio (props: InterviewCancellationRadioProps) {
  return (
    <span className={'d-flex flex-column gap-2'}
      style={{ height: '120px' }}>
      <RadioSelection setSelection={props.setSelection}
        selectedOption={props.selectedOption}
        options={props.options}/>
      {(props.selectedOption === 'Other') && <StandardInput
        type={StandardInputModeValues.TEXT_AREA}
        sizeMode={InputModeValues.AVERAGE_PERCENTAGE}
        inputValue={props.otherMessage}
        label={''} placeHolder={'Message'}
        setInputValue={props.setOtherMessage}/>}
    </span>
  )
}

export default InterviewCancellationRadio
