/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetICUserDto } from './GetICUserDto';
import {
    GetICUserDtoFromJSON,
    GetICUserDtoFromJSONTyped,
    GetICUserDtoToJSON,
} from './GetICUserDto';

/**
 * 
 * @export
 * @interface GetInterviewDto
 */
export interface GetInterviewDto {
    /**
     * Interview id
     * @type {string}
     * @memberof GetInterviewDto
     */
    id: string;
    /**
     * Preferred Languages
     * @type {Array<string>}
     * @memberof GetInterviewDto
     */
    languages?: Array<GetInterviewDtoLanguagesEnum>;
    /**
     * Accepted language
     * @type {string}
     * @memberof GetInterviewDto
     */
    acceptedLanguage?: GetInterviewDtoAcceptedLanguageEnum;
    /**
     * Interview level
     * @type {string}
     * @memberof GetInterviewDto
     */
    level: GetInterviewDtoLevelEnum;
    /**
     * Interview current status
     * @type {string}
     * @memberof GetInterviewDto
     */
    status: GetInterviewDtoStatusEnum;
    /**
     * Interview type
     * @type {string}
     * @memberof GetInterviewDto
     */
    type: string;
    /**
     * Interview type name
     * @type {string}
     * @memberof GetInterviewDto
     */
    name?: string;
    /**
     * Interview type description
     * @type {string}
     * @memberof GetInterviewDto
     */
    description?: string;
    /**
     * Requested dates
     * @type {Array<Date>}
     * @memberof GetInterviewDto
     */
    requestedDatesByGiver: Array<Date>;
    /**
     * Accepted date
     * @type {Date}
     * @memberof GetInterviewDto
     */
    acceptedDate?: Date;
    /**
     * 
     * @type {GetICUserDto}
     * @memberof GetInterviewDto
     */
    giver: GetICUserDto;
    /**
     * 
     * @type {GetICUserDto}
     * @memberof GetInterviewDto
     */
    taker?: GetICUserDto;
    /**
     * Balance type
     * @type {string}
     * @memberof GetInterviewDto
     */
    balanceType: GetInterviewDtoBalanceTypeEnum;
    /**
     * Is public or private
     * @type {boolean}
     * @memberof GetInterviewDto
     */
    isPublic: boolean;
}


/**
 * @export
 */
export const GetInterviewDtoLanguagesEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type GetInterviewDtoLanguagesEnum = typeof GetInterviewDtoLanguagesEnum[keyof typeof GetInterviewDtoLanguagesEnum];

/**
 * @export
 */
export const GetInterviewDtoAcceptedLanguageEnum = {
    Armenian: 'ARMENIAN',
    English: 'ENGLISH',
    Russian: 'RUSSIAN'
} as const;
export type GetInterviewDtoAcceptedLanguageEnum = typeof GetInterviewDtoAcceptedLanguageEnum[keyof typeof GetInterviewDtoAcceptedLanguageEnum];

/**
 * @export
 */
export const GetInterviewDtoLevelEnum = {
    Beginner: 'BEGINNER',
    Intermediate: 'INTERMEDIATE',
    Pro: 'PRO'
} as const;
export type GetInterviewDtoLevelEnum = typeof GetInterviewDtoLevelEnum[keyof typeof GetInterviewDtoLevelEnum];

/**
 * @export
 */
export const GetInterviewDtoStatusEnum = {
    Open: 'OPEN',
    Searching: 'SEARCHING',
    Canceled: 'CANCELED',
    Finished: 'FINISHED',
    Accepted: 'ACCEPTED',
    Expired: 'EXPIRED'
} as const;
export type GetInterviewDtoStatusEnum = typeof GetInterviewDtoStatusEnum[keyof typeof GetInterviewDtoStatusEnum];

/**
 * @export
 */
export const GetInterviewDtoBalanceTypeEnum = {
    WithRecording: 'INTERVIEW_WITH_RECORDING',
    WithoutRecording: 'INTERVIEW_WITHOUT_RECORDING'
} as const;
export type GetInterviewDtoBalanceTypeEnum = typeof GetInterviewDtoBalanceTypeEnum[keyof typeof GetInterviewDtoBalanceTypeEnum];


/**
 * Check if a given object implements the GetInterviewDto interface.
 */
export function instanceOfGetInterviewDto(value: object): value is GetInterviewDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('level' in value) || value['level'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('requestedDatesByGiver' in value) || value['requestedDatesByGiver'] === undefined) return false;
    if (!('giver' in value) || value['giver'] === undefined) return false;
    if (!('balanceType' in value) || value['balanceType'] === undefined) return false;
    if (!('isPublic' in value) || value['isPublic'] === undefined) return false;
    return true;
}

export function GetInterviewDtoFromJSON(json: any): GetInterviewDto {
    return GetInterviewDtoFromJSONTyped(json, false);
}

export function GetInterviewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'languages': json['languages'] == null ? undefined : json['languages'],
        'acceptedLanguage': json['acceptedLanguage'] == null ? undefined : json['acceptedLanguage'],
        'level': json['level'],
        'status': json['status'],
        'type': json['type'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'requestedDatesByGiver': json['requestedDatesByGiver'],
        'acceptedDate': json['acceptedDate'] == null ? undefined : (new Date(json['acceptedDate'])),
        'giver': GetICUserDtoFromJSON(json['giver']),
        'taker': json['taker'] == null ? undefined : GetICUserDtoFromJSON(json['taker']),
        'balanceType': json['balanceType'],
        'isPublic': json['isPublic'],
    };
}

export function GetInterviewDtoToJSON(value?: GetInterviewDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'languages': value['languages'],
        'acceptedLanguage': value['acceptedLanguage'],
        'level': value['level'],
        'status': value['status'],
        'type': value['type'],
        'name': value['name'],
        'description': value['description'],
        'requestedDatesByGiver': value['requestedDatesByGiver'],
        'acceptedDate': value['acceptedDate'] == null ? undefined : ((value['acceptedDate']).toISOString()),
        'giver': GetICUserDtoToJSON(value['giver']),
        'taker': GetICUserDtoToJSON(value['taker']),
        'balanceType': value['balanceType'],
        'isPublic': value['isPublic'],
    };
}

