import React, { useEffect, useState } from 'react'
import LineTextLine from '../components/util/LineTextLine'
import StandardInput from '../components/input/StandardInput'
import { COLORED_TEXT_VALUES, SINGLE_TEXT_VALUES } from '../util/Consts'
import ColoredText from '../components/text/ColoredText'
import StandardButton from '../components/buttons/StandardButton'
import ButtonWithSvg from '../components/buttons/ButtonWithSvg'
import google_icon from '../components/svg/resources/google_icon.svg'
import TextWithLeftArrow from '../components/text/TextWithLeftArrow'
import VerificationInput from '../components/input/VerificationInput'
import ResendCode from '../components/text/ResendCode'
import { useDispatch, useSelector } from 'react-redux'
import { GetInputValue, updateInputValue } from '../features/standartInputSlice'
import { GetPageValue, updatePageValue } from '../features/PageValueSlice'
import { SignupPage } from '../enumeration/SignupPage'
import Navigation from '../components/navigation/Navigation'
import { NavigationModeValues } from '../components/modes/NavigationMode'
import { ColorMode } from '../components/modes/ColorMode'
import MarginWrapper from '../components/util/MarginWrapper'
import CompanyNameFooter from '../components/util/CompanyNameFooter'
import { InputModeValues } from '../components/modes/InputModes'
import { ButtonModeValues } from '../components/modes/ButtonMode'
import { StandardInputModeValues } from '../components/modes/StandardInputMode'
import { AuthManager } from '../manager/AuthManager'
import {
  addSignupUserEmail,
  addSignupUserFirstName,
  addSignupUserLastName,
  addSignupUserPassword,
  clearSignupUser,
  getSignupUser
} from '../features/SignupUserSlice'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { useNavigate } from 'react-router-dom'
import ApiManager from '../manager/ApiManager'
import { ICExceptionDtoReasonEnum, type ResponseError, SignupControllerApi, type SignupDto } from '../api/ic'

function SignUpPage () {
  const [isMailLoading, setIsMailLoading] = useState(false)
  const [isVerificationLoading, setIsVerificationLoading] = useState(false)
  const [isPasswordLoading, setIsPasswordLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [triggerValidate, setTriggerValidate] = useState(false)
  const [isValidMail, setIsValidMail] = useState(false)
  const dispatch = useDispatch()

  const inputValue = useSelector(GetInputValue)
  const pageValue = useSelector(GetPageValue)
  const [confirmPassword, setConfirmPassword] = useState('')
  const signupUser: SignupDto = useSelector(getSignupUser)
  const navigate = useNavigate()
  const signupApi = ApiManager.getInstance(SignupControllerApi)

  const handlePasswordValueChange = (newValue: any) => {
    dispatch(addSignupUserPassword(newValue))
  }
  const handleConfirmValueChange = (newValue: any) => {
    setConfirmPassword(newValue)
  }
  const handleStandardInputChange = (newValue: any) => {
    dispatch(updateInputValue(newValue))
  }
  const handlePageValueChange = (newValue: any) => {
    return dispatch(updatePageValue(newValue))
  }
  const handleFirstNameValueChange = (newValue: any) => {
    return dispatch(addSignupUserFirstName(newValue))
  }
  const handleLastNameValueChange = (newValue: any) => {
    return dispatch(addSignupUserLastName(newValue))
  }

  const pageChangeLogic = () => {
    const pageValue = useSelector(GetPageValue)
    let signupPage: SignupPage
    if (pageValue === SignupPage.VERIFICATION) {
      signupPage = SignupPage.MAIL_SUBMIT
    } else if (pageValue === SignupPage.NAME_SURNAME) {
      signupPage = SignupPage.MAIL_SUBMIT
    } else if (pageValue === SignupPage.PASSWORD) {
      signupPage = SignupPage.NAME_SURNAME
    } else {
      signupPage = SignupPage.MAIL_SUBMIT
    }
    return signupPage
  }
  useEffect(() => {
    setIsLoading(isMailLoading || isVerificationLoading || isPasswordLoading)
  }, [isMailLoading,
    isVerificationLoading,
    isPasswordLoading
  ])

  useEffect(() => {
    const tokenBody = AuthManager.getAccessTokenBody()
    if (tokenBody && tokenBody.role === 'ROLE_TAKER') {
      // TODO make generic logic for all pages and roles
      navigate(NavigationRoute.HOME_PAGE)
    }
  })

  const signup = async () => {
    if (signupUser.password && signupUser.password === confirmPassword) {
      setIsPasswordLoading(true)

      await signupApi.signup({ signupDto: signupUser })
        .then(res => {
          setIsPasswordLoading(false)
          AuthManager.setTokens(res)
          dispatch(clearSignupUser())
          setConfirmPassword('')
          navigate(NavigationRoute.HOME_PAGE)
        }).catch(() => {})
    }
  }

  const continueWithEmail = async () => {
    setIsMailLoading(true)
    const sessionTokenBody = AuthManager.getAccessTokenBody()
    let deleted = false
    // TODO: strange
    if (sessionTokenBody && inputValue !== sessionTokenBody.email) {
      AuthManager.deleteAccessToken()
      deleted = true
    }
    dispatch(addSignupUserEmail(inputValue))

    if (!deleted && sessionTokenBody && sessionTokenBody.role === 'ROLE_PRE_SIGNUP') {
      handlePageValueChange(SignupPage.NAME_SURNAME)
      setIsMailLoading(false)
      return
    }
    await signupApi.prepare({
      signupRequestDto: { email: inputValue }
    }).then(() => {
      setIsMailLoading(false)
      handlePageValueChange(SignupPage.VERIFICATION)
    }).catch((err: ResponseError) => {
      setIsMailLoading(false)
      if (err.response.reason === ICExceptionDtoReasonEnum.EmailWasSentRecentlyException) {
        handlePageValueChange(SignupPage.VERIFICATION)
      }
    })
  }
  const pageClosed = () => {
    if (pageValue === SignupPage.MAIL_SUBMIT) {
      dispatch(updateInputValue(''))
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', pageClosed)
    return () => {
      window.removeEventListener('beforeunload', pageClosed)
    }
  }, [pageValue])

  const handleButtonClick = () => {
    alert('SIGN IN WITH GOOGLE') // TODO: replace with endpoint
  }

  return (
    <>
      <Navigation
        borderButtonMode={ColorMode.DARK}
        mobileBorderButtonMode={ColorMode.DARK}
        navigationMode={NavigationModeValues.FLOATING_WITH_BORDERED_BUTTON}/>
      <div
        className={'d-flex flex-column justify-content-center align-items-center mt-4'}>
        <TextWithLeftArrow
          page_to_be_moved={pageChangeLogic()}
          is_not_moved={pageValue === SignupPage.MAIL_SUBMIT}
          is_loading={isLoading}/>
        <div>
          {pageValue === SignupPage.MAIL_SUBMIT &&
            <div
              style={{ marginTop: '38px' }}
              className={'d-flex flex-column justify-content-center align-items-center'}>
              <StandardInput
                sizeMode={InputModeValues.LARGE}
                label={'Personal email'}
                placeHolder={'Enter your email address…'}
                inputValue={inputValue}
                type={StandardInputModeValues.EMAIL}
                backgroundColor={'rgba(246, 246, 246, 1)'}
                setInputValue={handleStandardInputChange}
                setTriggerValidate={setTriggerValidate}
                triggerValidate={triggerValidate}
                setIsValid={setIsValidMail}/>
              <MarginWrapper
                top={'20px'}>
                <StandardButton
                  sizeMode={ButtonModeValues.EXTRA_LARGE}
                  text={'Continue with email'}
                  isLoading={isMailLoading}
                  backgroundColorMode={'dark'}
                  textFontWeight={700}
                  setTriggerValidate={setTriggerValidate}
                  onClick={continueWithEmail}
                  isValid={isValidMail}
                />
              </MarginWrapper>
              <MarginWrapper
                top={'20px'}>
                <LineTextLine
                  text={'or'}/>
              </MarginWrapper>
              <MarginWrapper
                all={'20px'}>
                <ButtonWithSvg
                  onclick={handleButtonClick}
                  text={SINGLE_TEXT_VALUES.CONTINUE_WITH_GOOGLE}
                  svg={google_icon}
                  sizeMode={ButtonModeValues.EXTRA_LARGE}/>
              </MarginWrapper>
              <ColoredText
                texts={COLORED_TEXT_VALUES.ALREADY_MEMBER}
                centered={true}/>
            </div>}
          {pageValue === SignupPage.VERIFICATION &&
            <div className={'d-flex flex-column justify-content-center align-items-center'}>
              <MarginWrapper top={'53px'}>
                <ColoredText texts={SINGLE_TEXT_VALUES.VERIFY_MAIL}/>
                <ColoredText texts={COLORED_TEXT_VALUES.MAIL_EXAMPLE} parameters={[inputValue]}/>
                <MarginWrapper top={'10px'}>
                  <VerificationInput
                    isLoading={isVerificationLoading}
                    setVerificationLoading={setIsVerificationLoading}
                    email={inputValue}/>
                </MarginWrapper>
              </MarginWrapper>
              <MarginWrapper top={'24px'}>
                <ResendCode
                  email={inputValue}
                  pageValue={pageValue}/>
              </MarginWrapper>
            </div>}
          {pageValue === SignupPage.NAME_SURNAME &&
            <div className={'d-flex flex-column justify-content-center align-items-center'}>
              <MarginWrapper top={'42px'}>
                <StandardInput
                  type={StandardInputModeValues.TEXT}
                  sizeMode={InputModeValues.LARGE}
                  inputValue={signupUser.firstName ?? ''}
                  label={'First name'}
                  setInputValue={handleFirstNameValueChange}
                  placeHolder={'John'}/>
              </MarginWrapper>
              <MarginWrapper top={'20px'}>
                <StandardInput
                  type={StandardInputModeValues.TEXT}
                  sizeMode={InputModeValues.LARGE}
                  inputValue={signupUser.lastName ?? ''}
                  label={'Last name'}
                  setInputValue={handleLastNameValueChange}
                  placeHolder={'Smith'}/>
              </MarginWrapper>
              <MarginWrapper top={'20px'}>
                <StandardButton
                  sizeMode={ButtonModeValues.EXTRA_LARGE}
                  backgroundColorMode={'dark'}
                  text={'Next'}
                  textFontWeight={700}
                  onClick={() => { handlePageValueChange(SignupPage.PASSWORD) }}/>
              </MarginWrapper>
            </div>}
          {pageValue === SignupPage.PASSWORD &&
            <div className={'d-flex flex-column justify-content-center align-items-center'}>
              <MarginWrapper top={'42px'}>
                <StandardInput
                  sizeMode={InputModeValues.LARGE}
                  inputValue={signupUser?.password ?? ''}
                  label={'Password'}
                  showPassword={true}
                  setInputValue={handlePasswordValueChange}
                  placeHolder={'Abc123$'}
                  type={StandardInputModeValues.PASSWORD} />
              </MarginWrapper>
              <MarginWrapper top={'20px'}>
                <StandardInput
                  sizeMode={InputModeValues.LARGE}
                  inputValue={confirmPassword}
                  label={'Confirm Password'}
                  type={StandardInputModeValues.PASSWORD}
                  showPassword={true}
                  setInputValue={handleConfirmValueChange}
                  placeHolder={'Abc123$'}/>
              </MarginWrapper>
              <MarginWrapper top={'20px'}>
                <StandardButton
                  sizeMode={ButtonModeValues.EXTRA_LARGE}
                  clickable={!!(signupUser.password && signupUser.password === confirmPassword)}
                  backgroundColorMode={'dark'}
                  isLoading={isPasswordLoading}
                  text={'Confirm'}
                  textFontWeight={700}
                  onClick={signup}/>
              </MarginWrapper>
            </div>}
        </div>
        <MarginWrapper top={'30px'}>
          <ColoredText
            texts={COLORED_TEXT_VALUES.SECURITY_TEXT}
            centered={true}/>
        </MarginWrapper>
        <CompanyNameFooter/>
      </div>
    </>
  )
}

export default SignUpPage
